// DateField
//
// Shows an editable date text field as well as a date picker with calendar. Pass an ISO date string into 'date'. Receive new date str in on-update.
//
// Example usage:
//   <date-field date="Support.dateStr" on-update="Support.recordUpdated( value )"></date-field>

(function() {
    'use strict';

    angular
      .module('qms')
      .component('dateField', {
            templateUrl: 'ng1/components/dateField.component.html',
                // inputs and outputs go here
                // Inputs should use < and @ bindings. Use < for one-way bindings. Use @ for strings.
                // Outputs: Use & 
            bindings: {
                date: '<',            // The support incident's record
                onUpdate: '&'           // Output
            },
            controller: DateFieldController
    });

    function DateFieldController( $scope, QMSFactory, dialogs, qmsVocabulary, $timeout )
    {
        let vm = this;

        console.log("EditSupportController entered.");

    //    vm.date = new Date();
        vm.datePickerIsVisible = false;

        vm.$onChanges = function(changesObj)
        {   
            let keys = Object.keys(changesObj);
            for (let n=0; n < keys.length; n++)  // ngmin does not support for (let key of keys) style loops without --force to ignore a warning!
            {
                let key = keys[n];
                let simpleChange = changesObj[key];

                if (key == 'date')
                {
                    if ( simpleChange.currentValue && simpleChange.currentValue.length > 0)
                        vm.date = new Date(simpleChange.currentValue);
                    else
                        vm.date = ""; // new Date("");
                }

             //   console.log(" Set watched value: ", key, vm[key]);
            }
        };
        
            // Angular lifecycle hook. Called after bound values are ready.
        vm.$onInit = function()
        {
         //   vm.date = new Date();

        };

        vm.fieldChanged = function()
        {
                // Save instantly the first time, then delay for a second before (possibly) saving again.
            if (vm.timeout)
                $timeout.cancel(vm.timeout);
            else
                vm.notifyParentOfChange();

            vm.timeout = $timeout( vm.notifyParentOfChange, 1000);  // 1000 = 1 second
        };

        vm.notifyParentOfChange = function()
        {
            if (vm.date && typeof vm.date.getMonth !== 'undefined')
            {
                let dateStr = vm.date.toISOString();

            //    console.log("dateField emitting changed date: ", dateStr);
                vm.onUpdate( {value: dateStr} );    // TODO: Convert date obj to string
            }
            
            delete vm.timeout;
        };

        vm.openDatePicker = function($event)
        {
            $event.preventDefault();
            $event.stopPropagation();
            vm.datePickerIsVisible = !vm.datePickerIsVisible;
        };
    }

})();
