(function() {

    angular.module('qms').controller('SearchController',SearchController);

    function SearchController($scope, $http, $q, $routeParams, $location, $filter, QMSFactory, $sce) {
        var vm=this;
        vm.query = $routeParams["query"];
        // vm.searchresults = {
        //     tickets: [],
        //     customers: [],
        //     support: [],
        //     sales: [],
        // };

        vm.customer = {
            data: [],
            cols: [
                {field:'name', displayName:'Site', width:'15%'},
                {field:'city', displayName:'City', width:'15%'},
                {field:'state', displayName:'State', width:'10%'},

                {field:'contacts', displayName:' ', width: '5%', myType:'custom',
                    cellTemplate: "<div class=\"ngCellText colt{{$index}}\" align='center'>" +
                    "<button popover-template=\"'/partial/search/contacts_template.html'\" " +
                    "popover-title=\"Contacts\" popover-trigger=\"focus\" type=\"button\" class=\"btn btn-sm btn-default\" popover-append-to-body=\"true\"><i class=\"fa fa-address-card\"></i></button>" +
                    "</div>"},
                {field:'notes', displayName:'Notes', width:'45%'}
            ],
            openLink: "customer"
        };

        vm.license = {
            data: [],
            cols: [
                { field: 'prettyId', displayName: 'System ID', width: '25%' },
                { field: 'customer', displayName: 'Customer', width: '30%' },
                { field: 'computer', displayName: 'System Name', width: '15%' },
                { field: 'ref', displayName: 'Matching Ref/Order #', width: '25%' }
                // { field: 'computerNotes', displayName: 'System Notes' },
            ],
            openLink: 'license/history'
        };

        vm.working = false;
        vm.init = init;

        // vm.templateUrl=  '/partial/search/contacts_template.html';

        function init () {
            vm.working = true;
            QMSFactory.search(vm.query).success(function (data) {
                vm.customer.data = data.data.customers;
                if (data.data && data.data.licenses && data.data.licenses.forEach) {
                    data.data.licenses.forEach(function(v) {
                        var computer;
                        if (v.customer && v.customer.computers && v.customer.computers.find) {
                            computer = v.customer.computers.find(function(c) { return c.system_id === v.system_id; });
                        }
                        vm.license.data.push({
                            id: v.system_id,
                            prettyId: $filter('prettifySystemId')(v.system_id),
                            customer: (v.customer && v.customer.name) || '',
                            customerId: (v.customer && v.customer.id) || '',
                            computer: (computer && computer.name) || '',
                            computerNotes: (computer && computer.notes) || '',
                            ref: v.ref || ''
                        });
                    });
                }
                vm.working = false;
            });
        }

        vm.init();
    }

})();
