angular.module('qms').controller('SurveyEngagementController', function($q, $location, QMSFactory, Title) {
    'use strict';

    var vm = this;
    vm.stats = [];
    
    // initialization
    Title.setTitle('Survey Engagement');
    QMSFactory.getSurveyEngagementStats()
    .then(function(resp) {
        if (resp.status !== 200 || !resp.data || !resp.data.data) {
            return $q.reject('Backend Error');
        }
        vm.stats = resp.data.data.stats || [];
        if (vm.stats && vm.stats.forEach) {
            vm.stats.forEach(function(e) {
                e.percentage = 'N/A';
                if (e.sent > 0 && typeof e.responded === 'number') {
                    e.percentage = Math.round(e.responded / e.sent * 100.0);
                }
            });
        }
    });
});
