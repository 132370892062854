// Widget for viewing an AIMDoc changelist

(function() {
    'use strict';
    angular
        .module('qms')
        .controller('ViewCLController', ViewCLController);

		// AuthTokenFactory, QMSFactory, AutoSaveFactory, 
    function ViewCLController( AIMDocFactory, $location, $routeParams, dialogs, $window) {
        var vm = this;
        vm.init = init;
        vm.getAction = getAction;
        vm.getActionColor = getActionColor;
        vm.getDateFromTimestamp = getDateFromTimestamp;
        vm.titleText = "Loading...";
        vm.goToChangelists = goToChangelists;
        vm.getNewContents = getNewContents;
        vm.getPrevContents = getPrevContents;
        vm.openAIMDoc = openAIMDoc;
        vm.owner = "";

        function init()
        {     
            vm.clid = $routeParams.clID;
            AIMDocFactory.getChangelist( $routeParams.clID )
				.success( function(result) 
				{
					if (result.data !== null)
					{
						vm.cl = result.data;
						vm.owner = result.data.owner;
						console.log("getChangelist() successful: ", result);
						vm.titleText = "AIMDoc changelist for " + vm.cl.name + " " + vm.cl.suite + " (" + vm.cl.id + "): " + vm.cl.status;
					}
					else
						vm.titleText = "Changelist " + vm.clid + " does not exist.";
				})
				.error( function(error) 
				{
					vm.titleText = "Changelist " + vm.clid + " could not be loaded. See console log for error message from server.";
					console.log("getChangelist() HTTP ERROR: ", error);
				});
        }

        function goToChangelists()
        {
            $location.path("/aimdoc/listmine/");
        }

       	function openAIMDoc()
        {
            $location.path("/aimdoc/openbycl/" + vm.cl.id);
		}
        
        function getAction( item )
		{
			if (item.changeCode === "modify")
				return "<b style='color:red'>modify<b>";
			else
				return item.changeCode;
		}
		
		function getActionColor( item )
		{
			if (item.changeCode === "add")
				return "color:green";
			else if (item.changeCode === "modify")
				return "color:blue";
			else if (item.changeCode === "delete")
				return "color:red";
			else
				return "";
		}
		
		function getNewContents( item )
		{
			if (item.changeCode === "delete" )
			{
				if (item.tableType === "srs")
					return "<b>Description: </b>" + item.prevDataForUndo.description;
				else if (item.tableType === "vtp")
					return "<b>Description: </b>" + item.prevDataForUndo.description;
				else
					return "Oops!";
			}
			else
			{
				return item.fieldContents;
			}
		}

            // Changelist item example:
        /* changeCode: "modify"
            fieldContents: "Users shall be able to choose which statistical figure to use as the Y values in the plot among area and pixel value sum/max/min/average. MODIFIED BY VERN."
            fieldName: "description"
            id: "CSView.ANALYSIS.ROI_TIME_CURVE.STAT"
            modifiedDate: 1589828638
            modifiedUser: "vern"
            prevDataForUndo: "Users shall be able to choose which statistical figure to use as the Y values in the plot among area and pixel value sum/max/min/average."
            rowGUID: "srs7"
            tableType: "srs"
        */
		
		function getPrevContents( item )
		{
			if (item.changeCode === "delete" )
			{
				if (item.tableType === "srs")
					return "";
				else if (item.tableType === "vtp")
					return "<b>Expected Result:</b> " + item.prevDataForUndo.expected_result;
				else
					return "Oops!";
			}
			else
			{
				return item.prevDataForUndo;
			}
		}
        
		function getDateFromTimestamp( timestamp )
		{
				// For some reason we divide by 1000 when saving, so convert back
			var date = new Date(timestamp * 1000);
			return (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear();
		}

        vm.init();
    }

})();
