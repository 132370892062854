angular.module('qms').controller('ReconciledReportController', function($location, $routeParams, $filter, dialogs, uibDateParser, LicenseUtils, QMSFactory, qmsVocabulary, Title) {
    'use strict';

    var vm = this;
    vm.filters = {
        reconciledOpts: [
            { label: 'Yes', value: true }, { label: 'No', value: false }, { label: 'Any', value: null }
        ],
        reconciled: null,

        dateOpts: [],
        date: null,

        vendorOpts: [],
        vendor: null,

        typeOpts: [],
        type: null,
        abbreviatedView: false,      // Checkbox for Tech Transfer: show only licenses they care about

        // checks if a license event matches the current filters
        matches: function(event) {
            var d1, d2;
            if ((this.reconciled === true || this.reconciled === false) && event.reconciled !== this.reconciled) return false;
            if (this.vendor && this.vendor.length > 0 && !this.vendor.includes(event.vendor)) return false;
            if (this.type && this.type.length > 0 && event.licenses && event.licenses.findIndex) {
                if (event.licenses.findIndex(function(license) { return this.type.includes(license.type); }, this) < 0) return false;
            }
            if (this.date) {
                d1 = new Date(event.date);
                if (this.date.from) {
                    d2 = new Date(this.date.from + 'T00:00:00');
                    if (d1 < d2) return false;
                }
                if (this.date.to) {
                    d2 = new Date(this.date.to + 'T23:59:59');
                    if (d1 > d2) return false;
                }
            }
            return true;
        },
    };
    vm.histories = [];
    vm.loaded = false;

    Title.setTitle("Reconciled Report");

    // load report data from backend
    vm.load = function() {
        QMSFactory.licenseReconciledReport(vm.filters.reconciled, vm.filters.date.from, vm.filters.date.to, vm.filters.vendor, vm.filters.type)
        .success(function(data) {
            console.log("License reconciled report: ", data);
            console.log("Abbreviated view: ", vm.filters.abbreviatedView);
            vm.histories = [];
            vm.loaded = true;
            if (!data || !data.data || !data.data.histories || !data.data.histories.forEach) return;
            var histories = data.data.histories, matched = [];
            histories.forEach(function(doc) {
                doc.firstMatched = undefined;
                doc.otherMatched = [];
                // find events in this doc that match the filters
                var matchedEvents = (doc.events && doc.events.filter && doc.events.filter(vm.filters.matches, vm.filters)) || [];
                matchedEvents.forEach(function(event) {
                    event.date = new Date(event.date);
                    // group licenses in this event by qty and type
                    var groups = [], grouped = [];
                    event.licenses.forEach(function(license) {
                        if (!license.qty || !license.type) return; // ignore qty and type of undefined or 0
                        var group = groups.find(function(g) {
                            return g.qty === license.qty && g.type === license.type &&
                                (g.type !== qmsVocabulary.licenseDurationCustomized || g.duration === license.duration);
                        });
                        if (group) {
                            group.features.push(license.feature);
                        }
                        else {
                            group = { qty: license.qty, type: license.type, duration: license.duration, start: license.start, features: [license.feature] };
                            groups.push(group);
                        }
                    });
                    // sort the groups by type and then qty
                    groups.sort(function(a, b) { return a.type !== b.type ? a.type - b.type : b.qty - a.qty; });
                    // one line per group
                    groups.forEach(function(group) {
                        var features = [], featureList = '';
                        group.features.forEach(function(feature) {
                            var i = qmsVocabulary.licenseFeatures.findIndex(function(f) { return f.id === feature; });
                            if (i >= 0 && (!vm.filters.abbreviatedView || qmsVocabulary.licenseFeaturesMap[i].primary ) )
                                features.push(i);
                        });

                        if (features.length > 0) {
                            features.sort(function(a, b) { return a - b; });
                            features.forEach(function(i) { featureList += ', ' + qmsVocabulary.licenseFeatures[i].name; });
                            featureList = featureList.slice(2);
                            grouped.push({
                                qty: $filter('licenseQuantityLabel')(group.qty),
                                type: $filter('licenseDurationLabel')(group),
                                exp: $filter('licenseExpirationDate')(group),
                                features: featureList,
                            });
                        }
                    });
                    event.grouped = grouped;
                    // have a 1st event (earliest) in doc.firstMatched and the rest in doc.otherMatched
                    if (!doc.firstMatched) {
                        doc.firstMatched = event;
                    }
                    else if (event.date < doc.firstMatched.date) {
                        doc.otherMatched.push(doc.firstMatched);
                        doc.firstMatched = event;
                    }
                    else {
                        doc.otherMatched.push(event);
                    }
                    if (doc.system_id) {
                        doc.link = '#/license/history/' + encodeURIComponent(doc.system_id + (doc.sub_id ? ('#' + doc.sub_id) : ''));
                    }
                });
                if (doc.firstMatched) matched.push(doc);
            });
            // sort histories by dates of 1st events
            matched.sort(function(a, b) {
                return (a.firstMatched && b.firstMatched && (a.firstMatched.date.getTime() - b.firstMatched.date.getTime())) || 0;
            });
            vm.histories = matched;
        });
    };

    vm.refresh = function() {
        var search = { reconciled: vm.filters.reconciled === true ? '1' : (vm.filters.reconciled === false ? '0' : 'any') };
        search.fromDate = (vm.filters.date && vm.filters.date.from) || 'any';
        search.toDate = (vm.filters.date && vm.filters.date.to) || 'any';
        search.vendor = vm.filters.vendor && vm.filters.vendor.length > 0 ? vm.filters.vendor.join(',') : 'any';
        search.type = vm.filters.type && vm.filters.type.length > 0 ? vm.filters.type.join(',') : 'any';
        search.abbr = vm.filters.abbreviatedView;
        $location.search(search);
    };

    vm.dateChanged = function(oldFrom, oldTo) {
        if (vm.filters.date === null || vm.filters.date === undefined) {
            var data = { from: new Date(), to: new Date() }, opts = vm.filters.dateOpts;
            if (opts.length > 2 && !opts[opts.length - 2].label.startsWith('Year')) {
                data.from = uibDateParser.parse(opts[opts.length - 2].value.from, 'yyyy-MM-dd') || data.from;
                data.to = uibDateParser.parse(opts[opts.length - 2].value.to, 'yyyy-MM-dd') || data.to;
            }
            dialogs.create('/ng1/dialogs/license/customdates.html', 'ReconciledCustomDatesCtrl', data).result
            .then(function(data) {
                vm.filters.date = { from: printISODate(data.from), to: printISODate(data.to) };
                var opt = { label: (vm.filters.date.from || '') + ' to ' + (vm.filters.date.to || ''), value: vm.filters.date };
                vm.filters.dateOpts.splice(-1, 0, opt);
            }, function() {
                var o = vm.filters.dateOpts.find(function(v) { return v.value.from === oldFrom && v.value.to === oldTo; });
                if (o !== undefined) vm.filters.date = o.value;
            });
        }
    };

        // New (12/18/2020): Clicking the 'R'ed' column brings up a dialog box directly.
    vm.reconciledClicked = function(history, licenseEvent)
    {
        console.log("User clicked license event: ", licenseEvent);

            // The licenseEvent passed in does not contain all the information we need for the dialog. We must use this endpoint
            // and find the same event to get the full record.
        QMSFactory.loadLicenseHistory( history.system_id )
                .then(function(resp) {
                    if (resp.status === 200 && resp.data && resp.data.data) {

                        console.log(" loaded license history: ", resp.data.data);

                        let foundEvent = null;
                        let histories = resp.data.data.histories;
                        
                        if (histories)
                        {
                            LicenseUtils.groupLicenseHistories( histories );

                            for (let h=0; h < histories.length; h++)
                            {
                                let events = histories[h].events;
                                for (let n=0; n < events.length; n++)
                                {
                                    if (events[n]._id === licenseEvent._id)
                                        foundEvent = events[n];
                                }
                            }
                        }

                            // Match the license event the user clicked on with the appropriate one we just loaded. (The ones we loaded contain more info.)
                        if (foundEvent)
                        {
                            var licenseEventCopy = angular.copy(foundEvent);
                            dialogs.create('/ng1/dialogs/license/reconcile.html', 'LicenseReconcileCtrl', { event: licenseEventCopy }).result
                                .then(function(data) {

                                    // sanity check
                                    if (data.eventId === licenseEvent._id) {
                                        licenseEvent.reference = data.reference;
                                        licenseEvent.reconciled = data.reconciled;
                                        licenseEvent.note = data.note;
                                    }
                                    else
                                        console.log("reconciledClicked: WARNING: event ID and data eventID do not match!!", licenseEvent._id, data.eventId);
                                }, function() {
                                    console.log("User canceled reconciling a license event");
                                });
                        }
                        else
                        {
                            console.log("Could not find a matching license event for: ", licenseEvent);
                            console.log("Loaded histories: ", histories);
                        }
                    }
                });

    };

    // initialization

    // date options
    var today = new Date(), year = today.getFullYear(), i, l, opt;
    for (i = 0; i < 5; i++)
    {
        vm.filters.dateOpts.push({ label: 'Q1 ' + year, value: { from: year + '-01-01', to: year + '-03-31' } });
        vm.filters.dateOpts.push({ label: 'Q2 ' + year, value: { from: year + '-04-01', to: year + '-06-30' } });
        vm.filters.dateOpts.push({ label: 'Q3 ' + year, value: { from: year + '-07-01', to: year + '-09-30' } });
        vm.filters.dateOpts.push({ label: 'Q4 ' + year, value: { from: year + '-10-01', to: year + '-12-31' } });
        vm.filters.dateOpts.push({ label: 'Year ' + year, value: { from: year + '-01-01', to: year + '-12-31' } });
        year--;
    }
    vm.filters.dateOpts.push({ label: 'Custom dates...', value: null });
    // vendor options
    qmsVocabulary.licensePlatforms.forEach(function(platformName, platformId) {
        if (platformId === 0 || qmsVocabulary.platformHasSubvendors(platformId)) return;
        var ids = [platformId];
        var subvendorId = qmsVocabulary.platformToSubvendor.get(platformId);
        if (subvendorId !== undefined) ids.push(1000 + subvendorId);
        vm.filters.vendorOpts.push({ label: platformName, value: ids });
    });
    qmsVocabulary.licenseSubvendors.forEach(function(subvendorName, subvendorId) {
        var platformId = qmsVocabulary.subvendorToPlatform.get(subvendorId);
        if (platformId === undefined) vm.filters.vendorOpts.push({ label: subvendorName, value: [1000 + subvendorId]});
    });
    vm.filters.vendorOpts.sort(function(a, b) { return a.label.toUpperCase().localeCompare(b.label.toUpperCase()); });
    vm.filters.vendorOpts.unshift({ label: 'Any', value: [] });
    //  type options
    vm.filters.typeOpts.push({ label: 'Any', value: [] });
    vm.filters.typeOpts.push({ label: qmsVocabulary.licenseDurations.get(1).name, value: [1] });
    var paidTypes = [];
    // qmsVocabulary.licenseDurations.forEach(function(v, k) { /* jshint -W116 */ if (v.days == 0 || v.days >= 365) paidTypes.push(k); /* jshint +W116 */ });
    qmsVocabulary.licenseDurationsPaid.forEach(function(v) { this.push(v); }, paidTypes);
    paidTypes.push(qmsVocabulary.licenseDurationCustomized);
    vm.filters.typeOpts.push({ label: 'Any Paid', value: paidTypes });
    var tempTypes = [];
    // qmsVocabulary.licenseDurations.forEach(function(v, k) { if (v.days > 0 && v.days < 365) tempTypes.push(k); });
    qmsVocabulary.licenseDurationsFree.forEach(function(v) { this.push(v); }, tempTypes);
    vm.filters.typeOpts.push({ label: 'Any Temporary', value: tempTypes });
    qmsVocabulary.licenseDurations.forEach(function(v, k) {
        if (k === 1) return;
        vm.filters.typeOpts.push({ label: v.name, value: [k] });
    });
    vm.filters.typeOpts.push({ label: 'Custom', value: [qmsVocabulary.licenseDurationCustomized] });

    // defaults of filter choices
    vm.filters.reconciled = false;
    vm.filters.date = vm.filters.dateOpts[Math.floor(today.getMonth() / 3)].value;
    vm.filters.vendor = vm.filters.vendorOpts[0].value;
    vm.filters.type = vm.filters.typeOpts[1].value;
    // filters specified as search parameters?
    if ($routeParams.reconciled === '0') vm.filters.reconciled = false;
    else if ($routeParams.reconciled === '1') vm.filters.reconciled = true;
    else if ($routeParams.reconciled === 'any') vm.filters.reconciled = null;
    if ($routeParams.fromDate && /^[0-9]{4}-[01][0-9]-[0-3][0-9]$/.test($routeParams.fromDate)) {
        vm.filters.date = { from: $routeParams.fromDate, to: vm.filters.date.to };
    }
    else if ($routeParams.fromDate === 'any') {
        vm.filters.date = { from: null, to: vm.filters.date.to };
    }
    if ($routeParams.toDate && /^[0-9]{4}-[01][0-9]-[0-3][0-9]$/.test($routeParams.toDate)) {
        vm.filters.date = { from: vm.filters.date.from, to: $routeParams.toDate };
    }
    else if ($routeParams.toDate === 'any') {
        vm.filters.date = { from: vm.filters.date.from, to: null };
    }
    i = vm.filters.dateOpts.findIndex(function(v) { return v.value && v.value.from === this.from && v.value.to === this.to; }, vm.filters.date);
    if (i >= 0) {
        vm.filters.date = vm.filters.dateOpts[i].value;
    }
    else {
        opt = { label: (vm.filters.date.from || '') + ' to ' + (vm.filters.date.to || ''), value: vm.filters.date };
        vm.filters.dateOpts.splice(-1, 0, opt);
    }
    if ($routeParams.vendor) {
        if ($routeParams.vendor === 'any') vm.filters.vendor = [];
        else {
            l = $routeParams.vendor.split(',');
            vm.filters.vendor = [];
            l.forEach(function(v) { var id = parseInt(v, 10); if (!isNaN(id)) this.push(id); }, vm.filters.vendor);
        }
        i = vm.filters.vendorOpts.findIndex(function(v) {
            if (!v.value || v.value.length !== this.length) return false;
            for (var j = 0; j < this.length; j++) {
                if (!v.value.includes(this[j])) return false;
            }
            return true;
        }, vm.filters.vendor);
        if (i >= 0) vm.filters.vendor = vm.filters.vendorOpts[i].value;
    }
    if ($routeParams.type) {
        if ($routeParams.type === 'any') vm.filters.type = [];
        else {
            l = $routeParams.type.split(',');
            vm.filters.type = [];
            l.forEach(function(v) { var id = parseInt(v, 10); if (!isNaN(id)) this.push(id); }, vm.filters.type);
        }
        i = vm.filters.typeOpts.findIndex(function(v) {
            if (!v.value || v.value.length !== this.length) return false;
            for (var j = 0; j < this.length; j++) {
                if (!v.value.includes(this[j])) return false;
            }
            return true;
        }, vm.filters.type);
        if (i >= 0) vm.filters.type = vm.filters.typeOpts[i].value;
    }
    if ($routeParams.abbr)
        vm.filters.abbreviatedView = $routeParams.abbr;
    
    if ($routeParams.reconciled || $routeParams.fromDate || $routeParams.toDate || $routeParams.vendor || $routeParams.type) vm.load();
});
