
(function() {
    'use strict';
    angular
        .module('qms')
        .controller('UserManagerController',UserManagerController);

    function UserManagerController( dialogs, QMSFactory, AuthTokenFactory, Title ) {
        var vm=this;
        vm.init = init;

        vm.user = [
            {field:'username', displayName:'User name',
             cellClass: function(grid, row, col, rowRenderIndex, colRenderIndex) {
                if (row.entity.login_failures) {
                    if (row.entity.login_failures >= 5) return 'user-locked';
                    else if (row.entity.login_failures > 0) return 'user-dirty';
                }
             }},
            //{field:'password', displayName:'Password', myType: 'password', visible: false},
            {field:'fullname', displayName:'Full Name'},
            {field:'email', displayName:'Email'},
            {field:'lastlogin', displayName:'Last Login', myType: 'datetime', readOnly: true },
            {field:'createdby', displayName:'Created By', readOnly: true},
            {field:'vendor', displayName:'Vendor',
                myType: 'select', vals: ['ALL', 'Siemens', 'CVIT', 'Philips','Segami','Digirad']},
            {field:'groups', displayName:'Groups', myType: 'multi', vals: new AIMArray('groups'), cellTooltip: true},
            {field:'permissions', displayName:'Permissions',
                myType: 'multi', vals: ['CUSTOMER', 'QREGKEY', 'ADMIN', 'TICKETS','JOBS', 'AIMDOC','BETA'], cellTooltip: true},
            {field:'login_failures', displayName:'Login Failures', visible: false}
        ];

        vm.users = {
            data: [],
            cols: vm.user,
            onSelect: selectedRow,
            dialogs: [
                {
                    type : 'edit',
                    fields: vm.user,
                    template: "dialogs/user/user.html",
                    controller: "UserEditController"
                },
                {
                    type : 'delete'
                }
            ]
        };
        vm.newUser = newUser;
        vm.editUser = editUser;
        vm.deleteUser = deleteUser;
        vm.canChangePassword = canChangePassword;
        vm.unlockUser = unlockUser;
        vm.isAdmin = false;
        vm.changePassword = changePassword;
        vm.selectedIndex = -1;
        vm.selectedIsLocked = false;

        vm.record = {};

        function findIndex(id) {
            for (var i=0;i<vm.users.data.length;i++) {
                if (vm.users.data[i].id === id) {
                    return i;
                }
            }
            return -1;
        }

        function selectedRow(row) {
            if (row.isSelected) {
                vm.selectedIndex = findIndex(row.entity.id);
                vm.selectedIsLocked = row.entity.login_failures && row.entity.login_failures >= 5;
            } else {
                vm.selectedIndex = -1;
                vm.selectedIsLocked = false;
            }
        }

            // Called by New and Edit User
        function doUser(record) {
            var dlg = dialogs.create('/ng1/dialogs/user/user.html', 'UserEditController as user', {config: vm.user, record: record} );
            dlg.result.then(function (data) {
                if (typeof data === 'undefined') {
                    console.log("User Canceled");
                }
                vm.init();
            });
        }

        function newUser() {
            doUser({});
        }
        function editUser() {
            doUser(vm.users.data[vm.selectedIndex]);
        }
        function deleteUser() {

            var dlg = dialogs.confirm("Confirm Delete","Permanently Remove user '"+ vm.users.data[vm.selectedIndex].username+"'?");
            dlg.result.then(function(btn) {
                console.log('You confirmed "Yes."');
            },function(btn){
                console.log('Cancelled Delete');
            });
        }
        function canChangePassword() {
            if (vm.selectedIndex < 0 || vm.selectedIndex >= vm.users.data.length) return false;
            let u0 = vm.users.data[vm.selectedIndex];
            let u1 = AuthTokenFactory.getUser();
            return u0 && u1 && (u0.id === u1.id || u1.permissions.includes('ADMIN'));
        }
        function setPassword(record) {
            console.log("password");
            console.log(record);

            var dlg = dialogs.create('/ng1/dialogs/password/password.html', 'PasswordController as Password', {id:record.id, requirePass:false});
            dlg.result.then(function (data) {
                if (typeof data === 'undefined') {
                    console.log("User Canceled");
                } else {
                    vm.init();
                }
            });
        }
        function changePassword() {
            setPassword(vm.users.data[vm.selectedIndex]);
        }
        function unlockUser() {
            if (vm.selectedIndex < 0 || vm.selectedIndex >= vm.users.data.length) return false;
            let u = vm.users.data[vm.selectedIndex];
            if (u && u.id) {
                QMSFactory.unlockUser(u.id).success( function(response) {
                    if (response && response.data && response.data.worked) init();
                });
            }
        }

        function init() {
            Title.setTitle("User Manager");
            let u = AuthTokenFactory.getUser();
            vm.isAdmin = u.permissions.includes('ADMIN');
            QMSFactory.loadUsers().success( function(result) {
                vm.users.data = result.data;
                // PHP MongoDB Date object => JavaScript Date
                vm.users.data.forEach(function(u) {
                    if (u.lastlogin && u.lastlogin.$date && u.lastlogin.$date.$numberLong) u.lastlogin = new Date(parseInt(u.lastlogin.$date.$numberLong)); 
                });
                console.log("Loaded users: ", vm.users.data);
                $(window).resize();
            });
        }

        vm.init();
    }

})();
