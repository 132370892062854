angular.module('qms').controller('GenerateCouponController', function($q, $routeParams, $window, dialogs, QMSFactory, AuthTokenFactory, qmsVocabulary, $http, Title) {
    'use strict';

    var vm = this;

    // system info
    vm.system = {
        id: '',                 // ID of the system to issue licenses to (prettified after looking up in backend)
        normalizedId: '',       // normalized (no dashes, all uppercase) System ID
        suiteNum: '',           // Suite # from suffix of System ID
        details: undefined,     // details of the System ID after decoding by backend
        platform: -1,           // Platform ID embedded in the System ID
        reset: function() { this.id = this.normalizedId = this.suiteNum = ''; delete this.details; this.platform = -1; },
    };

    // 0: entering system ID, 1: invalid system ID, 2: choose features, 3: requesting coupon, 4: coupon retrieved
    vm.step = 0;

    // UI properties (button text, CSS class, component state)
    vm.ui = {
        systemLookUp: {             // system look up button
            button: 'Next',
            status: '',
            statusClass: '',
            reset: function() { this.button = 'Next'; this.status = ''; this.statusClass = ''; },
            setWorking: function() { this.status = 'Working on it...'; this.statusClass = ''; },
            setValid: function() { this.button = 'Reset'; this.status = 'Valid System ID'; this.statusClass = ''; },
            setInvalid: function() { this.button = 'Next'; this.status = 'Invalid System ID'; this.statusClass = 'has-error'; },
            setError: function() { this.status = 'Unable to communicate with the server. Please try again'; this.statusClass = 'has-error'; },
        },
        submitButton: 'Generate',   // coupon request submit button
        detailsCollapsed: true,     // system detail collapse-able
        globalQuantity: 1,          // choice of across-the-board license quantity
        globalType: 1,              // choice of across-the-board license duration type
        globalPackage: null,        // choice of pre-defined license packages
        reset: function() {
            this.systemLookUp.reset(); this.submitButton = 'Generate'; this.detailsCollapsed = true; this.globalQuantity = 1; this.globalType = 1;
        },
    };

    // license to request a coupon for
    vm.license = {
        issued: undefined,          // coupon JSON issued by backend
        codeIssued: undefined,      // coupon code issued by backend (for testing)
        request: {                  // coupon request to send to backend
            vendor: null,
            reference: '',
            note: '',
            features: [],
        },
        vendors: [],                // list of vendors (depending on platform)
        durations: [],              // list of duration types (don't change after initialization)
        quantities: [],             // list of quantity options (don't change after initialization)
        packages: [],               // list of common packages (don't change after initialization)
        features: [],               // list of features and their states
        noFeatureSelected: true,
        resetFeatures: function() {
            this.features = qmsVocabulary.licenseFeaturesCopy();
            this.features.forEach(function(v) {
                v.selected = false;
                v.qty = 1;
                v.type = 1;
            }, this);
        },
        reset: function() {
            delete this.issued;
            this.vendors = [];
            var r = this.request; r.vendor = null; r.reference = ''; r.note = ''; r.features = [];
            this.resetFeatures();
        },
        initVendors: function(platform) {
            this.vendors = [];
            if (qmsVocabulary.licensePlatforms.has(platform)) {
                if (qmsVocabulary.platformHasSubvendors(platform)) {
                    // have a place-holder option of invalid platform on top to force manual selection
                    this.vendors.push({ id: -1, name: 'Please select one:' });
                    qmsVocabulary.licenseSubvendors.forEach(function(v, k) {
                        this.vendors.push({ id: 1000 + k, name: v });
                    }, this);
                }
                else {
                    this.vendors.push({ id: platform, name: qmsVocabulary.licensePlatforms.get(platform) });
                }
                this.request.vendor = this.vendors[0].id;
            }
        },
    };

    // user currently logged in
    vm.user = undefined;

    // full reset of the whole page
    vm.reset = function() {
        vm.step = 0;
        vm.system.reset();
        vm.ui.reset();
        // vm.license.reset();
    };

    // validate the specified System ID
    vm.findSystem = function() {
        // reset?
        if (vm.step >= 2) {
            vm.reset();
            return;
        }
        // otherwise, query for the system
        vm.ui.systemLookUp.setWorking();
        QMSFactory.loadCurrentLicense(vm.system.id, null, false)
        .then(function(resp) {
            if (resp.status !== 200 || !resp.data || !resp.data.data) return $q.reject(new Error('Backend error'));
            var data = resp.data.data/* , i, temp */;
            vm.system.platform = (data.platform || data.platform === 0) ? data.platform : -1;
            vm.system.details = data.details;
            // show system ID prettified by the backend
            vm.system.id = data.prettyId || data.systemId || vm.system.id;
            vm.system.normalizedId = data.systemId;
            vm.system.suiteNum = data.suiteNum || '';
            // valid ID?
            if (qmsVocabulary.licensePlatforms.has(vm.system.platform)) {
                vm.step = 2;
                vm.ui.systemLookUp.setValid();
                vm.license.initVendors(vm.system.platform);
            }
            // invalid ID
            else {
                vm.step = 1;
                vm.ui.systemLookUp.setInvalid();
            }
        })
        .catch(function(error) {
            console.log(error);
            vm.ui.systemLookUp.setError();
            dialogs.error('Key Generator Error', 'Unable to communicate with the server. Please try again.');
        });
    };

    vm.updateNoFeatureSelected = function() {
        vm.license.noFeatureSelected = vm.license.features.every(function(v) { return !v.selected; });
    };

    vm.selectAllFeatures = function() {
        vm.license.features.forEach(function(v) { v.selected = true; });
        vm.updateNoFeatureSelected();
    };

    vm.selectNoFeatures = function() {
        vm.license.features.forEach(function(v) { v.selected = false; });
        vm.updateNoFeatureSelected();
    };

    vm.setGlobalQuantity = function() {
        var qty = vm.ui.globalQuantity;
        vm.license.features.forEach(function(v) {
            v.qty = qty;
            // certain features default to 99 floating if floating at all
            if (v.qty > 1 && qmsVocabulary.isLicenseFeatureUnlimitedFloating(v.id)) v.qty = 100;
        });
    };

    vm.setGlobalDuration = function() {
        var type = vm.ui.globalType;
        vm.license.features.forEach(function(v) { v.type = type; });
    };

    vm.setGlobalPackage = function() {
        var features = vm.ui.globalPackage;
        if (features && features.has) {
            vm.license.features.forEach(function(v) {
                v.selected = features.has(v.id);
                // if (v.qty <= 0) v.qty = vm.ui.globalQuantity;
                // if (v.type <= 0) v.type = vm.ui.globalType;
            });
            vm.updateNoFeatureSelected();
        }
    };

    vm.submitRequest = function() {
        var req;
        if (vm.step === 4) {
            vm.downloadCoupon();
        }
        else if (vm.step === 2) {
            // check for invalid vendor selection
            if (vm.license.request.vendor === undefined || vm.license.request.vendor < 0) {
                dialogs.error('Invalid Vendor', 'You have not selected a vendor.');
                return;
            }

            vm.step = 3;
            vm.ui.submitButton = 'Generating...';

            // prepare the coupon request
            req = angular.copy(vm.license.request);
            req.systemId = vm.system.normalizedId;
            if (vm.system.suiteNum) req.systemId += '-' + vm.system.suiteNum;
            req.features = [];
            vm.license.features.forEach(function(v) {
                if (v.selected) req.features.push({ feature: v.id, qty: v.qty, type: v.type });
            });
            // submit the coupon request
            QMSFactory.requestCoupon(req)
            .then(function(resp) {
                if (resp.status !== 200 || !resp.data || !resp.data.data)
                    return $q.reject(new Error('Backend error'));
                if (!resp.data.data.worked)
                    return $q.reject(resp.data.data);
                vm.license.issued = resp.data.data.json;
                vm.license.codeIssued = resp.data.data.code;
                vm.step = 4;
                vm.ui.submitButton = 'Download';
            })
            .catch(function(error) {
                console.log(error);
                vm.step = 2;
                vm.ui.submitButton = 'Try Again';
                if (error.worked === false && error.error) {
                    dialogs.error('Coupon Generator Error', error.error);
                }
                else {
                    dialogs.error('Coupon Generator Error', 'Unable to communicate with the server. Please try again.');
                }
            });
        }
    };

    vm.downloadCoupon = function() {
        if (vm.step !== 4 || !vm.license.issued) return;
        // generate license file content
        var element;
        element = $window.document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(vm.license.issued));
        element.setAttribute('download', 'coupon-' + vm.system.id + '.json');
        element.style.display = 'none';
        $window.document.body.appendChild(element);
        element.click();
        $window.document.body.removeChild(element);
    };

    // for testing
    vm.testRedeem = function() {
        if (vm.step !== 4 || !vm.license.codeIssued) return;
        $http.get('/api/vendorapi/license/coupon/redeem/' + encodeURIComponent(vm.license.codeIssued))
        .success(function(data) {
            console.log(data);
        });
    };

    //
    // initialization
    //

    // initialize vm.license.durations
    qmsVocabulary.licenseDurations.forEach(function(v, k) {
        var name = v.name;
        if (v.days > 0) name += ' (' + v.days + ')';
        vm.license.durations.push({ type: k, name: name });
    });
    // initialize vm.license.quantities
    angular.copy(qmsVocabulary.licenseQuantityOptions, vm.license.quantities);
    vm.license.reset();
    vm.user = AuthTokenFactory.getUser();
    // initialize vm.license.packages
    vm.license.packages.push({ name: 'Package:', features: null });
    qmsVocabulary.licensePackages.forEach(function(v, k) {
        vm.license.packages.push({ name: k, features: v });
    });

    // System ID given in the path?
    if ($routeParams.systemID) {
        vm.system.id = $routeParams.systemID;
        vm.findSystem();
    }

    Title.setTitle("Generate Coupon");
});