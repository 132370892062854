(function() {
    'use strict';
    angular.module('qms')
        .directive('csEZForm',csEZForm)
        .directive('ngBindModel', ngBindModel);

    function ngBindModel($compile) {
        return{
            link:function(scope,element,attr){
                element[0].removeAttribute('ng-bind-model');
                element[0].setAttribute('ng-model',attr.ngBindModel);
                $compile(element[0])(scope);
            }
        };
    }

    function csEZForm() {
        return {
            restrict: 'C',
            scope: {
                record: '=',
                config: '='
            },
            templateUrl: '/ng1/templates/cs_ezform.html',
            link: ListLink,
            controller: ['$scope', ListController],
            controllerAs: 'ezForm'
        };
        function ListLink(scope, element, attrs) {
        }

        function ListController($scope) {
        }
    }

})();