
(function() {
	'use strict';
	angular
		.module('qms')
		.controller('AimDocConfirmActionController',AimDocConfirmActionController);
	
	function AimDocConfirmActionController( $modalInstance, $rootScope, data )
	{
		var vm=this;
		vm.init = init;
		vm.cancel = cancel;
		vm.confirm = confirm;
		//   vm.watch= $rootScope.watch;
		vm.okButtonName = data.okButtonName;
		vm.title = data.title;
		vm.message = data.message;
		
		function init() {
		
		}
		
		function confirm() {
			console.log("Confirm() for Confirm Action dialog selected!");
			$modalInstance.close(true);	// Pass back data to user
		}
		
		function cancel() {
			console.log("cancel() for Confirm Action dialog selected!");
			$modalInstance.close();	// We need to call the user callback so that it can set no dialog as being open
		}
				
		vm.init();
	}

})();
