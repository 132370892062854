(function() {
    'use strict';
    angular.module('qms')
        .controller('UserEditController',UserEditController);

    function UserEditController(QMSFactory, $modalInstance, data) {
        var vm=this;
        vm.config = data.config;
        vm.record = data.record;
        vm.errorMsg = "";

        vm.save = save;
        vm.close = closeUserEditController;

        console.log("Edit user dialog called for: ", data);

        function save() {
            console.log("Attempting to save updated user: ", vm.record);

            QMSFactory.saveUser(vm.record).success(function(data)
            {
                console.log("Save user result: ", data);

                if (!data.data.worked)
                {
                    vm.errorMsg = "Server error: " + data.data.error;
                    console.log("Response server: ", data);
                }
                else
                    $modalInstance.close(data.data);
            });
        }

        function closeUserEditController() {
            $modalInstance.close();
        }

    }

})();
