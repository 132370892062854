
(function() {
    'use strict';
    angular
        .module('qms')
        .controller('PrefsController',PrefsController);

    function PrefsController( AuthTokenFactory, QMSFactory, AutoSaveFactory, $window, Title) {
        var vm=this;
        vm.init = init;
        vm.save = save;
        vm.record = {};
        vm.user = {};

        vm.about = [
            {field:'username', displayName:'Username', enabled: false},
            {field:'fullname', displayName:'Full name', enabled: false},
            //{field:'lastlogin', displayName:'Last Login', myType: "datetime", enabled: false},
            {field:'email', displayName:'Email Address', enabled: false},
            {field:'vendor', displayName:'Vendor', enabled: false},
            {field:'createdby', displayName:'Created By', enabled: false},
        ];

        vm.general = [
            {field:'tagline', displayName:'Status Message'},
            {field:'theme', displayName:'Theme',
                myType: 'select', vals: ["cerulean", "cosmo", "cyborg", "darkly", "flatly", "journal", "lumen", "paper", "readable", "sandstone", "simplex", "slate", "spacelab", "superhero", "united", "yeti"] }
        ];

        vm.notifications = [
            {field:'email_assigned', displayName:"Send me an email immediately when I'm assigned something", myType: 'bool'},
            {field:'email_daily', displayName:'Send me a summary e-mail every day', myType: 'bool'}
        ];

        vm.sample = {
            data: [
                {col1: "The", col2: "Quick", col3: "Brown", col4: "Fox", col5: "Jumps"},
                {col1: "Over", col2: "The", col3: "Lazy", col4: "Dog", col5: "123456"},
                {col1: "The", col2: "Quick", col3: "Brown", col4: "Fox", col5: "Jumps"},
                {col1: "Over", col2: "The", col3: "Lazy", col4: "Dog", col5: "123456"}
            ],
            cols: [
                {field:'col1', displayName:'Column 1'},
                {field:'col2', displayName:'Column 2'},
                {field:'col3', displayName:'Column 3'},
                {field:'col4', displayName:'Column 4'},
                {field:'col5', displayName:'Column 5'}
                ]
        };

        function init() {        
            vm.user = AuthTokenFactory.getUser();
            Title.setTitle("Prefs");

			if (typeof vm.user.prefs !== 'undefined') {
                console.log("has prefs");
				vm.record = vm.user.prefs;
			} else {
				vm.record = {};
			}
            vm.autoSave = new AutoSaveFactory(vm.record, vm.save );
        }

        function save() {
            return QMSFactory.savePrefs(vm.record);
        }

        vm.init();
    }

})();