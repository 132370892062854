angular.module('qms').controller('VendorLoginManagerController', function($location, $routeParams, $q, dialogs, QMSFactory, qmsVocabulary, Title) {
    'use strict';

    var vm = this, temp, i;
    vm.filters = {
        vendorOpts: [],     // options of specific vendors
        vendor: null        // array of hybrid vendor IDs of the selected vendor
    };
    vm.vendors = [];
    vm.newLogin = {
        vendorIndex: -1,  // index to vendors array of the vendor to which a new login is being added
        login: { email: '', fullname: '', note: '', password: false },
        reset: function() {
            this.vendorIndex = -1;
            this.login = { email: '', fullname: '', note: '', password: false };
        },
    };
    vm.ui = {
        requestPermanentOpts: [{ label: 'Yes', value: true }, { label: 'No', value: false }],
        issueTempOpts: [{ label: 'Yes', value: true }, { label: 'No', value: false }],
        issueSupportOpts: [{ label: 'Yes', value: true }, { label: 'No', value: false }],
    };

    Title.setTitle("Vendor Logins");

    // loads vendor list
    vm.load = function() {
        var id = (vm.filters.vendor && vm.filters.vendor[0]) || 'any';
        QMSFactory.loadVendorByPlatform(id).success(function(result) {
            var temp = [];
            if (result.data && result.data.forEach) {
                result.data.forEach(function(vendor) {
                    if (vendor.logins && vendor.logins.forEach) {
                        vendor.logins.forEach(function(v) {
                            if (v.issue_temp === undefined) v.issue_temp = false;
                            if (v.issue_support === undefined) v.issue_support = false;
                         });
                    }
                    if (vendor.platforms && vendor.platforms[0] && qmsVocabulary.isPlatformActive(vendor.platforms[0]) &&
                        !qmsVocabulary.isLicensePlatformInternal(vendor.platforms[0])) {
                        temp.push(vendor);
                    }
                });
                temp.sort(function(a, b) { return a.name.localeCompare(b.name); });
            }
            vm.vendors = temp;
        });
    };

    // refreshes vendor list (by changing the search query of $location)
    vm.refresh = function() {
        var search = { id: (vm.filters.vendor && vm.filters.vendor[0]) || 'any' };
        $location.search(search);
    };

    // saves a vendor document
    vm.save = function(index) {
        var vendor = vm.vendors[index];
        if (vendor && vendor._id && vendor.id) {
            QMSFactory.saveVendor(vendor).then(function(resp) {
                if (resp.status !== 200 || !resp.data || !resp.data.data || !resp.data.data.id || resp.data.data.id !== vendor.id) {
                    if (resp.data && resp.data.data && resp.data.data.error) console.log(resp.data.data.error);
                    return $q.reject('Backend Error');
                }
            }).catch(function(e) {
                console.log(e || 'Backend Error');
                //$window.alert('Unable to save the changes. Please try again');
            });
        }
    };

    // saves a vendor document with regards to changes to a specific login of the vendor
    vm.saveLogin = function(vendorIndex, loginIndex) {
        var vendor = vm.vendors[vendorIndex], login, promise;
        if (vendor && vendor._id && vendor.id) {
            // prompt for password if necessary
            login = vendor.logins && vendor.logins[loginIndex];
            if (!login) return;
            if (!login.email) {
                dialogs.error('Missing Email Address', 'You must enter an email address.');
                return 'Missing Email Address';
            }
            if (!login.password) {
                promise = dialogs.create('/ng1/dialogs/vendor/password.html', 'VendorPasswordCtrl', { username: login.email }).result
                .then(function(password) {
                    login.password = { plaintext: password };
                    return $q.resolve();
                }, function() {
                    console.log('User canceled setting vendor password');
                    return $q.reject('Canceled');
                });
            }
            else {
                promise = $q.resolve();
            }
            promise.then(function() {
                return QMSFactory.saveVendor(vendor).then(function(resp) {
                    if (login === vm.newLogin.login) vm.newLogin.reset();
                    if (resp.status !== 200 || !resp.data || !resp.data.data || !resp.data.data.id || resp.data.data.id !== vendor.id) {
                        if (resp.data && resp.data.data && resp.data.data.error) {
                            console.log(resp.data.data.error);
                            if (resp.data.data.error.startsWith && resp.data.data.error.startsWith('Duplicate vendor login email:')) {
                                dialogs.error('Duplicate Email Address', resp.data.data.error);
                            }
                        }
                        return $q.reject('Backend Error');
                    }
                    login.password = true;
                    return $q.resolve();
                });
            }).catch(function(e) {
                console.log(e || 'Backend Error');
                //$window.alert('Unable to save the changes. Please try again');
                vm.load();
            });
        }
    };

    // sets the password of a specific login of a vendor
    vm.setPassword = function(vendorIndex, loginIndex) {
        var vendor = vm.vendors[vendorIndex], login, promise;
        if (vendor && vendor._id && vendor.id) {
            login = vendor.logins && vendor.logins[loginIndex];
            if (login) {
                promise = dialogs.create('/ng1/dialogs/vendor/password.html', 'VendorPasswordCtrl', { username: login.email }).result
                .then(function(password) {
                    login.password = { plaintext: password };
                    return $q.resolve();
                }, function() {
                    console.log('User canceled setting vendor password');
                    return $q.reject('Canceled');
                });
            }
            promise.then(function() {
                return QMSFactory.saveVendor(vendor).then(function(resp) {
                    if (resp.status !== 200 || !resp.data || !resp.data.data || !resp.data.data.id || resp.data.data.id !== vendor.id) {
                        if (resp.data && resp.data.data && resp.data.data.error) console.log(resp.data.data.error);
                        return $q.reject('Backend Error');
                    }
                    if (login === vm.newLogin.login) vm.newLogin.reset();
                    login.password = true;
                    return $q.resolve();
                });
            }).catch(function(e) {
                console.log(e || 'Server Error');
                //$window.alert('Unable to save the changes. Please try again');
            });
        }
    };

    // unlock a specific vendor login
    vm.unlock = function(vendorIndex, loginIndex) {
        let vendor = vm.vendors[vendorIndex];
        if (vendor && vendor._id && vendor.id) {
            let login = vendor.logins && vendor.logins[loginIndex];
            if (login && login.email) {
                QMSFactory.resetVendorLockout(vendor.id, login.email).then(function(resp) {
                    if (resp.status !== 200 || !resp.data || !resp.data.data || !resp.data.data.id || resp.data.data.id !== vendor.id) {
                        if (resp.data && resp.data.data && resp.data.data.error) {
                            console.log(resp.data.data.error);
                        }
                    }
                    vm.load();
                });
            }
        }
    };

    // formats the list of Platform IDs of a vendor
    vm.formatPlatformList = function(platforms) {
        var l = [];
        if (platforms && platforms.forEach) {
            platforms.forEach(function(v) { l.push(v > 1000 ? ('CSMC Vendor/' + (v - 1000)) : (v + '')); });
        }
        return l.join(', ');
    };

    // adds a login (to the model without saving) to a vendor for further editing/saving
    vm.addLogin = function(vendorIndex) {
        var vendor = vm.vendors[vendorIndex];
        if (!vendor) return false;
        vm.newLogin.reset();
        vm.newLogin.vendorIndex = vendorIndex;
        vendor.logins.push(vm.newLogin.login);
        return true;
    };

    // removes a newly added login that haven't been saved if editing of it gets canceled
    vm.loginEditCanceled = function(vendorIndex) {
        var vendor = vm.vendors[vendorIndex];
        if (!vendor) return;
        var logins = vendor.logins;
        if (logins && logins.length && logins[logins.length - 1] === vm.newLogin.login) {
            logins.pop();
            vm.newLogin.reset();
        }
        return;
    };

    // pops up a dialog showing the login history of a specific login of a vendor
    vm.showHistory = function(vendorIndex, loginIndex) {
        var vendor = vm.vendors[vendorIndex];
        if (!vendor) return;
        var login = vendor.logins && vendor.logins[loginIndex];
        if (!login) return;
        dialogs.create('/ng1/dialogs/vendor/history.html', 'VendorHistoryCtrl', login).result
        .then(function() {}, function() {});
    };

    // deletes the specified login after confirmation
    vm.deleteLogin = function(vendorIndex, loginIndex) {
        var vendor = vm.vendors[vendorIndex];
        if (!vendor) return;
        var login = vendor.logins && vendor.logins[loginIndex];
        if (!login) return;
        dialogs.confirm('Please Confirm', 'Are you sure you want to delete this vendor login &lt;' + login.email + '&gt;?').result
        .then(function() {
            console.log('User wants to delete vendor login <' + login.email + '>');
            if (vendor.logins.splice(loginIndex, 1).length > 0) vm.save(vendorIndex);
        }, function() {
            console.log('User canceled deletion of vendor login <' + login.email + '>');
        });
    };

    // initialization

    // vendor options
    qmsVocabulary.licensePlatforms.forEach(function(platformName, platformId) {
        if (platformId === 0 || qmsVocabulary.platformHasSubvendors(platformId) || !qmsVocabulary.isPlatformActive(platformId) ||
            qmsVocabulary.isLicensePlatformInternal(platformId)) return;
        var ids = [platformId];
        var subvendorId = qmsVocabulary.platformToSubvendor.get(platformId);
        if (subvendorId !== undefined) ids.unshift(1000 + subvendorId);
        vm.filters.vendorOpts.push({ label: platformName, value: ids });
    });
    qmsVocabulary.licenseSubvendors.forEach(function(subvendorName, subvendorId) {
        if (!qmsVocabulary.isLicenseSubvendorActive(subvendorId)) return;
        var platformId = qmsVocabulary.subvendorToPlatform.get(subvendorId);
        if (platformId === undefined) vm.filters.vendorOpts.push({ label: subvendorName, value: [1000 + subvendorId] });
    });
    vm.filters.vendorOpts.sort(function(a, b) { return a.label.toUpperCase().localeCompare(b.label.toUpperCase()); });
    vm.filters.vendorOpts.unshift({ label: 'Any', value: [] });

    // initial filter option
    vm.filters.vendor = vm.filters.vendorOpts[0].value; // any
    if ($routeParams.id) {
        if ($routeParams.id === 'any') i = 0;
        else {
            temp = parseInt($routeParams.id);
            i = vm.filters.vendorOpts.findIndex(function(v) { return v.value.includes(temp); });
        }
        if (i >= 0) {
            vm.filters.vendor = vm.filters.vendorOpts[i].value;
            vm.load();
        }
    }
});
