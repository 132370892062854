angular.module('qms').controller('VendorHistoryCtrl', function($scope, $uibModalInstance, data) {

    $scope.login = data;
    $scope.pagination = {
        total: 0,
        perPage: 10,
        maxSize: 5,
        current: 1,
    };
    $scope.historyInView = [];

    $scope.close = function() {
        $uibModalInstance.close();
    };

    $scope.changePage = function() {
        var begin = ($scope.pagination.current - 1) * $scope.pagination.perPage, end = begin + $scope.pagination.perPage;
        $scope.historyInView = ($scope.login.login_logs && $scope.login.login_logs.slice &&
            $scope.login.login_logs.slice(begin,  end)) || [];
    };

    // initialization

    $scope.pagination.total = (data.login_logs && data.login_logs.length) || 0;
    if (data.login_logs) {
        if (data.login_logs.forEach) data.login_logs.forEach(function(v) { if (v.timestamp) v.timestamp = new Date(v.timestamp); });
        if (data.login_logs.sort) data.login_logs.sort(function(a, b) { return a.timestamp > b.timestamp; });
    }
    $scope.changePage();
});