angular.module('qms').controller('LicenseReplacementCtrl', function($scope, $filter, $uibModalInstance, data) {
    'use strict';

    $scope.systemId = data.systemId;
    $scope.replacedId = data.replacedId || '';
    $scope.replacedIdClass = 'has-error';
    $scope.replacedIdHelp = 'Please enter a valid System ID';

    $scope.validate = function() {
        var normalized = $filter('normalizeSystemId')($scope.replacedId);
        if (normalized=== "N/A" || normalized.length === 30) {
            if (normalized === $scope.systemId) {
                $scope.replacedIdClass = 'has-error';
                $scope.replacedIdHelp = 'ID of the system to be replaced cannot be the same as the replacement';
                return false;
            }
            else {
                $scope.replacedIdClass = 'has-success';
                $scope.replacedIdHelp = '';
                return normalized;
            }
        }
        else if (normalized.length === 17) {
            $scope.replacedIdClass = 'has-warning';
            $scope.replacedIdHelp = 'Old fashion System ID?';
            return normalized;
        }
        else if (normalized.length > 0) {
            $scope.replacedIdClass = 'has-error';
            $scope.replacedIdHelp = 'Doesn\'t look like a valid System ID';
            return normalized;
        }
        else {
            $scope.replacedIdClass = 'has-error';
            $scope.replacedIdHelp = 'Please enter a valid System ID';
            return false;
        }
    };

    $scope.na = function() {
        $scope.replacedId = 'N/A';
        $scope.validate();
    };

    $scope.ok = function() {
        var validated = $scope.validate();
        if (!validated) {
            document.getElementById('replacedId').focus();
            return;
        }
        $uibModalInstance.close(validated);
    };

    $scope.cancel = function() {
        $uibModalInstance.dismiss("User canceled replacing licenses");
    };
});