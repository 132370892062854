(function () {
    'use strict';

    angular
        .module('qms')
        .controller('SupportReportController', SupportReportController);

    function SupportReportController($scope, $http, QMSFactory, dialogs, uibDateParser, uiGridConstants, qmsVocabulary, Title) 
    {
        $scope.init = function()
        {
            Title.setTitle("Support Report");
            $scope.repsByID = {};

            $scope.initUIGridSettings();
            $scope.initMenuOptions();
            $scope.initBarChartSettings();
        }

        $scope.initUIGridSettings = function()
        {
                // UI-Grid data and column definitions. Updated by refresh(), which is called at end of initialization.
            $scope.gridConfig = {
                data: [],   // Loaded in response to query
                cols: [
                    {}, // Replaced by updateGridCols()
                    {}, // Set below
                    {}, // Replaced by updateGridCols()
                    {}  // Set below
                ]
            };

            $scope.groupByCol = {};
            $scope.timeCol = {};
            $scope.timeColIdx = 0;
            $scope.groupColIdx = 1;
            $scope.numRemoteColIdx = 2;
            $scope.avgDurationColIdx = 3;
            $scope.numHandledColIdx = 4;
            $scope.gridConfig.cols[$scope.numRemoteColIdx] = {field:'countRemoteLogin', displayName:'# Remote', width:'16%'};
            $scope.gridConfig.cols[$scope.avgDurationColIdx] = {field:'avgDuration', displayName:'Avg Duration', width:'18%'};

                // ui-grid and hence csListControl have their own isolated scope. This means by default we
                // can't access functions we define and attach to $scope here from cell templates for things
                // like ng-click. ui-grid does provide access to the app $scope via grid.appScope, but this
                // is broken by csListControl introducing its own isolated scope on top of ui-grid, i.e.,
                // grid.appScope only sees csListControl's scope but not the app's. I sort of hacked
                // this by adding an "inject" binding to csListControl's scope for us to sneak things into
                // ui-grid scopes. The binding maps "inject" attribute to "outerInjection" in csListControl's
                // scope, making the visible in ui-grid scope. For example, grid.appScope.outerInjection.xxx
                // Here we have to callback functions (remove and edit) under gridCallbacks which are mapped
                // to grid.appScope.outerInjection.{remove|edit}
                // See HTML for where gridCallbacks is used.
                // outerInjection is used in the CSListControl -- we don't reference it in this file.
            $scope.gridConfig.cols[$scope.numHandledColIdx] = {field:'count', displayName:'# Handled', width:'15%', sortingAlgorithm: $scope.sortIntColumn,
                     cellTemplate:'<div><a style="cursor:pointer" ng-click="grid.appScope.outerInjection.clickedNumHandled(row.entity)">{{row.entity.count}}</a></div>'
                   };
        }

            // Shows the 'Support incidents for this group' dialog when clicked
            // See HTML for where gridCallbacks is used 
        $scope.gridCallbacks = {};
        $scope.gridCallbacks.clickedNumHandled = function( record )
        {
                // Convert from an array of objects with an 'id' field to an array of ID strings
            let idsArray = [];
            for (let n=0; n < record.allIDs.length; n++)
                idsArray.push( record.allIDs[n].id )

            QMSFactory.getSupportByIDs( idsArray )
                .success( function (data)
                {
                    console.log("Got data", data);

                    let config = {
                        dialogTitle: " Support Interactions for Grouping",
                        data: data.data,
                        cols: [
                            {field:'dateopened', displayName:'Date', type:'date', cellFilter: 'date:\'yyyy-MM-dd\'', width:'13%'},
                            {field:'supportrep[0].fullname' , displayName:'Support Rep', width:'17%'},
                            {field:'description', displayName:'Description', width:'30%'},
                            {field:'issue', displayName:'Issue', width:'17%'},
                            {field:'duration', displayName:'min', width:'8%'},
                            {field:'remotemethod', displayName:"Remote", width:'15%'}

                        ],
                        openLinkNewWindow: "support"
                    };

                    dialogs.create('/ng1/dialogs/showdata/showdata.html', 'ShowDataDlg', config, {key: false, back: 'static'});
                });
        }

        $scope.initMenuOptions = function()
        {
                // menus
            $scope.groupByMenu = {
                current: 'owner',
                options: [
                    { value: 'owner', text: 'Support Rep' },
                    { value: 'issue', text: 'Issue (general)' },
                    { value: 'issueDetail', text: 'Issue (detailed)' }
                ],
            };

            $scope.timeMenu = {
                current: 'month',
                options: [
                    { value: 'week', text: 'Week' },
                    { value: 'month', text: 'Month' },
                    { value: 'year', text: 'Year' }  // Currently non-existant in support record
                ],
            };

        /*    $scope.yearMenu = {
                current: new Date().getFullYear(),
                options: []
            };
            for (let n=2018; n <= $scope.yearMenu.current; n++)
                $scope.yearMenu.options.push( {value:n, text: n + ""} );
        */

                // Date range menu ("Q1 2020" etc.)
            $scope.curDateRange = {from: "today", to:""};
            $scope.dateMenu = [];
            let today = new Date();
            for (let year = today.getFullYear(); year >= 2016; year--)
            {
                $scope.dateMenu.push({ label: 'Q1 ' + year, value: {from: year + '-01-01', to: year + '-03-31' } });
                $scope.dateMenu.push({ label: 'Q2 ' + year, value: {from: year + '-04-01', to: year + '-06-30' } });
                $scope.dateMenu.push({ label: 'Q3 ' + year, value: {from: year + '-07-01', to: year + '-09-30' } });
                $scope.dateMenu.push({ label: 'Q4 ' + year, value: {from: year + '-10-01', to: year + '-12-31'} });
                $scope.dateMenu.push({ label: 'Year ' + year, value: {from: year + '-01-01', to: year + '-12-31'} });
            }
            $scope.dateMenu.push({ label: 'Custom dates...', value: null }); 
            $scope.curDateRange = $scope.dateMenu[4].value;      //  [Math.floor(today.getMonth() / 3)]
            $scope.prevDateRange = $scope.curDateRange;
        }

        $scope.getCurrentGroupingText = function()
        {
            if ($scope.groupByMenu.current == 'owner')
                return "# Handled";
            else
                return "Issue";
        }

        $scope.initBarChartSettings = function()
        {
                // Constants used by bar chart
            $scope.weekLabels = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52];
            $scope.monthLabels = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];

                // Pastel colors: 
          /*   $scope.graphColors = ['rgba(255, 99, 132, 0.2)',
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255, 159, 64, 0.2)']; */
            $scope.graphColors = ["green", "blue", "cyan", "yellow", "red", "brown", "purple", "orange", "pink", "gray"];
            $scope.curColorIdx = 0;
            $scope.curChartLabels = $scope.monthLabels;

                // NEW bar chart (no angular)
            $scope.barChartObj = null;
            $scope.barChart = {
                datasets: [],       // [100, 20, 50]
                options: {
                    legend: {
                        display: true
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                    }
                }
            };

            $scope.issueColorMap = {};
            let source = qmsVocabulary.supportIncidentIssue;
            for (let n=0; n < source.length; n++)
                $scope.issueColorMap[ source[n].value ] = source[n].color;
            source = qmsVocabulary.supportIncidentIssueDetail["Database issues"];
            for (let n=0; n < qmsVocabulary.supportIncidentIssueDetail["Database issues"].length; n++)
                $scope.issueColorMap[ source[n].value ] = source[n].color;
        }

            // Custom sort function for the UIGrid Week/Month column, to sort it as an integer, not as a string
        $scope.sortIntColumn = function(a, b, rowA, rowB, direction)
        {
            let aInt = parseInt(a);
            let bInt = parseInt(b);

            if (aInt > bInt)
                return 1;
            else if (aInt < bInt)
                return -1;
            else
                return 0;
        };

            // Currently commented-out in HTML
     /*   $scope.showGridAtTop = false;
        $scope.toggleView = function()
        {
            $scope.showGridAtTop = !$scope.showGridAtTop;
        }*/

        $scope.getRepsByID = function()
        {
            let repsByID = {};
            let data = $scope.gridConfig.data;
            if (data)
                for (let n=0; n < data.length; n++)
                {
                    let repID = data[n]._id.owner;
                    if ( typeof repsByID[repID] == 'undefined' && typeof data[n].supportrep[0] != 'undefined')
                    {
                        repsByID[repID] = data[n].supportrep[0];
                        repsByID[repID].data = new Array($scope.curChartLabels.length).fill(0);
                    }
                }
            return repsByID;
        }

        $scope.getIssuesByName = function()
        {
            let issues = {};
            let data = $scope.gridConfig.data;
            if (data)
                for (let n=0; n < data.length; n++)
                {
                    let issueName = data[n]._id[ $scope.groupByMenu.current ];
                    issues[issueName] = data[n];
                    issues[issueName].data = new Array($scope.curChartLabels.length).fill(0);
                }
            return issues;
        }

        $scope.updateBarChart = function()
        {
                // Clear everything
        //    $scope.chartSeries = [];
        //    $scope.chartData = [];
        //    $scope.chartDatasetOverride = [];

            $scope.barChart.datasets = [];

            let data = $scope.gridConfig.data;
        //    if (typeof data == 'undefined' || data.length == 0)
        //        return;

            let chartElement = document.getElementById('barChart');
            if (typeof data == 'undefined' || data.length == 0)
            {
                if ($scope.barChartObj)
                    $scope.barChartObj.destroy();

                    // Empty out the chart's container
                while (chartElement.firstChild)
                    chartElement.removeChild(chartElement.firstChild);
                
                return;
            }

                // Step 1: Build a hashmap of support reps, where the key is their human-readable ID, and the value is their entire object
            let issuesByName = {};
            if ($scope.groupByMenu.current == "owner")
                $scope.repsByID = $scope.getRepsByID();
            else
            {
                delete $scope.repsByID;
                issuesByName = $scope.getIssuesByName();
            }

                // Generate year labels. Determine min and max year for range.
            let minYear=9999, maxYear=0;
            let yearLabels = [];
            if ($scope.timeMenu.current == "year")
            {
                for (let n=0; n < data.length; n++)
                {
                    let year = data[n]._id[ $scope.timeMenu.current ];
                    if (year < minYear)
                        minYear = year;
                    if (year > maxYear)
                        maxYear = year;
                }
                for (let n = minYear; n <= maxYear; n++)
                    yearLabels.push( n );
            }

                // 2) Iterate all data again, putting each count in the appropriate array element
            for (let n=0; n < data.length; n++)
            {
                let timeIdx = data[n]._id[ $scope.timeMenu.current ]; // Get the month (1-12) or week (1-52) index or the year (2020)
                if ($scope.timeMenu.current == "month")
                    timeIdx -= 1;   // Convert from 1-12 to 0-11
                else if ($scope.timeMenu.current == "year")
                    timeIdx -= minYear;     // Convert from 2018 to 0, if 2018 is the lowest year in the range

                if ($scope.groupByMenu.current == "owner")
                {
                    let repID = data[n]._id.owner;
                    if (typeof $scope.repsByID[repID] != 'undefined')
                        $scope.repsByID[repID].data[timeIdx] = data[n].count;
                }
                else
                    issuesByName[ data[n]._id[ $scope.groupByMenu.current ] ].data[timeIdx] = data[n].count;
            }

            
                // Update chart labels (bottom text)
            if ($scope.timeMenu.current == "month")
                $scope.curChartLabels = $scope.monthLabels;
            else if ($scope.timeMenu.current == "week")
                $scope.curChartLabels = $scope.weekLabels;
            else
                $scope.curChartLabels = yearLabels;

                // 3) Update the chart legend and data arrays
            if ( $scope.groupByMenu.current == "owner")
            {
                for (let repID in $scope.repsByID)
                {
                        // a) Update the legend
                //    $scope.chartSeries.push( $scope.repsByID[repID].fullname );

                        // b) Update the data
                //    $scope.chartData.push( $scope.repsByID[repID].data );

                        // Color by RepID
               //     $scope.chartDatasetOverride.push( { borderColor: $scope.getColorForRepID(repID), backgroundColor: $scope.getColorForRepID(repID) } );

                                            // NEW bar chart (non-angular)
                    let dataset = {
                        backgroundColor: $scope.getColorForRepID(repID),
                        borderColor: $scope.getColorForRepID(repID),
                        data: $scope.repsByID[repID].data,
                        label: $scope.repsByID[repID].fullname
                    };
                    $scope.barChart.datasets.push( dataset );
                }
            }
            else
            {
                for (let issueName in issuesByName)
                {
                        // a) Update the legend
                //    $scope.chartSeries.push( issueName );
                        // b) Update the data
                //    $scope.chartData.push( issuesByName[issueName].data );
                //    $scope.chartDatasetOverride = [];

                    console.log("issueName", issueName);

                    let color = $scope.issueColorMap[ issueName ];
                    if (typeof color == 'undefined')
                        color = "lightgray";

                        // The label might be a string "undefined" which we change to 'Not specified'
                    let label = (issueName !== 'undefined') ? issueName : "Not specified";

                         // NEW bar chart (non-angular)
                    let dataset = {
                        backgroundColor: color,
                        borderColor: color,
                        data: issuesByName[issueName].data,
                        label: label
                    };
                    $scope.barChart.datasets.push( dataset );
                }
            }

            

            console.log("Abot to render barChart:", $scope.barChart);

                // NEW: Use chart.js directly (not through angular wrapper)
            if ($scope.barChartObj)
                $scope.barChartObj.destroy();
                // We use chart.js -- see www.chartjs.org
            $scope.barChartObj = new Chart(chartElement, {
                type: 'bar',
                data: {
                    datasets: $scope.barChart.datasets,

                    // These labels appear in the legend and in the tooltips when hovering different areas
                    labels: $scope.curChartLabels
                },
                options: $scope.barChart.options
            });
        }

            // Assigns colors to rep IDs so they are consistent across graphs
        $scope.colorsByRepID = {};
        $scope.getColorForRepID = function(repID)
        {
            if ( typeof $scope.colorsByRepID[repID] == 'undefined')
                $scope.colorsByRepID[repID] = $scope.graphColors[$scope.curColorIdx++];

            if ( typeof $scope.colorsByRepID[repID] == 'undefined')
                return "black";     // If we ran out of colors, use black instead of undefined
            else
                return $scope.colorsByRepID[repID];
        }

            // NOTE: Unlike CSS, the % widths MUST add up to exactly 100%, otherwise columns might not be shown, or you might have extra space!
            // Based on menu choices, update the ui-grid columns
        $scope.updateGridCols = function()
        {
            let time = $scope.timeMenu.current;
            let timeCol = {};
            if (time == "week")
            {
                timeCol = {field:'_id.week', displayName:'Week', width:'15%', sort: {direction: uiGridConstants.ASC, ignoreSort: false, priority: 0}};
                timeCol.sortingAlgorithm = $scope.sortIntColumn;
            }
            else if (time == "month")
            {
                timeCol = {field:'_id.month', displayName:'Month', width:'15%', sort: {direction: uiGridConstants.ASC, ignoreSort: false, priority: 0}};
                timeCol.sortingAlgorithm = $scope.sortIntColumn;
            }
            else
                timeCol = {field:'_id.year', displayName:'Year', width:'15%', sort: {direction: uiGridConstants.ASC, ignoreSort: false, priority: 0}};

            let groupBy = $scope.groupByMenu.current;
            let groupByCol = {};
            if (groupBy == "owner")
                groupByCol = {field:'supportrep[0].fullname', displayName:'Owner', width:'36%'};
            else
                groupByCol = {field:'_id.' + $scope.groupByMenu.current, displayName:'Issue', width:'36%'};

                // The only way to change the ui-grid column names or data source after having created it is to modify the original array (while keeping it intact)
            $scope.gridConfig.cols.splice( $scope.groupColIdx, 1, groupByCol)[0];
            $scope.gridConfig.cols.splice( $scope.timeColIdx, 1, timeCol)[0];
        };

            // Since the $round operator is only in MongodB 4.2 and later, and we're currently on MongoDB 3.x, we round avgDuration here in the client.
        $scope.roundAvgDurations = function()
        {
            if ($scope.gridConfig.data)
                for (let n=0; n < $scope.gridConfig.data.length; n++)
                {
                    let item = $scope.gridConfig.data[n];
                    if ( typeof item.avgDuration == 'number' )
                        item.avgDuration = Math.round( item.avgDuration );
                }
        }

        $scope.working = false;

            // When user changes 'Issue Date'...
        $scope.dateRangeChanged = function()
        {
                // Did user choose the 'Custom date...' option? (This has a null value)
            if ($scope.curDateRange == null)
            {
                let srcData = { from: new Date(), to: new Date() };
                let opts = $scope.dateMenu;
                if (opts.length > 2 && !opts[opts.length - 2].label.startsWith('Year')) {

                        // TODO: value.from and value.to don't exist. Must parse the way I've stored dates (YYYYMMDD-YYYYMMDD)
                    srcData.from = uibDateParser.parse(opts[opts.length - 2].value.from, 'yyyy-MM-dd') || srcData.from;
                    srcData.to = uibDateParser.parse(opts[opts.length - 2].value.to, 'yyyy-MM-dd') || srcData.to;
                }

                console.log("User chose custom date rante: ", srcData);

                let prevDateIndex = $scope.curDateRange;

                dialogs.create('/ng1/dialogs/license/customdates.html', 'ReconciledCustomDatesCtrl', srcData).result
                .then(function(data) {
                    console.log("Custom date  range selected: ", data);
                    $scope.curDateRange = { from: printISODate(data.from), to: printISODate(data.to) };
                    $scope.prevDateRange = $scope.curDateRange;
                    var opt = { label: ($scope.curDateRange.from || '') + ' to ' + ($scope.curDateRange.to || ''), value: $scope.curDateRange };
                    $scope.dateMenu.splice(-1, 0, opt);

                    $scope.refresh();
                },
                function()  // Called when dialog is canceled, to restore the previously selected date.
                {
                    console.log("Second function called.");
                //    var o = vm.filters.dateOpts.find(function(v) { return v.value.from === oldFrom && v.value.to === oldTo; });
                    $scope.curDateRange = $scope.prevDateRange;
                });
            }
            else
            {
                $scope.prevDateRange = $scope.curDateRange;
                $scope.refresh();
            }
        };

        $scope.refresh = function()
        {
            $scope.working = true;
         //   let query = $scope.query.period || 'today';

            $scope.updateGridCols();

            QMSFactory.supportReport( $scope.groupByMenu.current, $scope.timeMenu.current, $scope.curDateRange.from, $scope.curDateRange.to )
                .success( function (data)
                {
                    console.log("Got HTTP response: ", data.data);
                    $scope.gridConfig.data = data.data;
                    $scope.roundAvgDurations();

                    console.log("Support report page has set gridConfig: ", $scope.gridConfig);
                    
                    $scope.updateBarChart();
                    $scope.working = false;
                });
        };

        $scope.init();
        $scope.refresh();
    }

})();
