
(function() {
    'use strict';
    angular
        .module('qms')
        .controller('DebugController',DebugController);

    function DebugController( AuthTokenFactory, $window, $modalInstance, $rootScope ) {
        var vm=this;
        vm.init = init;
        vm.close = closeDebugController;
        vm.fancyDisplay = fancyDisplay;
        vm.watch= $rootScope.watch;

        function init() {
            var token = AuthTokenFactory.getToken();
            if (token == null) {
                vm.token = "null token";
                return;
            }
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace('-', '+').replace('_', '/');
            vm.token = JSON.parse($window.atob(base64));
        }

        function closeDebugController() {
            $modalInstance.close();
        }

        function fancyDisplay(obj) {
            return JSON.stringify(obj, null, 4);
        }

        vm.init();
    }

})();