// ignore the 'bad formatting' warning for &&s that span multiple lines
/* jshint -W014 */

(function () {
'use strict';
angular.module('qms')
    .controller('PasswordController',PasswordController);


function PasswordController(QMSFactory, $modalInstance, data, $timeout) {
    var vm = this;
    vm.id = data.id;
    vm.errorMessage = "";
    vm.newpass = "";
    vm.confirm = "";
    console.log("here: " + vm.id);

    vm.init = init;
    vm.cancel= cancel;
    vm.changePass= changePass;
    vm.saveEnabled= saveEnabled;
    vm.errorMessage = errorMessage;
    vm.verifyPasswords = verifyPasswords;
    vm.verifyConfirm = verifyConfirm;

    vm.inProgress = false;
    vm.passwordsMatch = true;
    vm.passwordStrongEnough = false;
    vm.passwordStrengthMessage = "Password cannot be empty";
    vm.passwordStrengthMessageClass = "has-error";
    vm.passwordStrengthHint = "";
    vm.currentPasswordCorrect = true;
    vm.success = false;

    function init() {

        if (data.hasOwnProperty("requirePass")) {
            vm.requirePass = data.requirePass;
        }
        console.log(data);
    }

    function saveEnabled() {
        return vm.passwordsMatch && vm.passwordStrongEnough && !vm.inProgress;
    }

    function errorMessage() {
        if (!vm.passwordsMatch) {
            return "New passwords do not match";
        }
        else if (!vm.currentPasswordCorrect) {
            return "Current Password Incorrect";
        }
        return "";
    }

    function verifyPasswords() {
        if (vm.newpass.length > 0) {
            let result = zxcvbn(vm.newpass);
            vm.passwordStrongEnough = result.score >= 3;
            if (result.score === 3) {
                vm.passwordStrengthMessage =  "Good. Password is safely unguessable";
                vm.passwordStrengthMessageClass = "has-success";
                vm.passwordStrengthHint = "";
            }
            else if (result.score >= 4) {
                vm.passwordStrengthMessage =  "Great. Password is very unguessable";
                vm.passwordStrengthMessageClass = "has-success";
                vm.passwordStrengthHint = "";
            }
            else {
                if (result.score === 0) {
                    vm.passwordStrengthMessage = "Too weak. Password is too guessable";
                    vm.passwordStrengthMessageClass = "has-error";
                }
                else if (result.score == 1) {
                    vm.passwordStrengthMessage = "Still weak. Password is very guessable";
                    vm.passwordStrengthMessageClass = "has-error";
                }
                else {
                    vm.passwordStrengthMessage = "Almost there. Password is somewhat guessable";
                    vm.passwordStrengthMessageClass = "has-warning";
                }
                vm.passwordStrengthHint = result.feedback.warning;
                if (result.feedback.suggestions) {
                    if (vm.passwordStrengthHint) vm.passwordStrengthHint += '. ';
                    vm.passwordStrengthHint += result.feedback.suggestions;
                }
            }
        }
        else {
            vm.passwordStrongEnough = false;
            vm.passwordStrengthMessage = "Password cannot be empty";
            vm.passwordStrengthMessageClass = "has-error";
            vm.passwordStrengthHint = "";
        }
        vm.verifyConfirm();
    }

    function verifyConfirm() {
        vm.passwordsMatch = (vm.newpass === vm.confirm);
    }

    function changePass() {
        if (vm.inProgress) {
            return;
        }

        vm.inProgress = true;
        QMSFactory.saveUserPassword( vm.id, vm.current, vm.newpass ).success( function(response) {
            console.log(response);
            if ( response && response.data && response.data.worked ) {
                vm.success = true;
                $timeout(vm.cancel(), 1000);
            } else {
                vm.currentPasswordCorrect = false;
                vm.inProgress = false;
            }
        });

    }

    function cancel() {
        $modalInstance.close();
    }

    vm.init();
}


})();