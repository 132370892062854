angular.module('qms').controller('EnterinfoCtrl',function($scope, $http, $q, $routeParams, $location){
    $scope.id = $routeParams["eventid"];
    $scope.record = {}; //activation event
    $scope.system = {}; //system information

    $scope.init = function() {
        $http.get('/api/event/' + $scope.id).success(function(data) {
            $scope.record = data;

            $http.get('/api/system/' + $scope.record.systemid).success(function(data) {
                $scope.system = data;
            });

        });
    };

    $scope.featureToString = function( featureid ) {
        switch (featureid) {
            case -1: return "Undefined";
            case 0: return "QGS+QPS";
            case 1: return "QGS";
            case 2: return "QPS";
            case 3: return "QBS";
            case 4: return "CSImport";
            case 5: return "Companion";
            case 6: return "QARG Local";
            case 7: return "QARG Site";
            case 8: return "ARG Local";
            case 9: return "ARG Site";
            case 10: return "Motion Correction";
            case 11: return "Clinical Use";
            case 12: return "Suite2013";
            case 13: return "AutoSPECT";
            case 14: return "-------";
            case 15: return "VuSlice";
            case 16: return "-------";
            case 17: return "-------";
            case 18: return "Import Enabled";
            case 19: return "QRegKey";
            case 20: return "-------";
            case 21: return "PlusPack";
            case 22: return "QPet";
            case 23: return "FusionCT";
            case 24: return "Powerpoint";
            case 25: return "CSMC Button";
            case 26: return "MFSC";
            case 27: return "Suite2010";
            case 28: return "Remote Desktop";
            case 29: return "Suite2012";
        }
        return "";
    };

    $scope.confirm = function() {
        var xsrf = $.param({data: JSON.stringify($scope.record)});
        $http({
            method: 'POST',
            url: '/api/event/' + $scope.id,
            data: xsrf,
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).success(function(data) {
            $location.path("/enterinfo/" + $scope.id);
        });
    };

    $scope.init();
});