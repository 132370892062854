(function() {
    'use strict';
    angular.module('qms')
        .directive('csListControl',CSListControl);

    function CSListControl() {
        return {
            restrict: 'C',
            scope: {
                data: '=',
                config: '=',
                outerInjection: '=inject' // for the user of csListControl to inject something into ui-grid scope; maps to grid.appScope.outerInjection
            },
            templateUrl: '/ng1/templates/cs_listcontrol.html',
            link: ListLink,
            controller: ['$scope', '$location', ListController],
            controllerAs: 'listCtrl'
        };
        function ListLink(scope, element, attrs) {  }

        function ListController($scope, $location, $window)
        {
            var vm=this;
            vm.config = $scope.config;
            vm.init = init;
            vm.getTableHeight = getTableHeight;
            vm.getISODate = getISODate;
            vm.gridOptions = {
                data: 'listCtrl.config.data',
                enableHorizontalScrollbar: 0,
                enableVerticalScrollbar: 0,
                enableRowSelection: true,
                enableRowHeaderSelection: false,
                multiSelect: false,
                columnDefs: vm.config.cols
            };

            $scope.f = {
                iso : getISODate
            };

            function init() {
                for (var i=0;i<vm.config.cols.length;i++) {
                    if (vm.config.cols[i].myType === "date") {
                        // vm.config.cols[i].cellTemplate="<div class=\"ngCellText colt{{$index}}\">{{grid.appScope.f.iso(row.entity[col.field])}}</div>";
                        vm.config.cols[i].cellTemplate="<div class=\"ui-grid-cell-contents\">{{grid.appScope.f.iso(row.entity[col.field])}}</div>";
                    }
                    if (vm.config.cols[i].myType === "datetime") {
                        // vm.config.cols[i].cellTemplate="<div class=\"ngCellText colt{{$index}}\">{{grid.appScope.f.iso(row.entity[col.field], true)}}</div>";
                        vm.config.cols[i].cellTemplate="<div class=\"ui-grid-cell-contents\">{{grid.appScope.f.iso(row.entity[col.field], true)}}</div>";
                    }
                }
                if (typeof vm.config.onSelect !== 'undefined') {
                    vm.gridOptions.onRegisterApi = function(gridApi) {
                        $scope.gridApi = gridApi;
                        gridApi.selection.on.rowSelectionChanged($scope, vm.config.onSelect);
                    };
                }

                var hasOpen = false;

                // callback function that handles the navigation when a link is activated
                if (typeof vm.config.onOpen !== 'undefined') {
                    hasOpen = true;
                    $scope.f.onOpen = vm.config.onOpen;
                }

                // callback function that returns the actual URL for the links derived from the given base URL
                if (typeof vm.config.openLink !== 'undefined') {
                    hasOpen = true;
                    $scope.f.getLink = getLink;
                }
                else if (typeof vm.config.openLinkNewWindow !== 'undefined')
                {
                    hasOpen = true;
                    $scope.f.getLink = getLinkNewWindow;
                }

                if (hasOpen) {
                    var cellTemplate;
                    // show an anchor instead of a button if a base URL is provided
                    if ($scope.f.onOpen)
                        cellTemplate = "<div class=\"ui-grid-cell-contents\"><button class=\"btn btn-xs\" ng-click=\"grid.appScope.f.onOpen(row.entity)\"><i class=\"fa fa-external-link\"></i></button></div>";
                    else if ($scope.f.getLink === getLink)
                        cellTemplate = "<div class=\"ui-grid-cell-contents\"><a href=\"{{grid.appScope.f.getLink(row.entity)}}\"><i class=\"fa fa-external-link\"></i></a></div>";
                    else
                        cellTemplate = "<div class=\"ui-grid-cell-contents\"><a href=\"{{grid.appScope.f.getLink(row.entity)}}\" target='_blank'><i class=\"fa fa-external-link\"></i></a></div>";
                    var newCol = {
                        name: "x",
                        width: "36",
                        sortable: false,
                        displayName: "",
                        // cellTemplate: "<div class=\"ngCellText colt{{$index}}\" align=\"center\"><button class=\"btn btn-xs\" ng-click=\"grid.appScope.f.onOpen(row.entity)\"><i class=\"fa fa-external-link\"></i></button></div>"
                        cellTemplate: cellTemplate
                    };
                    vm.config.cols.unshift(newCol); // radios always go in front, if both, then put radio at end
                }

                //vm.gridOptions.rowTemplate ="<div ng-style=\"{ 'cursor': row.cursor }\" ng-repeat=\"col in renderedColumns\" ng-class=\"col.colIndex()\" class=\"ngCell {{col.cellClass}}\"><div class=\"ngVerticalBar\" ng-style=\"{height: rowHeight}\" ng-class=\"{ ngVerticalBarVisible: !$last }\">&nbsp;</div><div ng-cell></div></div>";
            }

                // Seems unused
            function openLink(row) {
                console.log("openLink called");
                $location.path("/"+ $scope.config.openLink + "/" + row.id);
            }

            function getLink(row) {
                return "#/" + $scope.config.openLink + "/" + row.id;
            }

            function getLinkNewWindow(row) {
                return "#/" + $scope.config.openLinkNewWindow + "/" + row.id;
            }

            function getISODate(dt, inc_time) {
                if (typeof(inc_time) === 'undefined') {
                    inc_time = false;
                }
                var d = new Date(dt);
                if (!d.isValid()) {
                    if (typeof dt !== 'undefined') {
                        d = new Date(dt.sec * 1000);    // check if mongo date
                    }
                    if (!d.isValid()) {
                        return 'Unknown';
                    }
                }
                var date_str = d.getFullYear() + "-" + pad(d.getMonth() + 1, 2) + "-" + pad(d.getDate(), 2);
                if (inc_time) {
                    date_str += " " + pad(d.getHours(), 2) + ":" + pad(d.getMinutes(), 2);
                }
                return date_str;
            }

            function getTableHeight() {
                var rowHeight = 30; // your row height
                var headerHeight = 50; // your header height
                var minRows = 0;
                if (typeof vm.config !== 'undefined' && typeof vm.config.data !== 'undefined') {
                    minRows = vm.config.data.length;
                }
                if (minRows<1) {
                    minRows = 1;
                }
                return {
                    height: (minRows * rowHeight + headerHeight) + "px"
                };
            }

            vm.init();
        }
    }

})();
