(function() {
    'use strict';

/**
 * MultiSelect widget for Angular
 *
 * Depends on BootStrap
 *
 * Example: <div title="Branches" class="multiSelect" options="branches" selections="record.branches"></div>
 * Usage: title: Title of the Button
 *        class: must be "multiSelect"
 *        options: array of options
 *              eg. options = ["Apple", "Banana", "Pear"];
 *    OUT selections: destination of selected items (array)
 *
 * TODO: Allow a 'primary' selected widget
 */
    angular.module('qms')
        .directive('ngBindSelections', ngBindSelections)
        .directive('multiSelect', multiSelect);

    function ngBindSelections($compile) {
        return{
            link:function(scope,element,attr){
                element[0].removeAttribute('ng-bind-selections');
                element[0].setAttribute('selections',attr.ngBindSelections);
                //console.log("binding to: " + attr.ngBindSelections);
                $compile(element[0])(scope);
            }
        };
    }

    function multiSelect() {
        return {
            // 'C' = class ... should be 'E' (element; <multiSelect>), but had issues getting it to work correctly
            restrict: 'C',
            replace: true,
            transclude: true,
            scope: {
                title: '@',
                items: '=options',
                selections:'='
            },
            templateUrl: '/ng1/templates/cs_multiselect.html',
            controller: ['$scope', '$element', '$attrs', '$transclude', function($scope, $element, $attrs, $transclude) {

                $scope.init = function() {
                    if (typeof($scope.items) === 'undefined' || $scope.items.length <= 0 ) {
                        return;
                    }
                  if (typeof($scope.selections) === 'undefined') {
                      $scope.selections = [];
                  }
                };

                $scope.toggleChoice = function(choice) {
                    var found = false;
                    for (var i=$scope.selections.length-1;i>=0;i--) {
                        if ($scope.selections[i] === choice) {
                            $scope.selections.splice(i,1);
                            found=true;
                        }
                    }
                    if (!found) {
                        $scope.selections.push(choice);
                    }
                };

                $scope.isSelected = function(item) {
                    var ret = false;
                    if (typeof($scope.selections) === 'undefined'){
                        return false;
                    }
                    for (var i=0;i<$scope.selections.length;++i) {
                        if ($scope.selections[i] === item) { ret = true; }
                    }
                    return ret;
                };

                $scope.testit = function() {
                    console.log($scope);
                };
                $scope.init();
            }]
        };
    }

})();
