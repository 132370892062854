// Wrapper for the Angular 2 Job page.
// This file is included in angular.json

(function() {
    'use strict';
    angular.module('qms')
        .controller('JobPageWrapperController', JobPageWrapperController);
    
    function JobPageWrapperController($scope, $routeParams, $location){
        
        $scope.jobid = $routeParams["id"];
        console.log("JobPageWrapper got route id: ", $scope.jobid);
    }
})();