// Report - Reminders

angular.module('qms').controller('ReminderListController', function( $scope, $filter, dialogs, uiGridConstants, QMSFactory, qmsVocabulary, Title ) {
    'use strict';

    // date ranges of queries
    var today = new Date();
    var yesterday = new Date(); yesterday.setDate( yesterday.getDate() - 1 );
    var oneWeek = new Date(); oneWeek.setDate( oneWeek.getDate() + 7 );
    var oneMonth = new Date(); oneMonth.setMonth( oneMonth.getMonth() + 1 );
    var sToday = printISODate( today ).replace(/-/g, '');
    var sYesterday = printISODate( yesterday ).replace(/-/g, '');
    var sOneWeek = printISODate( oneWeek ).replace(/-/g, '');
    var sOneMonth = printISODate( oneMonth ).replace(/-/g, '');
    $scope.due = {
        current: '-' + sOneWeek,
        options: [
            { label: 'Overdue or in a week', value: '-' + sOneWeek },
            { label: 'Overdue or in a month', value: '-' + sOneMonth },
            { label: 'In a week', value: sToday + '-' + sOneWeek },
            { label: 'In a month', value: sToday + '-' + sOneMonth },
            { label: 'Overdue', value: '-' + sYesterday },
            { label: 'All time', value: 'all' },
        ],
    };
    // owner for querying
    $scope.owner = {
        current: '*',
        options: [ { ownerId: '*', ownerName: 'All' } ],
    };
    // category for querying
    $scope.category = {
        current: '*',
        options: [ { value: '*', text: 'All', group: '' } ],
    };
    // status filters
    $scope.status = {
        current: '-completed',
        options: [
            { value: '-completed', text: 'All but Completed' },
            { value: '*', text: 'All' },
        ],
    };

    // cell template for [Edit] (an edit button and a remove button)
    var edit = '<div class="buttons">' + 
        '<button class="btn btn-primary btn-xs" ng-click="grid.appScope.outerInjection.edit(row.entity.id)">' +
        '<i class="fa fa-pencil-square-o"></i></button>' +
        '<button class="btn btn-danger btn-xs" ng-click="grid.appScope.outerInjection.remove(row.entity.id)">' +
        '<i class="fa fa-trash-o"></i></button></div>';
    // cell template for [Source]
    // var source = '<div class="ui-grid-cell-contents" title="TOOLTIP"><a href="#/{{row.entity.source}}">{{COL_FIELD}}</a></div>';
    var source = '<div class="ui-grid-cell-contents" title="TOOLTIP"><a ng-href="#/{{row.entity.source}}"><i class="fa fa-external-link"></i></a> {{COL_FIELD}}</div>';
    $scope.reminders = {
        data: [],
        orgData: [],
        cols: [
            { field: 'formattedDue', displayName: 'Due Date', width: '13%', cellClass: cellClass, cellTooltip: true,
                sort: {direction: uiGridConstants.ASC, ignoreSort: false, priority: 0} },
            { field: 'sourceName', displayName: 'Source', width: '14%', cellClass: cellClass, cellTooltip: true, cellTemplate: source },
            { field: 'ownerName', displayName: 'Owner', width: '10%', cellClass: cellClass, cellTooltip: true },
            { field: 'category', displayName: 'Category', width: '15%', cellFilter: 'reminderCategoryLabel', cellClass: cellClass },
            { field: 'description', displayName: 'Description', width: '17%', cellClass: cellClass, cellTooltip: true },
            { field: 'status', displayName: 'Status', width: '10%', cellFilter: 'reminderStatusLabel', cellClass: cellClass },
            { field: 'note', displayName: 'Note', width: '15%', cellTooltip: true },
            // { field: 'timestamp', displayName: 'Last Updated', width: '10%', cellFilter: 'date:"yyyy-MM-dd h:mma"', cellTooltip: true },
            { field: 'edit', displayName: 'Edit', width: '6%', cellTemplate: edit, enableSorting: false, enableColumnMenu: false },
        ],
    };
    // CSS class for a cell depending on completion/overdue status
    function cellClass(grid, row, col, rowRenderIndex, colRenderIndex) {
        var yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        if (row.entity.status === 'completed') return 'completedReminder';
        else if (new Date(row.entity.due) < yesterday) return 'overdueReminder';
        else return '';
    }

    $scope.working = false;
    $scope.refresh = function() {
        // if ($scope.working) return; // ???
        $scope.working = true;
        var query = {}, matches = null;
        if ($scope.due.current) {
            if ((matches = /^-([0-9]{8})$/.exec($scope.due.current))) {
                query.dueBefore = matches[1];
            }
            else if ((matches = /^([0-9]{8})-([0-9]{8})$/.exec($scope.due.current))) {
                query.dueAfter = matches[1];
                query.dueBefore = matches[2];
            }
        }
        if ($scope.owner.current && $scope.owner.current !== '*') {
            query.owner = $scope.owner.current; // single owner filtering for now
        }
        if ($scope.category.current && $scope.category.current !== '*') {
            query.category = $scope.category.current; // single category filtering for now
        }
        QMSFactory.queryForReminders(query).success(function(data) {
            $scope.reminders.orgData = data.data;
            if ($scope.reminders.orgData.forEach) {
                // format due date with time if necessary
                $scope.reminders.orgData.forEach(function(v) {
                    var format = v.hasDueTime ? 'yyyy-MM-dd h:mma' : 'yyyy-MM-dd';
                    v.formattedDue = (v.due && $filter('date')(v.due, format)) || '';
                });
            }
            $scope.filter();
            $scope.working = false;
        });
    };

    // filter by statuses (as I can't figure out how to do this by ui-grid's filtering which uses a text field)
    $scope.filter = function() {
        var filter = $scope.status.current;
        // not filtering?
        if (filter === '*') {
            $scope.reminders.data = $scope.reminders.orgData;
        }
        // negative filter?
        else if (filter.startsWith('-')) {
            filter = filter.slice(1);
            $scope.reminders.data = $scope.reminders.orgData.filter(function(d) { return d.status !== filter; });
        }
        // positive filter
        else {
            $scope.reminders.data = $scope.reminders.orgData.filter(function(d) { return d.status === filter; });
        }
    };

    // ui-grid and hence csListControl have their own isolated scope. This means by default we
    // can't access functions we define and attach to $scope here from cell templates for things
    // like ng-click. ui-grid does provide access to the app $scope via grid.appScope, but this
    // is broken by csListControl introducing its own isolated scope on top of ui-grid, i.e.,
    // grid.appScope only sees csListControl's scope but not the app's. I sort of hacked
    // this by adding an "inject" binding to csListControl's scope for us to sneak things into
    // ui-grid scopes. The binding maps "inject" attribute to "outerInjection" in csListControl's
    // scope, making the visible in ui-grid scope. For example, grid.appScope.outerInjection.xxx
    // Here we have to callback functions (remove and edit) under gridCallbacks which are mapped
    // to grid.appScope.outerInjection.{remove|edit}
    $scope.gridCallbacks = {};
    $scope.gridCallbacks.remove = function(id) {
        dialogs.confirm('Delete Reminder', 'Are you sure you want to delete the reminder?').result
            .then( function() {
                $scope.removeReminder(id);
            }, function() {
                console.log("User canceled deleting reminder");
            } );
    };
    $scope.removeReminder = function(id) {
        if (!id) return;
        QMSFactory.removeReminder(id).success(function(data) {
            console.log("Reminder " + id + " removed!");
            $scope.refresh();
        });
    };
    $scope.gridCallbacks.edit = function(id) {
        var index = -1;
        for (var i = 0; i < $scope.reminders.data.length; i++) {
            if ($scope.reminders.data[i].id === id) {
                index = i;
                break;
            }
        }
        if (index < 0) return;
        var reminder = angular.copy($scope.reminders.data[index]);
        delete reminder.formattedDue; // remove the formatted due date before saving in DB
        var dlg = dialogs.create('/ng1/dialogs/reminder/reminderedit.html', 'ReminderEditCtrl', reminder, {key: false, back: 'static'});
        dlg.result.then(function() {
            // console.log(data);
            $scope.refresh();
        }, function() {
            console.log("User canceled editing reminder");
        });
    };

    //
    // actual initialization
    //

    Array.prototype.push.apply($scope.category.options, qmsVocabulary.allReminderCategoriesWithGroup());
    for (var i = 0; i < qmsVocabulary.reminderStatusOptions.length; i++) {
        $scope.status.options.push(qmsVocabulary.reminderStatusOptions[i]);
    }
    // populate $scope.users
    QMSFactory.getUsersForGroup('*').success(function(result) {
        $scope.owner.options = [ { ownerId: '*', ownerName: 'All' } ];
        for (var i = 0; i < result.data.length; i++ ) {
            $scope.owner.options.push({ ownerId: (result.data[i]._id.$oid || result.data[i]._id.$id), ownerName: result.data[i].fullname });
        }
    });

    Title.setTitle("Reminders");
    $scope.refresh();
});
