/*
*  Adds AutoSave functionality to a controller.
*
*  saveFuncPtr must return a promise
*
*  Usage:
*       vm.autoSave = new AutoSaveFactory(vm.record, vm.save );
*       vm.save = function() { return QMSFactory.savePrefs(vm.record); }
* */

// TODO: Add global icons for saving and dirty
// TODO: Add warning dialog if navigating and unsaved
// TODO: Save immediately on first change then wait 1 second for next save (currently we wait 1 second for first save)

(function() {
    'use strict';
    angular.module('qms')
        .factory('AutoSaveFactory',  ['$rootScope','$timeout', AutoSaveFactory]);

    function AutoSaveFactory($rootScope, $timeout) {
        return function(watch, saveFuncPtr) {
            var vm = this;
            vm.inProgress = false;
            $rootScope.watch = watch;

            // functions
            vm.autoSaveFactory_AutoSave = autoSaveFactory_AutoSave;
            vm.autoSaveFactory_Save = autoSaveFactory_Save;

            $rootScope.$watch(function() {return watch;}, vm.autoSaveFactory_AutoSave, true);

            function autoSaveFactory_AutoSave(newVal, oldVal) {
                if (newVal !== oldVal) {
                    if (vm.timeout) {
                        $timeout.cancel(vm.timeout);
                    }
                    vm.timeout = $timeout(vm.autoSaveFactory_Save, 1000);  // 1000 = 1 second
                }
            }

            function autoSaveFactory_Save() {
                if (!vm.inProgress) {
                    vm.inProgress = true;
                    saveFuncPtr().success(function (data) {
                        vm.inProgress = false;
                    });
                }
            }
        };
    }

})();