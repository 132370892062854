
(function() {
	'use strict';
	angular
		.module('qms')
		.controller('AimDocAlertController',AimDocAlertController);

	function AimDocAlertController( $modalInstance, $document, $sce, $rootScope, data )
	{
		var vm=this;
		//   vm.watch= $rootScope.watch;
		vm.title = data.title;
		vm.message = $sce.trustAsHtml( data.message );
		
		vm.init = function()
        {
                // Return key should dismiss dialog
			$modalInstance.rendered.then(function()
            {
                $document.bind('keydown', function(event)
                    { 
                        console.log("AIMDocAlertConroller: Key pressed!", event);

                        if (event.keyCode === 27)	// 27 = Escape key
                            vm.close();
                        else if (event.keyCode === 13) // return
                            vm.close();
                    }
                );
            });
		};
		
		vm.close = function()
        {
            $document.unbind('keydown');
			$modalInstance.close();
        };
				
		vm.init();
	}

})();
