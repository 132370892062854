angular.module('qms').controller('LicenseCustomExpirationCtrl', function($scope, $uibModalInstance, data) {
    'use strict';

    $scope.minDate = new Date();
    $scope.expiration = new Date();

    $scope.ok = function() {
        $uibModalInstance.close($scope.expiration);
    };
    $scope.cancel = function() {
        $uibModalInstance.dismiss('canceled');
    };
});