angular.module('qms').controller('EditComplaintCtrl',function($scope, $uibModalInstance, QMSFactory, qmsVocabulary, data) {
    'use strict';

    // pre-populate fields with known values
    $scope.record = {};
    $scope.record.supplier = '';
    $scope.record.supplierInitialDate = null;
    $scope.record.supplierPhone = '';
    $scope.record.supplierEmail = '';
    $scope.record.contact = data.record.contact;
    $scope.record.initialDate = data.record.dateopened;
    $scope.record.customerid = data.customer.id;
    $scope.record.customer = data.customer.name;
    let contact = data.customer.contacts.find(function(e) { return e.name == data.record.contact; });
    if (contact) {
        $scope.record.contactTitle = contact.role;
        $scope.record.phone = contact.phone;
        $scope.record.email = contact.email;
    }
    else {
        $scope.record.contactTitle = '';
        $scope.record.phone = '';
        $scope.record.email = '';
    }
    $scope.record.product = (data.record.issueDetail && data.record.issueDetail !== data.record.issue)
        ? data.record.issue + ' -- ' + data.record.issueDetail : data.record.issue;
    $scope.record.build = '';
    $scope.record.platform = '';
    $scope.record.reporter = data.record.owner;
    QMSFactory.loadOneUser(data.record.owner).success(function(result) {
        $scope.record.reporter = (result.data && result.data[0] && result.data[0].fullname) || $scope.record.reporter;
    });
    $scope.record.description = data.record.description;
    $scope.ui = { initialDateOpened: false, supplierChecked: false, supplierInitialDateOpened: false, valid: false };

    // consolidate platform choices
    $scope.ui.platforms = [];
    var platformsAdded = new Set();
    function addPlatform(platform, list) {
        if (platformsAdded.has(platform)) return;
        platformsAdded.add(platform);
        if (platform <= 1000) {
            if (qmsVocabulary.licensePlatforms.has(platform)) {
                list.push(qmsVocabulary.licensePlatforms.get(platform));
            }
        }
        else {
            platform -= 1000;
            if (qmsVocabulary.licenseSubvendors.has(platform)) {
                list.push('CSMC Vendor: ' + qmsVocabulary.licenseSubvendors.get(platform));
            }
        }
    }
    // keep CSMC Direct on top
    addPlatform(qmsVocabulary.platformCSMCDirect(), $scope.ui.platforms);
    // then add minors (more likely to appear in CRM than majors) sorted alphabetically
    var minors = [];
    qmsVocabulary.licenseSubvendors.forEach(function(v, k) {
        // skip the duplicate CSMC Direct or inactive ones
        if (k === 2 || !qmsVocabulary.isLicenseSubvendorActive(k)) return;
        addPlatform(k + 1000, minors);
    });
    minors.sort((a, b) => a.localeCompare(b));
    $scope.ui.platforms = $scope.ui.platforms.concat(minors);
    // then majors with their own csplatforms sorted alphabetically
    var majors = [];
    qmsVocabulary.activeLicensePlatforms.forEach(function(v, k) {
        // skip internal ones and CSMC Direct and CSMC Vendor
        if (qmsVocabulary.isLicensePlatformInternal(k) || qmsVocabulary.platformHasSubvendors(k)) return;
        // skip platforms replaced by subvendor designations
        if (qmsVocabulary.platformToSubvendor.has(k)) return;
        addPlatform(k, majors);
    });
    majors.sort((a, b) => a.localeCompare(b));
    $scope.ui.platforms = $scope.ui.platforms.concat(majors);

    $scope.openInitialDate = function($event) {
        $event.preventDefault();
        $event.stopPropagation();
        $scope.ui.initialDateOpened = true;
    };

    $scope.openSupplierInitialDate = function($event) {
        $event.preventDefault();
        $event.stopPropagation();
        $scope.ui.supplierInitialDateOpened = true;
    };

    $scope.validate = function() {
        $scope.ui.valid = Boolean($scope.record.contact && $scope.record.customer && ($scope.record.phone || $scope.record.email) &&
            $scope.record.product && $scope.record.build && $scope.record.platform && $scope.record.reporter && $scope.record.description &&
            (!$scope.ui.supplierChecked || ($scope.record.supplier && $scope.record.supplierInitialDate && ($scope.record.supplierPhone || $scope.record.supplierEmail))));
    };

    $scope.save = function() {
        if ($scope.record.initialDate && $scope.record.initialDate instanceof Date) {
            $scope.record.initialDate = printISODate($scope.record.initialDate);
        }
        if ($scope.record.supplierInitialDate && $scope.record.supplierInitialDate instanceof Date) {
            $scope.record.supplierInitialDate = printISODate($scope.record.supplierInitialDate);
        }
        if (!$scope.ui.supplierChecked) {
            delete $scope.record.supplier;
            delete $scope.record.supplierInitialDate;
            delete $scope.record.supplierEmail;
            delete $scope.record.supplierPhone;
        }
        $uibModalInstance.close($scope.record);
    };

    $scope.cancel = function() {
        $uibModalInstance.dismiss('canceled');
    };

    // initialization
    $scope.validate();
});
