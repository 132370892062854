// WARNING: This does not work with QMS! The $.getScript() call doesn't work when deployed.
// CURRENTLY UNUSED.

/* globals gAIMDoc */
(function() {
    'use strict';
    angular
        .module('qms')
        .controller('AimDocViewCLController',AimDocViewCLController);

    function AimDocViewCLController( $modalInstance, $rootScope, $location, dialogs, AIMDocFactory, AuthTokenFactory, data )
    {
		var vm=this;
		vm.init = init;
		vm.close = closeDlg;
		vm.showInContext = showInContext;
		vm.showInContextLink = "";
		//   vm.watch= $rootScope.watch;
		vm.title = data.title;
		vm.cl = data.cl;
		
		function init() {
                // NOTE: This $.getScript() call does NOT work when deployed to production server.
			$.getScript("partial/aimdoc/aimdoc.js", function()
			{
					// Little bit of a hack: set these since the controller in aimdoc.js is never called to set them
                gAIMDoc.Dialogs = dialogs;
				gAIMDoc.AIMDocFactory = AIMDocFactory;
				gAIMDoc.AuthTokenFactory = AuthTokenFactory;
				
				console.log("Changelist: ", vm.cl);
				
				gAIMDoc.showOnlyChangelist = true;
				gAIMDoc.openAIMDoc( vm.cl.suite, vm.cl.name );
				
				vm.showInContextLink = "/#/aimdoc/openbycl/" + vm.cl.id;
			});
		}
		
		function closeDlg() {
            gAIMDoc.showOnlyChangelist = false;
			$modalInstance.close();
		}
		
		function showInContext() {
            $modalInstance.close();
			gAIMDoc.showOnlyChangelist = false;
		//	gAIMDoc.openAIMDoc( vm.cl.suite, vm.cl.name );

            $location.path("/aimdoc/openbycl/" + vm.cl.id);
		}
		
		vm.init();
	}

})();
