angular.module('qms').controller('AddfileCtrl',function($scope, $modalInstance, data){
	$scope.record = {};
	$scope.record.filename = data.name;
	$scope.record.display_name = data.name;
	$scope.record.size = data.size;

	$scope.init = function() {
		if ($scope.record.size > 1000000) {
			$scope.record.displaysize = ($scope.record.size / 1000000).toFixed(1) + "MB";
		} else {
			$scope.record.displaysize = Math.floor(($scope.record.size / 1000) + 1) + "K";
		}
	};

	$scope.pad =function(n, width, z) {
		z = z || '0';
		n = n + '';
		return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
	};

	$scope.save = function() {
		//alert(JSON.stringify(newfile));
		var currentdate = new Date();
		$scope.record.date_uploaded = currentdate.getFullYear() + "-" + $scope.pad((currentdate.getMonth()+1),2) + "-" + $scope.pad(currentdate.getDate(),2) + " ";
		$scope.record.date_uploaded += $scope.pad(currentdate.getHours(),2) + ":";
		$scope.record.date_uploaded += $scope.pad(currentdate.getMinutes(),2) + ":" + $scope.pad(currentdate.getSeconds(),2);

		$modalInstance.close($scope.record);
	};

	$scope.close = function() {
		$modalInstance.close();
	};

	$scope.init();
});
