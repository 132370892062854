
(function() {
    'use strict';
    angular.module('qms')
        .controller('CustomerController', CustomerController);
    
    function CustomerController($scope, $routeParams, $location, $filter, $window, dialogs, QMSFactory, qmsVocabulary, AuthTokenFactory, $timeout, uibDateParser, Title){
        var vm=this;
        vm.id = $routeParams["id"];
        vm.record= {
            name : "",
            supportends : "",
            address1 : "",
            address2 : "",
            city : "",
            state : "",
            zip : "",
            contacts : [],
            computers : [],
            installEvents: [],  // Installation and Servicing events
            training : [],
            files : [],
            sales : {},
            notes : "",
            purchasedsoftware : false,
            asmamount : "",             // Annual Support & Maintenaqnce amount
            owner: undefined,           // Site Guru
            reseller: undefined,
            database: {
                type: "N/A",
                notes: ""
            }
        };   // system
        vm.licenses = []; // this array corresponds to vm.record.computers array
        vm.sales = [];
        vm.saveInProgress = false;
        vm.timeout = null;
        vm.reminders = [];
        vm.owners = [];     // Users who are part of 'Support' grooup
        vm.user = null;     // Currently logged-in user
        vm.countryList = qmsVocabulary.combinedCountryList();


        vm.installVTPSchema = qmsVocabulary.installVTPSchema;

            // Validation Test Plan record. Each customer.installEvents[] entry has a vtp
        vm.baseVTP = {
            dateTimeOfValidation: "",
            usernameOfValidator: "",

                // Geoff's desired defaults for apps the customer has purchased. Later it would be cool to automatically get this info from the license key
            appsPurchased: {
                qpsqgs: true,
                qbs: true,
                csi: true,
                csview: false,
                arg: false
            },

                // These are created dynamically when this object is created
        /*    csi: {appOpens: 0},
            csview: {appOpens: 0},
            qpsqgs: {appOpens: 0},
            qbs: {appOpens: 0},
            qarg: {appOpens: 0}
        */

        //    QPS+QGS[x], QBS[x], CSView, QARG, CSI[x]]
        };

        vm.vtpStatusStrings = {
            0: "Not tested",
            1: "Passed",
            2: "Incomplete"
        };
        vm.vtpStatusStrings[-1] = "Failed";

            // Training Date picker
        vm.trainingDate = new Date();
        vm.trainingDateOpened = false;
        vm.trainingDateColWidth = "10%";

            // Installation and Servicing Date picker
        vm.installDate = new Date();
        vm.installDateOpened = false;
        vm.installDateColWidth = "10%";

        // status lists;
        vm.list = {
        //    users : [],     // Former hard-coded list of users (already stored in database like this):  ["alya","tejas","yadvinder","geoff","serge","piotr","parker","vern"],
            apps: ["Suite-All","QGS+QPS","ARG/QARG","AutoRecon/MoCo","QBS","Installation","Phase","CFR","QPET"],
            distributor: [ "CSMC Direct","SIEMENS USA","NIS","AIS","DIGIRAD" ],
            salesstatus: [ "Pending", "Complete" ],
            vendors: [],
            databaseTypes: [
                { label: 'N/A', value: 'N/A'},
                { label: 'Standalone (SQLite)', value: 'SQLite' },
                { label: 'Central (SQL Server)', value: 'SQL Server' },
                { label: 'Central (PostgreSQL)', value: 'PostgreSQL' }
            ]
        };

        vm.init = init;
        vm.open = openCustomer;
        vm.newSupport = newSupport;
        vm.removeTraining = removeTraining;
        vm.openTrainingDatePicker = openTrainingDatePicker;
        vm.startedEditingTraining = startedEditingTraining;
        vm.doneEditingTraining = doneEditingTraining;
        vm.editInstallTestPlan = editInstallTestPlan;
        vm.addInstallEvent = addInstallEvent;
        vm.removeInstallEvent = removeInstallEvent;
        vm.openInstallDatePicker = openInstallDatePicker;
        vm.startedEditingInstallEvent = startedEditingInstallEvent;
        vm.doneEditingInstallEvent = doneEditingInstallEvent;
        vm.getStyleForInstallTestPlan = getStyleForInstallTestPlan;
        vm.newVTP = newVTP;
        vm.addTraining = addTraining;
        vm.removeContact= removeContact;
        vm.addContact = addContact;
        vm.removeComputer= removeComputer;
        vm.addComputer = addComputer;        
        vm.addReminder = addReminder;
        vm.editReminder = editReminder;
        vm.loadReminders = loadReminders;
        vm.removeReminder = removeReminder;
        vm.reminderClass = reminderClass;
        vm.toggleLicense = toggleLicense;
        vm.newLicense = newLicense;
        vm.downloadLicense = downloadLicense;
        vm.save = save;
        vm.load = load;
        vm.loadSupport = loadSupport;
        vm.autoSave = autoSave;
        vm.getTodaysDate = getTodaysDate;
        vm.getDefaultUsername = getDefaultUsername;
        vm.newSurveyInvitation = newSurveyInvitation;

            // Support Incidents table
        vm.support = {
            enableRowSelection: true,
            enableSelectAll: true,
            showGridFooter: true,
            multiSelect: true,
            enableSorting: true,
            enableFiltering: true,
            enableFullRowSelection: true,
            enableRowHeaderSelection: true,

            data: [],
            cols: [
                {field:'dateopened', displayName:'Date Opened', type:'date', cellFilter: 'date:\'yyyy-MM-dd\'', width:'12%'},
            //    {field:'status', displayName: 'Status', cellFilter: 'supportIncidentStatusLabel', width: '10%'},  // REMOVED per Geoff/Tejas
                {field:'contact', displayName:'Contact'},
                {field:'description', displayName:'Description', width: '64%'},
                    // Delete button:

                    // Adding buttons with functioning ng-click IS EASY TO DO WRONG! See comments by removeSupportIncident() function later in this file!!!!
                {name:' ', displayName:' ', width: '6%', myType:'custom',
                    cellTemplate: "<div align='center' class='ui-grid-cell-contents'>" +
                    "<button class='btn btn-danger btn-xs' ng-click='grid.appScope.outerInjection.removeSupportIncident(rowRenderIndex)'><i class='fa fa-trash-o'></i></button></div>"},
            ],
            //onOpen: function(val) { console.log(val); }
            openLink: "support"     // see directive/cs_listcontrol.js > openLink()
        };

        $scope.supportEndsDatePopup = {
            dt: null,
            opened: false,
            format: 'yyyy-MM-dd',
            open: function($event) {
                $event.preventDefault();
                $event.stopPropagation();
                $scope.supportEndsDatePopup.opened = true;
            },
            sync: function() {
                if ($scope.supportEndsDatePopup.dt) {
                    var d = $scope.supportEndsDatePopup.dt;
                    vm.record.supportends = printISODate(d);
                }
                else
                    vm.record.supportends = null;
            },
            parse: function() {
                if (vm.record)
                    $scope.supportEndsDatePopup.dt = uibDateParser.parse(vm.record.supportends, 'yyyy-MM-dd');
                if ($scope.supportEndsDatePopup.dt === undefined) $scope.supportEndsDatePopup.dt = null;
            }
        };

        function init() {
            $(function () {
                $('[data-toggle="tooltip"]').tooltip();
            });

            vm.user = AuthTokenFactory.getUser();

            let customerID = $routeParams['id'];
            if (customerID) {
                vm.load(customerID);
                vm.loadReminders(customerID);
                Title.setTitle( customerID);    // Modern Angular method that sets the tab's title text
            }
            QMSFactory.getUsersForGroup("SUPPORT").success(function (result) {
                vm.owners = result.data;
            });

            qmsVocabulary.licenseVendorsActive.forEach(function(v) { vm.list.vendors.push(angular.copy(v)); });
            vm.list.vendors.push({ name: '', id: -1, platforms: [] });
            vm.list.vendors.sort(function(a, b) { return a.name.toLowerCase().localeCompare(b.name.toLowerCase()); });
        }

        function openCustomer($event) {
            $event.preventDefault();
            $event.stopPropagation();
            vm.opened = true;
        }

        function newSupport() {
            let defaultContactName = (vm.record.contacts.length > 0) ? vm.record.contacts[0].name : "";
            var data = { customerid: vm.id, contact: defaultContactName, owner: AuthTokenFactory.getUser().id };
            var dlg = dialogs.create('/ng1/dialogs/supportnew/supportnew.html','SupportNewCtrl', data, {key: false,back: 'static', size: 'lg'});
            dlg.result.then(function(data){
                if (typeof data === 'undefined') {
                    console.log("User Canceled");
                } else {
                    console.log(data);
                    $location.path("/support/" + data.id);
                }
            });
        }

        function autoSave(newVal, oldVal) {
            if (newVal !== oldVal) {
                if (vm.timeout) {
                    $timeout.cancel(vm.timeout);
                }
                vm.timeout = $timeout(vm.save, 1000);  // 1000 = 1 second
            }
        }

        function validateRecord(record) {
            if (record)
            {
                if (typeof record.computers === 'undefined')
                    record.computers=[];
                if (!record.reseller) 
                    record.reseller = -1;
            }
        }

        function load(id) {
            QMSFactory.loadCustomer(id).success( function(result) {
                vm.record=result.data;
                $scope.record = vm.record;
                if ($scope.record)
                {
                    validateRecord($scope.record);
                    vm.licenses = [];
                    vm.record.computers.forEach(function(v) { vm.licenses.push({isCollapsed: true, sets: []}); });

                        // Formerly, training.trainer was one of these hard-coded values: ["tejas","yadvinder","geoff","serge","piotr","parker","vern"]
                        // Now, it's a username. We convert from the former values to the usernames here. [Can remove this code if we ever do a query that does the conversion on all existing records.]
                    vm.record.training.forEach(function(t) {
                        if (t.trainer === "yadvinder")
                            t.trainer = "ysingh";
                        if (t.trainer === "tejas")
                            t.trainer = "parekh";
                    });
                    if (vm.record.database === undefined) {
                        vm.record.database = {
                            type: "N/A",
                            notes: ""
                        }
                    }
                }

                // parse supportends into a Date object
                $scope.supportEndsDatePopup.parse();
                $scope.$watch('record', vm.autoSave, true);

                console.log("Loaded customer: ", vm.record);
            });

            vm.loadSupport(id);
        }

            // Can pass vm.id
        function loadSupport(id)
        {
            QMSFactory.loadSupportForCustomer(id).success( function(result) {
                vm.support.data = result.data;
                $(window).resize();
            });
        }

        function save() {
            vm.saveInProgress = true;
            // sync supportends with the Date object before saving
            $scope.supportEndsDatePopup.sync();
            QMSFactory.saveCustomer( vm.record ).success( function(data) {
              // These were used when 'new' wasn't a dialog
              //  $location.path("/customer/" + data["id"]);
              //  vm.load(data["id"]);
                console.log("Save Success!");
                vm.saveInProgress = false;
            });
        }

        function addContact(){
            vm.inserted = { name: '', role: '', phone: '', email: '', isBillingContact: false };
            vm.record.contacts.push(vm.inserted);
        }

        function removeContact(index) {
            vm.record.contacts.splice(index, 1);
        }


        
            // ui-grid and hence csListControl have their own isolated scope. This means by default we
            // can't access functions we define and attach to $scope here from cell templates for things
            // like ng-click. ui-grid does provide access to the app $scope via grid.appScope, but this
            // is broken by csListControl introducing its own isolated scope on top of ui-grid, i.e.,
            // grid.appScope only sees csListControl's scope but not the app's. I sort of hacked
            // this by adding an "inject" binding to csListControl's scope for us to sneak things into
            // ui-grid scopes. The binding maps "inject" attribute to "outerInjection" in csListControl's
            // scope, making the visible in ui-grid scope. For example, grid.appScope.outerInjection.xxx
            // Here we have to callback functions (remove and edit) under gridCallbacks which are mapped
            // to grid.appScope.outerInjection.{remove|edit}
            // See HTML for where gridCallbacks is used.
            // outerInjection is used in the CSListControl -- we don't reference it in this file.
        $scope.gridCallbacks = {};
        $scope.gridCallbacks.removeSupportIncident = function(index) {
            let incident = vm.support.data[index];
            dialogs.confirm('Delete', 'Are you sure you want to delete this support interaction?<br /><br />Description:<br /> ' + incident.description).result
                .then( function() {
                    QMSFactory.deleteSupport( incident.id ).success(function(data) {
                        console.log("delete support interaction response: ", data);

                        vm.loadSupport( vm.id );
                    });
                }, function() {
                    console.log("User canceled deleting reminder");
                } );
        };

        function addComputer(){
            vm.inserted = { name: '', notes: ''};
            vm.record.computers.push(vm.inserted);
            vm.licenses.push({isCollapsed: true, sets: []});
        }

        function removeComputer(index) {
            vm.record.computers.splice(index, 1);
            vm.licenses.splice(index, 1);
        }

        function addTraining() {
            vm.inserted = { trainer: vm.getDefaultUsername(), app: '', duration: '60', details: '', date: vm.getTodaysDate() };
            vm.record.training.push(vm.inserted);
        }

            // We do this instead of new Date()).toISOString().split("T")[0], because that strips out time zone information, and can result in it showing
            // *tomorow's* date.
        function getTodaysDate() {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();

            return yyyy + "-" + mm + '-' + dd;
        }

        function getDefaultUsername() {
            let defaultUsername = "";
            for (let n=0; n < vm.owners.length; n++) {
               if (vm.owners[n].username === vm.user.username) {
                   defaultUsername = vm.user.username;
                   break;
               }
            }
            return defaultUsername;
        }

        function removeTraining(index) {
            vm.record.training.splice(index, 1);
        }

        function openTrainingDatePicker(event)
        {
            event.preventDefault();
            event.stopPropagation();
            vm.trainingDateOpened = true;
        }

        function startedEditingTraining( training )
        {
            console.log("Started editing training: ", training);
            vm.trainingDateColWidth = "17%";
            vm.trainingDate = training.date;
        }

            // Called when training inline editor is saved OR canceled. Handles our custom date field.
        function doneEditingTraining( training, saved )
        {
            console.log("About to save trainingDate", vm.trainingDate, typeof vm.trainingDate);

            vm.trainingDateColWidth = "10%";

            if (saved)
            {
                if (vm.trainingDate && vm.trainingDate.getMonth) // This is how we check if an object is a Date object
                {
                    console.log("ISO string: ", vm.trainingDate.toISOString());
                    training.date = vm.trainingDate.toISOString().split("T")[0];

                    console.log("Training date fixed", training.date);
                }
                else
                    training.date = vm.trainingDate.split("T")[0];
            }
        }

        function openInstallDatePicker(event)
        {
            event.preventDefault();
            event.stopPropagation();
            vm.installDateOpened = true;
        }

            // Add Installation or Servicing Event
        function addInstallEvent()
        {
            if ( typeof vm.record.installEvents === 'undefined' )
                vm.record.installEvents = [];
            vm.inserted = { date: vm.getTodaysDate(), version: '', supportrep: vm.getDefaultUsername(), description: '', installtestplan: vm.newVTP(), status: 0 };
            vm.record.installEvents.push(vm.inserted);
        }

        function newVTP()
        {
            let obj = angular.copy( vm.baseVTP );

            for (let appVar in vm.installVTPSchema)
                for (let questionVar in vm.installVTPSchema[appVar].questions)
                {
                    if (typeof obj[appVar] === 'undefined')
                        obj[appVar] = {};
                    obj[appVar][questionVar] = 0;    // 0 = Not started
                }

            console.log("New vtp: ", obj);

            return obj;
        }

        function getStyleForInstallTestPlan( installEvent )
        {
            if (installEvent.status === 2)
                return { "color": "orange"};
            else if (installEvent.status === -1)
                return { "color": "red"};
            else if (installEvent.status === 1)
                return { "color": "green"};

            return { "color": "black"};
        }

        function removeInstallEvent(index) {
            vm.record.installEvents.splice(index, 1);
        }

        function editInstallTestPlan( installevent, canEdit )
        {
        //    if (canEdit)
            {
                console.log("Edit Install Test plan clicked with install event: ", installevent);
                var tp = angular.copy(installevent.installtestplan);
                tp.supportrep = installevent.supportrep || ''; // also pass supportrep to installtestplan dialog for display if usernameOfValidator is missing
                var dlg = dialogs.create('/ng1/dialogs/installtestplan/installtestplan.html','InstallTestPlanCtrl', tp, {key: false,back: 'static'});

                dlg.result.then(function(data)
                {
                    let allAppTestsPass = true;
                    let anyAppTestFailed = false;
                    if (data.supportrep !== undefined) delete data.supportrep; // remove supportrep, which was just for display, from installtestplan

                        // Determine if all tests passed, or some failed, or any are incomplete
                    for (let appVar in vm.installVTPSchema)    // Iterate all apps in schema
                        if ( data.appsPurchased[appVar] )      // App was purchased by this customer?
                        {
                                // Iterate all questions for this app
                            for (let questionVar in vm.installVTPSchema[appVar].questions)
                            {
                                if ( typeof data[appVar] !== 'undefined')
                                {
                                    if (data[appVar][questionVar] !== 1)
                                        allAppTestsPass = false;
                                    if (data[appVar][questionVar] === -1)
                                        anyAppTestFailed = true;
                                }
                            }
                        }


               /*     for (let key in data.appsPurchased)
                        if ( data.appsPurchased[key] )      // App was purchased?
                        {
                            if (data[key].appOpens !== 1)
                                allAppTestsPass = false;
                            if (data[key].appOpens === -1)
                                anyAppTestFailed = true;
                        }
                */

                    installevent.status = 2; // 2 = incomplete, -1 = one or more test failed
                    if (anyAppTestFailed)
                        installevent.status = -1;
                    else if (allAppTestsPass)
                        installevent.status = 1;

                    data.dateTimeOfValidation = (new Date()).toISOString();
                    data.usernameOfValidator = vm.getDefaultUsername();
                    if (data.usernameOfValidator.length === 0)
                        data.usernameOfValidator = installevent.supportrep;

                    console.log("Updated test plan: ", data);
                    console.log("Install event: ", installevent);

                    installevent.installtestplan = data;
                }, function() {
                    console.log("User canceled editing install test plan.");
                });
            }
        }

        function startedEditingInstallEvent( installEvent )
        {
            console.log("Started editing install/servicing event: ", installEvent );
            vm.installDateColWidth = "17%";
            vm.installDate = installEvent.date;
        }

            // Called when training inline editor is saved OR canceled. Handles our custom date field.
        function doneEditingInstallEvent( installEvent, saved )
        {
            console.log("About to save install/servicing date:", vm.installDate, typeof vm.installDate);

            vm.installDateColWidth = "10%";

            if (saved)
            {
                if (vm.installDate && vm.installDate.getMonth) // This is how we check if an object is a Date object
                {
                    console.log("ISO string: ", vm.installDate.toISOString());
                    installEvent.date = vm.installDate.toISOString().split("T")[0];

                    console.log("Training date fixed", installEvent.date);
                }
                else
                    installEvent.date = vm.installDate.split("T")[0];
            }

        }

        function loadReminders(id) {
            QMSFactory.loadReminderForCustomer(id).success( function(result) {
                vm.reminders = result.data;
                vm.reminders.sort(function(a, b) {
                    // completed last then by due date
                    if (a.status === 'completed' && b.status !== 'completed') return +1;
                    else if (a.status !== 'completed' && b.status === 'completed') return -1;
                    else if (a.due > b.due) return +1;
                    else if (a.due < b.due) return -1;
                    else if (a.timestamp > b.timestamp) return +1;
                    else if (a.timestamp < b.timestamp) return -1;
                    else return 0;
                });
            });
        }

        function addReminder() {
            var dlg = dialogs.create('/ng1/dialogs/reminder/remindernew.html', 'ReminderNewCtrl',
                {source: 'customer/' + vm.id}, {key: false, back: 'static'});
            dlg.result.then(function() {
                // console.log(data);
                vm.loadReminders(vm.id);
            }, function() {
                console.log("User canceled adding reminder");
            });
        }

        function editReminder(index) {
            if (!(vm.reminders[index] && vm.reminders[index].id)) return;
            var dlg = dialogs.create('/ng1/dialogs/reminder/reminderedit.html', 'ReminderEditCtrl',
                vm.reminders[index], {key: false, back: 'static'});
            dlg.result.then(function() {
                // console.log(data);
                vm.loadReminders(vm.id);
            }, function() {
                console.log("User canceled editing reminder");
            });
        }

        function removeReminder(index) {
            if (!(vm.reminders[index] && vm.reminders[index].id)) return;
            dialogs.confirm('Delete Reminder', 'Are you sure you want to delete the reminder?').result
                .then(function() {
                    QMSFactory.removeReminder(vm.reminders[index].id).success(function(data) {
                        console.log("Reminder " + vm.reminders[index].id + " removed!");
                        vm.loadReminders(vm.id);
                    });
                }, function() {
                    console.log("User canceled deleting reminder");
                });
        }

        function reminderClass(reminder) {
            var yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);
            if (reminder.status === 'completed') return 'completedReminder';
            else if (new Date(reminder.due) < yesterday) return 'overdueReminder';
            else return '';
        }

        function toggleLicense(index) {
            var license = vm.licenses[index], computer = vm.record.computers[index];
            if (!license || !computer || !computer.system_id) return;
            license.isCollapsed = !license.isCollapsed;
            if (!license.isCollapsed && license.sets.length === 0) {
                license.systemId = "Loading...";
                // load licenses on demand
                QMSFactory.loadCurrentLicense(computer.system_id, null, false).success(function(result) {
                    var data = result.data;
                    if (!data || !data.current || !data.current.forEach) return;
                    // license.current = data.current;
                    license.systemId = data.prettyId || data.systemId;
                    license.normalizedId = data.systemId;
                    // if (data.platform !== undefined) {
                    //     license.systemId += ' (' + $filter('licensePlatformLabel')(data.platform) + ')';
                    // }
                    // go through license sets of this System ID
                    data.current.forEach(function(set) {
                        if (!set.events || !set.events[0] || !set.events[0].licenses || !set.events[0].licenses.forEach) return;
                        // group licenses in this set by qty and type
                        var event = set.events[0], groups = [], grouped = [];
                        event.licenses.forEach(function(license) {
                            if (!license.qty || !license.type) return; // ignore qty and type of undefined or 0
                            var group = groups.find(function(g) {
                                return g.qty === license.qty && g.type === license.type &&
                                    (g.type !== qmsVocabulary.licenseDurationCustomized || g.duration === license.duration);
                            });
                            if (group) {
                                group.features.push(license.feature);
                            }
                            else {
                                group = { qty: license.qty, type: license.type, duration: license.duration, start: license.start, features: [license.feature] };
                                groups.push(group);
                            }
                        });
                        // sort the groups by type and then qty
                        groups.sort(function(a, b) { return a.type !== b.type ? a.type - b.type : b.qty - a.qty; });
                        // one line per group
                        groups.forEach(function(group) {
                            var features = [], featureList = '';
                            group.features.forEach(function(feature) {
                                var i = qmsVocabulary.licenseFeatures.findIndex(function(f) { return f.id === feature; });
                                if (i >= 0) features.push(i);
                            });
                            if (features.length > 0) {
                                features.sort(function(a, b) { return a - b; });
                                features.forEach(function(i) { featureList += ', ' + qmsVocabulary.licenseFeatures[i].name; });
                                featureList = featureList.slice(2);
                                grouped.push({
                                    qty: $filter('licenseQuantityLabel')(group.qty),
                                    type: $filter('licenseDurationLabel')(group),
                                    exp: $filter('licenseExpirationDate')(group),
                                    features: featureList,
                                });
                            }
                        });
                        license.sets.push({
                            systemId: license.systemId,
                            subId: set.sub_id,
                            grouped: grouped,
                            vendor: event.vendor,
                            user: event.userName || event.user,
                            date: event.date,
                            suite: event.suite,
                            licenses: event.licenses,
                        });
                    });
                });
            }
        }

        function newLicense(index) {
            var computer = vm.record.computers[index];
            if (!computer || !computer.system_id) return;
            $location.path('/regkey/' + computer.system_id);
        }

        function downloadLicense(set) {
            // console.log(set);
            // generate license file content
            var text, element;
            text = '#ID\n';
            text += set.systemId + '\n';
            set.licenses.forEach(function(v) {
                if (v.qty > 0 && v.key) {
                    text += '# ' + $filter('licenseQuantityLabel')(v.qty) + ': ' + $filter('licenseFeatureLabel')(v.feature) +
                        ' (' + $filter('licenseDurationLabel')(v);
                    if (v.duration !== 0) text += ' - Expires: ' + $filter('licenseExpirationDate')(v);
                    text += ')\n';
                    text += v.key + '\n';
                }
            });
            text += '\n';
            element = $window.document.createElement('a');
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
            element.setAttribute('download', set.systemId + '#' + set.subId + '.lic');
            element.style.display = 'none';
            $window.document.body.appendChild(element);
            element.click();
            $window.document.body.removeChild(element);
        }

        function newSurveyInvitation() {
            var data = { contacts: vm.record.contacts };
            var dlg = dialogs.create('/ng1/dialogs/surveyinvite/invite.html', 'SurveyInviteDlgCtrl', data, { key: false, back: 'static' });
            dlg.result.then(function(data){
                if (typeof data === 'undefined') {
                    console.log("User Canceled");
                }
                else {
                    console.log(data);
                    if (data.invited) {
                        if (vm.record.surveys === undefined) vm.record.surveys = [];
                        vm.record.surveys.push({
                            ticket: data.ticket || null,
                            invited: data.invited.name || data.invited,
                            date: printISODateTimeLocal(new Date())
                        });
                        vm.save();
                    }
                }
            });
        }

        vm.init();
    }

})();
