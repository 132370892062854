angular.module('qms').controller('ActivateCtrl',function($scope, $http, $q, $routeParams, $dialogs, $location, Title){
    $scope.id = $routeParams["id"];
    $scope.record = {}; //activation event
    $scope.system = {}; //system information

    $scope.sitename = "";
    $scope.init = function() {
        Title.setTitle("Activate " + $scope.id);

        $http.get('/api/event/' + $scope.id).success(function(data) {
            $scope.record = data;
            $http.get('/api/system/' + $scope.record.systemid).success(function(data) {
                $scope.system = data;
                if ( $scope.siteSelected() ) {
                    $http.get('/api/site/' + $scope.system.site).success(function(data) {
                       $scope.sitename = data.name;
                    });
                } else {
                    $scope.sitename = '-- unspecified --';
                }
            });
        });
    };

    $scope.siteSelected = function(allowUnspecified) {
        allowUnspecified = typeof allowUnspecified !== 'undefined' ? allowUnspecified : false;

        if (typeof $scope.system.site === 'undefined') {
            return false;
        } else {
            return (allowUnspecified || $scope.system.site !== 'unspecified');
        }
    };

    $scope.saveSystem = function() {
        var xsrf = $.param({data: JSON.stringify($scope.system)});
        $http({
            method: 'POST',
            url: '/api/system',
            data: xsrf,
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).success(function(data) {
            // TODO: display the check and move to next
        });
    };

    $scope.skipSite = function() {
        $scope.sitename = '-- unspecified --';
        $scope.system.site = 'unspecified';
        $scope.saveSystem();
    };

    $scope.createSite = function() {
       // dlg = $dialogs.error('This is my error message');
        var dlg = $dialogs.create('/ng1/dialogs/addsite/addsite.html','AddsiteCtrl',{},{key: false,back: 'static'});
        dlg.result.then(function(data){
            $scope.sitename = data.name;
            $scope.system.site = data._id;
            $scope.saveSystem();
        },function(){
            $scope.sitename = '-- unspecified --';
        });
    };

    $scope.viewSystem = function() {
        // dlg = $dialogs.error('This is my error message');
        var dlg = $dialogs.create('/ng1/dialogs/system/system.html','SystemDialogCtrl',{id: $scope.system.systemid},{key: false,back: 'static'});
        dlg.result.then(function(data){

        },function(){

        });
    };

    $scope.displayStatus = function() {
      var status = "New System";
      if (typeof $scope.record.details === 'undefined') {
        return "";
      }

      for (var i=0;i<$scope.record.details.length;++i) {
          if ($scope.record.details[i].action !== "new") {
            status = "Updating System";
          }
      }
      status+= " (" + $scope.record.details.length + " items)";
      if ($scope.record.details.length <= 0 ) {
          status = "System up-to-date";
      }
      return status;
    };

    $scope.viewLicense = function() {
        // dlg = $dialogs.error('This is my error message');
        var dlg = $dialogs.create('/ng1/dialogs/license/license.html','LicenseDialogCtrl',{id: $scope.id},{key: false,back: 'static'});
        dlg.result.then(function(data){
            $scope.init();  //reload
        },function(){

        });
    };

    $scope.confirm = function() {
        var xsrf = $.param({data: JSON.stringify($scope.record)});
        $http({
            method: 'POST',
            url: '/api/event/' + $scope.id,
            data: xsrf,
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).success(function(data) {
           if (!$scope.record.isOffline) {
               window.location = "/api/onlinefile/" + $scope.system.systemid;
           } else {
               $scope.init();
           }

        });
    };

    $scope.init();


});