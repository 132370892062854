angular.module('qms').controller('VendorRequestApprovalController', function($filter, $routeParams, $window, $q, QMSFactory, AuthTokenFactory, qmsVocabulary, dialogs, Title) {
    'use strict';

    var vm = this;
    vm.req = null;
    vm.user = null;

    // Steps of approving a vendor request and issuing licenses
    // 0: look up system ID (this is done automatically upon initialization)
    // 1: fill out customer/computer (if the System ID is not found as an existing system of a customer)
    // 2: review features requested by vendor
    // 3: requesting licenses and awaiting license generation
    // 4: licenses retrieved
    vm.step = 0;

    // system info
    vm.system = {
        id: '',                 // ID of the system to issue licenses to (prettified after looking up in backend)
        normalizedId: '',       // normalized (no dashes, all uppercase) System ID
        suiteNum: '',           // Suite # from suffix of System ID
        details: undefined,     // details of the System ID after decoding by backend
        platform: -1,           // Platform ID embedded in the System ID
        hasOldLicense: false,   // whether this system has any old licenses
        isFloatingLicenseVM: false, // whether this System ID is a floating license server running in a VM
        isNodeLockedVM: false,  // whether this System ID is an attempt to use node-locked licenses in a VM
        reset: function() {
            this.id = this.normalizedId = this.suiteNum = ''; this.subId = undefined; this.details = undefined; this.platform = -1;
             this.hasOldLicense = false; this.isFloatingLicenseVM = false; this.isNodeLockedVM = false;
         },
    };

    // UI properties (button text, CSS class, component state)
    vm.ui = {
        systemLookUp: {             // system look up button
            // button: 'Look Up',
            status: '',
            statusClass: '',
            reset: function() { /* this.button = 'Look Up'; */ this.status = ''; this.statusClass = ''; },
            setWorking: function() { this.status = 'Working on it...'; this.statusClass = ''; },
            setFound: function() { this.status = 'Found this system'; this.statusClass = ''; },
            setNewSystem: function() { this.status = 'This is a new system'; this.statusClass = 'has-warning'; },
            setInvalid: function() { this.status = 'Invalid System ID'; this.statusClass = 'has-error'; },
            setIdSaved: function() { this.status = 'System ID saved'; this.statusClass = ''; },
            setError: function() { this.status = 'Unable to communicate with the server. Please try again'; this.statusClass = 'has-error'; },
        },
        submitButton: 'Generate',   // license request submit button
        detailsCollapsed: true,     // system detail collapse-able
        globalQuantity: 1,          // choice of across-the-board license quantity
        globalType: 1,              // choice of across-the-board license duration type
        globalTypeOrg: 1,           // old value of globalType
        // globalPackage: null,        // choice of pre-defined license packages
        alreadyReplacedShown: false,
        reset: function() {
            this.systemLookUp.reset(); 
            this.submitButton = 'Generate'; 
            this.detailsCollapsed = true; 
            this.globalQuantity = 1; 
            this.globalType = 1;
            this.globalTypeOrg = 1;
            this.alreadyReplacedShown = false;
        },
    };

    // customer selection data
    vm.customer = {
        data: [],                   // array of customer candidates
        selected: null,             // id of selected customer
        name: '',                   // name of selected customer
        location: '',               // location of selected customer
        locationClass: '',          // CSS class for location
        query: '',                  // keywords to query for customers
        system: {                   // systems of selected customer
            data: [],               // array of system candidates
            showGroups: false,      // whether to show groups in the system drop down
            selected: null,         // index of selected system (systems have no primary keys)
            name: '',               // name of selected system
            notes: '',              // notes of selected system
            id: '',                 // System ID of selected system
            reset: function() { this.data = []; this.showGroups = false; this.selected = null; this.name = ''; this.notes = ''; this.id = ''; },
            setName: function(system) { this.name = system.name || ''; this.notes = system.notes || ''; this.id = system.system_id || ''; },
        },
        reset: function() { this.data = []; this.selected = null; this.name = this.location = this.locationClass = this.query = ''; this.system.reset(); },
        setName: function(customer) {
            this.name = customer.name || '';
            this.location = (customer.city || '') + ' ' + (customer.state || '') + ' ' + (customer.zip || '')
                + (customer.country ? (', ' + customer.country) : '');
            this.locationClass = '';
        }
    };

    // license, current and request
    vm.license = {
        baselineId: '',             // System ID whose license history is to be the baseline for new licenses (normalized as soon as possible)
        // baselinePlatform: -1,       // Platform ID embedded in baselineId
        isReplacing: false,         // whether this system is created to replace another (if true, replacing is to be replaced and must be != vm.system.normalizedId)
        replacingLocked: false,     // whether to lock down the replacement UI controls
        replacing: '',              // system to be replaced
        current: [],                // current license sets of this system
        currentChoices: [],         // choices of current license sets (plus creating a new set)
        currentSelIdx: -1,          // selected choice index
        currentSelected: {},        // events[0] of the selected license set, if available
        currentPrompt: '',
        issued: undefined,          // licenses issued by backend
        request: {                  // license request to send to backend
            vendor: null,
            reference: '',
            reconciled: false,
            note: '',
            newEnc: true,           // new 2015.11+ encryption
            features: [],
        },
        durations: [],              // list of duration types (don't change after initialization)
        quantities: [],             // list of quantity options (don't change after initialization)
        // packages: [],               // list of common packages (don't change after initialization)
        features: [],               // list of features and their states
        noFeatureSelected: true,
        allowsPowerPoint: false,    // whether we're allowing adding PowerPoint
        resetFeatures: function() {
            this.features = qmsVocabulary.licenseFeaturesCopy();
            this.features.forEach(function(v) {
                v.selected = false;
                v.qty = 1;
                v.type = 1;
                v.orgSelected = false;
                v.orgQty = 0;
                v.orgType = 0;
                v.orgExp = '';
                // per-feature copy to accommodate optional "reuse"
                // filter out the custom duration option only to be used by the global duration dropdown as a place holder (days<-1)
                v.durations = this.durations.filter(function(u) { return u.days >= -1; });
            }, this);
        },
        reset: function() {
            this.baselineId = ''; // this.baselinePlatform = -1;
            this.isReplacing = this.isReplacement = this.lockReplacing = false; this.replacing = ''; this.issued = undefined;
            this.current = []; this.currentSelIdx = -1; this.currentSelected = {}; this.currentPrompt = ''; this.vendors = [];
            var r = this.request; r.vendor = null; r.reference = ''; r.reconciled = false; r.note = ''; r.newEnc = true; r.features = [];
            this.resetFeatures();
        },
        setCurrent: function(current, isReplacing) {
            this.current = current || [];
            this.currentChoices = [];
            this.current.forEach(function(v, i) {
                this.currentChoices.push({idx: i, text: 'License Set ' + v.sub_id});
            }, this);
            if (isReplacing) {
                if (this.currentChoices.length === 0) {
                    this.currentChoices.push({idx: -2, text: 'No Prior Licenses'});
                }
            }
            else {
                this.currentChoices.push({idx: -1, text: 'New License Set'});
            }
            this.currentSelIdx = this.currentChoices[0].idx;
            switch (this.current.length) {
                case 0: this.currentPrompt = 'This system has no prior license sets'; break;
                case 1: this.currentPrompt = 'This system has one existing set of licenses'; break;
                default: this.currentPrompt = 'This system has multiple sets of licenses'; break;
            }
        }
    };

    // event handler of customer drop down
    vm.onCustomerChange = function() {
        var v = vm.customer, customer, computers, match, withoutId = [], withId = [];
        v.setName({}); v.system.reset();
        customer = v.data.find(function(c) { return c.id === v.selected; });
        if (customer !== undefined) {
            v.setName(customer);
            // populate the list of systems
            computers = angular.copy(customer.computers); // deep copy as we're making changes
            if (computers && computers.forEach) {
                computers.forEach(function(c, i) {
                    c.idx = i;
                    if (c.system_id === vm.system.normalizedId) match = c;
                    else if (c.system_id) {
                        c.group = 'Replacing a system with an existing ID';
                        c.label = c.name + ' (' + $filter('prettifySystemId')(c.system_id) + ')';
                        withId.push(c);
                    }
                    else {
                        c.group = 'Assign to a system with no existing ID';
                        c.label = c.name;
                        withoutId.push(c);
                    }
                });
            }
            // system ID match found?
            if (match) {
                v.system.data = [match];
                v.system.showGroups = false;
                v.system.selected = match;
                v.system.setName(match);
            }
            else {
                v.system.data = withoutId.concat(withId);
                v.system.showGroups = true;
                v.system.selected = v.system.data[0] ? v.system.data[0] : null;
                v.system.setName(v.system.data[0] ? v.system.data[0] : {});
            }
        }
    };

    // event handler of system drop down
    vm.onSystemChange = function() {
        vm.customer.system.setName(vm.customer.system.selected || {});
    };

    // do customer search
    vm.findCustomer = function() {
        if (!vm.customer.query) return;
        vm.customer.location = 'Working on it...';
        QMSFactory.searchCustomer(vm.customer.query)
        .then(function(resp) {
            if (resp.status !== 200 || !resp.data || !resp.data.data) return $q.reject(new Error('Backend error'));
            vm.customer.data = resp.data.data;
            if (vm.customer.data.forEach) {
                vm.customer.data.forEach(function(v) { if (!v.computers) v.computers = []; });
            }
            if (vm.customer.data[0]) {
                vm.customer.selected = vm.customer.data[0].id;
                vm.onCustomerChange();
            }
            else {
                vm.customer.location = 'No match';
                vm.customer.locationClass = 'has-warning';
            }
        })
        .catch(function(error) {
            console.log(error);
            vm.customer.location = 'Unable to communicate with the server. Please try again';
            vm.customer.locationClass = 'has-error';
            dialogs.error('Key Generator Error', 'Unable to communicate with the server. Please try again.');
        });
    };

    // pop the dialog for creating a new customer
    vm.newCustomer = function() {
        dialogs.create('/ng1/dialogs/customernew/customernew.html', 'NewCustomerController as vm', {}).result
        .then(function(data) {
            // this is how NewCustomerController "cancels" (by close() w/o passing anything instead dismiss())
            if (typeof data === 'undefined') {
                console.log("User canceled adding customer");
            }
            else {
                // can only reliably expect id in the value returned by dialog
                QMSFactory.loadCustomer(data.id).success(function(result) {
                    vm.customer.data = [result.data];
                    if (!vm.customer.data[0].computers) vm.customer.data[0].computers = [];
                    vm.customer.selected = result.data.id;
                    vm.onCustomerChange();
                });
            }
        });
    };

    // pop the dialog for creating a new computer for a customer
    vm.newSystem = function() {
        var customer = vm.customer.data.find(function(c) { return c.id === vm.customer.selected; });
        if (!customer) return;
        var data = {
            customer: customer,
            name: (vm.req.system && vm.req.system.name) || '',
            notes: (vm.req.camera_sn && ('Camera/Workstation S/N: ' + vm.req.camera_sn)) || '',
        };
        dialogs.create('/ng1/dialogs/system/computernew.html', 'ComputerNewCtrl', data).result
        .then(function(data) {
            // data has just an id of the customer so reload that customer
            QMSFactory.loadCustomer(data.id).success(function(result) {
                // update the particular customer
                var i = vm.customer.data.findIndex(function(c) { return c.id === result.data.id; });
                if (i >= 0) {
                    vm.customer.data[i] = result.data;
                    if (!vm.customer.data[i].computers) vm.customer.data[i].computers = [];
                    // also update the system list if the customer is selected
                    if (result.data.id === vm.customer.selected) {
                        vm.onCustomerChange();
                        if (vm.customer.system.data.length > 1) {
                            // select the last one w/o a System ID, assuming it's the most recently added
                            for (i = vm.customer.system.data.length - 1; i >= 0; i--) {
                                if (!vm.customer.system.data[i].system_id) break;
                            }
                            if (i >= 0) {
                                vm.customer.system.selected = vm.customer.system.data[i];
                                vm.onSystemChange();
                            }
                        }
                    }
                }
            });
        }, function() {
            console.log("User canceled adding system");
        });
    };

    // assign the System ID to an existing system or make a new system with the ID to replace an existing system
    vm.assignSystemId = function() {
        if (!vm.customer.system.selected) return;
        var i, customer;
        var baselineId = vm.customer.system.selected.system_id;

        // assigning ID to an existing system that has none?
        if (!baselineId) {
            // sanity checks
            customer = vm.customer.data.find(function(c) { return c.id === vm.customer.selected; });
            if (!customer) return;
            i = vm.customer.system.selected.idx;
            if (i === undefined || !customer.computers[i]) return;
            // assign the ID and save the entire customer document
            customer.computers[i].system_id = vm.system.normalizedId;
            QMSFactory.saveCustomer(customer)
            .then(function(resp) {
                var i, temp;
                if (resp.status !== 200 || !resp.data || !resp.data.data || resp.data.data.id !== customer.id) return $q.reject(new Error('Backend error'));
                // |
                // go to step 2: review features requested by vendor
                // |
                vm.step = 2;
                vm.ui.systemLookUp.setIdSaved();
                // fill out customer info
                vm.customer.data = [customer];
                vm.customer.selected = customer.id;
                vm.customer.setName(customer);
                // fill out system info
                i = customer.computers.findIndex(function(c) { return c.system_id === vm.system.normalizedId; });
                if (i >= 0) {
                    temp = angular.copy(customer.computers[i]);
                    temp.idx = i;
                    vm.customer.system.data = [temp];
                    vm.customer.system.showGroups = false;
                    vm.customer.system.selected = temp;
                    vm.customer.system.setName(temp);
                }
            })
            .catch(function(error) {
                console.log(error);
                vm.ui.systemLookUp.setError();
                dialogs.error('Key Generator Error', 'Unable to communicate with the server. Please try again.');
            });
        }

        // replacing an existing system with a different ID by creating a new system with this ID?
        else if (baselineId !== vm.system.normalizedId) {
            // sanity checks
            customer = vm.customer.data.find(function(c) { return c.id === vm.customer.selected; });
            if (!customer) return;
            // prompt the user to create a new system
            dialogs.create('/ng1/dialogs/system/computernew.html', 'ComputerNewCtrl', {customer: customer, systemId: vm.system.normalizedId,
                name: vm.customer.system.selected.name + ' (replacement)', notes: vm.customer.system.selected.notes}).result
            .then(function(data) {
                // a series of backend calls need to be performed; use a promise chain here
                // 1) reload the customer with the newly added system
                QMSFactory.loadCustomer(data.id)
                .then(function(resp) {
                    if (resp.status !== 200 || !resp.data || !resp.data.data || !resp.data.data.id) return $q.reject('Backend error');
                    // update the particular customer
                    var i = vm.customer.data.findIndex(function(c) { return c.id === resp.data.data.id; });
                    if (i < 0) return $q.reject('Saved customer ID did not match');
                    vm.customer.data[i] = resp.data.data;
                    if (!vm.customer.data[i].computers) vm.customer.data[i].computers = [];
                    vm.customer.selected = resp.data.data.id;
                    vm.onCustomerChange(); // this should select the new system upon seeing the matching ID
                    // 2) load the licenses of the system to be replaced
                    return QMSFactory.loadCurrentLicense(baselineId, null, false);
                })
                .then(function(resp) {
                    if (resp.status !== 200 || !resp.data || !resp.data.data || !resp.data.data.systemId) return $q.reject(new Error('Backend error'));
                    vm.license.baselineId = resp.data.data.systemId;
                    // vm.license.baselinePlatform = resp.data.data.platform;
                    vm.license.setCurrent(resp.data.data.current, true);
                    vm.showCurrentLicense();
                    // |
                    // go to step 2: review features requested by vendor
                    // |
                    vm.step = 2;
                    vm.license.isReplacing = true;
                    vm.license.replacing = $filter('prettifySystemId')(vm.license.baselineId);
                    vm.license.replacingLocked = true;
                })
                .catch(function(error) {
                    console.log(error);
                    dialogs.error('Key Generator Error', 'Unable to communicate with the server. Please try again.');
                });
            }, function() {
                console.log("User canceled replacing system");
            });
        }
    };

    vm.showCurrentLicense = function() {
        var sel = vm.license.current[vm.license.currentSelIdx];
        vm.license.currentSelected = (sel && sel.events && sel.events[0]) || {};
        vm.license.currentSelected.vendorName = $filter('licenseVendorLabel')(vm.license.currentSelected.vendor);
        // vm.license.request.vendor = vm.license.vendors[0] && vm.license.vendors[0].id;
        // if (vm.license.currentSelected.vendor) {
        //     if (vm.license.vendors.some(function(v) { return v.id === vm.license.currentSelected.vendor; })) {
        //         vm.license.request.vendor = vm.license.currentSelected.vendor;
        //     }
        // }
        // vm.license.resetFeatures();
        vm.license.features.forEach(function(v) { v.orgSelected = false; });
        if (vm.license.currentSelected.licenses) {
            vm.license.currentSelected.licenses.forEach(function(v) {
                var feature = vm.license.features.find(function(f) { return f.id === v.feature; });
                if (feature !== undefined) {
                    /* feature.selected = */ feature.orgSelected = (v.qty > 0);
                    feature.orgQty = v.qty;
                    if (v.type !== undefined) {
                        feature.orgExp = $filter('licenseDurationLabel')(v.type);
                        if (v.type !== 1) feature.orgExp += ' @ ' + $filter('licenseExpirationDate')(v);
                        feature.orgType = v.type;
                    }
                    // feature.qty = feature.selected ? v.qty : 1;
                }
            });
        }
        vm.license.features.forEach(function(v) {
            // filter out the custom duration option only to be used by the global duration dropdown as a place holder (days<-1)
            v.durations = vm.license.durations.filter(function(u) { return u.days >= -1; });
        //     // add "reuse" when there's prior and quantity hasn't changed
        //     if (v.orgSelected && v.orgQty === v.qty) {
        //         v.durations.unshift({type: -1, name: (vm.license.isReplacing ? 'Replace' : 'Reuse') + ' (not extending)'});
        //         v.type = -1;
        //     }
        //     else {
        //         v.type = 1;
        //     }
        });
        vm.updateNoFeatureSelected();
        // warn about system already replaced
        vm.ui.alreadyReplacedShown = false;
        if (sel && sel.system_id && sel.replaced_by) {
            vm.ui.alreadyReplacedShown = true;
            if (sel.system_id !== vm.system.normalizedId) {
                dialogs.error('Already Replaced', 'License Set ' + sel.sub_id + ' of ' + $filter('prettifySystemId')(sel.system_id) +
                    ' has already been replaced by ' + $filter('prettifySystemId')(sel.replaced_by) + '. It should not be replaced again.');
            }
            else {
                dialogs.error('Already Replaced', 'License Set ' + sel.sub_id + ' of ' + $filter('prettifySystemId')(sel.system_id) +
                    ' has already been replaced by ' + $filter('prettifySystemId')(sel.replaced_by) + '. No new licenses should be issued to it.');
            }
        }
    };

    vm.updateNoFeatureSelected = function() {
        vm.license.noFeatureSelected = vm.license.features.every(function(v) { return !v.selected; });
    };

    // not using global feature modification controls
    // vm.updateReuse = function(index) {
    //     if (index < 0 || index >= vm.license.features.length) return;
    //     var feature = vm.license.features[index];
    //     if (feature.orgSelected && feature.orgQty === feature.qty) {
    //         // not already have reuse? add it
    //         if (feature.durations[0].type !== -1) {
    //             feature.durations.unshift({ type: -1, name: (vm.license.isReplacing ? 'Replace' : 'Reuse') + ' (not extending)'});
    //         }
    //     }
    //     else {
    //         // have reuse? remove it
    //         if (feature.durations[0].type === -1) {
    //             // if reuse was selected, change to the original type (do this before changing the options)
    //             if (feature.type === -1) feature.type = feature.orgType;
    //             feature.durations.shift();
    //         }
    //     }
    // };

    // not using global feature modification controls
    // vm.selectAllFeatures = function() {
    //     vm.license.features.forEach(function(v) { v.selected = true; });
    //     vm.updateNoFeatureSelected();
    // };

    // not using global feature modification controls
    // vm.selectNoFeatures = function() {
    //     vm.license.features.forEach(function(v) { v.selected = false; });
    //     vm.updateNoFeatureSelected();
    // };

    // not using global feature modification controls
    // vm.copyCurrentFeatures = function() {
    //     vm.license.features.forEach(function(v) { v.selected = false; });
    //     if (!vm.license.currentSelected || !vm.license.currentSelected.licenses) return;
    //     vm.license.currentSelected.licenses.forEach(function(v) {
    //         var feature = vm.license.features.find(function(f) { return f.id === v.feature; });
    //         if (feature !== undefined) {
    //             feature.selected = feature.orgSelected;
    //             feature.qty = feature.selected ? feature.orgQty : 1;
    //             feature.type = feature.selected ? -1 : 1;
    //         }
    //     });
    //     vm.updateNoFeatureSelected();
    // };

    vm.setGlobalQuantity = function() {
        var qty = vm.ui.globalQuantity;
        vm.license.features.forEach(function(v) {
            v.qty = qty;
            // certain features default to 99 floating if floating at all
            if (v.qty > 1 && qmsVocabulary.isLicenseFeatureUnlimitedFloating(v.id)) v.qty = 100;
        });
    };

    vm.setGlobalDuration = function() {
        var type = vm.ui.globalType;
        // special handling of licenseDurationCustom
        if (type === qmsVocabulary.licenseDurationCustom) {
            dialogs.create('/ng1/dialogs/license/customexpiration.html', 'LicenseCustomExpirationCtrl', {}, { size: 'md' }).result
            .then(function(expiration) {
                // zero out the time part for duration calculation (in days)
                expiration.setHours(0, 0, 0, 0);
                var today = new Date();
                today.setHours(0, 0, 0, 0);
                var days = Math.ceil((expiration - today) / 1000 / 86400) + 1;
                if (days <= 0) {
                    // revert the choice
                    vm.ui.globalType = vm.ui.globalTypeOrg;
                    return;
                }
                var customOpt = {
                    type: qmsVocabulary.licenseDurationCustomized,
                    name: 'Until ' + printISODate(expiration),
                    days: days,
                };
                // replace existing licenseDurationCustomized option
                if (vm.license.durations[vm.license.durations.length - 1].type === customOpt.type) {
                    vm.license.durations[vm.license.durations.length - 1] = customOpt;
                }
                // append licenseDurationCustomized option
                else {
                    vm.license.durations.push(customOpt);
                }
                // propagate the duration to all features
                vm.license.features.forEach(function(v) {
                    if (v.durations[v.durations.length - 1].type === customOpt.type) {
                        v.durations[v.durations.length - 1] = customOpt;
                    }
                    else {
                        v.durations.push(customOpt);
                    }
                    v.type = customOpt.type;
                });
                vm.ui.globalType = customOpt.type;
            }, function() {
                console.log('User canceled custom expiration date');
                // revert the choice
                vm.ui.globalType = vm.ui.globalTypeOrg;
            });
        }
        // a normal duration type was chosen
        else {
            // propagate the duration to all features
            vm.license.features.forEach(function(v) { v.type = type; });
            vm.ui.globalTypeOrg = vm.ui.globalType;
        }
    };

    // not using global feature modification controls
    // vm.setGlobalPackage = function() {
    //     var features = vm.ui.globalPackage;
    //     if (features && features.has) {
    //         vm.license.features.forEach(function(v) {
    //             v.selected = features.has(v.id);
    //             // if (v.qty <= 0) v.qty = vm.ui.globalQuantity;
    //             // if (v.type <= 0) v.type = vm.ui.globalType;
    //         });
    //         vm.updateNoFeatureSelected();
    //     }
    // };

    vm.featureDoubleClicked = function(index) {
        if (vm.license.features[index] && vm.license.features[index].id === 24) {
            vm.license.allowsPowerPoint = true;
        }
    };

    vm.toggleReplacement = function() {
        if (vm.license.isReplacing) {
            // pop a model dialog to ask for ID of system being replaced
            var data = {
                systemId: vm.system.normalizedId,
                replacedId: vm.req.system && vm.req.system.replacement && vm.req.system.replacement.id,
            };
            dialogs.create('/ng1/dialogs/license/replacement.html', 'LicenseReplacementCtrl', data).result
            .then(function(replacedId) {
                vm.license.replacing = replacedId;
                if (vm.license.replacing === 'N/A') {
                    vm.license.baselineId = vm.license.replacing;
                    vm.license.baselinePlatform = -1;
                    vm.license.replacing = 'System ID not available';
                    vm.license.setCurrent([], true);
                    vm.showCurrentLicense();
                }
                else {
                    vm.license.baselineId = replacedId;
                    QMSFactory.loadCurrentLicense(vm.license.baselineId, null, false)
                    .then(function(resp) {
                        if (resp.status !== 200 || !resp.data || !resp.data.data || !resp.data.data.systemId) return $q.reject(new Error('Backend error'));
                        vm.license.baselineId = resp.data.data.systemId;
                        vm.license.baselinePlatform = resp.data.data.platform;
                        vm.license.replacing = resp.data.data.prettyId || resp.data.data.systemId;
                        vm.license.setCurrent(resp.data.data.current, true);
                        vm.showCurrentLicense();
                    })
                    .catch(function(error) {
                        console.log(error);
                        dialogs.error('Key Generator Error', 'Unable to communicate with the server. Please try again.');
                    });
                }
            }, function() {
                console.log('User canceled replacing licenses');
                vm.license.isReplacing = false;
            });
        }
        else {
            // reload the original baseline
            vm.license.replacing = '';
            vm.license.baselineId = vm.system.normalizedId;
            QMSFactory.loadCurrentLicense(vm.license.baselineId, null, false)
            .then(function(resp) {
                if (resp.status !== 200 || !resp.data || !resp.data.data || !resp.data.data.systemId) return $q.reject(new Error('Backend error'));
                vm.license.baselineId = resp.data.data.systemId;
                vm.license.baselinePlatform = resp.data.data.platform;
                vm.license.setCurrent(resp.data.data.current, false);
                vm.showCurrentLicense();
            })
            .catch(function(error) {
                console.log(error);
                dialogs.error('Key Generator Error', 'Unable to communicate with the server. Please try again.');
            });
        }
    };

    vm.submitRequest = function() {
        var req, promise, err;
        if (vm.step === 4) {
            vm.downloadLicense();
        }
        else if (vm.step === 2) {
            // check for invalid vendor selection
            if (vm.license.request.vendor === undefined || vm.license.request.vendor < 0) {
                dialogs.error('Invalid Vendor', 'You have not selected a vendor.');
                return;
            }

            vm.step = 3;
            vm.ui.submitButton = 'Generating...';

            // not replacing?
            if (!vm.license.isReplacing) {
                // prepare the license request
                req = angular.copy(vm.license.request);
                req.systemId = vm.system.normalizedId;
                req.suiteNum = vm.system.suiteNum || '';
                req.subId = vm.license.current[vm.license.currentSelIdx] && vm.license.current[vm.license.currentSelIdx].sub_id; // this may be undefined which means we need to create a new license set
                req.features = [];
                vm.license.features.forEach(function(v) {
                    if (v.selected) {
                        var f = { feature: v.id, qty: v.qty, type: v.type };
                        if (v.type === qmsVocabulary.licenseDurationCustomized && v.durations[v.durations.length - 1].type === v.type) {
                            f.customDuration = v.durations[v.durations.length - 1].days || -1;
                        }
                        req.features.push(f);
                    }
                });
                // create a new license set if necessary
                if (req.subId === undefined) {
                    // make a promise that returns the Sub ID generated by backend
                    promise = QMSFactory.newLicenseSet(req.systemId)
                    .then(function(resp) {
                        return (resp.status === 200 && resp.data && resp.data.data && resp.data.data.subId !== undefined) ?
                            $q.resolve(resp.data.data.subId) : $q.reject(new Error('Backend error'));
                    });
                }
                else {
                    // make a fake promise that just returns the Sub ID we already have
                    promise = $q.resolve(req.subId);
                }
                // follow up with a license request
                promise.then(function(subId) {
                    req.subId = subId;
                    return QMSFactory.requestLicense(req);
                })
                .then(function(resp) {
                    if (resp.status !== 200 || !resp.data || !resp.data.data || !resp.data.data.current ||
                        !resp.data.data.current.events || !resp.data.data.current.events[0])
                        return $q.reject(new Error('Backend error'));
                    vm.license.issued = resp.data.data.current.events[0];
                    vm.license.issued.subId = req.subId;
                    vm.step = 4;
                    vm.ui.submitButton = 'Download';
                })
                .catch(function(error) {
                    console.log(error);
                    vm.step = 2;
                    vm.ui.submitButton = 'Try Again';
                    if (error.resp && error.resp.data && error.resp.data.data && error.resp.data.data.error) {
                        if (/cannot have new licenses/i.test(error.resp.data.data.error)) {
                            dialogs.error('Key Generator Error', 'License Set ' + req.subId + ' of ' + $filter('prettifySystemId')(req.systemId) +
                                ' has already been replaced and cannot have new licenses.');
                            return;
                        }
                    }
                    dialogs.error('Key Generator Error', 'Unable to communicate with the server. Please try again.');
                });
            }

            // replacing?
            else if (vm.license.isReplacing && vm.license.baselineId !== vm.system.normalizedId) {
                // prepare the license replacement request
                req = angular.copy(vm.license.request);
                req.replacedId = vm.license.baselineId;
                req.replacedSubId = (vm.license.current[vm.license.currentSelIdx] && vm.license.current[vm.license.currentSelIdx].sub_id) || null;
                req.systemId = vm.system.normalizedId;
                req.features = [];
                vm.license.features.forEach(function(v) {
                    if (v.selected) {
                        var f = { feature: v.id, qty: v.qty, type: v.type };
                        if (v.type === qmsVocabulary.licenseDurationCustomized && v.durations[v.durations.length - 1].type === v.type) {
                            f.customDuration = v.durations[v.durations.length - 1].days || -1;
                        }
                        req.features.push(f);
                    }
                });
                QMSFactory.replaceLicense(req)
                .then(function(resp) {
                    if (resp.status !== 200 || !resp.data || !resp.data.data || !resp.data.data.replacement ||
                        !resp.data.data.replacement.events || !resp.data.data.replacement.events[0]) {
                        err = new Error('Backend error');
                        err.resp = resp;
                        return $q.reject(err);
                    }
                    vm.license.issued = resp.data.data.replacement.events[0];
                    vm.license.issued.subId = resp.data.data.subId;
                    vm.step = 4;
                    vm.ui.submitButton = 'Download';
                })
                .catch(function(error) {
                    console.log(error);
                    vm.step = 2;
                    vm.ui.submitButton = 'Try Again';
                    if (error.resp && error.resp.data && error.resp.data.data && error.resp.data.data.error) {
                        if (/cannot be replaced again/i.test(error.resp.data.data.error)) {
                            dialogs.error('Key Generator Error', 'License Set ' + req.replacedSubId + ' of ' + $filter('prettifySystemId')(req.replacedId) +
                                ' has already been replaced and cannot be replaced again.');
                            return;
                        }
                    }
                    dialogs.error('Key Generator Error', 'Unable to communicate with the server. Please try again.');
                });
            }

            // noop
            else {
                vm.step = 2;
                vm.ui.submitButton = 'Generate';
            }
        }
    };

    vm.downloadLicense = function() {
        if (vm.step !== 4 || !vm.license.issued || !vm.license.issued.licenses) return;
        // generate license file content
        var text, element;
        text = '#ID\n';
        text += vm.system.id + '\n';
        vm.license.issued.licenses.forEach(function(v) {
            if (v.qty > 0 && v.key) {
                text += '# ' + $filter('licenseQuantityLabel')(v.qty) + ': ' + $filter('licenseFeatureLabel')(v.feature) +
                    ' (' + $filter('licenseDurationLabel')(v.type);
                if (v.duration !== 0) text += ' - Expires: ' + $filter('licenseExpirationDate')(v);
                text += ')\n';
                text += v.key + '\n';
            }
        });
        text += '\n';
        element = $window.document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', vm.system.id + (vm.license.issued.subId ? ('#' + vm.license.issued.subId) : '') + '.lic');
        element.style.display = 'none';
        $window.document.body.appendChild(element);
        element.click();
        $window.document.body.removeChild(element);
    };

    vm.copyToClipboard = function(str) {
        var doc = $window.document;
        var el = doc.createElement('textarea');         // Create a <textarea> element
        el.value = str;                                 // Set its value to the string that you want copied
        el.setAttribute('readonly', '');                // Make it readonly to be tamper-proof
        el.style.position = 'absolute';                 
        el.style.left = '-9999px';                      // Move outside the screen to make it invisible
        doc.body.appendChild(el);                       // Append the <textarea> element to the HTML document
        // var selected =            
        //   doc.getSelection().rangeCount > 0             // Check if there is any content selected previously
        //     ? document.getSelection().getRangeAt(0)     // Store selection if found
        //     : false;                                    // Mark as false to know no selection existed before
        el.select();                                    // Select the <textarea> content
        doc.execCommand('copy');                        // Copy - only works as a result of a user action (e.g. click events)
        doc.body.removeChild(el);                       // Remove the <textarea> element
        // if (selected) {                                 // If a selection existed before copying
        //   doc.getSelection().removeAllRanges();         // Unselect everything on the HTML document
        //   doc.getSelection().addRange(selected);        // Restore the original selection
        // }
    };

    //
    // initialization
    //

    Title.setTitle("Vendor Approvals");

    // initialize vm.license.durations
    qmsVocabulary.licenseDurations.forEach(function(v, k) {
        var name = v.name;
        if (v.days > 0) name += ' (' + v.days + ')';
        vm.license.durations.push({ type: k, name: name, days: v.days });
    });
    vm.license.durations.push({ type: qmsVocabulary.licenseDurationCustom, name: 'Custom...', days: -2 });
    // initialize vm.license.quantities
    angular.copy(qmsVocabulary.licenseQuantityOptions, vm.license.quantities);
    vm.license.reset();
    vm.user = AuthTokenFactory.getUser();

    // load the vendor request specified in path
    if ($routeParams['id']) {
        QMSFactory.loadVendorRequest($routeParams['id'])
        .success(function(data) {
            var temp, lic;
            if (data && data.data) vm.req = data.data;
                // Bug fix: If user did not enter any spaces between multiple comma-separate emails, it will display all on one line and be truncated.
                // Adding a space allows the additional emails to wrap onto another line. -VJ 3/1/2021
            if (vm.req && vm.req.cc)
            {
                let ccSplit = vm.req.cc.split(",");
                vm.req.cc = ccSplit.join(", ");
            }
            if (vm.req && vm.req.licenses) {
                lic = vm.req.licenses;
                vm.license.resetFeatures();
                // compile feature list of selected package and also initial feature selection for licenses
                if (lic.package && lic.package.features && lic.package.features.forEach) {
                    temp = [];
                    lic.package.features.forEach(function(f) {
                        temp.push($filter('licenseFeatureLabel')(f));
                        var feature = vm.license.features.find(function(f2) { return f2.id === f; });
                        if (feature !== undefined) {
                            feature.selected = true;
                            feature.qty = lic.qty;
                            feature.type = lic.type;
                            // certain features default to 99 floating if floating at all
                            if (feature.qty > 1 && qmsVocabulary.isLicenseFeatureUnlimitedFloating(feature.id)) feature.qty = 100;
                        }
                    });
                    lic.package.featureText = temp.join(', ');
                }
                // compile feature list of selected add-ons and also initial feature selection for licenses
                if (lic.add_on && lic.add_on.forEach) {
                    temp = [];
                    lic.add_on.forEach(function(f) {
                        temp.push($filter('licenseFeatureLabel')(f));
                        var feature = vm.license.features.find(function(f2) { return f2.id === f; });
                        if (feature !== undefined) {
                            feature.selected = true;
                            feature.qty = lic.qty;
                            feature.type = lic.type;
                            // certain features default to 99 floating if floating at all
                            if (feature.qty > 1 && qmsVocabulary.isLicenseFeatureUnlimitedFloating(feature.id)) feature.qty = 100;
                        }
                    });
                    lic.addOnText = temp.join(', ');
                }
                // automatically include RDP in licenses by default
                // 1) paid node-locked => 180 days RDP
                // 3) all else => RDP of same duration
                if (qmsVocabulary.licenseDurationsPaid.has(lic.type) && lic.qty === 1) { // paid node-locked
                    let feature = vm.license.features.find(function(f) { return f.id === 28; }); // 28: RDP
                    if (feature !== undefined && !feature.selected) {
                        feature.selected = true;
                        feature.qty = lic.qty;
                        feature.type = 6; // TEMP: Other 180 days
                        // certain features default to 99 floating if floating at all
                        if (feature.qty > 1 && qmsVocabulary.isLicenseFeatureUnlimitedFloating(feature.id)) feature.qty = 100;
                    }
                }
                else { // all else
                    let feature = vm.license.features.find(function(f) { return f.id === 28; }); // 28: RDP
                    if (feature !== undefined && !feature.selected) {
                        feature.selected = true;
                        feature.qty = lic.qty;
                        feature.type = lic.type; // same duration
                        // certain features default to 99 floating if floating at all
                        if (feature.qty > 1 && qmsVocabulary.isLicenseFeatureUnlimitedFloating(feature.id)) feature.qty = 100;
                    }
                }
                // automatically include PowerPoint in Hermes floating
                if (vm.req.vendorPlatform === 1017 && lic.qty > 1) { // 1017: Hermes
                    let feature = vm.license.features.find(function(f) { return f.id === 24; }); // 24: PowerPoint
                    if (feature !== undefined && !feature.selected) {
                        feature.selected = true;
                        feature.qty = /* lic.qty */100; // same as other unlimited floating features
                        feature.type = lic.type;
                    }
                }
                vm.license.allowsPowerPoint = qmsVocabulary.vendorGetsPowerPoint(vm.req.vendorPlatform);
                vm.license.request.reference = vm.req.ref;
                vm.license.request.vendorReqId = vm.req.id;
                vm.license.request.vendor = vm.req.vendorPlatform;
                vm.system.suiteNum = (vm.req.system && vm.req.system.suite) || '';
            }
            //
            // step 0 (automatic): look up existing licenses for the system
            //
            if (vm.req && vm.req.system && vm.req.system.id) {
                vm.system.id = vm.req.system.id;
                vm.ui.systemLookUp.setWorking();
                QMSFactory.loadCurrentLicense(vm.system.id, null, true)
                .then(function(resp) {
                    if (resp.status !== 200 || !resp.data || !resp.data.data) return $q.reject(new Error('Backend error'));
                    var data = resp.data.data;
                    // vm.ui.systemLookUp.button = 'Reset';
                    /* vm.license.baselinePlatform = */ vm.system.platform = (data.platform || data.platform === 0) ? data.platform : -1;
                    vm.system.details = data.details;
                    if (data.details && data.details.net_macid) {
                        vm.system.isFloatingLicenseVM = qmsVocabulary.isMacAddressFloatingLicenseVM(data.details.net_macid);
                        vm.system.isNodeLockedVM = qmsVocabulary.isMacAddressNodeLockedVM(data.details.net_macid);
                    }
                    else {
                        vm.system.isFloatingLicenseVM = false;
                        vm.system.isNodeLockedVM = false;
                    }
                    // show system ID prettified by the backend
                    vm.system.id = data.prettyId || data.systemId || vm.system.id;
                    vm.license.baselineId = vm.system.normalizedId = data.systemId;
                    vm.system.suiteNum = data.suiteNum || vm.system.suiteNum;
                    // found as an existing system of a customer?
                    if (data.customer) {
                        //
                        // go to step 2: review features requested by vendor
                        //
                        vm.step = 2;
                        vm.ui.systemLookUp.setFound();
                        // fill out customer info
                        vm.customer.data = [data.customer];
                        vm.customer.selected = data.customer.id;
                        vm.onCustomerChange();
                    }
                    // valid ID but not found?
                    else if (qmsVocabulary.licensePlatforms.has(data.platform)) {
                        //
                        // go to step 1: fill out customer/computer
                        //
                        vm.step = 1;
                        vm.ui.systemLookUp.setNewSystem();
                        QMSFactory.hasOldLicenses(vm.system.normalizedId).success(function(data) { vm.system.hasOldLicense = data.data && data.data.found; });
                        // JW 2022-03-01: per Alya's request, stop auto matching the customer and keep it manual only
                        // automatically search for the customer given in the vendor request
                        if (vm.req.customer && vm.req.customer.name) {
                            vm.customer.query = vm.req.customer.name;
                            // vm.findCustomer();
                        }
                    }
                    // invalid ID
                    else {
                        //
                        // stuck at step 0: should never happen as System ID should have been checked already by vendor request page
                        //
                        vm.step = 0;
                        vm.ui.systemLookUp.setInvalid();
                    }
                    // found existing licenses?
                    vm.license.setCurrent(data.current, false);
                    vm.showCurrentLicense();
                })
                .catch(function(error) {
                    console.log(error);
                    vm.ui.systemLookUp.setError();
                    dialogs.error('Key Generator Error', 'Unable to communicate with the server. Please try again.');
                });
            }
        });
    }
});
