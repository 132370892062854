(function() {
    'use strict';
    angular
        .module('qms')
        .controller('NewCustomerController', NewCustomerController);

    function NewCustomerController(dialogs, QMSFactory, qmsVocabulary, $modalInstance) {
        var vm = this;
        vm.init = init;
        vm.record = {
            training: [],
            files: [],
            contacts: [
                {
                    name: "",
                    role: "",
                    phone: "",
                    email: ""
                }
            ],
            reseller: -1,
            country: ""
        };
        vm.countryList = qmsVocabulary.combinedCountryList();

        vm.similar = [];
        vm.search = search;
        vm.save = save;
        vm.docancel = docancel;
        vm.ok = ok;
        vm.selectedRow = selectedRow;
        vm.optSelected = optSelected;
        vm.customer = {
            data: [],
            cols: [
                {field:'name', displayName:'Site', width:'20%'},
                {field:'city', displayName:'City', width:'15%'},
                {field:'state', displayName:'State', width:'10%'},
                {field:'country', displayName:'Country', width:'15%'},

                {field:'contacts', displayName:' ', width: '5%', myType:'custom',
                    cellTemplate: "<div class=\"ngCellText colt{{$index}}\" align='center'>" +
                    "<button popover-template=\"'/partial/search/contacts_template.html'\" " +
                    "popover-title=\"Contacts\" popover-trigger=\"focus\" type=\"button\" class=\"btn btn-sm btn-default\" popover-append-to-body=\"true\"><i class=\"fa fa-users\"></i></button>" +
                    "</div>"},
                {field:'notes', displayName:'Notes', width:'35%'}
            ],
           // openLink: "customer",
            onSelect: vm.selectedRow
        };

        vm.users = {
            data: [],
            cols: vm.user,
            onSelect: selectedRow,
            dialogs: [
                {
                    type: 'edit',
                    fields: vm.user,
                    template: "dialogs/user/user.html",
                    controller: "UserEditController"
                },
                {
                    type: 'delete'
                }
            ]
        };
        vm.optSelect = "";
        vm.selectedIndex = -1;
        vm.vendorOpts = [];

        function selectedRow(row) {
            if (row.isSelected) {
                vm.selectedIndex = row.entity.id;
                vm.optSelect = "existing";
            } else {
                vm.selectedIndex = -1;
            }
            console.log("selected row: " + vm.selectedIndex);
        }

        function optSelected(opt) {
            if (opt === "new") {
                $("#existingBody").find('*').prop('disabled',true);
                $("#newBody").find('*').prop('disabled',false);
            } else {
                $("#existingBody").find('*').prop('disabled',false);
                $("#newBody").find('*').prop('disabled',true);
            }
        }

        function search() {
            vm.working = true;
            if ( typeof vm.record.name !== 'undefined')
                QMSFactory.search(vm.record.name).success(function (data) {
                    vm.customer.data = data.data.customers;
                    vm.working = false;
                    if (typeof vm.customer.data !== 'undefined' && vm.customer.data.length > 0) {
                        $("#radioExisting").find('*').prop('disabled',false);
                        $("#noItems").hide();
                        $("#searchResults").show();
                    } else {
                        vm.optSelect = "new";
                        $("#radioExisting").find('*').prop('disabled',true);
                        $("#noItems").show();
                        $("#searchResults").hide();
                    }
                });
        }

        function init() {
            console.log("New Customer dialog controller entered.");
            $("#newBody").find('*').prop('disabled',true);
            $("#existingBody").find('*').prop('disabled',true);
            $("#noItems").show();
            $("#searchResults").hide();
            qmsVocabulary.licenseVendorsActive.forEach(function(v) { vm.vendorOpts.push(angular.copy(v)); });
            vm.vendorOpts.push({ name: '', id: -1, platforms: [] });
            vm.vendorOpts.sort(function(a, b) { return a.name.toLowerCase().localeCompare(b.name.toLowerCase()); });
        }

        function save() {
            if (!vm.record.country) {
                window.alert("Missing customer country!");
                return;
            }
            console.log("About to save customer record: ", vm.record);
            QMSFactory.saveCustomer(vm.record).success(function (data) {
                console.log("created new customer: " + data.data.id);
                $modalInstance.close(data.data);
            }).catch( function(error) {
                console.log("Save customer failed. Caught error: ", error);
            });
        }

        function ok() {
            if (vm.optSelect === 'new') {
                vm.save();
            } else {
                var ret = {id: vm.selectedIndex};
                $modalInstance.close(ret);
            }
        }

        function docancel() {
            $modalInstance.close();
        }

        vm.init();
    }
})();
