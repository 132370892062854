angular.module('qms').controller('ShowDataDlg',function($scope, $modalInstance, QMSFactory, qmsVocabulary, data)
{
    $scope.init = function()
    {
        $scope.gridConfig = data;
        console.log("The dialolg got data: ", data);
    };


    $scope.close = function()
    {
        $modalInstance.dismiss('canceled');
    };

    $scope.gridCallbacks = {};
    $scope.gridCallbacks.clickedItem = function( record )
    {
        console.log("Record clicked!", record);
        // Call callback passed in by user! Specified in data.clickCallback
    };

    $scope.init();
});
