angular.module('qms').controller('ReconciledCustomDatesCtrl', function($scope, $uibModalInstance, data) {
    'use strict';

    $scope.from = data.from || new Date();
    $scope.to = data.to || new Date();

    $scope.ok = function() {
        $uibModalInstance.close({ from: $scope.from, to: $scope.to });
    };
    $scope.cancel = function() {
        $uibModalInstance.dismiss('canceled');
    };
});