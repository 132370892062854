/*
 * Generic 'get X numbers as input' dialog.
 *
 * Example that gets 2 numbers, Suite and Dot version:
 * var dlg = dialogs.create('/ng1/dialogs/getNumbers/getNumbers.html', 'GetNumbersController', {title: "Create Release", prompts: ["Suite", "Dot version"]}, {key: false, back: 'static'});
            dlg.result.then(function(data) {
                    if (typeof data !== 'undefined')
                    {
*/

(function () {
'use strict';
angular.module('qms')
    .controller('GetNumbersController', GetNumbersController);


function GetNumbersController( $scope, QMSFactory, $modalInstance, data, $timeout )
{
    $scope.data = data;
    console.log("GetNumbersControler got data: " + data.title);

    $scope.numbers = [];
    for (let n=0; n < data.prompts.length; n++)
        $scope.numbers[n] = 0;

    $scope.init = function()
    {
        if (data.hasOwnProperty("requirePass")) {
            
        }
    };

    $scope.cancel = function()
    {
        $modalInstance.close();
    };

    $scope.okay = function()
    {
		var result = {};
        result.numbers = $scope.numbers;
		
		$modalInstance.close( result );	// Pass back data to user
	};

    $scope.init();
}


})();
