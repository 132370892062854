// To add a new dialog: this file must be included in the project's index.html!

(function() {
	'use strict';
	angular
		.module('qms')
		.controller('AimDocOpenController',AimDocOpenController);

	
	function AimDocOpenController( AIMDocFactory, $scope, $modalInstance, $document, $sce, $rootScope, data )
	{
		var vm=this;
        vm.loadedData = {};
		
		vm.init = function()
        {
                // Return key should dismiss dialog
			$modalInstance.rendered.then(function()
            {
                $document.bind('keydown', function(event)
                { 
                    console.log("AimDocOpenController: Key pressed!", event);

                    if (event.keyCode === 27)	// 27 = Escape key
                        vm.cancel();
                    else if (event.keyCode === 13) // return
                        vm.doOpen();
                });
            });
            
			AIMDocFactory.listAllAIMDocs() 
				.success( function(json)
				{
					console.log("Got suites/applications from server: ", json);

					if ( Object.keys(json.data).length > 0)
					{
						if (json.data)
                        {
                            vm.loadedData = json.data;
							$scope.suiteItems = Object.keys(json.data);

                        //    $scope.selectedSuiteItem = $scope.suiteItems[0]; // selectedSuiteItem and selectedAppItem
                            $scope.selectedSuiteItem = "Suite2021";

                            vm.suiteChanged();  // Select top-most item based on suite

                            console.log("Open dialog got items: ", $scope.appItems, $scope.suiteItems);
                        }
						else
							console.log("WARNING: Open dialog could not get items, since json.data is not defined: ", json);
					}
					else
						console.log("ERROR: No AIMDocs found to open.");
				})
				.error( function(error) 
				{
					console.log("Error attempting to load Suites and applications for Open dialog: " + error);
					window.alert("Error getting list of AIMDoc Types and Suites. Server message: " + error);	
				});
		};

        vm.appChanged = function()
        {
            console.log("User chose new app: ", $scope.selectedAppItem);
        };

        vm.suiteChanged = function()
        {
            console.log("User chose new suite: ", $scope.selectedSuiteItem);

            $scope.appItems = vm.loadedData[ $scope.selectedSuiteItem ];
            $scope.appItems.sort(); // Sort alphabetically
            $scope.selectedAppItem = $scope.appItems[0];    // Select top-most item
        };
        
		vm.doOpen = function()
        {
			var data = {};

			data.suite =  $scope.selectedSuiteItem; // $( "#openDialogSuite option:selected" ).text();
			data.app = $scope.selectedAppItem; // $( "#openDialogApp option:selected" ).text();
		
			console.log("open() for open dialog selected!");
            $document.unbind('keydown');
			$modalInstance.close(data);	// Pass back data to user
		};
		
		vm.cancel = function()
        {
			console.log("cancel() for open dialog selected!");
		//	$modalInstance.dismiss('canceled'); // Doesn't call user callback it seems
            $document.unbind('keydown');
			$modalInstance.close();
		};
		
		vm.init();
	}

})();
