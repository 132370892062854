angular.module('qms').controller('InstallTestPlanCtrl', function($scope, $modalInstance, QMSFactory, qmsVocabulary, data)
{
    $scope.appToString = {
        qpsqgs: "QPS+QGS",
        csi: "CSI",
        qbs: "QBS",
        arg: "ARG",
        csview: "CSView"
    };

    $scope.init = function() {
            // We should still do all of the following:
    //    $scope.loadCustomer(data.customerid);
        $scope.record = angular.copy(data);
        $scope.installVTPSchema = qmsVocabulary.installVTPSchema;

        $scope.dateValidatedStr = "";
        if (data.dateTimeOfValidation.length > 0)
            $scope.dateValidatedStr = new Date(data.dateTimeOfValidation).toLocaleString();

        console.log("Dialog got data from caller: ", data);

        $scope.testMenuOptions = [
            { value: 0,   text: 'Not Tested' },
            { value: 1,   text: 'Yes' },
            { value: -1,   text: 'No' }
        ];
    };

  /*  $scope.loadCustomer = function(id) {
        QMSFactory.loadCustomer(id).success( function(result) {
            $scope.customer=result.data;
        });
    };*/

    $scope.getOKButtonName = function()
    {
        if ($scope.record.dateTimeOfValidation.length > 0)
            return "Update";
        else
            return "Save";
    };

    $scope.getButtonStyle = function( record, app, questionVariable, buttonIndex )
    {
        let value = record[app][questionVariable];

        if (value === 0 && buttonIndex === 0)
            return { "background-color": "orange" };
        else if (value === 1 && buttonIndex === 1)
            return { "background-color": "green" };
        else if (value === -1 && buttonIndex === -1)
            return { "background-color": "red" };
        else
            return { "background-color": "0xdddddd" };
    };

    $scope.save = function()
    {
            // Reset tests to 'Not started' for unchecked apps
        for (let appVar in $scope.installVTPSchema)
            if ( !$scope.record.appsPurchased[appVar] )      // App was NOT purchased?
            {
                for (let questionVar in $scope.installVTPSchema[appVar].questions)
                {
                    if (typeof $scope.record[appVar] === 'undefined')
                        $scope.record[appVar] = {};
                    $scope.record[appVar][questionVar] = 0;    // 0 = Not started
                }
            }

            // For unchecked checkboxes, remove the corresponding test entries
     //   for (let key in $scope.record.appsPurchased)
     //       if ( !$scope.record.appsPurchased[key] )
    //            $scope.record[key] = {appOpens: 0}; // Objects MUST HAVE at least one property, or they'll be converted to an array by the backend!

        console.log("Dialog about to return test plan: ", $scope.record);

        $modalInstance.close( $scope.record );
    };

    $scope.cancel = function() {
        $modalInstance.dismiss('canceled');
    };

    $scope.init();
});
