// ignore the 'bad formatting' warning for &&s that span multiple lines
/* jshint -W014 */

//(function () {
//'use strict';


console.log("navbar.js is executing.");

angular
    .module('qms')
    .controller('MainAppController', MainAppController);

function MainAppController($scope, $timeout, $http, $location, dialogs, $window, QMSFactory, AuthTokenFactory) {
    var vm = this;
    vm.user = {};
    vm.theme = "flatly";
    vm.working = false; //search
	
	console.log("LEGACY angular.js MainAppController() in navbar.js entered!!");

    vm.doToken = doToken;
    vm.css = css;
    vm.init = init;
    vm.showitem = showitem;
    vm.getExpireTime = getExpireTime;
    vm.refreshExpireTimeTimer = refreshExpireTimeTimer;
    vm.expireTimeStr = "";

    vm.servers = [
        {name: "aimbuildbot", href: "http://10.240.214.247"},
        {name: "dtrack", href: "http://10.240.214.250"},
        {name: "gluon", href: "http://10.240.214.251"},
        {name: "Knowledgeowl Wiki", href: "http://csaim.knowledgeowl.com"},
        {name: "ESXi (user: 'root')", href: "http://aimesxi/"},
        {name: "ESXi vCenter", href: "https://aimvcenter:5480/"},
        {name: "Developer floating license server", href: "http://espwaimunit:6433"},
        {name: "P4AIM", href: "http://p4aim/"},
        {name: "Cedars-Sinai AIM Page", href: "https://www.cedars-sinai.edu/research/areas/aim.html"},
    ];

    vm.rdpServers = [
        {name: "aimbuild2017"},
        {name: "espwaimx000884 [mainline build machine: aimadmin]"},
        {name: "cshsaimbuild2"},
        
        {name: "estlaimweb01 [mydev]"},
        {name: "cshsaim [hospital floating license server]"},
        {name: "esplaimweb01 [my.csaim.com server]"},
        {name: "esdwaimdev01 [MongoDB for my.csaim.com]"},
        {name: "essnfs03 [my.csaim.com server]"}
    ];

    function getToken() {
        var token = AuthTokenFactory.getToken();

         if (token == null) {
             logout();
             return;
         }
    
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace('-', '+').replace('_', '/');
        var happyToken = JSON.parse($window.atob(base64));
        return happyToken;
    }

    function logout()
    {
        vm.user = null;
        console.log("No token or token expired. Logging user out.");
        AuthTokenFactory.logout();
    }

        // Setting the user toggles the navbar between logged-in and logged-out state
    function doToken( happyToken ) {
        if (!happyToken)
            happyToken = getToken();
        if (typeof happyToken === 'undefined') {
            return;
        }

        vm.user = happyToken.user;

        if (typeof happyToken.user !== 'undefined'
         && typeof happyToken.user.prefs !== 'undefined'
         && typeof happyToken.user.prefs.theme !== 'undefined')
        {
            vm.theme = happyToken.user.prefs.theme;
        }
    }

        // Formerly called from index.html to dynamically fetch the CSS file for the bootstrap theme. 
        // TODO: Figure out a way to do this that's compatible with the new Angular hybrid setup.
    function css() {
        return vm.theme;
    }

    function init() {
            // This is no longer emitted, as the class that emitted it is now a modern Angular service. - 3/10/2021
            // Instead, refreshExpireTimeTimer() does the same every 1 second.
    //    $scope.$on('TokenChanged', function(event, data) {
    //        vm.doToken();
    //    });
        
        vm.refreshExpireTimeTimer();
    }

    function refreshExpireTimeTimer()
    {
            // Option 1: Use window.setTimeout(), then $scope.$evalAsync() so Angular.js can 'see' the change we made!
            // This is the Angular.js way of ensuring Angular 'sees' changes to a variable made outside of its scope (such as from within a setTimeout)
    //    $scope.$evalAsync(function () {
    //        vm.expireTimeStr = vm.getExpireTime();
    //     });
        
    //    setTimeout( vm.refreshExpireTimeTimer, 1000 );
    
        var token = getToken();
        // JW 2021/08/20
        // For some reason a failed login attempt would still get a token with a valid timestamp,
        // just without any other fields from the database, e.g., id, username, permissions, groups
        // But this bad token may still get the user past the login page to the home page, unless
        // we check the token more closely
        if (token && !token.user)
            logout();

            // Change navbar between logged in and logged out state
        vm.doToken(token);

            // Option 2 (better): Use Angular.js's $timeout instead
        vm.expireTimeStr = vm.getExpireTime( token );

        if (vm.expireTimeStr === "expired")
            logout();

     //   console.log("New expire time: ", vm.expireTimeStr);
       
        $timeout( function(){
            refreshExpireTimeTimer();
        }, 1000 );
    }

    function getExpireTime( happyToken ) {
        if (!happyToken)
            happyToken = getToken();
        if (typeof happyToken === 'undefined') {
            return "";
        }
        var exp = happyToken.exp;

        var curtime = Math.floor((new Date()).getTime() / 1000);
        if (curtime > exp) {
            return "expired";
        } else {
            var diffInSec = exp - curtime;

            var seconds = diffInSec % 60;
            if (seconds < 10)
                seconds = "0" + seconds;
            let minutes = Math.floor(diffInSec/60);
            if (minutes>60) {
                var hours = Math.floor(minutes/60);
                minutes = minutes % 60;
                if (minutes < 10)
                    minutes = "0" + minutes;
                return hours + ":" + minutes + ":" + seconds;
            } else {
                if (minutes < 10)
                    minutes = "0" + minutes;
                return minutes + ":" + seconds;
            }
        }
    }

    function showitem(item) {
        var ret=false;
        if (vm.user&&vm.user.groups) {
            ret = $.inArray(item, vm.user.permissions) > 0;
        }
       // console.log("showitem ("+ item +"): "+ ret);
        return ret;
    }

    vm.newsupport = function () {
        vm.doToken($window.localStorage.getItem('auth-token'));
        console.log("new support");
    };

    vm.newsales = function () {
        console.log("new sales");
    };

    vm.dosearch = function () {
        //  $scope.working=true;
        $location.path("/search/" + vm.search.trim());
    };

    vm.newcustomer = function () {
        var dlg = dialogs.create('/ng1/dialogs/customernew/customernew.html', 'NewCustomerController as vm', {} );
        console.log("new customer");
        dlg.result.then(function (data) {
            if (typeof data === 'undefined') {
                console.log("User Canceled");
            } else {
                console.log(data);
                $location.path("/customer/" + data.id);
            }
        });
    };

    vm.qmsdugger = function () {
        console.log("Opening Debugger");
        dialogs.create('/ng1/dialogs/debug/debug.html', 'DebugController as Debug', {} );
    };

    vm.logout = function () {
    //    AuthTokenFactory.setToken();
    //    $location.path("/login");

        logout();
    };

    vm.changeTheme = function (x) {
        vm.user.theme = x;
    };

    vm.isLoggedIn = function () {
        if (vm.user == null) {
            return false;
        }
        return vm.user.username !== "";
    };

    vm.testToken = function () {
        return $http.get('/api/tokentest').success(function (data) {
            vm.doToken();
        });
    };

    vm.changePassword = function() {
        if (!vm.user || !vm.user.id) return;
        let dlg = dialogs.create('/ng1/dialogs/password/password.html', 'PasswordController as Password', {id:vm.user.id, requirePass:false});
        dlg.result.then(function (data) {
            if (typeof data === 'undefined') {
                console.log("User Canceled");
            } else {
                console.log("Password changed");
            }
        });
    };

    vm.init();
}

//})();
