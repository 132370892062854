angular.module('qms').controller('LicenseReconcileCtrl', function($scope, $uibModalInstance, $q, QMSFactory, data) {
    'use strict';

    $scope.event = data.event;

    $scope.ok = function() {
        var req = {
            eventId: $scope.event._id,
            reference: $scope.event.reference,
            reconciled: $scope.event.reconciled,
            note: $scope.event.note,
        };
        QMSFactory.reconcileLicenseEvent(req)
        .then(function(resp) {
            if (resp.status !== 200 || !resp.data || !resp.data.data || resp.data.data.eventId !== req.eventId) {
                return $q.reject(new Error('Backend error'));
            }
            $uibModalInstance.close(resp.data.data);
        });
    };

    $scope.cancel = function() {
        $uibModalInstance.dismiss("User canceled replacing licenses");
    };
});