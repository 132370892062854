angular.module('qms').controller('ComputerNewCtrl', function($scope, $uibModalInstance, QMSFactory, data) {
    'use strict';

    $scope.customer = null;
    $scope.computer = {
        name: '',
        notes: '',
    };

    $scope.init = function() {
        $scope.customer = data.customer;
        $scope.computer.name = data.name || '';
        $scope.computer.notes = data.notes || '';
        if (data.systemId) $scope.computer.system_id = data.systemId;
    };

    $scope.save = function() {
        $scope.customer.computers.push($scope.computer);
        QMSFactory.saveCustomer($scope.customer).success(function(data) {
            console.log("Created new system for customer: " + data.data.id);
            $uibModalInstance.close(data.data);
        });
    };

    $scope.cancel = function() {
        $uibModalInstance.dismiss("User canceled adding system");
    };

    $scope.init();
});