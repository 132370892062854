(function() {
    'use strict';
    var app = angular.module('qms');
    
    app.constant('API_URL', '/api');    // Duplicated 'modern Angular' half of the site in qmsnetwork.service.ts
    
	app.factory('AIMDocFactory', function AIMDocFactory($http, API_URL) {
        return {
            createNewAIMDoc: createNewAIMDoc,
            getChangelists: getChangelists,
            listAllAIMDocs: listAllAIMDocs,
            getMyChangelistForDoc: getMyChangelistForDoc,
            getChangelistForDocAnyUser: getChangelistForDocAnyUser,
            getClosedChangelistsForDoc: getClosedChangelistsForDoc,
            getChangelist: getChangelist,
            deleteChangelist: deleteChangelist,
            getDocument: getDocument,
            saveChangelist: saveChangelist,
            closeChangelist: closeChangelist,
            updateAimdocPrefs: updateAimdocPrefs
        };

			// This returns an array of open changelists.
            // allUsers is optional param. If true, returns changelists for all users, otherwise returns only for current user.
        	// The entire data for each changelist is contained in the result.
        function getChangelists( allUsers ) {
            if (allUsers)
                return $http.get(API_URL + '/aimdoc/cl/all' );
            else
                return $http.get(API_URL + '/aimdoc/cl/me' );
        }

            // Creates SRS and VTP
        function createNewAIMDoc( json )  {
            console.log("Json before stringify: ", json);
       //     var jsonStr = JSON.stringify(json);
      //      console.log("Create New AIMDoc stringified json: ", jsonStr);
       //     return $http.put(API_URL + '/aimdoc/doc/', {json:jsonStr}, {headers: {'Content-Type': "application/json"}});

            var xsrf = $.param({data: JSON.stringify(json)});

            return $http.post(API_URL + '/aimdoc/doc', xsrf, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}});
        }

            /* Example output:
             * {"data":{"mainline":["ARG","AUTORECON","AUTOCONFIG","CSI","INSTALL","MOCO","QBS","QXS","VIEWER"],
             *          "2015":["ARG","AUTORECON","AUTOCONFIG","CSI","INSTALL","MOCO","QBS","QXS","VIEWER"],
             *          "2013":["AUTORECON","AUTOCONFIG","CSI","INSTALL","MOCO","QBS","VIEWER","ARG","QXS"],
             *          "2012":["AUTORECON","AUTOCONFIG","CSI","INSTALL","MOCO","ARG","QBS","QXS"],
             *          "2010":["AUTOCONFIG","CSI","INSTALL","MOCO","ARG","QBS","QXS"],
             *          "unitTestData":["AUTORECON","AUTOCONFIG","MOCO","ARG","CSI","INSTALL","QBS","QXS","VIEWER"]},
             *          "token":"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE1ODg5MDI3OTAsIm5iZiI6MTU4ODkwMjc5MCwiZXhwIjoxNTg4OTA5OTkwfQ.5VccHHVNtIBxtILKtdFLDS0KapiaXzivWE88qas3C7Q"}*/
        function listAllAIMDocs() {
            return $http.get(API_URL + '/aimdoc/list' );
        }

            // returns the open changelist for name/suite (ARG/2013) for the logged in user
            // if no open changelist exists, then it returns an empty object eg. {}  
        function getMyChangelistForDoc(name, suite) {
            return $http.get(API_URL + '/aimdoc/cl/me/' + name + '/' + suite );
        }

        function getChangelistForDocAnyUser(name, suite) {
            return $http.get(API_URL + '/aimdoc/cl/' + name + '/' + suite );
        }

            // For Document History AND Merge/Integrate feature
        function getClosedChangelistsForDoc(name, suite) {
            return $http.get(API_URL + '/aimdoc/cl/closed/' + name + '/' + suite );
        }

        function getChangelist(id) {
			return $http.get(API_URL + '/aimdoc/cl/' + id );
        }

        function deleteChangelist(id) {
            return $http.delete(API_URL + '/aimdoc/cl/' + id );
        }


            // version is an optional parameter. If not specified, the latest version is loaded
        function getDocument(type, name, suite, version) {
            if (typeof version === 'undefined') {
                version = 0;		// This actually causes the php .find() function to load the latest version (see php-api/aimdoc.php)
            }
            return $http.get(API_URL + '/aimdoc/doc/' + type + '/' + name + '/' + suite + '/' + version);
        }
		
			// This is used in two cases:
			// 1) Like Google Docs, this is called every time the user makes a change, to keep track of his changes as he goes. (cl.status set to "open" by caller)
			// 2) When the user selects "Submit Changes..." to a reviewer, this is used in that case as well, with cl.status set to "review".
        function saveChangelist(cl) {
            cl.dateSaved = (new Date()).toISOString();

            console.log("Requesting HTTP POST to: ", API_URL + '/aimdoc/cl' );
            var xsrf = $.param({cl: JSON.stringify(cl)});
            return $http.post(API_URL + '/aimdoc/cl', xsrf, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}});
        }

        function updateAimdocPrefs(aimdocPreferences) {
            var xsrf = $.param({data: JSON.stringify(aimdocPreferences)});
            return $http.post(API_URL + '/user/me/aimdoc', xsrf, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}});
        }
		
			// Called when user does Save Changelist. Creates a new version of the AIMDoc, and sets the associated changelist to 'closed'.
        function closeChangelist(cl, newSRS, newVTP, newHazard) {
                // Store the date this was saved in a format Javascript can read easily
            cl.dateSaved = (new Date()).toISOString();

            console.log("Closing changelist with cl: ", cl);
            console.log("Closing changelist with srs: ", newSRS);
            console.log("Closing changelist with vtp: ", newVTP);
            console.log("Closing changelist with hazard: ", newHazard);

            var xsrf = $.param({cl: JSON.stringify(cl), 
                                srs: JSON.stringify(newSRS),
                                vtp: JSON.stringify(newVTP),
                                hzd: JSON.stringify(newHazard)
                                });

            console.log( "Size of upload in k: ", xsrf.length / 1024 );

            return $http.post(API_URL + '/aimdoc/submitcl', xsrf, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}});
        }
    });

})();

