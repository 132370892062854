angular.module('qms').controller('SurveyInviteController', function($q, $location, QMSFactory, Title) {
    'use strict';

    var vm = this;
    vm.email = '';
    vm.fullname = '';
    vm.showLink = false;
    vm.ticket = '';
    vm.link = '';
    vm.showAlert = false;
    vm.alertClass = 'alert alert-warning';
    vm.alertText = '';

    Title.setTitle('New Survey Invitation');

    vm.submit = function() {
        QMSFactory.createSurveyTicket(vm.email, vm.fullname)
        .then(function(resp) {
            if (resp.status !== 200 || !resp.data || !resp.data.data) {
                return $q.reject('Backend Error');
            }
            if (resp.data.data.worked) {
                vm.ticket = resp.data.data.ticket;
                vm.link = $location.protocol() + '://' + $location.host() +
                    (($location.protocol() === 'http' && $location.port() === 80) || ($location.protocol() === 'https' && $location.port() === 443) ? '' : (':' + $location.port())) +
                    '/survey/questionnaire.php?ticket=' + vm.ticket;
                vm.showLink = true;
                if (!vm.email) {
                    vm.showAlert = true;
                    vm.alertClass = 'alert alert-success';
                    vm.alertText = 'Invitation was created.';
                }
                else if (resp.data.data.emailed) {
                    vm.showAlert = true;
                    vm.alertClass = 'alert alert-success';
                    vm.alertText = 'Invitation was created and emailed.';
                }
                else {
                    vm.showAlert = true;
                    vm.alertClass = 'alert alert-warning';
                    vm.alertText = 'Invitation was created but could not be emailed.';
                }
            }
            else {
                vm.ticket = '';
                vm.link = '';
                vm.showLink = false;
                vm.showAlert = true;
                vm.alertClass = 'alert alert-danger';
                vm.alertText = 'Unable to create a survey invitation.';
            }
        })
        .catch(function(e) {
            vm.ticket = '';
            vm.link = '';
            vm.showLink = false;
            vm.showAlert = true;
            vm.alertClass = 'alert alert-danger';
            vm.alertText = 'Unable to communicate with server.';
        });
    };

    vm.copyLink = function() {
        navigator.clipboard.writeText(vm.link);
    };
});
