angular.module('qms').controller('VendorPasswordCtrl', function($scope, $uibModalInstance, data) {

    $scope.username = data.username;
    $scope.password = '';
    $scope.password2 = '';
    $scope.errorMessage = '';
    $scope.passwordStrongEnough = false;
    $scope.passwordStrengthMessage = 'Password cannot be empty';
    $scope.passwordStrengthMessageClass = 'has-error';
    $scope.passwordStrengthHint = '';

    $scope.checkPassword = function() {
        if ($scope.password.length <= 0) {
            $scope.passwordStrongEnough = false;
            $scope.passwordStrengthMessage = "Password cannot be empty";
            $scope.passwordStrengthMessageClass = "has-error";
            $scope.passwordStrengthHint = "";
        }
        else {
            let result = zxcvbn($scope.password);
            $scope.passwordStrongEnough = result.score >= 3;
            if (result.score === 3) {
                $scope.passwordStrengthMessage =  "Good. Password is safely unguessable";
                $scope.passwordStrengthMessageClass = "has-success";
                $scope.passwordStrengthHint = "";
            }
            else if (result.score >= 4) {
                $scope.passwordStrengthMessage =  "Great. Password is very unguessable";
                $scope.passwordStrengthMessageClass = "has-success";
                $scope.passwordStrengthHint = "";
            }
            else {
                if (result.score === 0) {
                    $scope.passwordStrengthMessage = "Too weak. Password is too guessable";
                    $scope.passwordStrengthMessageClass = "has-error";
                }
                else if (result.score == 1) {
                    $scope.passwordStrengthMessage = "Still weak. Password is very guessable";
                    $scope.passwordStrengthMessageClass = "has-error";
                }
                else {
                    $scope.passwordStrengthMessage = "Almost there. Password is somewhat guessable";
                    $scope.passwordStrengthMessageClass = "has-warning";
                }
                $scope.passwordStrengthHint = result.feedback.warning;
                if (result.feedback.suggestions) {
                    if ($scope.passwordStrengthHint) $scope.passwordStrengthHint += '. ';
                    $scope.passwordStrengthHint += result.feedback.suggestions;
                }
            }
        }
        $scope.errorMessage = '';
    };

    $scope.save = function() {
        if (!$scope.passwordStrongEnough) {
            $scope.errorMessage = 'New password is not strong enough';
            document.getElementById('password').focus();
            return;
        }
        if ($scope.password !== $scope.password2) {
            $scope.errorMessage = 'New passwords do not match'
            document.getElementById('password2').focus();
            return;
        }
        $scope.errorMessage = '';
        $uibModalInstance.close($scope.password);
    };

    $scope.cancel = function() {
        $uibModalInstance.dismiss('canceled');
    };
});