(function() {
    'use strict';
    angular.module('qms').controller('KeyValidatorController', KeyValidatorController);

    function KeyValidatorController($q, $filter, $window, dialogs, QMSFactory, qmsVocabulary, Title) {
        var vm = this;

        Title.setTitle("Key Validator");

        vm.ui = {
            systemLookUp: {
                id: '',
                status: 'Ready to validate',
                class: '',
                reset: function() { this.systemId = ''; this.status = 'Ready to validate'; this.class = ''; },
                setError: function(status) { this.status = status; this.class = 'has-error'; },
                setSuccess: function(status) { this.status = status; this.class = 'has-success'; },
            },
            licenseLookUp: {
                key: '',
                status: 'Ready to validate',
                class: '',
                reset: function() { this.systemId = ''; this.status = 'Ready to validate'; this.class = ''; },
                setError: function(status) { this.status = status; this.class = 'has-error'; },
                setSuccess: function(status) { this.status = status; this.class = 'has-success'; },
            }
        };

        vm.system = {};
        vm.license = {};

        vm.validateSystemId = function() {
            var id = vm.ui.systemLookUp.id.trim();
            vm.system = {};
            if (!id) {
                vm.ui.systemLookUp.reset();
                return;
            }
            QMSFactory.validateSystemId(id)
            .then(function(resp) {
                if (resp.status === 200 && resp.data && resp.data.data) {
                    vm.system = resp.data.data;
                    if (!vm.system.platform /* && vm.system.platform !== 0 */) {
                        vm.ui.systemLookUp.setError('Invalid System ID');
                    }
                    else {
                        vm.ui.systemLookUp.setSuccess('Valid System ID');
                        if (vm.system.prettyId) vm.ui.systemLookUp.id = vm.system.prettyId;
                        if (vm.system.details && vm.system.details.net_macid) {
                            vm.system.isFloatingLicenseVM = qmsVocabulary.isMacAddressFloatingLicenseVM(vm.system.details.net_macid);
                            vm.system.isNodeLockedVM = qmsVocabulary.isMacAddressNodeLockedVM(vm.system.details.net_macid);
                        }
                        else {
                            vm.system.isFloatingLicenseVM = false;
                            vm.system.isNodeLockedVM = false;
                        }
                    }
                }
                else {
                    return $q.reject(new Error('Backend error'));
                }
            })
            .catch(function(error) {
                console.log(error);
                vm.ui.systemLookUp.setError('Unable to communicate with server');
            });
        };

        vm.validateLicense = function() {
            var key = vm.ui.licenseLookUp.key.trim();
            vm.license = {};
            if (!key) {
                vm.ui.licenseLookUp.reset();
                return;
            }
            QMSFactory.validateLicenseKey(key)
            .then(function(resp) {
                if (resp.status === 200 && resp.data && resp.data.data) {
                    vm.license = resp.data.data;
                    if (vm.license.valid) {
                        vm.ui.licenseLookUp.setSuccess('Valid License Key');
                        if (vm.license.pretty) vm.ui.licenseLookUp.key = vm.license.pretty;
                        if (vm.license.systemId) {
                            vm.license.fullId = encodeURIComponent(vm.license.systemId + (vm.license.subId ? '#' + vm.license.subId : ''));
                        }
                    }
                    else {
                        vm.ui.licenseLookUp.setError('Invalid License Key');
                    }
                }
                else {
                    return $q.reject(new Error('Backend error'));
                }
            }).catch(function(error){
                console.log(error);
                vm.ui.licenseLookUp.setError('Unable to communicate with server');
            });
        };
    }
})();