
(function() {
	'use strict';
	angular
		.module('qms')
		.controller('AimDocGetSuiteController', AimDocGetSuiteController);

	function AimDocGetSuiteController( AIMDocFactory, $scope, $modalInstance, $document, data )
	{
		$scope.suite = data.suite;
        $scope.name = data.name;
        $scope.loadedData = {};

		$scope.init = function()
        {
            $scope.addKeyListeners();
            $scope.loadSuites();
		};

        $scope.loadSuites = function()
        {
			AIMDocFactory.listAllAIMDocs() 
				.success( function(json)
				{
					console.log("Got suites from server: ", json);

					if ( Object.keys(json.data).length > 0)
					{
						if (json.data)
                        {
                            $scope.loadedData = json.data;
							$scope.suiteItems = Object.keys(json.data);
                            $scope.selectedSuiteItem = $scope.suiteItems[0];
                        }
						else
							console.log("WARNING: DownloadReport dialog could not get list of Suites, since json.data is not defined: ", json);
					}
					else
						console.log("ERROR: No Suites found.");
				})
				.error( function(error) 
				{
					console.log("Error attempting to load Suites and applications for DownloadReport dialog: " + error);
					window.alert("Error getting list of AIMDoc Suites for DownloadReport dialog. Server message: " + error);	
				});

        };

        $scope.addKeyListeners = function()
        {
                // Return key should dismiss dialog
			$modalInstance.rendered.then(function()
            {
                $document.bind('keydown', function(event)
                    { 
                        if (event.keyCode === 27)	// 27 = Escape key
                            $scope.close();
                        else if (event.keyCode === 13) // return
                            $scope.close();
                    }
                );
            });
        };

        $scope.suiteChanged = function()
        {
            console.log("Suite changed: ", $scope.selectedSuiteItem );

            // TODO: Generate 'diff' reports based on selected suite. Need to do callbacks to aimdocs.ts to do this!
            //
            // OR: We could have 2 separate dialogs. One where user selects the Suite to diff with, the 2nd that shows clickable download results. (This would be fine!)
        };

        $scope.okayPushed = function()
        {
			var data = {};
			data.suite =  $scope.selectedSuiteItem; // $( "#openDialogSuite option:selected" ).text();
            data.pushedOK = true;
		
            $document.unbind('keydown');
			$modalInstance.close( data );	// Pass back data to user
		};
		
		$scope.cancelPushed = function()
        {
            var data = {pushedOK:false};
            $document.unbind('keydown');
			$modalInstance.close( data );
        };
				
		$scope.init();
	}

})();
