// Support Incident editor
//
// Shown when user is viewing a Customer, and clicks on a Support Incident

(function() {
    'use strict';
    angular
        .module('qms')
        .controller('SupportController', SupportController);

    function SupportController( $routeParams, $q, QMSFactory, qmsVocabulary, Title) {
        var vm=this;

        vm.showFewerFields = false; // True for dialog box (for New Support Incident), false for this page (Edit Existing Support Incident)
        vm.record = {};     // The support incident record

        vm.init = init;
        vm.load = load;
        vm.save = save;
        vm.recordUpdated = recordUpdated;

        function init()
        {
            Title.setTitle("Support");

            if ($routeParams['id']) {
                Title.setTitle("Support " + $routeParams['id']);
                vm.load($routeParams['id']);
            }
        }

        function load(id)
        {
            QMSFactory.loadSupport(id)
            .then(function(resp)
            {
                if (resp.status !== 200 || !resp.data || !resp.data.data) {
                    return $q.reject((resp.data && resp.data.data && resp.data.data.error) || 'Backend Error');
                }
                vm.record = resp.data.data;

                console.log("support.js initial loaded record: ", vm.record);

                // post processing of vm.record after de-serialization
                if (vm.record.owner === undefined) vm.record.owner = '';
                if (vm.record.dateopened && !(vm.record.dateopened instanceof Date)) {
                    vm.record.dateopened = new Date(vm.record.dateopened);
                }
                if (vm.record.description === undefined) vm.record.description = '';
                if (vm.record.notes === undefined) vm.record.notes = '';
                if (vm.record.remoterequired === undefined) vm.record.remoterequired = false;
            /*    if (vm.record.remotemethod) {
                    var temp = qmsVocabulary.supportIncidentRemoteOptions.find(function(o) {
                        return o.value && o.value.toLowerCase() === vm.record.remotemethod.toLowerCase();
                    });
                    if (temp) {
                        vm.record.remote = temp.value;
                        vm.record.remoteOther = '';
                    }
                    else {
                        vm.record.remote = null;
                        vm.record.remoteOther = vm.record.remotemethod;
                    }
                }*/
                vm.record.events = vm.record.events || [];
            //    vm.updateTotal();


                console.log("support.js final loaded record: ", vm.record);

                // load customer
                return QMSFactory.loadCustomer(vm.record.customerid);
            })
        /*    .then(function(resp) {
                if (resp.status !== 200 || !resp.data || !resp.data.data) {
                    return $q.reject((resp.data && resp.data.data && resp.data.data.error) || 'Backend Error');
                }
                vm.customer = resp.data.data;
                if (vm.record.contact) {
                    var found = vm.customer.contacts && vm.customer.contacts.findIndex &&
                        vm.customer.contacts.findIndex(function(v) { return v.name === vm.record.contact; }) >= 0;
                    if (!found) vm.customer.contacts.push({ name: vm.record.contact });
                }
            })*/
            .catch(function(e) {
                console.log('SupportController.load(): ' + (e || 'Backend Error'));
            });
        }

            // Child component uses this to notify us the support record has changed and should be saved
        function recordUpdated( supportObj )
        {
            console.log("Record updated", supportObj);

            vm.record = supportObj;
            vm.save();
        }

        function save()
        {
            // pre processing of vm.record before serialization
            // do this on a deep copy of vm.record
            var copy = angular.copy(vm.record);
            if (copy.owner !== undefined && !copy.owner) delete copy.owner;
            if (copy.dateopened && (copy.dateopened instanceof Date)) {
                copy.dateopened = copy.dateopened.toISOString();
            }
            if (!copy.remoterequired) {
                delete copy.remotemethod;
                delete copy.remoteOther;
            }
            else {
                //copy.remotemethod = copy.remote ? copy.remote : copy.remoteOther;
            }
            delete copy.remote;
           // delete copy.remoteOther;
            if (copy.events && copy.events.forEach) {
                copy.events.forEach(function(e) {
                    // convert Date to ISO8601 if needed
                    if (e.date && (e.date instanceof Date)) {
                        e.date = e.date.toISOString();
                    }
                });
            }
            return QMSFactory.saveSupport(copy);
        }

        vm.init();
    }

})();
