/**
 * Created by geoff on 4/30/2015.
 */
/* jshint -W014 */
Dropzone.autoDiscover = false;
/**
    This class does *all* the dropzoney stuff you need
    Usage:
    <div class="panel panel-info">
        <div class="panel-heading">
            <h3 class="panel-title">Files</h3>
        </div>
        <div class="panel-body">
            <div class="CSDropZone" obj="controller" folder="myfolder"></div>
        </div>
    </div>

    "controller" object is the MyController as controller
        controller MUST:
        * have a record object
          - record MUST have a unique id field (see folder below)
          - CSDropZone will manage the controller.record.files []
        * have a save() function (gets called after upload and delete)

      "myfolder" is the folder where items get physically stored on \\AIM\Share\qms\<folder>\id

 */
(function() {
    'use strict';
    angular.module('qms')
        .factory('DropZoneFactory', DropZoneFactory)
        .directive('csDropZone', DropZoneBlock);

    function DropZoneBlock() {
        return {
           restrict: 'C',
           scope: {
               obj: '=',
               folder: '@'
           },
           templateUrl: '/ng1/templates/cs_dropzone.html',
           link: DZlink,
           controller: ['$scope', 'DropZoneFactory', DZController],
           controllerAs: 'dzCtrl'
        };
        function DZlink(scope, element, attrs) {  }

        function DZController($scope, DropZoneFactory) {
            var dropzone = new DropZoneFactory($scope.obj, $scope.folder);
        }
    }

    function DropZoneFactory(dialogs) {
        return function (scopePtr, urlPrefix) {
            var _dropzone = {};
            var _dropzoneName = "#CSDropZone";
            var _scopePtr = scopePtr;   // requires a 'record' object with and id field and a save() function
            var _urlPrefix = "/api/file/" + urlPrefix + "/";

            if (Dropzone.instances.length > 0) {
                // DropZone doesn't like multiple instances...
                // since we are using a single page app approach we need to delete the old instance and re-create
                var container = $( "#CSDropZoneContainer" );
                container.empty();
                container.append( $('<form id="CSDropZone" class="dropzone"></form>') );
            }

            _dropzone = new Dropzone(_dropzoneName, {
                url: _urlPrefix + "xxx",
                method: "post",
                srcWidth: 50,
                maxFilesize: 200, // MB
                accept: accept
            });

            _scopePtr.deleteFile = deleteFile;
            _scopePtr.refreshFiles = refreshFiles;

            return {
                setURLKey: setURLKey,
                accept: accept,
                deleteFile: deleteFile
            };

            function setURLKey(urlKey) {
                var url = _urlPrefix + urlKey;
                if (typeof _dropzone.options.url !== 'undefined') {
                    _dropzone.options.url = url;
                }
                console.log("Setting URL: " + url);
            }

            function refreshFiles() {
                console.log("refresh Clicked");
            }

            function deleteFile(index) {
                var title = "Confirm File Delete";
                var msg = 'Really delete file: <b><u>' + _scopePtr.record.files[index].display_name + '</u></b>? <p/>This cannot be undone.';

                dialogs.confirm(title, msg).result.then(function () {
                    _scopePtr.record.files.splice(index, 1);
                    _scopePtr.save();
                }, function (btn) { // cancel
                    console.log("cancelled");
                });
            }

            function accept(file, done) {
                setURLKey(_scopePtr.record.id);

                var fileinfo = {};
                fileinfo.name = file.name;
                fileinfo.size = file.size;
                fileinfo.lastmodified = file.lastmodified;

                if ( !_scopePtr.record.hasOwnProperty("files")
                  || Object.prototype.toString.call( _scopePtr.record.files ) !== '[object Array]') {
                    _scopePtr.record.files = [];
                }
                var dlg = dialogs.create('/ng1/dialogs/addfile/addfile.html', 'AddfileCtrl', fileinfo);
                dlg.result.then(function (newfile) {
                    if (typeof newfile === 'undefined') {
                        done("User Cancelled");
                    } else {
                        _scopePtr.record.files.push(newfile);
                        _scopePtr.save();    // now save with the
                        done(); //send the file
                    }
                });
            }
        };
    }
})();
