angular.module('qms').controller('SystemDialogCtrl',function($scope, $http, $q, $modalInstance, data){
    $scope.id = data.id;
    $scope.record={};

    $scope.init = function() {
       $http.get('/api/system/' + $scope.id).success(function(data) {
          $scope.record = data;
        });
    };

    $scope.save = function() {
        var xsrf = $.param({data: JSON.stringify($scope.record)});
        $http({
            method: 'POST',
            url: '/api/system',
            data: xsrf,
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).success(function(data) {
            $modalInstance.close(data);
        });
    };

    $scope.cancel = function() {
        $modalInstance.dismiss('canceled');
    };

    $scope.init();
});