
(function() {
	'use strict';
	angular
		.module('qms')
		.controller('AimDocViewHistoryController',AimDocViewHistoryController);

	function AimDocViewHistoryController( AIMDocFactory, $modalInstance, $window, $location, data )
	{
		var vm = this;

        vm.aimdoc = data.aimdoc;
		vm.title = data.title;
        vm.changelists = [];
	//	vm.message = $sce.trustAsHtml( data.message );
		
		vm.init = function()
        {
            vm.title = "Document History for " + vm.aimdoc.name + " " + vm.aimdoc.suite + " v" + vm.aimdoc.version;

            /* Example changelist data:
                author: "vern"
                baseversion: 1
                changes: (8) [{…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}]
                debug: "UPDATE"
                desc: "Fixed error: 'MOCO' ID was used more than once in SRS."
                id: "CL000099"
                logs: (3) [{…}, {…}, {…}]
                name: "MOCO"
                nextRowGUIDIndex: 4330
                owner: ""
                redoChangelist: []
                status: "closed"
                suite: "Suite2020"
                timestamp: {$date: {…}}
            */

            AIMDocFactory.getClosedChangelistsForDoc( vm.aimdoc.name, vm.aimdoc.suite )
				.success( function(result) 
				{
					if (result.data !== null)
					{
                        result.data.sort( function(a, b) {
                            if (a.baseversion > b.baseversion)
                                return 1;
                            else if (a.baseversion < b.baseversion)
                                return -1;
                            else
                                return 0;
                        });

						vm.changelists = result.data;
						console.log("getClosedChangelistsForDoc() successful: ", result);
					}
					else
						vm.titleText = "There is no history for this document.";
				})
				.error( function(error) 
				{
					vm.title = "Error: could not open history for " + vm.aimdoc.name + " " + vm.aimdoc.suite;
					console.log("getClosedChangelistsForDoc() HTTP ERROR: ", error);
				});
		};

        vm.close = function()
        {
			$modalInstance.close();
        };

        vm.openInAIMDoc = function(cl)
        {
        //    $location.path("/aimdoc/openbycl/" + item.id);

            $window.open('/#/aimdoc/openbycl/' + cl.id, '_blank');
        };
        
		vm.getDate = function( cl )
		{
				// multiplied by 1000 so that the argument is in milliseconds, not seconds
            if (typeof cl.dateSaved !== 'undefined')
            {
			    var date = new Date(cl.dateSaved);
                console.log("Item: ", cl, "date: ", date);
			    return (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear();
            }
            else if (cl.changes.length > 0)
                return vm.getCLDateString(cl.changes[0]);
            else
                return "unknown";
		};

            // CODE DUPLICATED from Aimdoc.ts: ChangelistItem:getDateString()
        vm.getCLDateString = function(item)
        {
            if (typeof item.modifiedDateStr !== 'undefined')
            {
                let date = new Date(item.modifiedDateStr);
                return date.toLocaleDateString("en-US");
            }
            else
            {
                var date = new Date(item.modifiedDate * 1000);
			    return (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear();
            }
        };
		
		vm.init();
	}

})();
