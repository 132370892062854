(function() {
    'use strict';
    angular.module('qms')
        .controller('LoginController',LoginController);


function LoginController(QMSFactory, $location, Title) {
    var vm=this;
    vm.login = login;
    vm.openNav = openNav;
    vm.closeNav = closeNav;
    vm.inProgress = false;
    vm.errorMsg = "";

    Title.setTitle("Login");

    function login() {
        if (!vm.inProgress) {
            vm.inProgress = true;
            vm.error = "Contacting server...";

            QMSFactory.login(vm.username, vm.password)
                .success(function (data) {
                    console.log("login response from server: ", data);
                    console.log("worked: " + data.data.worked);

                    if (!data.data.worked) {
                        vm.error = data.data.reason || "Incorrect username / password combination";
                    } else {
                        $location.path("/home");
                    }

                    vm.inProgress = false;
                })
                .error( function(data, status, headers, config) {
                    console.log("Login error: ", data, status, headers, config);
                    vm.error = "Server error: " + data.substring(0, 150) + "...";
                    vm.inProgress = false;
                });
        }
    }

    function openNav(event) {
        console.log("Open clicked with event: ", event);
        document.getElementById("mySidenav").style.width = "450px";
    }
    
    function closeNav(event) {
        document.getElementById("mySidenav").style.width = "0";
    }
}

})();
