// Wrapper for the Angular 2 Test Worksheet page.
// This file is included in angular.json
// See js/setup.js as well.

(function() {
    'use strict';
    angular.module('qms')
        .controller('TicketPageWrapperController', TicketPageWrapperController);
    
    function TicketPageWrapperController($scope, $routeParams, $location){
        
        $scope.ticketid = $routeParams["id"];
        console.log("TicketPageWrapper got route id: ", $scope.ticketid);
    }
})();