// EditSupport Component
// This component shows the fields for editing a Support incident record.
// It's used both when creating a New support incident (dialogs/supportnew) and when editing an existing incident (partial/support)
// The 'showFewerFields' variable can be set to true for editing an existing support incident, false for creating a new one (fewer fields shown).
// The support record is created or loaded and saved by the parent. We emit an onUpdate event when any value has changed.
//
// Example usage:
//   <edit-support record="Support.record" showfewerfields="Support.showFewerFields" on-update="Support.recordUpdated( value )"></edit-support>

(function() {
    'use strict';

    angular
      .module('qms')
      .component('editSupport', {
            templateUrl: 'ng1/components/editSupport.component.html',
                // inputs and outputs go here
                // Inputs should use < and @ bindings. Use < for one-way bindings. Use @ for strings.
                // Outputs: Use & 
            bindings: {
                record: '<',            // The support incident's record
                showfewerfields: '<',   // true/false
                onUpdate: '&'           // Output
            },
            controller: EditSupportController
    });

    function EditSupportController( $scope, QMSFactory, dialogs, qmsVocabulary, $timeout )
    {
        let vm = this;

        console.log("EditSupportController entered.");

        vm.owners = [];             // Owners are support reps
        vm.ownerMap = new Map();    // Owners by key/value

        vm.customer = {};

            // Menu contents for various menus
        vm.initOpts = angular.copy(qmsVocabulary.supportIncidentInitiations); // Deep copy so we can modify our version
        vm.initOpts.pop(); // remove the last 'Unknown' option
        vm.remoteOpts = qmsVocabulary.supportIncidentRemoteOptions;
     //   vm.statusTypes = qmsVocabulary.supportIncidentStatuses;   // REMOVED per Tejas/Geoff
        // JW 2022-09-10: consolidate issue (component now) options and issue detail sub-options into one select
        vm.issueTypes = [];
        qmsVocabulary.supportIncidentIssue.forEach(function(e) {
            // w/o detail options?
            let suboptions = qmsVocabulary.supportIncidentIssueDetail[e.value];
            if (suboptions === undefined || !suboptions.forEach) {
                // continuing the convention of having issue detail = issue for issues without detail sub-options
                vm.issueTypes.push({ text: e.text, value: e.value, detail: e.text });
            }
            else {
                // with detail options
                suboptions.forEach(function(sub) {
                    vm.issueTypes.push({ text: e.text + ' -- ' + sub.text, value: e.value, detail: sub.value });
                });
            }
        });
        vm.issueCombined = null; // model for the combined select
        vm.reasons = [];
        vm.reasonsClass = 'has-error';
        qmsVocabulary.supportReason.forEach(function(e) {
            vm.reasons.push({ label: e.text, value: e.value, component: e.component, selected: false });
        });
        vm.surveyLastInvitation = '';


    //    vm.durationOpts = [];

            //    vm.remoteSelected = null;
    //    vm.remoteOther = '';

        vm.datePickerIsVisible = false;

        vm.$onChanges = function(changesObj)
        {
       //     for (const [key, simpleChange] of Object.entries(changesObj))     
            let keys = Object.keys(changesObj);
            for (let n=0; n < keys.length; n++)  // ngmin does not support for (let key of keys) style loops without --force to ignore a warning!
            {
                let key = keys[n];
                console.log("Iterating key: ", key);
                let simpleChange = changesObj[key];
                vm[key] = simpleChange.currentValue;        // i.e. vm['record'] = {the changed record};
                if (key == 'record')
                {
                    vm.loadCustomer(vm.record.customerid);

                    let copy = angular.copy(vm.record);
                    console.log("Record before being modified: ", copy);

                        // In the database, both 'remote' and 'remoteOther' are stored in one field: 'remotemethod'
                        // We extract that into 2 fields here.
                    if (vm.record.remotemethod)
                    {
                        console.log("Looking up remotemethod: ", vm.record.remotemethod);
                        var temp = qmsVocabulary.supportIncidentRemoteOptions.find(function(o) {
                            return o.value && o.value.toLowerCase() === vm.record.remotemethod.toLowerCase();
                        });

                        console.log("Found it: ", temp);;

                        if (temp) {
                            vm.record.remote = temp.value;
                            vm.record.remoteOther = '';
                        }
                        else {
                            vm.record.remote = null;
                            vm.record.remoteOther = vm.record.remotemethod;
                        }

                        console.log("Record updated: ", vm.record);
                    }

                    // usertype defaults to 'commercial'
                    if (!vm.record.usertype) vm.record.usertype = 'commercial';

                    // convert reasons[] to individual true/false flags
                    if (vm.record.reasons && vm.record.reasons.includes) {
                        vm.reasons.forEach(function(v) { v.selected = vm.record.reasons.includes(v.value); });
                    }
                    vm.reasonsClass = (vm.record.reasons && vm.record.reasons.length > 0) ? 'has-success' : 'has-error';

                    // look up issueCombined from issue and issueDetail
                    vm.issueCombined = vm.issueTypes.find((v) => v.value === vm.record.issue && v.detail === vm.record.issueDetail);
                    if (!vm.issueCombined) vm.issueCombined = vm.issueTypes.find((v) => v.value === vm.record.issue);
                }

                console.log(" Set watched value: ", key, vm[key]);
            }

            console.log("editSupport onChanges: ", changesObj);
        };
        
            // Angular lifecycle hook. Called after bound values are ready.
        vm.$onInit = function()
        {
            console.log("Initial record: ", vm.record);

            vm.loadOwners();

            console.log("Show fewer fields: ", vm.showFewerFields);

                // TODO: Make sure caller puts customerid in record.customerid before passing record to us.
            vm.loadCustomer(vm.record.customerid);
        //    vm.record.customerid = data.customerid;
            vm.record.dateopened = new Date();
        //    vm.record.contact = data.contact;
       //     vm.record.owner = data.owner;
            
        //    vm.record.initiation = vm.initOpts[0].value;
            
         //   vm.remoteSelected = vm.remoteOpts[0].value;

                // Duration menu options
      /*      vm.durationOpts.push({ text: 'None', value: 0 });
            vm.durationOpts.push({ text: '0.5 hour', value: 0.5 });
            vm.durationOpts.push({ text: '1 hour', value: 1 });
            for (var i = 1; i < 8; i++) {
                vm.durationOpts.push({ text: ((i + 0.5) + ' hours'), value: (i + 0.5) });
                vm.durationOpts.push({ text: ((i + 1) + ' hours'), value: (i + 1) });
            }
        */
        };

        vm.needsSaving = false;

        vm.fieldChanged = function()
        {
        //    console.log("Field changed", vm.record);
            vm.needsSaving = true;

                // Save instantly the first time, then delay for a second before (possibly) saving again.
            if (vm.timeout)
                $timeout.cancel(vm.timeout);
            else
                vm.notifyParentOfChange();

            vm.timeout = $timeout( vm.notifyParentOfChange, 1000);  // 1000 = 1 second
        };

        vm.issueChanged = function()
        {
            vm.record.issue = vm.issueCombined.value;
            vm.record.issueDetail = vm.issueCombined.detail;
            console.log("Newly selected issue: ", vm.issueCombined);
            vm.fieldChanged();
        };

        vm.customerIDChanged = function( newCustomerID )
        {
            console.log("Customer ID changed: ", newCustomerID);
            vm.record.customerid = newCustomerID;
            vm.fieldChanged();
        };

        vm.reasonsChanged = function(value)
        {
            let reasons = [];
            vm.reasons.forEach(function(e) { if (e.selected) reasons.push(e.value); });
            vm.reasonsClass = reasons.length > 0 ? 'has-success' : 'has-error';
            vm.record.reasons = reasons;
            vm.fieldChanged();
        };

        vm.contactChanged = function(callFieldChanged)
        {
            let lastInvitation = null;
            vm.customer.surveys.forEach(function(v) {
                if (v.invited === vm.record.contact) {
                    if (!lastInvitation || lastInvitation.date < v.date) {
                        lastInvitation = v;
                    }
                }
            });
            if (lastInvitation) {
                vm.surveyLastInvitation = 'Last invited on ' + lastInvitation.date.substring(0, 10);
                if (lastInvitation.submitted) {
                    vm.surveyLastInvitation += '. Last submitted on ' + lastInvitation.submitted.substring(0, 10);
                }
                else {
                    vm.surveyLastInvitation += '. No yet responded';
                }
            }
            else {
                vm.surveyLastInvitation = 'Yet to be invited';
            }
            if (callFieldChanged) vm.fieldChanged();
        };

        vm.notifyParentOfChange = function()
        {
            if (vm.needsSaving)
            {
                let copy = angular.copy(vm.record);

                if (!copy.remoterequired) {
                    delete copy.remotemethod;
                    delete copy.remoteOther;
                }
                else {
                    copy.remotemethod = copy.remote ? copy.remote : copy.remoteOther;
                }
            //    delete copy.remote; // Do NOT do this or it breaks.

                console.log("Notifying parent of change",copy);
                vm.onUpdate( {value: copy} );
                vm.needsSaving = false;
            }
            delete vm.timeout;
        };

        vm.openDatePicker = function($event)
        {
            $event.preventDefault();
            $event.stopPropagation();
            vm.datePickerIsVisible = !vm.datePickerIsVisible;
        };

        vm.loadCustomer = function(id)
        {
            if (typeof id != 'undefined')
            {
                console.log("LOADING CUSTOMER BY ID: ", id);
                QMSFactory.loadCustomer(id).success( function(result) {
                    vm.customer=result.data;
                    console.log("LOAD CUSTOMER RESULT: ", result);

                    // initialize vm.surveyLastInvitation if needed
                    if (!vm.surveyLastInvitation && vm.customer && vm.customer.surveys && vm.customer.surveys.forEach && vm.record.contact) {
                        vm.contactChanged(false);
                    }
                });
            }
        };

            // Owners = Support Reps
        vm.loadOwners = function()
        {
            QMSFactory.getUsersForGroup("SUPPORT").then(function(resp) {
                if (resp.status !== 200 || !resp.data || !resp.data.data) {
                    return $q.reject((resp.data && resp.data.data && resp.data.data.error) || 'Backend Error');
                }
                vm.owners = resp.data.data;
                vm.ownerMap.clear();
                if (vm.owners.forEach) {
                    vm.owners.forEach(function(o) { vm.ownerMap.set(o.id, o.fullname); });
                }
            });
        };

        vm.newSurveyInvite = function()
        {
            let data = { contacts: vm.customer.contacts, invited: vm.record.contact };
            let dlg = dialogs.create('/ng1/dialogs/surveyinvite/invite.html', 'SurveyInviteDlgCtrl', data, { keyboard: false, back: 'static' });
            dlg.result.then(function(result){
                if (typeof result === 'undefined') {
                    console.log("User Canceled");
                }
                else {
                    if (result.invited) {
                        if (vm.customer.surveys === undefined) vm.customer.surveys = [];
                        vm.customer.surveys.push({
                            ticket: result.ticket || null,
                            invited: result.invited.name || result.invited,
                            date: printISODateTimeLocal(new Date())
                        });
                        QMSFactory.saveCustomer(vm.customer).success(function(data) {
                            console.log("Save Success!");
                        });
                        vm.contactChanged(false);
                    }
                }
            });

        };
    }

})();
