(function() {
    'use strict';

    angular.module('qms').service('LicenseUtils', function LicenseUtils(qmsVocabulary) {

        return {
            groupLicenseHistories: groupLicenseHistories,
            groupEvent: groupEvent
        };

        function groupLicenseHistories( histories )
        {
            if (histories && histories.forEach)
            {
                histories.forEach(function(history, i)
                {
                    history.isOpen = (i === 0);
                    // going with descending chronological order => reverse the replaced_by_chain
                    history.replaced_by_chain = (history.replaced_by_chain && history.replaced_by_chain.reverse()) || [];
                    // and replacing_chain as is
                    history.replacing_chain = history.replacing_chain || [];
                    // group deltas in events in each chain
                    history.replaced_by_chain.forEach(function(r) {
                        if (r.events && r.events.forEach) r.events.forEach( groupEvent );
                    });
                    history.replacing_chain.forEach(function(r) {
                        if (r.events && r.events.forEach) r.events.forEach( groupEvent );
                    });
                    if (history.events && history.events.forEach) history.events.forEach( groupEvent );
                });
            }
        }

            // groups the deltas in a license event for display purpose. Used by License History page as well as Reconciled Report
        function groupEvent(event) {
            // makes keys for grouping features consisting of only current info (for the same, added, and removed maps)
            function mapKey(license) {
                var ret = license.qty + '|' + license.type + '|' + license.duration; // duration matters for custom type
                ret += '|' + (license.type !== 1 ? license.start : ''); // start doesn't matter for permanent keys
                return ret;
            }

            // makes keys for grouping features consisting of both original and current info (for the extended and modified maps)
            function mapKey2(license) {
                var ret = license.org_qty + '|' + license.org_type + '|' + license.org_duration +
                    '|' + (license.org_type !== 1 ? license.org_start : '');
                ret += license.qty + '|' + license.type + '|' + license.duration +
                    '|' + (license.type !== 1 ? license.start : '');
                return ret;
            }

            // compiles a feature list
            function makeFeatureList(features) {
                var featureIndexes = [], featureList = '';
                if (features && features.forEach) {
                    features.forEach(function(feature) {
                        var i = qmsVocabulary.licenseFeatures.findIndex(function(f) { return f.id === feature; });
                        if (i >= 0) featureIndexes.push(i);
                    });
                }
                if (featureIndexes.length > 0) {
                    featureIndexes.sort(function(a, b) { return a - b; });
                    featureIndexes.forEach(function(i) { featureList += ', ' + qmsVocabulary.licenseFeatures[i].name; });
                    featureList = featureList.slice(2);
                }
                return featureList;
            }

            function mapToArray(map, array) {
                map.forEach(function(v) {
                    v.featureList = makeFeatureList(v.features);
                    array.push(v);
                });
            }

            // sort into these categories: same, added (qty from 0 to nonzero), removed (qty from nonzero to 0), 
            // extended (qty (nonzero)/type remain the same, start changed)
            var same = new Map(), extended = new Map(), added = new Map(), removed = new Map(), modified = new Map();

            if (event.licenses && event.licenses.forEach) {
                event.licenses.forEach(function(license) {
                    var key, group;
                    // same qty and type?
                    if (license.qty === license.org_qty && license.type === license.org_type) {
                        // really the same?
                        if (license.type === 1 || (license.start === license.org_start && license.duration === license.org_duration)) {
                            key = mapKey(license);
                            group = same.get(key);
                            if (!group) {
                                group = { qty: license.qty, type: license.type, start: license.start, duration: license.duration, features: [] };
                                same.set(key, group);
                            }
                            group.features.push(license.feature);
                        }
                        // extended (assuming start>=org_start and duration>=org_duration)
                        else {
                            key = mapKey2(license);
                            group = extended.get(key);
                            if (!group) {
                                group = { qty: license.qty, type: license.type, start: license.start, duration: license.duration,
                                    org_qty: license.org_qty, org_type: license.org_type, org_start: license.org_start, org_duration: license.org_duration,
                                    org_exp: { start: license.org_start, duration: license.org_duration }, features: [] };
                                extended.set(key, group);
                            }
                            group.features.push(license.feature);
                        }
                    }
                    // added (qty 0->1+)?
                    /* jshint eqeqeq: false, -W041 */
                    else if (license.qty > 0 && license.org_qty == 0) {
                        key = mapKey(license);
                        group = added.get(key);
                        if (!group) {
                            group = { qty: license.qty, type: license.type, start: license.start, duration: license.duration, features: [] };
                            added.set(key, group);
                        }
                        group.features.push(license.feature);
                    }
                    // removed (qty 1+->0)?
                    else if (license.qty == 0 && license.org_qty > 0) {
                        key = mapKey({ qty: license.org_qty, type: license.org_type, start: license.org_start });
                        group = removed.get(key);
                        if (!group) {
                            group = { qty: license.org_qty, type: license.org_type, start: license.org_start, duration: license.org_duration, features: [] };
                            removed.set(key, group);
                        }
                        group.features.push(license.feature);
                    }
                    // modified
                    else {
                        key = mapKey2(license);
                        group = modified.get(key);
                        if (!group) {
                            group = { qty: license.qty, type: license.type, start: license.start, duration: license.duration,
                                org_qty: license.org_qty, org_type: license.org_type, org_start: license.org_start, org_duration: license.org_duration,
                                org_exp: { start: license.org_start, duration: license.org_duration }, features: [] };
                            modified.set(key, group);
                        }
                        group.features.push(license.feature);
                    }
                });
            }

            // convert Map to Array for display
            event.same = [];
            mapToArray(same, event.same);
            event.added = [];
            mapToArray(added, event.added);
            event.removed = [];
            mapToArray(removed, event.removed);
            event.extended = [];
            mapToArray(extended, event.extended);
            event.modified = [];
            mapToArray(modified, event.modified);
        }

    });
})();
