(function () {
    'use strict';

    angular
        .module('qms')
        .controller('ReleasesController', ReleasesController);

    function ReleasesController( $scope, dialogs, QMSFactory, Title )
    {
        $scope.statusMenuOptions = [
            { text: 'Planning', value: 'planning' },
            { text: 'Implementation', value: 'implementation' },
            { text: 'Testing', value: 'testing' },
            { text: 'Released', value: 'released' }
        ];

        $scope.emptyReleaseObj = {
            files: {},
            dateFrozen: "",
            dateReleased: "",
            status: "",
            releaseNotes: "",
            milestoneSignoff: "",
            testSummaryReport: ""
        };
        /*{
	            suites: {
		            2017: {
			            13: {
				            releaseNotesLink: string,
				            testSummaryReportLink: string,
                            dateReleased: string,
                            dateFrozen: string,
                            status: string, // Development, Testing, Released
                            milestoneSignoff: string,
                            releaseNotes: string,
                            files: {
				                csmcvendor_Win32_2017_32030: 
                                {
                                    zip: "csmcvendor_Win32_2017_32030.zip",
                                    md5: "csmcvendor_Win32_2017_32030.md5",
                                    md5str: We might add this later
                                }
                            }
			            }
		            }
             }
         } */
        $scope.syncStatusMsg = "";
        $scope.qmsReleases = {};
        $scope.qmsReleasesSorted = [];  // Same as above, but stored in an array so ng-repeat can sort it by Suite year

            // Filled out by parseCloudFiles()
        $scope.cloudSuiteVersions = {};  // {2017.15.21325: [{filename: "x", size:2}, {filename: "y", size:3}]}
        $scope.miscFiles = [];

    //    $scope.cloudFiles = []; // Array of objects {name:string, link:string, size:number, contentType:string}

        $scope.init = function()
        {
            Title.setTitle("Releases");

            QMSFactory.loadReleases()
                .success( function(result)
                {
                    $scope.qmsReleases = angular.copy(result.data); // angular.copy(result.data[0]);

                        // BUG FIXES: The backend can convert an object with number-only keys into an array. That is BAD! Convert any arrays back here to an object.
                    if ( !$scope.qmsReleases || Array.isArray($scope.qmsReleases.suites) )
                        $scope.qmsReleases = { suites: {} }; // We MUST have at least one item in the suites object, or it'll be converted to an array on save to DB!

                    let suites = $scope.qmsReleases.suites;
                    for (let year in suites)
                    {
                        let yearObj = suites[year];
                        if ( Array.isArray( yearObj ) )
                        {
                            let fixedYearObj = {};
                            for (let n=0; n < yearObj.length; n++)
                                if (yearObj[n])
                                    fixedYearObj[n] = yearObj[n];
                            suites[year] = fixedYearObj;
                        //    console.log("WARNING!!! WARNING!!! Object is an array!!!", yearObj);
                        //    console.log("FIXED year object!!", suites[year]);
                        }
                        // also make sure each dot release's 'files' field is an object, not array
                        for (let dot in yearObj)
                        {
                            let files = yearObj[dot].files;
                            if (files !== undefined && Array.isArray(files))
                            {
                                let fixedFiles = {};
                                files.forEach(function(v, i) { fixedFiles[i] = v; });
                                yearObj[dot].files = fixedFiles;
                            }
                        }
                    }

                    console.log("LOAD releases result: ", $scope.qmsReleases);

                    $scope.updateQMSReleasesSorted();
                });

        //    QMSFactory.googleCloudListBuckets().success( function(result) {
        //        console.log("Google cloud list of buckets: ", result);
        //    });
            
        };

        $scope.updateQMSReleasesSorted = function()
        {
            $scope.qmsReleasesSorted = [];
            let suites = $scope.qmsReleases.suites;
            for (let year in suites)
            {
                let suiteObj = suites[year];

                let dotArray = [];
                for (let dotVers in suiteObj)
                {
                    let newObj = suiteObj[dotVers];
                    //newObj.dotVers = parseInt(dotVers); // JW 2021-06-09: not converting dotVers to int to allow 2017.17_1
                    dotArray.push( newObj );
                }
                // properly sort dot versions including patch versions like 17_1
                // JW 2022-05-05: I do this by converting 17_1 to 17.1, which won't work if we go 17_1_1 (hopefully not)
                dotArray.sort(function(a, b) {
                    let dotA = a.dotVers, dotB = b.dotVers;
                    if ((typeof dotA) === 'string') dotA = parseFloat(dotA.replace('_', '.'));
                    if ((typeof dotB) === 'string') dotB = parseFloat(dotB.replace('_', '.'));
                    return /* dotA - dotB */dotB - dotA; // sort in descending order
                });
                suiteObj = dotArray;

                $scope.qmsReleasesSorted.push( {year: year, obj: suiteObj} );

                    // TODO: Also convert 'dot version' map to an array so it can be sorted.
           //     $scope.qmsReleasesSorted.push( {year: year, obj: suites[year]} );
            }

            console.log("Suite obj converted to array: ", $scope.qmsReleasesSorted );
        };

            // Users pushes a button to activate this
        $scope.syncWithCloud = function()
        {
            $scope.syncStatusMsg = "Syncing...";
            QMSFactory.googleCloudListBucketObjects("releases.csaim.com")
                .success( function(result)
                {
                    console.log("Google cloud list of bucket objects: ", result);

                //    $scope.cloudFiles = result.data.objects;
                    $scope.parseCloudFiles( result.data.objects );
                    $scope.compareCloudFilesWithQMSReleases();

                //    console.log("cloudFiles: ", $scope.cloudFiles);
                });
        };

            // Sort the list of objects in the bucket based on 'directory'.
            // Also delete cloud files we don't want (_pdb and empty zips)
        $scope.parseCloudFiles = function( objects )
        {
            let objectsToDelete = [];

            for (let n=0; n < objects.length; n++)
            {
                    // First part of object name is the 'directory' - i.e. "2017.3/"
                let parts = objects[n].name.split("/");
                let dirName = parts[0];
                let filename = parts[1];
                if (objects[n].name.indexOf("/") !== -1)
                {
                    if ( typeof $scope.cloudSuiteVersions[ dirName ] === 'undefined')
                        $scope.cloudSuiteVersions[ dirName ] = [];
                }
                else
                    $scope.miscFiles.push( objects[n].name ); // Files at the top level of the bucket (not in a 2015.0 folder for example)

                if (parts.length > 1)
                {
                        // Delete PDB files or zips that are tiny
                    if ( filename.indexOf("_pdb") !== -1 || (objects[n].size < 1000 && filename.indexOf(".zip") !== -1) )
                        objectsToDelete.push( objects[n].name );
                    else if (filename.length > 0)
                    {
                        let obj = {
                            filename: filename,
                            size: objects[n].size
                        };
                        $scope.cloudSuiteVersions[ dirName ].push( obj );
                    }
                }
            }

            if (objectsToDelete.length > 0)
            {
                QMSFactory.googleCloudDeleteObjects("releases.csaim.com", objectsToDelete)
                    .success( function(result)
                    {
                        console.log("Delete file HTTP endpoint called for: ", objectsToDelete);
                        console.log("Delete HTTP results: ", result);
                    });
            }

            console.log("Suite versions: ", $scope.cloudSuiteVersions);
            console.log("misc files: ", $scope.miscFiles);
        };

        $scope.compareCloudFilesWithQMSReleases = function()
        {
            let suites = angular.copy( $scope.qmsReleases.suites );
            let changedInfo = {};

            let entries = Object.entries($scope.cloudSuiteVersions);
            for (let n=0; n < entries.length; n++)
            {
                let dirName = entries[n][0];
            //    console.log("Dir name: ", dirName);
                let filesInDir = entries[n][1];
                let versParts = dirName.split("."); // 2017.13.30315 or possibly just 2017.13

                    // Ignore folders that don't have, at a minimum, Year and Dot Version (2013.13)
                if (versParts.length >= 2)
                {
                        // Cloud 'folder' name split into parts:
                    let suiteYear = versParts[0];
                    let dotVersion = versParts[1];

                        // Fix: if user loads this webpage before all files have been uploaded to Google Drive, ones uploaded later will never be imported. Commented out this check to fix.
                    if ( !isNaN( parseInt(suiteYear, 10)) && !isNaN( parseInt(dotVersion, 10)) /*&& 
                         typeof suites[suiteYear] === 'undefined' || suites[suiteYear][dotVersion] === 'undefined'*/)
                    {
                    //    console.log("About to add ", dirName, " with these files to QMS's Released list: ", filesInDir);

                        if (typeof suites[suiteYear] === 'undefined')
                            suites[suiteYear] = {};
                        if (typeof suites[suiteYear][dotVersion] === 'undefined')
                        {
                            suites[suiteYear][dotVersion] = angular.copy( $scope.emptyReleaseObj );
                            suites[suiteYear][dotVersion].status = "released";
                        }

                        let suiteObj = suites[suiteYear][dotVersion];

                        for (let n=0; n < filesInDir.length; n++)
                        {
                            let obj = filesInDir[n];
                            let filename = obj.filename;
                            let filenameWithoutExt = filename.split(".")[0];
                            
                            if ( filename.indexOf("_pdb") > 0)
                            {
                                // Ignore -- user shouldn't be uploading PDB files.
                                // OR: we could automatically delete them here via the Cloud API.
                            }
                            else if ( filename.endsWith(".zip") || filename.endsWith(".pkg") || filename.endsWith(".md5") )
                            {
                            //    console.log("Checking : ", suiteYear, dotVersion, filename,  suites[suiteYear][dotVersion].files[filenameWithoutExt]);

                                    // No info for this file yet?
                                if (typeof suiteObj.files[filenameWithoutExt] === 'undefined')
                                {
                                    suiteObj.files[filenameWithoutExt] = {};
                                    suiteObj.status = "released";  // Change status for an existing QMS 'releases' entry once any files are added
                                 //   suiteObj.dateReleased = (new Date()).toISOString();  // DISABLED. User should enter the same date the Milestone Signoff Sheet had, not the date they uploaded to the Releases page

                                        // Keep track of what we changed
                                    if (typeof changedInfo[suiteYear] === 'undefined')
                                        changedInfo[suiteYear] = {};
                                    changedInfo[suiteYear][dotVersion] = true;
                                }

                                let fileObj = suiteObj.files[filenameWithoutExt];

                                if ( filename.endsWith(".zip") || filename.endsWith(".pkg"))
                                {
                                    fileObj.zip = filename;
                                    fileObj.zipFull = dirName + "/" + filename;
                                    fileObj.size = obj.size;
                                    fileObj.sizeStr = $scope.getSizeString( obj.size );
                                }
                                else if ( filename.endsWith(".md5") )
                                {
                                    fileObj.md5 = filename;
                                    fileObj.md5Full = dirName + "/" + filename;
                                }
                            }
                            else if ( filename.indexOf("Release_Notes_") !== -1)
                            {
                                suiteObj.releaseNotes = dirName + "/" + filename;
                            }
                            else if ( filename.indexOf("Milestone_Signoff_") !== -1) // .pdf  This also catches filenames of "Minor_Milestone_Signoff_"
                            {
                                suiteObj.milestoneSignoff = dirName + "/" + filename;
                            }
                            else if ( filename.indexOf("Test_Summary_Report_") !== -1 || filename.indexOf("MTR_Summary_Report_") !== -1 ) 
                            {
                                suiteObj.testSummaryReport = dirName + "/" + filename;
                            }
                            else
                            {
                                console.log("Deleting info for ", suiteObj, filenameWithoutExt);
                                delete suiteObj.files[filenameWithoutExt];  // Filename ends with some other extension (.pkg or others)
                            }
                        }

                       $scope.removeEntriesWithNoZips( suites );
                    }

                 //   console.log( "Year, dot release, build number: ", versParts[0], versParts[1], versParts[2] );
                }
            }


            $scope.syncStatusMsg = "Sync completed.";


            console.log("About to ask user if they want to save new qmsReleases object: ", suites);
            console.log("Former qmsReleases object: ", $scope.qmsReleases.suites);
            console.log("New files exist in cloud for: ", changedInfo);

            $scope.qmsReleases.suites = suites;

            console.log("About to save suites: ", angular.copy(suites) );

            console.log("qms.releases after cloud import: ", $scope.qmsReleases);

                // Save 'releases' to database after import from cloud files.
            $scope.save();
        };

        $scope.removeEntriesWithNoZips = function( suites )
        {
            for (let year in suites)
            {
                let yearObj = suites[year];
                for (let dotVers in yearObj)
                {
                    let obj = yearObj[dotVers];

                    for (let key in obj.files)
                    {
                        let fileObj = obj.files[key];

                        if ( typeof fileObj.zip === 'undefined' )
                            delete obj.files[key];
                    }
                }
            }
        };

            // Convert size in bytes to size in KB or MB
        $scope.getSizeString = function( sizeInBytes )
        {
            if (sizeInBytes > 1028 * 1028)
                return Math.floor(sizeInBytes / (1028 * 1028)) + " MB";
            else if (sizeInBytes > 1028)
                return Math.floor(sizeInBytes / 1028) + " KB";
            else
                return sizeInBytes + " bytes";
        };

            // This is the on-change callback for menus and dates. We do it manually instead of having Angular watch data
        $scope.dataChanged = function()
        {
            $scope.save();
            console.log("Something changed.");
        };

        $scope.add = function()
        {
            var dlg = dialogs.create('/ng1/dialogs/getNumbers/getNumbers.html', 'GetNumbersController', {title: "Create Release", prompts: ["Suite (i.e. 2021)", "Dot version (i.e. 0)"]}, {key: false, back: 'static'});
            dlg.result.then(function(data) {
                    if (typeof data !== 'undefined')
                    {
                        let suiteYear = data.numbers[0];
                        let dotVersion = data.numbers[1];

                        let suites =  $scope.qmsReleases.suites;
                        if (typeof suites[suiteYear] === 'undefined')
                            suites[suiteYear] = {};
                        if (typeof suites[suiteYear][dotVersion] === 'undefined')
                        {
                            suites[suiteYear][dotVersion] = angular.copy( $scope.emptyReleaseObj );
                            suites[suiteYear][dotVersion].status = "planning";
                        }
                        else
                            window.alert("Suite " + suiteYear + "." + dotVersion + " already exists");

                        $scope.save();
                    }
                },
                function() {

                });
        };

        $scope.deleteRelease = function( suiteYear, dotVers )
        {
            dialogs.confirm("Delete", "Are you sure you want to delete the release " + suiteYear + "." + dotVers + "?<br/><br />[Note: This will not delete any related files.]").result
                .then( function()
                {
                    delete $scope.qmsReleases.suites[suiteYear][dotVers];
                    if ( Object.keys( $scope.qmsReleases.suites[suiteYear] ).length === 0)
                        delete $scope.qmsReleases.suites[suiteYear];

                    console.log("qmsReleases after deleting", suiteYear, dotVers, $scope.qmsReleases );

                    $scope.save();
                }, function() {
                    console.log("User chose not to delete.");
                } );
        };

            // Also updates the array-based copy of the qmsReleases object that's used by angular for rendering
        $scope.save = function()
        {
            console.log("Saving qmsReleases: ", $scope.qmsReleases);

            $scope.updateQMSReleasesSorted();

            QMSFactory.saveReleases( $scope.qmsReleases ).success( function(result)
            {
                console.log("Save releases result: ", result);
            });
        };

        $scope.init();
    }

})();
