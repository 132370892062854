angular.module('qms').controller('SupportNewCtrl',function($scope, $modalInstance, QMSFactory, qmsVocabulary, dialogs, data){
    $scope.record = {
        status: 1,
        events: [],
        comments: [],
        initiation: null,
        remoterequired: false,
        logs: []
    };

    $scope.showFewerFields = true;

    $scope.customer = {};
    $scope.initOpts = [];
    $scope.remoteOpts = [];
    $scope.remoteSelected = null;
    $scope.remoteOther = '';
   // $scope.durationOpts = [];
    $scope.duration = 0;

    $scope.init = function() {
            // We should still do all of the following:
        $scope.loadCustomer(data.customerid);
        $scope.record.customerid = data.customerid;
        $scope.record.dateopened = new Date();
        $scope.record.contact = data.contact;
        $scope.record.owner = data.owner;

        $scope.initOpts = angular.copy(qmsVocabulary.supportIncidentInitiations);
        $scope.initOpts.pop(); // remove the last 'Unknown' option
        $scope.record.initiation = $scope.initOpts[0].value;
        $scope.remoteOpts = angular.copy(qmsVocabulary.supportIncidentRemoteOptions);
        $scope.remoteSelected = $scope.remoteOpts[0].value;
    
   /*     $scope.durationOpts.push({ text: 'None', value: 0 });
        $scope.durationOpts.push({ text: '0.5 hour', value: 0.5 });
        $scope.durationOpts.push({ text: '1 hour', value: 1 });
        for (var i = 1; i < 8; i++) {
            $scope.durationOpts.push({ text: ((i + 0.5) + ' hours'), value: (i + 0.5) });
            $scope.durationOpts.push({ text: ((i + 1) + ' hours'), value: (i + 1) });
        }
    */
    };

    $scope.loadCustomer = function(id) {
        QMSFactory.loadCustomer(id).success( function(result) {
            $scope.customer=result.data;
        });
    };

    $scope.recordUpdated = function( supportObj )
    {
    //    console.log("Record updated", supportObj);
        $scope.record = supportObj;
    };

    $scope.save = function() {
        // complaint?
        if ($scope.record.reasons.includes('complaint')) {
            let data = {
                record: $scope.record,      // support interaction record
                customer: $scope.customer   // customer record
            };
            let opts = { keyboard: false, size: 'lg' };
            dialogs.create('/ng1/dialogs/complaint/editcomplaint.html', 'EditComplaintCtrl as vm', data, opts).result
                .then(function(complaint) {
                    QMSFactory.addComplaint(complaint).success(function(data) {
                        if (data.data && data.data.worked) {
                            $scope.record.complaint = data.data.id || 'missing';
                            $scope._save();
                        }
                        else {
                            window.alert('Internal error: server was unable to save a new complaint');
                        }
                    }).error(function() {
                        window.alert('Unable to communicate with server to save a new complaint');
                    });
                }, function() { 
                    console.log('Complaint cancelled');
                });
        }
        else {
            $scope._save();
        }
    }

    $scope._save = function() {
        if ($scope.record.remoterequired) {
        //    if ($scope.record.remotemethod == null)
        //        $scope.record.remotemethod = $scope.record.remoteOther;
        }
        else {
            delete $scope.record.remotemethod;
        }

        if ($scope.duration > 0) {
            var now = new Date(); now.setSeconds(0); now.setMilliseconds(0);
            $scope.record.events = [{
                date: now,
                rep: data.owner,
                duration: $scope.duration,
                summary: $scope.record.description,
            }];
        }
        else {
            $scope.record.events = [];
        }
        QMSFactory.saveSupport($scope.record).success(function(data) {
            console.log("created new support interaction ID: " + data.data.id);
            $modalInstance.close(data.data);
        });
    };

    $scope.cancel = function() {
        $modalInstance.dismiss('canceled');
    };

    $scope.init();
});
