(function () {
    'use strict';

    angular
        .module('qms')
        .controller('HomeController', HomeController);

    function HomeController( QMSFactory, QMSNetwork, Title) {
        var vm=this;
        vm.init=init;

        function init()
        {
            Title.setTitle("Home");
            
        /*    QMSFactory.tokenTest()
                .success(function (data) {
                    console.log("NEW token test response from server: ", data);
                });
        */

        /*    QMSFactory.testPOST()
                .success(function (data, status, headers, config) {
                    console.log("ng1 test POST success: ", data);
                })
                .error( function(data, status, headers, config) {
                    console.log("ng1 test POST error: ", data, status, headers, config);
                });*/
        }

        vm.init();
    }

})();
