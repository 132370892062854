// To add a new dialog: this file must be included in the project's index.html!

(function() {
	'use strict';
	angular
		.module('qms')
		.controller('AimDocSaveDocumentController',AimDocSaveDocumentController);
	
	function AimDocSaveDocumentController( $modalInstance, $sce, $rootScope, data )
	{
		var vm=this;
		vm.init = init;
		vm.cancel = cancel;
		vm.submit = submit;
        vm.errorMsg = "";
		//   vm.watch= $rootScope.watch;
	//	vm.title = data.title;
		
		function init() {
			console.log("Save Document got data: ", data);
			vm.nextDocVersion = data.aimdoc.version + 1;
			vm.aimdoc = data.aimdoc;
			console.log("Next doc version:", vm.curDocVersion);
		}
		
		function submit() {
			var data = {};
			data.reviewer = $( "#submitChangesReviewer option:selected" ).text();
			data.desc = document.getElementById("submitChangesDescription").value;

            if (data.desc.trim().length < 3)
            {
                vm.errorMsg = "Please enter a summary of the changes you made.";
                return;
            }
			
			   // TIP: See aimdoc_submitChanges to see how we can obtain other data from the user here
			console.log("submit() for Save Document dialog selected!");
			$modalInstance.close(data);	// Pass back data to user
		}
		
		function cancel() {
			console.log("cancel() for Save Document dialog selected!");
		//	$modalInstance.dismiss('canceled'); // Doesn't call user callback it seems
			$modalInstance.close();	// We need to call the user callback so that it can set no dialog as being open
		}
		
		vm.init();
	}

})();
