(function() {
    'use strict';
    angular.module('qms').controller('RegkeyController', RegkeyController);

    // -------------------
    // License Replacement
    // -------------------
    // License replacements are tracked on the License Set level instead of systems or events.
    // This means different sets of the same system can be (and have to be) replaced independently.
    //
    // Each set can have two pointers for the replacement chain, one to the set that it replaced
    // and another to the set that replaced itself. Basically a doubly-linked list. Each pointer
    // can only be set once.
    //
    // A new set will always be created for the replacement system, regardless of existing sets
    // for that system. Its 'replacing' pointer will be immediately assigned to the replaced set.
    //
    // An existing set cannot be replaced twice. Once the 'replaced_by' pointer is set, it cannot
    // be modified.

    function RegkeyController($q, $routeParams, $window, $filter, dialogs, QMSFactory, AuthTokenFactory, qmsVocabulary, Title) {
        var vm = this;

        Title.setTitle("RegKey");

        // system info
        vm.system = {
            id: '',                 // ID of the system to issue licenses to (prettified after looking up in backend)
            normalizedId: '',       // normalized (no dashes, all uppercase) System ID
            suiteNum: '',           // Suite # from suffix of System ID
            details: undefined,     // details of the System ID after decoding by backend
            platform: -1,           // Platform ID embedded in the System ID
            hasOldLicense: false,   // whether this system has any old licenses
            isFloatingLicenseVM: false, // whether this System ID is a floating license server running in a VM
            isNodeLockedVM: false,  // whether this System ID is an attempt to use node-locked licenses in a VM
            reset: function() {
                this.id = this.normalizedId = this.suiteNum = ''; this.subId = undefined; this.details = undefined; this.platform = -1;
                this.hasOldLicense = false; this.isFloatingLicenseVM = false; this.isNodeLockedVM = false;
            },
        };

        // 0: entering system ID, 1: invalid system ID, 2: entering customer/system, 3: choose features, 4: requesting license, 5: license retrieved
        vm.step = 0;

        // UI properties (button text, CSS class, component state)
        vm.ui = {
            systemLookUp: {             // system look up button
                button: 'Look Up',
                status: '',
                statusClass: '',
                reset: function() { this.button = 'Look Up'; this.status = ''; this.statusClass = ''; },
                setWorking: function() { this.status = 'Working on it...'; this.statusClass = ''; },
                setFound: function() { this.status = 'Found this system'; this.statusClass = ''; },
                setNewSystem: function() { this.status = 'This is a new system'; this.statusClass = 'has-warning'; },
                setInvalid: function() { this.status = 'Invalid System ID'; this.statusClass = 'has-error'; },
                setIdSaved: function() { this.status = 'System ID saved'; this.statusClass = ''; },
                setError: function() { this.status = 'Unable to communicate with the server. Please try again'; this.statusClass = 'has-error'; },
            },
            submitButton: 'Generate',   // license request submit button
            detailsCollapsed: true,     // system detail collapse-able
            globalQuantity: 1,          // choice of across-the-board license quantity
            globalType: 1,              // choice of across-the-board license duration type
            globalTypeOrg: 1,           // old value of globalType
            globalPackage: null,        // choice of pre-defined license packages
            alreadyReplacedShown: false,
            flowPackSelected: false,    // whether Flow Pack is selected

            reset: function() {
                this.systemLookUp.reset();
                this.submitButton = 'Generate';
                this.detailsCollapsed = true;
                this.globalQuantity = 1;
                this.globalType = 1;
                this.globalTypeOrg = 1;
                this.globalPackage = null;  // Bug fix: formerly this variable was omitted. Alya reported it. -VJ 3/1/2021
                this.alreadyReplacedShown = false;
            },
        };

        // customer selection data
        vm.customer = {
            data: [],                   // array of customer candidates
            selected: null,             // id of selected customer
            name: '',                   // name of selected customer
            location: '',               // location of selected customer
            locationClass: '',          // CSS class for location
            query: '',                  // keywords to query for customers
            system: {                   // systems of selected customer
                data: [],               // array of system candidates
                showGroups: false,      // whether to show groups in the system drop down
                selected: null,         // index of selected system (systems have no primary keys)
                name: '',               // name of selected system
                notes: '',              // notes of selected system
                id: '',                 // System ID of selected system
                reset: function() { this.data = []; this.showGroups = false; this.selected = null; this.name = ''; this.notes = ''; this.id = ''; },
                setName: function(system) { this.name = system.name || ''; this.notes = system.notes || ''; this.id = system.system_id || ''; },
            },
            reset: function() { this.data = []; this.selected = null; this.name = this.location = this.locationClass = this.query = ''; this.system.reset(); },
            setName: function(customer) {
                this.name = customer.name || '';
                this.location = (customer.city || '') + ' ' + (customer.state || '') + ' ' + (customer.zip || '');
                this.locationClass = '';
            }
        };

        // license, current and request
        vm.license = {
            baselineId: '',             // System ID whose license history is to be the baseline for new licenses (normalized as soon as possible)
            baselinePlatform: -1,       // Platform ID embedded in baselineId
            isReplacing: false,         // whether this system is created to replace another (if true, replacing is to be replaced and must be != vm.system.normalizedId)
            replacingLocked: false,     // whether to lock down the replacement UI controls
            replacing: '',              // system to be replaced
            current: [],                // current license sets of this system
            currentChoices: [],         // choices of current license sets (plus creating a new set)
            currentSelIdx: -1,          // selected choice index
            currentSelected: {},        // events[0] of the selected license set, if available
            currentPrompt: '',
            issued: undefined,          // licenses issued by backend
            request: {                  // license request to send to backend
                vendor: null,
                reference: '',
                reconciled: false,
                note: '',
                newEnc: true,           // new 2015.11+ encryption
                features: [],
            },
            vendors: [],                // list of vendors (depending on platform)
            durations: [],              // list of duration types (don't change after initialization)
            quantities: [],             // list of quantity options (don't change after initialization)
            packages: [],               // list of common packages (don't change after initialization)
            features: [],               // list of features and their states
            noFeatureSelected: true,
            allowsPowerPoint: false,    // whether we're allowing adding PowerPoint
            resetFeatures: function() {
                this.features = qmsVocabulary.licenseFeaturesCopy();
                this.features.forEach(function(v) {
                    v.selected = false;
                    v.qty = 1;
                    v.type = 1;
                    v.orgSelected = false;
                    v.orgQty = 0;
                    v.orgType = 0;
                    v.orgExp = '';
                    // per-feature copy to accommodate optional "reuse"
                    // filter out the custom duration option only to be used by the global duration dropdown as a place holder (days<-1)
                    v.durations = this.durations.filter(function(u) { return u.days >= -1; });
                }, this);
            },
            reset: function() {
                this.baselineId = ''; this.baselinePlatform = -1;
                this.isReplacing = this.isReplacement = this.lockReplacing = false; this.replacing = ''; this.issued = undefined;
                this.current = []; this.currentSelIdx = -1; this.currentSelected = {}; this.currentPrompt = ''; this.vendors = [];
                var r = this.request; r.vendor = null; r.reference = ''; r.reconciled = false; r.note = ''; r.newEnc = true; r.features = [];
                this.resetFeatures();
            },
            initVendors: function(platform) {
                var temp;
                this.vendors = [];
                if (qmsVocabulary.licensePlatforms.has(platform)) {
                    if (qmsVocabulary.platformHasSubvendors(platform)) {
                        // have a place-holder option of invalid platform on top to force manual selection
                        this.vendors.push({ id: -1, name: 'Please select one:' });
                        temp = [];
                        qmsVocabulary.licenseSubvendors.forEach(function(v, k) {
                            this.push({ id: 1000 + k, name: v });
                        }, temp);
                        // assuming the first element in qmsVocabulary.licenseSubvendors is CSMC Direct, keep it on top
                        this.vendors.push(temp.shift());
                        // sort the remaining alphabetically
                        temp.sort(function(a, b) { return a.name.localeCompare(b.name); });
                        temp.forEach(function(v) { this.vendors.push(v); }, this);
                    }
                    else {
                        this.vendors.push({ id: platform, name: qmsVocabulary.licensePlatforms.get(platform) });
                    }
                    this.request.vendor = this.vendors[0].id;
                }
            },
            setCurrent: function(current, isReplacing) {
                this.current = current || [];
                this.currentChoices = [];
                this.current.forEach(function(v, i) {
                    this.currentChoices.push({idx: i, text: 'License Set ' + v.sub_id});
                }, this);
                if (isReplacing) {
                    if (this.currentChoices.length === 0) {
                        this.currentChoices.push({idx: -2, text: 'No Prior Licenses'});
                    }
                }
                else {
                    this.currentChoices.push({idx: -1, text: 'New License Set'});
                }
                this.currentSelIdx = this.currentChoices[0].idx;
                switch (this.current.length) {
                    case 0: this.currentPrompt = 'This system has no prior license sets'; break;
                    case 1: this.currentPrompt = 'This system has one existing set of licenses'; break;
                    default: this.currentPrompt = 'This system has multiple sets of licenses'; break;
                }
            }
        };

        // user currently logged in
        vm.user = undefined;
        
        // event handler of customer drop down
        vm.onCustomerChange = function() {
            var v = vm.customer, customer, computers, match, withoutId = [], withId = [];
            v.setName({}); v.system.reset();
            customer = v.data.find(function(c) { return c.id === v.selected; });
            if (customer !== undefined) {
                v.setName(customer);
                // populate the list of systems
                computers = angular.copy(customer.computers); // deep copy as we're making changes
                if (computers && computers.forEach) {
                    computers.forEach(function(c, i) {
                        c.idx = i;
                        if (c.system_id === vm.system.normalizedId) match = c;
                        else if (c.system_id) {
                            c.group = 'Replacing a system with an existing ID';
                            c.label = c.name + ' (' + $filter('prettifySystemId')(c.system_id) + ')';
                            withId.push(c);
                        }
                        else {
                            c.group = 'Assign to a system with no existing ID';
                            c.label = c.name;
                            withoutId.push(c);
                        }
                    });
                }
                // system ID match found?
                if (match) {
                    v.system.data = [match];
                    v.system.showGroups = false;
                    v.system.selected = match;
                    v.system.setName(match);
                }
                else {
                    v.system.data = withoutId.concat(withId);
                    v.system.showGroups = true;
                    v.system.selected = v.system.data[0] ? v.system.data[0] : null;
                    v.system.setName(v.system.data[0] ? v.system.data[0] : {});
                }
            }
        };

        // event handler of system drop down
        vm.onSystemChange = function() {
            vm.customer.system.setName(vm.customer.system.selected || {});
        };

        // full reset of the whole page
        vm.reset = function() {
            vm.step = 0;
            vm.system.reset();
            vm.ui.reset();
            vm.customer.reset();
            vm.license.reset();
        };

        // look up the specified System ID
        vm.findSystem = function() {
            // reset?
            if (vm.step > 0) {
                vm.reset();
                return;
            }
            // otherwise, query for the system
            vm.ui.systemLookUp.setWorking();
            QMSFactory.loadCurrentLicense(vm.system.id, null, true)
            .then(function(resp) {
                if (resp.status !== 200 || !resp.data || !resp.data.data) {
                    var error = new Error('Backend error');
                    error.resp = resp;
                    return $q.reject(error);
                }
                var data = resp.data.data/* , i, temp */;
                vm.ui.systemLookUp.button = 'Reset';
                vm.license.baselinePlatform = vm.system.platform = (data.platform || data.platform === 0) ? data.platform : -1;
                vm.system.details = data.details;
                if (data.details && data.details.net_macid) {
                    vm.system.isFloatingLicenseVM = qmsVocabulary.isMacAddressFloatingLicenseVM(data.details.net_macid);
                    vm.system.isNodeLockedVM = qmsVocabulary.isMacAddressNodeLockedVM(data.details.net_macid);
                }
                else {
                    vm.system.isFloatingLicenseVM = false;
                    vm.system.isNodeLockedVM = false;
                }
                // show system ID prettified by the backend
                vm.system.id = data.prettyId || data.systemId || vm.system.id;
                vm.license.baselineId = vm.system.normalizedId = data.systemId;
                vm.system.suiteNum = data.suiteNum || '';
                // found as an existing system of a customer?
                if (data.customer) {
                    vm.step = 3;
                    vm.ui.systemLookUp.setFound();
                    // fill out customer info
                    vm.customer.data = [data.customer];
                    vm.customer.selected = data.customer.id;
                    vm.onCustomerChange();
                }
                // valid ID but not found?
                else if (qmsVocabulary.licensePlatforms.has(data.platform)) {
                    vm.step = 2;
                    vm.ui.systemLookUp.setNewSystem();
                    QMSFactory.hasOldLicenses(vm.system.normalizedId).success(function(data) { vm.system.hasOldLicense = data.data && data.data.found; });
                }
                // invalid ID
                else {
                    vm.step = 1;
                    vm.ui.systemLookUp.setInvalid();
                }
                // vendor options depending on platform
                vm.license.initVendors(vm.system.platform);
                // found existing licenses?
                vm.license.setCurrent(data.current, false);
                vm.showCurrentLicense();
            })
            .catch(function(error) {
                console.log(error);
                vm.ui.systemLookUp.setError();
                var msg = 'Unable to communicate with the server. Please try again.';
                // JW 2019-11-19: show HTTP response for debugging
                var resp = error.resp ? error.resp : error;
                var parts = [];
                if (resp.status) parts.push('' + resp.status);
                if (resp.statusText) parts.push(resp.statusText);
                if (resp.data && typeof resp.data !== 'string') parts.push(JSON.stringify(resp.data));
                msg += '<h6>' + parts.join(' ') + '</h6>';
                if (resp.data && typeof resp.data === 'string') msg += '<br>' + resp.data;
                dialogs.error('Key Generator Error', msg, 'lg');
            });
        };

        // do customer search
        vm.findCustomer = function() {
            if (!vm.customer.query) return;
            vm.customer.location = 'Working on it...';
            QMSFactory.searchCustomer(vm.customer.query)
            .then(function(resp) {
                if (resp.status !== 200 || !resp.data || !resp.data.data) {
                    var error = new Error('Backend error');
                    error.resp = resp;
                    return $q.reject(error);
                }
                vm.customer.data = resp.data.data;
                if (vm.customer.data.forEach) {
                    vm.customer.data.forEach(function(v) { if (!v.computers) v.computers = []; });
                }
                if (vm.customer.data[0]) {
                    vm.customer.selected = vm.customer.data[0].id;
                    vm.onCustomerChange();
                }
                else {
                    vm.customer.location = 'No match';
                    vm.customer.locationClass = 'has-warning';
                }
            })
            .catch(function(error) {
                console.log(error);
                vm.customer.location = 'Unable to communicate with the server. Please try again';
                vm.customer.locationClass = 'has-error';
                var msg = 'Unable to communicate with the server. Please try again.';
                // JW 2019-11-19: show HTTP response for debugging
                var resp = error.resp ? error.resp : error;
                var parts = [];
                if (resp.status) parts.push('' + resp.status);
                if (resp.statusText) parts.push(resp.statusText);
                if (resp.data && typeof resp.data !== 'string') parts.push(JSON.stringify(resp.data));
                msg += '<h6>' + parts.join(' ') + '</h6>';
                if (resp.data && typeof resp.data === 'string') msg += '<br>' + resp.data;
                dialogs.error('Key Generator Error', msg, 'lg');
            });
        };

        // pop the dialog for creating a new customer
        vm.newCustomer = function() {
            dialogs.create('/ng1/dialogs/customernew/customernew.html', 'NewCustomerController as vm', {}).result
            .then(function(data) {
                // this is how NewCustomerController "cancels" (by close() w/o passing anything instead dismiss())
                if (typeof data === 'undefined') {
                    console.log("User canceled adding customer");
                }
                else {
                    // can only reliably expect id in the value returned by dialog
                    QMSFactory.loadCustomer(data.id).success(function(result) {
                        vm.customer.data = [result.data];
                        if (!vm.customer.data[0].computers) vm.customer.data[0].computers = [];
                        vm.customer.selected = result.data.id;
                        vm.onCustomerChange();
                    });
                }
            });
        };

        // pop the dialog for creating a new computer for a customer
        vm.newSystem = function() {
            var customer = vm.customer.data.find(function(c) { return c.id === vm.customer.selected; });
            if (!customer) return;
            dialogs.create('/ng1/dialogs/system/computernew.html', 'ComputerNewCtrl', {customer: customer}).result
            .then(function(data) {
                // data has just an id of the customer so reload that customer
                QMSFactory.loadCustomer(data.id).success(function(result) {
                    // update the particular customer
                    var i = vm.customer.data.findIndex(function(c) { return c.id === result.data.id; });
                    if (i >= 0) {
                        vm.customer.data[i] = result.data;
                        if (!vm.customer.data[i].computers) vm.customer.data[i].computers = [];
                        // also update the system list if the customer is selected
                        if (result.data.id === vm.customer.selected) {
                            vm.onCustomerChange();
                            if (vm.customer.system.data.length > 1) {
                                // select the last one w/o a System ID, assuming it's the most recently added
                                for (i = vm.customer.system.data.length - 1; i >= 0; i--) {
                                    if (!vm.customer.system.data[i].system_id) break;
                                }
                                if (i >= 0) {
                                    vm.customer.system.selected = vm.customer.system.data[i];
                                    vm.onSystemChange();
                                }
                            }
                        }
                    }
                });
            }, function() {
                console.log("User canceled adding system");
            });
        };

        // assign the System ID to an existing system or make a new system with the ID to replace an existing system
        vm.assignSystemId = function() {
            if (!vm.customer.system.selected) return;
            var i, customer;
            var baselineId = vm.customer.system.selected.system_id, baselinePlatform, baselineCurrent;

            // assigning ID to an existing system that has none?
            if (!baselineId) {
                // sanity checks
                customer = vm.customer.data.find(function(c) { return c.id === vm.customer.selected; });
                if (!customer) return;
                i = vm.customer.system.selected.idx;
                if (i === undefined || !customer.computers[i]) return;
                // assign the ID and save the entire customer document
                customer.computers[i].system_id = vm.system.normalizedId;
                QMSFactory.saveCustomer(customer)
                .then(function(resp) {
                    var i, temp;
                    if (resp.status !== 200 || !resp.data || !resp.data.data || resp.data.data.id !== customer.id) {
                        var error = new Error('Backend error');
                        error.resp = resp;
                        return $q.reject(error);
                    }
                    vm.step = 3;
                    vm.ui.systemLookUp.setIdSaved();
                    // fill out customer info
                    vm.customer.data = [customer];
                    vm.customer.selected = customer.id;
                    vm.customer.setName(customer);
                    // fill out system info
                    i = customer.computers.findIndex(function(c) { return c.system_id === vm.system.normalizedId; });
                    if (i >= 0) {
                        temp = angular.copy(customer.computers[i]);
                        temp.idx = i;
                        vm.customer.system.data = [temp];
                        vm.customer.system.showGroups = false;
                        vm.customer.system.selected = temp;
                        vm.customer.system.setName(temp);
                    }
                })
                .catch(function(error) {
                    console.log(error);
                    vm.ui.systemLookUp.setError();
                    var msg = 'Unable to communicate with the server. Please try again.';
                    // JW 2019-11-19: show HTTP response for debugging
                    var resp = error.resp ? error.resp : error;
                    var parts = [];
                    if (resp.status) parts.push('' + resp.status);
                    if (resp.statusText) parts.push(resp.statusText);
                    if (resp.data && typeof resp.data !== 'string') parts.push(JSON.stringify(resp.data));
                    msg += '<h6>' + parts.join(' ') + '</h6>';
                    if (resp.data && typeof resp.data === 'string') msg += '<br>' + resp.data;
                    dialogs.error('Key Generator Error', msg, 'lg');
                });
            }

            // replacing an existing system with a different ID by creating a new system with this ID?
            else if (baselineId !== vm.system.normalizedId) {
                // sanity checks
                customer = vm.customer.data.find(function(c) { return c.id === vm.customer.selected; });
                if (!customer) return;
                // prompt the user to create a new system
                dialogs.create('/ng1/dialogs/system/computernew.html', 'ComputerNewCtrl', {customer: customer, systemId: vm.system.normalizedId,
                    name: vm.customer.system.selected.name + ' (replacement)', notes: vm.customer.system.selected.notes}).result
                .then(function(data) {
                    // a series of backend calls need to be performed; use a promise chain here
                    // 1) reload the customer with the newly added system
                    QMSFactory.loadCustomer(data.id)
                    .then(function(resp) {
                        if (resp.status !== 200 || !resp.data || !resp.data.data || !resp.data.data.id) {
                            var error = new Error('Backend error');
                            error.resp = resp;
                            return $q.reject(error);
                        }
                        // update the particular customer
                        var i = vm.customer.data.findIndex(function(c) { return c.id === resp.data.data.id; });
                        if (i < 0) return $q.reject('Saved customer ID did not match');
                        vm.customer.data[i] = resp.data.data;
                        if (!vm.customer.data[i].computers) vm.customer.data[i].computers = [];
                        vm.customer.selected = resp.data.data.id;
                        vm.onCustomerChange(); // this should select the new system upon seeing the matching ID
                        // 2) load the licenses of the system to be replaced
                        return QMSFactory.loadCurrentLicense(baselineId, null, false);
                    })
                    .then(function(resp) {
                        if (resp.status !== 200 || !resp.data || !resp.data.data || !resp.data.data.systemId) {
                            var error = new Error('Backend error');
                            error.resp = resp;
                            return $q.reject(error);
                        }
                        vm.license.baselineId = resp.data.data.systemId;
                        vm.license.baselinePlatform = resp.data.data.platform;
                        vm.license.setCurrent(resp.data.data.current, true);
                        vm.showCurrentLicense();
                        vm.step = 3;
                        vm.license.isReplacing = true;
                        vm.license.replacing = $filter('prettifySystemId')(vm.license.baselineId);
                        vm.license.replacingLocked = true;
                    })
                    .catch(function(error) {
                        console.log(error);
                        var msg = 'Unable to communicate with the server. Please try again.';
                        // JW 2019-11-19: show HTTP response for debugging
                        var resp = error.resp ? error.resp : error;
                        var parts = [];
                        if (resp.status) parts.push('' + resp.status);
                        if (resp.statusText) parts.push(resp.statusText);
                        if (resp.data && typeof resp.data !== 'string') parts.push(JSON.stringify(resp.data));
                        msg += '<h6>' + parts.join(' ') + '</h6>';
                        if (resp.data && typeof resp.data === 'string') msg += '<br>' + resp.data;
                        dialogs.error('Key Generator Error', msg, 'lg');
                    });
                }, function() {
                    console.log("User canceled replacing system");
                });
            }
        };

        vm.showCurrentLicense = function() {
            var sel = vm.license.current[vm.license.currentSelIdx];
            vm.license.currentSelected = (sel && sel.events && sel.events[0]) || {};
            vm.license.currentSelected.vendorName = $filter('licenseVendorLabel')(vm.license.currentSelected.vendor);
            vm.license.request.vendor = vm.license.vendors[0] && vm.license.vendors[0].id;
            if (vm.license.currentSelected.vendor) {
                if (vm.license.vendors.some(function(v) { return v.id === vm.license.currentSelected.vendor; })) {
                    if (vm.license.request.vendor != vm.license.currentSelected.vendor) {
                        vm.license.request.vendor = vm.license.currentSelected.vendor;
                        vm.onVendorChange();
                    }
                }
            }
            vm.license.resetFeatures();
            if (vm.license.currentSelected.licenses) {
                vm.license.currentSelected.licenses.forEach(function(v) {
                    if (v.qty === 0 || v.type === undefined) return; // ignore feature that's been removed
                    var feature = vm.license.features.find(function(f) { return f.id === v.feature; });
                    if (feature !== undefined) {
                        feature.selected = feature.orgSelected = (v.qty > 0);
                        feature.orgQty = v.qty;
                        feature.orgExp = $filter('licenseDurationLabel')(v.type);
                        if (v.type !== 1) feature.orgExp += ' @ ' + $filter('licenseExpirationDate')(v);
                        feature.orgType = v.type;
                        feature.qty = feature.selected ? v.qty : 1;
                        // certain features default to 99 floating if floating at all
                        if (feature.qty > 1 && qmsVocabulary.isLicenseFeatureUnlimitedFloating(feature.id)) feature.qty = 100;
                    }
                });
            }
            vm.license.features.forEach(function(v) {
                // filter out the custom duration option only to be used by the global duration dropdown as a place holder (days<-1)
                v.durations = vm.license.durations.filter(function(u) { return u.days >= -1; });
                // add "reuse" when there's prior and quantity hasn't changed
                if (v.orgSelected && v.orgQty === v.qty) {
                    v.durations.unshift({type: -1, name: (vm.license.isReplacing ? 'Replace' : 'Reuse') + ' (not extending)'});
                    v.type = -1;
                }
                else {
                    v.type = 1;
                }
            });
            vm.updateNoFeatureSelected();
            vm.checkPowerPoint();
            // warn about system already replaced
            vm.ui.alreadyReplacedShown = false;
            if (sel && sel.system_id && sel.replaced_by) {
                vm.ui.alreadyReplacedShown = true;
                if (sel.system_id !== vm.system.normalizedId) {
                    dialogs.error('Already Replaced', 'License Set ' + sel.sub_id + ' of ' + $filter('prettifySystemId')(sel.system_id) +
                        ' has already been replaced by ' + $filter('prettifySystemId')(sel.replaced_by) + '. It should not be replaced again.');
                }
                else {
                    dialogs.error('Already Replaced', 'License Set ' + sel.sub_id + ' of ' + $filter('prettifySystemId')(sel.system_id) +
                        ' has already been replaced by ' + $filter('prettifySystemId')(sel.replaced_by) + '. No new licenses should be issued to it.');
                }
            }
        };

        vm.updateNoFeatureSelected = function() {
            // special handling of Flow Pack
            var flowPack = vm.license.features.find(function(f) { return f.id === 33; });
            if (flowPack && flowPack.selected != vm.ui.flowPackSelected) {
                // Flow Pack = Flow AutoMoco + Flow RAC + Flow Configuration
                vm.ui.flowPackSelected = flowPack.selected;
                vm.license.features.forEach(function(feature) {
                    if (feature.id === 30 || feature.id === 31 || feature.id === 32) {
                        feature.selected = flowPack.selected;
                        if (feature.selected) {
                            feature.qty = flowPack ? flowPack.qty : vm.ui.globalQuantity;
                            feature.type = flowPack ? flowPack.type : vm.ui.globalType;
                        }
                    }
                });
            }
            vm.license.noFeatureSelected = vm.license.features.every(function(v) { return !v.selected; });
        };

        vm.updateReuse = function(index) {
            if (index < 0 || index >= vm.license.features.length) return;
            var feature = vm.license.features[index];
            if (feature.orgSelected && feature.orgQty === feature.qty) {
                // not already have reuse? add it
                if (feature.durations[0].type !== -1) {
                    feature.durations.unshift({ type: -1, name: (vm.license.isReplacing ? 'Replace' : 'Reuse') + ' (not extending)'});
                }
            }
            else {
                // have reuse? remove it
                if (feature.durations[0].type === -1) {
                    // if reuse was selected, change to the original type (do this before changing the options)
                    if (feature.type === -1) feature.type = feature.orgType;
                    feature.durations.shift();
                }
            }
        };

        vm.selectAllFeatures = function() {
            vm.license.features.forEach(function(v) { v.selected = true; });
            vm.updateNoFeatureSelected();
        };

        vm.selectNoFeatures = function() {
            vm.license.features.forEach(function(v) { v.selected = false; });
            vm.updateNoFeatureSelected();
        };

        vm.copyCurrentFeatures = function() {
            vm.license.features.forEach(function(v) { v.selected = false; });
            if (!vm.license.currentSelected || !vm.license.currentSelected.licenses) return;
            vm.license.currentSelected.licenses.forEach(function(v) {
                var feature = vm.license.features.find(function(f) { return f.id === v.feature; });
                if (feature !== undefined) {
                    feature.selected = feature.orgSelected;
                    feature.qty = feature.selected ? feature.orgQty : 1;
                    feature.type = feature.selected ? -1 : 1;
                    // certain features default to 99 floating if floating at all
                    if (feature.qty > 1 && qmsVocabulary.isLicenseFeatureUnlimitedFloating(feature.id)) feature.qty = 100;
                }
            });
            vm.updateNoFeatureSelected();
        };

        vm.setGlobalQuantity = function() {
            var qty = vm.ui.globalQuantity;
            vm.license.features.forEach(function(v, i) {
                v.qty = qty;
                // certain features default to 99 floating if floating at all
                if (v.qty > 1 && qmsVocabulary.isLicenseFeatureUnlimitedFloating(v.id)) v.qty = 100;
                vm.updateReuse(i);
            });
            vm.checkPowerPoint();
        };

        vm.setGlobalDuration = function() {
            var type = vm.ui.globalType;
            // special handling of licenseDurationCustom
            if (type === qmsVocabulary.licenseDurationCustom) {
                dialogs.create('/ng1/dialogs/license/customexpiration.html', 'LicenseCustomExpirationCtrl', {}, { size: 'md' }).result
                .then(function(expiration) {
                    // zero out the time part for duration calculation (in days)
                    expiration.setHours(0, 0, 0, 0);
                    var today = new Date();
                    today.setHours(0, 0, 0, 0);
                    var days = Math.ceil((expiration - today) / 1000 / 86400) + 1;
                    if (days <= 0) {
                        // revert the choice
                        vm.ui.globalType = vm.ui.globalTypeOrg;
                        return;
                    }
                    var customOpt = {
                        type: qmsVocabulary.licenseDurationCustomized,
                        name: 'Until ' + printISODate(expiration),
                        days: days,
                    };
                    // replace existing licenseDurationCustomized option
                    if (vm.license.durations[vm.license.durations.length - 1].type === customOpt.type) {
                        vm.license.durations[vm.license.durations.length - 1] = customOpt;
                    }
                    // append licenseDurationCustomized option
                    else {
                        vm.license.durations.push(customOpt);
                    }
                    // propagate the duration to all features
                    vm.license.features.forEach(function(v) {
                        if (v.durations[v.durations.length - 1].type === customOpt.type) {
                            v.durations[v.durations.length - 1] = customOpt;
                        }
                        else {
                            v.durations.push(customOpt);
                        }
                        v.type = customOpt.type;
                    });
                    vm.ui.globalType = customOpt.type;
                }, function() {
                    console.log('User canceled custom expiration date');
                    // revert the choice
                    vm.ui.globalType = vm.ui.globalTypeOrg;
                });
            }
            // a normal duration type was chosen
            else {
                // propagate the duration to all features
                vm.license.features.forEach(function(v) { v.type = type; });
                vm.ui.globalTypeOrg = vm.ui.globalType;
            }
        };

        vm.setGlobalPackage = function() {
            var features = vm.ui.globalPackage;
            if (features && features.has) {
                vm.license.features.forEach(function(v) {
                    v.selected = features.has(v.id);
                    // if (v.qty <= 0) v.qty = vm.ui.globalQuantity;
                    // if (v.type <= 0) v.type = vm.ui.globalType;
                });
                vm.updateNoFeatureSelected();
                vm.checkPowerPoint();
            }
        };

        vm.checkPowerPoint = function() {
            // disable PowerPoint except for whitelisted vendors
            vm.license.allowsPowerPoint = qmsVocabulary.vendorGetsPowerPoint(vm.license.request.vendor);
            let ppt = vm.license.features.find(function(f) { return f.id === 24; }); // 24: PowerPoint
            if (!vm.license.allowsPowerPoint && ppt !== undefined) ppt.selected = false;
            // automatically bundle PowerPoint with Hermes floating (pegged to QGS+QPS)
            if (vm.license.request.vendor === 1017) { // 1017: Hermes
                let qca = vm.license.features.find(function(f) { return f.id === 0; }); // 0: QGS+QPS
                if (qca && qca.selected && qca.qty > 1) {
                    if (ppt !== undefined && !ppt.selected) {
                        ppt.selected = true;
                        ppt.qty = /* qca.qty */100; // same as other unlimited floating features
                        ppt.type = qca.type;
                    }
                }
            }
        };

        vm.onVendorChange = function() {
            // vendor-specific package
            vm.license.packages = [];
            vm.license.packages.push({ name: 'Package:', features: null });
            var vendor = vm.license.request.vendor;
            if (vendor != 1002 && qmsVocabulary.licenseVendorPackages.has(vendor)) {
                qmsVocabulary.licenseVendorPackages.get(vendor).forEach(function(v) {
                    vm.license.packages.push({ name: v.name, features: new Set(v.features)});
                });
            }
            else {
                qmsVocabulary.licensePackages.forEach(function(v, k) {
                    vm.license.packages.push({ name: k, features: v });
                });
            }
            vm.checkPowerPoint();
        };

        vm.featureDoubleClicked = function(index) {
            if (vm.license.features[index] && vm.license.features[index].id === 24) {
                vm.license.allowsPowerPoint = true;
            }
        };
    
        vm.toggleReplacement = function() {
            if (vm.license.isReplacing) {
                // pop a model dialog to ask for ID of system being replaced
                dialogs.create('/ng1/dialogs/license/replacement.html', 'LicenseReplacementCtrl', {systemId: vm.system.normalizedId}).result
                .then(function(replacedId) {
                    vm.license.replacing = replacedId;
                    if (vm.license.replacing === 'N/A') {
                        vm.license.baselineId = vm.license.replacing;
                        vm.license.baselinePlatform = -1;
                        vm.license.replacing = 'System ID not available';
                        vm.license.setCurrent([], true);
                        vm.showCurrentLicense();
                    }
                    else {
                        vm.license.baselineId = replacedId;
                        QMSFactory.loadCurrentLicense(vm.license.baselineId, null, false)
                        .then(function(resp) {
                            if (resp.status !== 200 || !resp.data || !resp.data.data || !resp.data.data.systemId) {
                                var error = new Error('Backend error');
                                error.resp = resp;
                                return $q.reject(error);
                            }
                            vm.license.baselineId = resp.data.data.systemId;
                            vm.license.baselinePlatform = resp.data.data.platform;
                            vm.license.replacing = resp.data.data.prettyId || resp.data.data.systemId;
                            vm.license.setCurrent(resp.data.data.current, true);
                            vm.showCurrentLicense();
                        })
                        .catch(function(error) {
                            console.log(error);
                            var msg = 'Unable to communicate with the server. Please try again.';
                            // JW 2019-11-19: show HTTP response for debugging
                            var resp = error.resp ? error.resp : error;
                            var parts = [];
                            if (resp.status) parts.push('' + resp.status);
                            if (resp.statusText) parts.push(resp.statusText);
                            if (resp.data && typeof resp.data !== 'string') parts.push(JSON.stringify(resp.data));
                            msg += '<h6>' + parts.join(' ') + '</h6>';
                            if (resp.data && typeof resp.data === 'string') msg += '<br>' + resp.data;
                            dialogs.error('Key Generator Error', msg, 'lg');
                        });
                    }
                }, function() {
                    console.log('User canceled replacing licenses');
                    vm.license.isReplacing = false;
                });
            }
            else {
                // reload the original baseline
                vm.license.replacing = '';
                vm.license.baselineId = vm.system.normalizedId;
                QMSFactory.loadCurrentLicense(vm.license.baselineId, null, false)
                .then(function(resp) {
                    if (resp.status !== 200 || !resp.data || !resp.data.data || !resp.data.data.systemId) {
                        var error = new Error('Backend error');
                        error.resp = resp;
                        return $q.reject(error);
                    }
                    vm.license.baselineId = resp.data.data.systemId;
                    vm.license.baselinePlatform = resp.data.data.platform;
                    vm.license.setCurrent(resp.data.data.current, false);
                    vm.showCurrentLicense();
                })
                .catch(function(error) {
                    console.log(error);
                    var msg = 'Unable to communicate with the server. Please try again.';
                    // JW 2019-11-19: show HTTP response for debugging
                    var resp = error.resp ? error.resp : error;
                    var parts = [];
                    if (resp.status) parts.push('' + resp.status);
                    if (resp.statusText) parts.push(resp.statusText);
                    if (resp.data && typeof resp.data !== 'string') parts.push(JSON.stringify(resp.data));
                    msg += '<h6>' + parts.join(' ') + '</h6>';
                    if (resp.data && typeof resp.data === 'string') msg += '<br>' + resp.data;
                    dialogs.error('Key Generator Error', msg, 'lg');
                });
            }
        };

        function filterFlowPack(req) {
            // Flow Pack included?
            if (req.features.some(function(f) { return f.feature == 33; })) {
                // fliter out Flow AutoMoco, Flow RAC, Flow Configuration
                var temp = [];
                req.features.forEach(function(f) {
                    if (f.feature != 30 && f.feature != 31 && f.feature != 32) temp.push(f);
                });
                req.features = temp;
            }
        }

        vm.submitRequest = function() {
            var req, promise, err;
            if (vm.step === 5) {
                vm.downloadLicense();
            }
            else if (vm.step === 3) {
                // check for invalid vendor selection
                if (vm.license.request.vendor === undefined || vm.license.request.vendor < 0) {
                    dialogs.error('Invalid Vendor', 'You have not selected a vendor.');
                    return;
                }

                vm.step = 4;
                vm.ui.submitButton = 'Generating...';

                // not replacing?
                if (!vm.license.isReplacing) {
                    // prepare the license request
                    req = angular.copy(vm.license.request);
                    req.systemId = vm.system.normalizedId;
                    req.suiteNum = vm.system.suiteNum || '';
                    req.subId = vm.license.current[vm.license.currentSelIdx] && vm.license.current[vm.license.currentSelIdx].sub_id; // this may be undefined which means we need to create a new license set
                    req.features = [];
                    vm.license.features.forEach(function(v) {
                        if (v.selected) {
                            var f = { feature: v.id, qty: v.qty, type: v.type };
                            if (v.type === qmsVocabulary.licenseDurationCustomized && v.durations[v.durations.length - 1].type === v.type) {
                                f.customDuration = v.durations[v.durations.length - 1].days || -1;
                            }
                            req.features.push(f);
                        }
                    });
                    filterFlowPack(req);
                    // create a new license set if necessary
                    if (req.subId === undefined) {
                        // make a promise that returns the Sub ID generated by backend
                        promise = QMSFactory.newLicenseSet(req.systemId)
                        .then(function(resp) {
                            if (resp.status === 200 && resp.data && resp.data.data && resp.data.data.subId !== undefined) {
                                return $q.resolve(resp.data.data.subId);
                            }
                            else {
                                var error = new Error('Backend error');
                                error.resp = resp;
                                return $q.reject(error);
                            }
                        });
                    }
                    else {
                        // make a fake promise that just returns the Sub ID we already have
                        promise = $q.resolve(req.subId);
                    }
                    // follow up with a license request
                    promise.then(function(subId) {
                        req.subId = subId;
                        return QMSFactory.requestLicense(req);
                    })
                    .then(function(resp) {
                        if (resp.status !== 200 || !resp.data || !resp.data.data || !resp.data.data.current ||
                            !resp.data.data.current.events || !resp.data.data.current.events[0]) {
                            var error = new Error('Backend error');
                            error.resp = resp;
                            return $q.reject(error);
                        }
                        vm.license.issued = resp.data.data.current.events[0];
                        vm.license.issued.subId = req.subId;
                        vm.step = 5;
                        vm.ui.submitButton = 'Download';
                    })
                    .catch(function(error) {
                        console.log(error);
                        vm.step = 3;
                        vm.ui.submitButton = 'Try Again';
                        if (error.resp && error.resp.data && error.resp.data.data && error.resp.data.data.error) {
                            if (/cannot have new licenses/i.test(error.resp.data.data.error)) {
                                dialogs.error('Key Generator Error', 'License Set ' + req.subId + ' of ' + $filter('prettifySystemId')(req.systemId) +
                                    ' has already been replaced and cannot have new licenses.');
                                return;
                            }
                        }
                        var msg = 'Unable to communicate with the server. Please try again.';
                        // JW 2019-11-19: show HTTP response for debugging
                        var resp = error.resp ? error.resp : error;
                        var parts = [];
                        if (resp.status) parts.push('' + resp.status);
                        if (resp.statusText) parts.push(resp.statusText);
                        if (resp.data && typeof resp.data !== 'string') parts.push(JSON.stringify(resp.data));
                        msg += '<h6>' + parts.join(' ') + '</h6>';
                        if (resp.data && typeof resp.data === 'string') msg += '<br>' + resp.data;
                        dialogs.error('Key Generator Error', msg, 'lg');
                    });
                }

                // replacing?
                else if (vm.license.isReplacing && vm.license.baselineId !== vm.system.normalizedId) {
                    // prepare the license replacement request
                    req = angular.copy(vm.license.request);
                    req.replacedId = vm.license.baselineId;
                    req.replacedSubId = (vm.license.current[vm.license.currentSelIdx] && vm.license.current[vm.license.currentSelIdx].sub_id) || null;
                    req.systemId = vm.system.normalizedId;
                    req.features = [];
                    vm.license.features.forEach(function(v) {
                        if (v.selected) {
                            var f = { feature: v.id, qty: v.qty, type: v.type };
                            if (v.type === qmsVocabulary.licenseDurationCustomized && v.durations[v.durations.length - 1].type === v.type) {
                                f.customDuration = v.durations[v.durations.length - 1].days || -1;
                            }
                            req.features.push(f);
                        }
                    });
                    filterFlowPack(req);
                    QMSFactory.replaceLicense(req)
                    .then(function(resp) {
                        if (resp.status !== 200 || !resp.data || !resp.data.data || !resp.data.data.replacement ||
                            !resp.data.data.replacement.events || !resp.data.data.replacement.events[0]) {
                            err = new Error('Backend error');
                            err.resp = resp;
                            return $q.reject(err);
                        }
                        vm.license.issued = resp.data.data.replacement.events[0];
                        vm.license.issued.subId = resp.data.data.subId;
                        vm.step = 5;
                        vm.ui.submitButton = 'Download';
                    })
                    .catch(function(error) {
                        console.log(error);
                        vm.step = 3;
                        vm.ui.submitButton = 'Try Again';
                        if (error.resp && error.resp.data && error.resp.data.data && error.resp.data.data.error) {
                            if (/cannot be replaced again/i.test(error.resp.data.data.error)) {
                                dialogs.error('Key Generator Error', 'License Set ' + req.replacedSubId + ' of ' + $filter('prettifySystemId')(req.replacedId) +
                                    ' has already been replaced and cannot be replaced again.');
                                return;
                            }
                        }
                        var msg = 'Unable to communicate with the server. Please try again.';
                        // JW 2019-11-19: show HTTP response for debugging
                        var resp = error.resp ? error.resp : error;
                        var parts = [];
                        if (resp.status) parts.push('' + resp.status);
                        if (resp.statusText) parts.push(resp.statusText);
                        if (resp.data && typeof resp.data !== 'string') parts.push(JSON.stringify(resp.data));
                        msg += '<h6>' + parts.join(' ') + '</h6>';
                        if (resp.data && typeof resp.data === 'string') msg += '<br>' + resp.data;
                        dialogs.error('Key Generator Error', msg, 'lg');
                    });
                }

                // noop
                else {
                    vm.step = 3;
                    vm.ui.submitButton = 'Generate';
                }
            }
        };

        vm.downloadLicense = function() {
            if (vm.step !== 5 || !vm.license.issued || !vm.license.issued.licenses) return;
            // generate license file content
            var text, element;
            text = '#ID\n';
            text += vm.system.id + '\n';
            vm.license.issued.licenses.forEach(function(v) {
                if (v.qty > 0 && v.key) {
                    text += '# ' + $filter('licenseQuantityLabel')(v.qty) + ': ' + $filter('licenseFeatureLabel')(v.feature) +
                        ' (' + $filter('licenseDurationLabel')(v);
                    if (v.duration !== 0) text += ' - Expires: ' + $filter('licenseExpirationDate')(v);
                    text += ')\n';
                    text += v.key + '\n';
                }
            });
            text += '\n';
            element = $window.document.createElement('a');
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
            element.setAttribute('download', vm.system.id + (vm.license.issued.subId ? ('#' + vm.license.issued.subId) : '') + '.lic');
            element.style.display = 'none';
            $window.document.body.appendChild(element);
            element.click();
            $window.document.body.removeChild(element);
        };

        //
        // initialization
        //

        // initialize vm.license.durations
        qmsVocabulary.licenseDurations.forEach(function(v, k) {
            var name = v.name;
            if (v.days > 0) name += ' (' + v.days + ')';
            vm.license.durations.push({ type: k, name: name, days: v.days });
        });
        vm.license.durations.push({ type: qmsVocabulary.licenseDurationCustom, name: 'Custom...', days: -2 });
        // initialize vm.license.quantities
        angular.copy(qmsVocabulary.licenseQuantityOptions, vm.license.quantities);
        vm.license.reset();
        vm.user = AuthTokenFactory.getUser();
        // initialize vm.license.packages
        vm.license.packages.push({ name: 'Package:', features: null });
        qmsVocabulary.licensePackages.forEach(function(v, k) {
            vm.license.packages.push({ name: k, features: v });
        });

        // System ID given in the path?
        if ($routeParams.systemID) {
            vm.system.id = $routeParams.systemID;
            vm.findSystem();
        }
    }

})();
