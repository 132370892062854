angular.module('qms').controller('ImportOldRefCtrl', function($scope, $uibModalInstance, data) {
    'use strict';

    $scope.systemId = data.systemId;
    $scope.subId = data.subId;
    $scope.reference = data.reference;
    $scope.reconciled = data.reconciled;
    $scope.note = data.note;
    $scope.events = data.events;

    $scope.ok = function() {
        $uibModalInstance.close({ reference: $scope.reference, reconciled: $scope.reconciled, note: $scope.note });
    };

    $scope.cancel = function() {
        $uibModalInstance.dismiss("User canceled importing licenses");
    };
});