// To add a new dialog: this file must be included in the project's index.html!

(function() {
	'use strict';
	angular
		.module('qms')
		.controller('AimDocSubmitChangesController',AimDocSubmitChangesController);
	
	function AimDocSubmitChangesController( $modalInstance, $sce, $rootScope, data )
	{
		var vm=this;
		vm.init = init;
		vm.cancel = cancel;
		vm.submit = submit;
        vm.errorMsg = "";
		//   vm.watch= $rootScope.watch;
	//	vm.title = data.title;
		
		function init() {
			
		}
		
		function submit() {
			var data = {};
			data.reviewer = $( "#submitChangesReviewer option:selected" ).text();
			data.desc = document.getElementById("submitChangesDescription").value;
			data.jobid = document.getElementById("submitChangesJobID").value;
			data.isHazard = document.getElementById("submitChangesIsHazard").checked;

            if (data.desc.trim().length < 3)
            {
                vm.errorMsg = "Please enter a summary of the changes you made.";
                return;
            }
			
			data.mergeWith2010 = document.getElementById("mergeWith2010").checked;
			data.mergeWith2013 = document.getElementById("mergeWith2013").checked;
			data.mergeWith2015 = document.getElementById("mergeWith2015").checked;
			data.mergeWithMainline = document.getElementById("mergeWithMainline").checked;
			
			console.log("submit() for Submit Changes dialog selected!");
			$modalInstance.close(data);	// Pass back data to user
		}
		
		function cancel() {
			console.log("cancel() for Submit Changes dialog selected!");
		//	$modalInstance.dismiss('canceled'); // Doesn't call user callback it seems
			$modalInstance.close();	// We need to call the user callback so that it can set no dialog as being open
		}
				
		vm.init();
	}

})();
