(function() {
    'use strict';
    angular.module('qms').controller('ImportOldLicenseController', ImportOldLicenseController);

    function ImportOldLicenseController($location, $q, $routeParams, dialogs, QMSFactory, qmsVocabulary, Title) {
        var vm = this;

        vm.systemId = '';
        vm.customer = {};
        vm.events = [];
        vm.subIdOptions = [];
        vm.importInProgress = false;
        vm.progressMsg = '';

        vm.goToKeyGen = function() {
            $location.path('/regkey/' + vm.systemId);
        };

        vm.importChecked = function() {
            if (vm.importInProgress) return;
            vm.importInProgress = true;
            var bySubId = new Map(), subIds = [], good = [], cancelled = [], deferred = $q.defer(), promise = deferred.promise;
            if (vm.events.forEach) {
                vm.events.forEach(function(v) {
                    var set;
                    if (v.checked) {
                        set = bySubId.get(v.subId);
                        if (!set) {
                            set = [];
                            bySubId.set(v.subId, set);
                        }
                        set.push(v);
                    }
                });
            }
            if (!bySubId.size) return;
            bySubId.forEach(function(v, k) { subIds.push(k); });
            subIds.sort(function(a, b) { return a - b; });
            // submit the import request one sub ID by one, chaining the promises to make sure sequentiality
            subIds.forEach(function(subId) {
                promise = promise.then(function() {
                    // prompt for confirmation and let user enter/edit reference, reconciled and note
                    var dlgData = {
                        systemId: vm.systemId, subId: subId, reference: '', reconciled: true, note: '', events: bySubId.get(subId), eventIds: []
                    };
                    dlgData.events.forEach(function(v) {
                        this.reference = this.reference || (v.reference || '');
                        this.note = this.note || (v.description || '');
                        this.eventIds.push(v.eventId);
                    }, dlgData);
                    if (dlgData.eventIds) {
                        if (dlgData.note) dlgData.note += '\n\n';
                        dlgData.note += 'Imported from License 2.0: <' + dlgData.eventIds.join('>, <') + '>';
                    }
                    vm.progressMsg = 'Importing into License Set ' + subId;
                    return dialogs.create('/ng1/dialogs/license/oldref.html', 'ImportOldRefCtrl', dlgData).result
                        .then(function(dlgResult) {
                            // send import request
                            return QMSFactory.importOldLicenses(vm.systemId, subId, dlgData.eventIds, dlgResult.reference, dlgResult.reconciled, dlgResult.note)
                                .then(function(resp) {
                                    // abort when the backend returns errors
                                    if (resp.status !== 200 || !resp.data || !resp.data.data || resp.data.data.systemId !== vm.systemId) {
                                        return $q.reject(new Error((resp.data && resp.data.data && resp.data.data.error) || 'Backend error'));
                                    }
                                    else {
                                        // resolve normally by returning nothing (undefined)
                                        good.push(subId);
                                    }
                                });
                        }, function() {
                            // resolve normally when the dialog was cancelled by returning nothing (undefined)
                            cancelled.push(subId);
                        });
                });
            });
            promise.then(function() {
                var msg = [];
                if (good.length) msg.push('Import of License Set(s) ' + good.join(', ') + ' was successful.');
                if (cancelled.length) msg.push('Import of License Set(s) ' + cancelled.join(', ') + ' was cancelled.');
                dialogs.notify('Licenses Imported', msg.join('<br>'));
                vm.importInProgress = false;
                vm.progressMsg = '';
            }).catch(function(error) {
                console.log(error);
                var msg = [];
                msg.push(error.message || 'Something went wrong. Please try again.');
                if (good.length) msg.push('Import of License Set(s) ' + good.join(', ') + ' was successful.');
                if (cancelled.length) msg.push('Import of License Set(s) ' + cancelled.join(', ') + ' was cancelled.');
                if (good.length + cancelled.length < subIds.length) {
                    msg.push((good.length + cancelled.length ? 'The rest' : 'All') + ' were aborted.');
                }
                dialogs.error('License Import Error', msg.join('<br>'));
                vm.importInProgress = false;
                vm.progressMsg = '';
            });
            deferred.resolve(); // trigger the promise chain
        };

        // groups features in an event for display purpose
        function groupEvent(event) {
            var grouped = new Map(), array = [];
            event.dateObj = event.date ? new Date(event.date) : new Date();
            event.checked = false;
            event.subId = 1;
            if (event.licenses && event.licenses.forEach) {
                event.licenses.forEach(function(license) {
                    var exp = license.type === 1 ? '0' : license.exp;
                    var key = license.qty + '|' + exp, group;
                    group = grouped.get(key);
                    if (!group) {
                        group = { qty: license.qty, type: license.type, exp: exp, features: [] };
                        grouped.set(key, group);
                    }
                    group.features.push(license.feature);
                });
            }
            grouped.forEach(function(v) {
                var featureIndexes = [], featureList = '';
                if (v.features && v.features.forEach) {
                    v.features.forEach(function(feature) {
                        var i = qmsVocabulary.licenseFeatures.findIndex(function(f) { return f.id === feature; });
                        if (i >= 0) featureIndexes.push(i);
                    });
                }
                if (featureIndexes.length > 0) {
                    featureIndexes.sort(function(a, b) { return a - b; });
                    featureIndexes.forEach(function(i) { featureList += ', ' + qmsVocabulary.licenseFeatures[i].name; });
                    featureList = featureList.slice(2);
                }
                v.featureList = featureList;
                array.push(v);
            });
            array.sort(function(a, b) { return (a.qty - b.qty) || (a.type - b.type); });
            event.grouped = array;
        }

        function init() {
            var i;
            for (i = 1; i <= 5; i++) vm.subIdOptions.push({ subId: i, label: 'License Set ' + i});
            if ($routeParams['id']) {
                Title.setTitle("Import Old license");
                QMSFactory.loadOldLicenses($routeParams['id'])
                .then(function(resp) {
                    if (resp.status === 200 && resp.data && resp.data.data) {
                        vm.systemId = resp.data.data.systemId;
                        vm.customer = resp.data.data.customer;
                        vm.customer.vendor = vm.customer.subvendor > 0 && qmsVocabulary.platformHasSubvendors(vm.customer.companyid) ? vm.customer.subvendor + 1000
                            : vm.customer.companyid;
                        vm.events = resp.data.data.events;
                        if (vm.events.forEach) vm.events.forEach(groupEvent);
                        if (vm.events.sort) vm.events.sort(function(a, b) { return b.dateObj.getTime() - a.dateObj.getTime(); });
                    }
                });
            }
        }

        init();
    }
})();