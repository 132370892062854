/* jshint -W014 */
/* global MaskedInput */
(function() {
    'use strict';

    angular.module('qms')
        .directive('dateSelect', dateSelect);


    function dateSelect() {
        return {
            // 'C' = class ... should be 'E' (element; <multiSelect>), but had issues getting it to work correctly
            restrict: 'C',
            replace: true,
            transclude: true,
            scope: {
                includeTime:'='
            },
            templateUrl: '/ng1/templates/cs_dateselect.html',
            controller: ['$scope', function($scope) {

                $scope.init = function() {
                    console.log("dateSelect");
                    var elem = document.getElementById('dateselect');
                    new MaskedInput({
                        elm: elem,
                        format: 'YYYY-MM-DD',
                        separator: '\/',
                        typeon: 'MDY',
                        allowedfx: function (mask, ch, index) {
                            //return true;
                            var str = mask.elm.value;
                            switch (index) {
                                case 1:
                                    return ('01'.indexOf(ch) > -1);
                                case 2:
                                    if (str[0] === '1') {
                                        // Ensure month does not exceed 12
                                        return ('012'.indexOf(ch) > -1);
                                    }
                                    break;
                                case 4:
                                    return ('0123'.indexOf(ch) > -1);
                                case 5:
                                    if (str[3] === '3') {
                                        // Ensure day does not exceed 31
                                        return ('01'.indexOf(ch) > -1);
                                    }
                                    break;
                                case 7:
                                    return ('12'.indexOf(ch) > -1);
                            }
                            return true;
                        }
                    });
                    console.log(elem);
                };
                $(window).load(function() {
                    $scope.init();
                });

            }]
        };
    }

})();

