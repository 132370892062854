(function() {

    angular.module('qms').controller('SupportEndingController', SupportEndingController);

    function SupportEndingController($scope, $http, $q, $routeParams, $location, QMSFactory, $sce, uiGridConstants, Title) {

        var vm = $scope;

        vm.customer = {
            data: [],
            cols: [
                {field:'supportends', displayName:'Support Ends', width:'15%',
                    sort: {direction: uiGridConstants.ASC, ignoreSort: false, priority: 0},
                },
                {field:'name', displayName:'Site', width:'45%'},
                {field:'city', displayName:'City', width:'20%'},
                {field:'state', displayName:'State', width:'10%'},

                {field:'contacts', displayName:' ', width: '5%', myType:'custom',
                    cellTemplate: "<div class=\"ngCellText colt{{$index}}\" align='center'>" +
                    "<button popover-template=\"'/partial/search/contacts_template.html'\" " +
                    "popover-title=\"Contacts\" popover-trigger=\"focus\" type=\"button\" class=\"btn btn-sm btn-default\" popover-append-to-body=\"true\"><i class=\"fa fa-address-card\"></i></button>" +
                    "</div>"},
            ],
            openLink: "customer"
        };

        var today = new Date(), sToday = printISODate(today).replace(/-/g, '');
        var oneMonth = new Date(); oneMonth.setMonth(oneMonth.getMonth() + 1);
        var sOneMonth = printISODate(oneMonth).replace(/-/g, '');
        var threeMonths = new Date(); threeMonths.setMonth(threeMonths.getMonth() + 3);
        var sThreeMonths = printISODate(threeMonths).replace(/-/g, '');
        var threeMonthsPlus1 = threeMonths; threeMonthsPlus1.setDate(threeMonthsPlus1.getDate() + 1);
        var sThreeMonthsPlus1 = printISODate(threeMonthsPlus1).replace(/-/g, '');
        var sixMonths = new Date(); sixMonths.setMonth( sixMonths.getMonth() + 6 );
        var sSixMonths = printISODate(sixMonths).replace(/-/g, '');
        vm.query = {
            period: 'today',
            options: [
                {value: 'today', label: 'have expired by now'},
                {value: '-' + sOneMonth, label: 'have expired or will expire in a month'},
                {value: '-' + sThreeMonths, label: 'have expired or will expire in 3 months'},          // example value: "-20210303"
                {value: '-' + sSixMonths, label: 'have expired or will expire in 6 months'},
                {value: sToday + '-' + sOneMonth, label: 'are current but will expire in a month'},
                {value: sToday + '-' + sThreeMonths, label: 'are current but will expire in 3 months'},  // example value: "20201203-20210303"
                {value: sToday + '-' + sSixMonths, label: 'are current but will expire in 6 months'},
                {value: sThreeMonthsPlus1 + '-' + sSixMonths, label: 'will expire 3-6 months from now'}
            ]
        };

        // console.log("Date menu options: ", vm.query.options);

        vm.working = false;
        // vm.init = init;
        // vm.templateUrl=  '/partial/search/contacts_template.html';
        vm.refresh = refresh;

        function refresh() {
            vm.working = true;
            var query = $scope.query.period || 'today';
            QMSFactory.customerBySupportEnds(query).success(function(data) {
                vm.customer.data = data.data;
                console.log("Got Support Ends data: ", data);
                vm.working = false;
            });
        }

        Title.setTitle("Support Ending");
        refresh();
    }

})();
