// DEPRECATED -- no longer in use as of 11/2020

angular.module('qms').controller('SupportEventCtrl', function($scope, $uibModalInstance, AuthTokenFactory, data) {

    $scope.record = {};
    $scope.ui = {
        dateOpened: false,
        durationOpts: [],
    };
    $scope.customer = {};
    $scope.users = [];

    $scope.init = function() {
        // populate $scope.ui.durationOpts
        $scope.ui.durationOpts.push({ value: 0, text: 'None' });
        $scope.ui.durationOpts.push({ value: 0.5, text: '0.5 hour' });
        $scope.ui.durationOpts.push({ value: 1, text: '1 hour' });
        for (var i = 1; i < 8; i++) {
            $scope.ui.durationOpts.push({ value: (i + 0.5), text: ((i + 0.5) + ' hours') });
            $scope.ui.durationOpts.push({ value: (i + 1), text: ((i + 1) + ' hours') });
        }
        // populate $scope.record with data or defaults
        $scope.record = data.record;
        $scope.record.date = $scope.record.date || new Date();
        $scope.record.rep = $scope.record.rep || AuthTokenFactory.getUser().id;
        $scope.record.duration = $scope.record.duration || 0;
        $scope.record.summary = $scope.record.summary || '';
        $scope.customer = data.customer || {};
        $scope.users = data.users || [];
    };

    $scope.ok = function() {
        $uibModalInstance.close($scope.record);
    };

    $scope.cancel = function() {
        $uibModalInstance.dismiss('canceled');
    };

    $scope.openDate = function($event) {
        $event.preventDefault();
        $event.stopPropagation();
        $scope.ui.dateOpened = true;
    };

    $scope.init();
});
