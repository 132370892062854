// License History

(function() {
    'use strict';
    angular.module('qms').controller('LicenseHistoryController', LicenseHistoryController);

    function LicenseHistoryController($location, $routeParams, $filter, $window, dialogs, QMSFactory, qmsVocabulary, LicenseUtils, Title) {
        var vm = this;

        vm.system = { isDetailsOpen: false };
        vm.customer = undefined;
        vm.histories = [];

        vm.reconcile = function(event) {

            var copy = angular.copy(event);
            console.log("User reconciling license event: ", copy);

            dialogs.create('/ng1/dialogs/license/reconcile.html', 'LicenseReconcileCtrl', { event: copy }).result
            .then(function(data) {
                // sanity check
                if (data.eventId === event._id) {
                    event.reference = data.reference;
                    event.reconciled = data.reconciled;
                    event.note = data.note;
                }
            }, function() {
                console.log("User canceled reconciling a license event");
            });
        };

        vm.edit = function(license, event) {
            var licenseCopy = angular.copy(license), eventCopy = angular.copy(event);
            dialogs.create('/ng1/dialogs/license/editevent.html', 'LicenseEventEditCtrl', { license: licenseCopy, event: eventCopy }).result
            .then(function(data) {
                // sanity check
                if (data.eventId === event._id) {
                    event.reference = data.reference;
                    event.reconciled = data.reconciled;
                    event.note = data.note;
                    event.vendor = data.vendor;
                }
            }, function() {
                console.log("User canceled editing a license event");
            });
        };

        vm.newLicense = function(history) {
            if (!history.system_id || !history.sub_id) return;
            $location.path('/regkey/' + history.system_id + '#' + history.sub_id);
        };

        vm.downloadLicense = function(systemId, subId, licenses) {
            console.log(licenses);
            // generate license file content
            var text, element, prettyId = $filter('prettifySystemId')(systemId);
            text = '#ID\n';
            text += prettyId + '\n';
            licenses.forEach(function(v) {
                if (v.qty > 0 && v.key) {
                    text += '# ' + $filter('licenseQuantityLabel')(v.qty) + ': ' + $filter('licenseFeatureLabel')(v.feature) +
                        ' (' + $filter('licenseDurationLabel')(v);
                    if (v.duration !== 0) text += ' - Expires: ' + $filter('licenseExpirationDate')(v);
                    text += ')\n';
                    text += v.key + '\n';
                }
            });
            text += '\n';
            element = $window.document.createElement('a');
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
            element.setAttribute('download', prettyId + (subId ? ('#' + subId) : '') + '.lic');
            element.style.display = 'none';
            $window.document.body.appendChild(element);
            element.click();
            $window.document.body.removeChild(element);
        };

        vm.isVendorEditable = function(platform) {
            return qmsVocabulary.platformHasSubvendors(platform);
        };

        function init() {
            if ($routeParams['id']) {
                Title.setTitle("License History: " + $routeParams['id']);

                QMSFactory.loadLicenseHistory($routeParams['id'])
                .then(function(resp) {
                    if (resp.status === 200 && resp.data && resp.data.data) {

                        vm.system.systemId = resp.data.data.systemId;
                        vm.system.prettyId = resp.data.data.prettyId;
                        vm.system.platform = resp.data.data.platform;
                        vm.system.details = resp.data.data.details;
                        vm.customer = resp.data.data.customer;
                        if (vm.customer) {
                            vm.customer.location = (vm.customer.city || '') + ' ' + (vm.customer.state || '') + ' ' + (vm.customer.zip || '');
                            // find the system in 'computers' list
                            if (vm.customer.computers && vm.customer.computers.find) {
                                vm.system.computer = vm.customer.computers.find(function(v) { return v.system_id === vm.system.systemId; });
                            }
                        }
                        if (vm.system.details && vm.system.details.net_macid) {
                            vm.system.isFloatingLicenseVM = qmsVocabulary.isMacAddressFloatingLicenseVM(vm.system.details.net_macid);
                            vm.system.isNodeLockedVM = qmsVocabulary.isMacAddressNodeLockedVM(vm.system.details.net_macid);
                        }
                        else {
                            vm.system.isFloatingLicenseVM = false;
                            vm.system.isNodeLockedVM = false;
                        }
        
                        vm.histories = resp.data.data.histories;
                        LicenseUtils.groupLicenseHistories( vm.histories );
                    }
                });
            }
        }

        init();
    }
})();
