// Wrapper for the Angular 2 Job page.
// This file is included in angular.json
// See js/setup.js as well.


(function() {
    'use strict';
    angular.module('qms')
        .controller('TestWorksheetWrapperController', TestWorksheetWrapperController);
    
    function TestWorksheetWrapperController($scope, $routeParams, $location){
        
        $scope.jobid = $routeParams["jobid"];
        $scope.twid = $routeParams["twid"];
        console.log("TestWorksheetWrapperController got jobid: ", $scope.jobid, "and twid: ", $scope.twid);
    }
})();