// Service providing vocabulary (database values or enums to display text) for QMS
angular.module('qmsVocabulary', []).factory('qmsVocabulary', function() {
    'use strict';
    var vocabulary = {};
    var i, temp;

    // Reminder Categories
    // values are what we store in MongoDB; text for display
    vocabulary.reminderCategoryGroups = {
        customer: [
            { value: 'customer_req', text: 'Customer Request' },
            { value: 'demo_exp', text: 'Demo Licenses Expiring' },
            { value: 'installation', text: 'Installation/Training' },
            { value: 'payment', text: 'Payment Expected' },
            { value: 'support_end', text: 'Support Ending' },
            { value: 'upgrade', text: 'Upgrade Related' },
        ],
        common: [
            { value: 'other',   text: 'Other' },
        ],
    };
    vocabulary.reminderCategoryGroupLabels = {
        customer: 'Customer Related',
        common: '',
    };

    // Reminder Statuses
    vocabulary.reminderStatusOptions = [
        { value: 'scheduled',   text: 'Scheduled' },
        { value: 'pending',     text: 'Pending' },
        { value: 'completed',   text: 'Completed' }
    ];

    // Pass a group name postfixed with '+' to also include the common categories (to the end)
    vocabulary.reminderCategoriesForGroup = function(group) {
        var groups = vocabulary.reminderCategoryGroups;
        var g = String(group);
        if (g.endsWith('+')) {
            g = g.slice(0, -1);
            if (groups.hasOwnProperty(g)) {
                return g !== 'common' ? groups[g].concat(groups['common']) : groups[g];
            }
        }
        return groups.hasOwnProperty(g) ? groups[g] : [];
    };

    // A flattened array of categories with a 'group' tag added to each entry
    vocabulary.allReminderCategoriesWithGroup = function() {
        // convert the object properties collection to flat array
        var orgGroups = vocabulary.reminderCategoryGroups;
        var outGroups = [];
        for (var k in orgGroups) {
            // deep clone before adding a 'group' tag to each entry
            var g = JSON.parse(JSON.stringify(orgGroups[k]));
            for (var i = 0; i < g.length; i++) {
                g[i].group = vocabulary.reminderCategoryGroupLabels.hasOwnProperty(k) ? vocabulary.reminderCategoryGroupLabels[k] : k;
            }
            Array.prototype.push.apply(outGroups, g);
        }
        return outGroups;
    };

    // REMOVED per Geoff/Tejas: Support Incident Status
   /* vocabulary.supportIncidentStatuses = [
        { id: 1,    type: "Open" },
        { id: 2,    type: "Wait - Customer" },
        { id: 3,    type: "Wait - Engineering" },
        { id: 100,  type: "Closed" },
    ];*/

    // Support Incident Initiation
    vocabulary.supportIncidentInitiations = [
        { value: 'email',   text: 'Email' },
        { value: 'phone',   text: 'Phone' },
        { value: 'other',   text: 'Other' },
        { value: '',        text: 'Unknown' }, // keep this last and insert new options above
    ];

    // Support Incident Remote Logon Options
    vocabulary.supportIncidentRemoteOptions = [
        { text: 'GoToAssist', value: 'GoToAssist' },
        { text: 'GoToMeeting', value: 'GoToMeeting' },
        { text: 'LogMeIn', value: 'LogMeIn' },
        { text: 'TeamViewer', value: 'TeamViewer' },
        { text: 'Other', value: null },
    ];

    // Support Incident Issue menu options (see editSupport.component.js)
    // color is used by support incident graph
    // JW 2022-09-08
    // A misnomer. More appropriately "component"
    vocabulary.supportIncidentIssue = [
        { text: 'QGS+QPS', value: 'QGS+QPS', color: "red"  },
        { text: 'QPET', value: 'QPET', color: "orange" },
        { text: 'QBS', value: 'QBS', color:  "purple" },
        { text: 'CSI', value: 'CSI', color: "green" },
        { text: 'ARG/QARG', value: 'ARG/QARG', color: "brown" },
        { text: 'CSView', value: 'CSView', color: 'salmon' },
        { text: 'Floating License Server', value: 'Floating License Server', color: "black" },
        { text: 'System setup / Installation', value: 'System setup / Installation', color: "cyan" },
        { text: 'Database issues', value: 'Database issues', color: "blue" },
        { text: 'DICOM Connectivity issues', value: 'DICOM issues', color: "yellow" },
        { text: 'User Permission issues', value: 'User Permission issues', color: 'magenta' },
        { text: 'License Update', value: 'License Update', color: 'pink' },
        { text: 'Estimate/Invoice', value: 'Estimate/Invoice', color: 'plum' },
        { text: 'Cybersecurity Inquiry', value: 'Cybersecurity Inquiry', color: '' },
        { text: 'Other', value: 'Other', color: "gray" },
    ];

    // Support Incident Issue Detail options (only for some Issues)
    // Make sure the key here exactly matches the 'text' in vocabulary.supportIncidentIssue.
    // JW 2022-09-08
    // Again, not really "issue" but more appropriately "component"
    vocabulary.supportIncidentIssueDetail = {
        'Database issues': [
            { text: 'General', value: 'Database issues', color: "blue" },
            { text: 'SQLite', value: 'SQLite', color: "aqua" },
            { text: 'MS SQL Server', value: 'MS SQL Server', color: "cornflowerblue" },
            { text: 'PostgreSQL', value: 'postgreSQL', color: "darkturquoise" }
        ],
        'QGS+QPS': [
            { text: 'General', value: 'QGS+QPS', color: "red" },
            { text: 'AutoRecon / Moco', value: 'AutoRecon / Moco', color: "Crimson" },
            { text: 'CFR', value: 'CFR', color: "DarkRed" },
            { text: 'Phase', value: 'Phase', color: "IndianRed" }
        ]
    };

    // JW 2022-09-08
    // New support interaction "reason" options. Note there can be more than one selected.
    vocabulary.supportReason = [
        { value: 'routine',     text: 'Routine Support' },
        { value: 'business',    text: 'Financial/Business/License' },
        { value: 'install',     text: 'Installation' },
        { value: 'training',    text: 'Training' },
        { value: 'featurereq',  text: 'Feature Request' },
        { value: 'complaint',   text: 'Complaint' }
    ];

        // List of applications and corresponding questions for the Install Test Plan. Simply add items here to add more items to the test plan.
    vocabulary.installVTPSchema = {
        qpsqgs: {
            title: "QPS-QGS",
            questions: {
                appOpens: "Does QPS-QGS open?",
                datasetLoads: "Does the sample dataset ‘Abnormal, Study’ load?",
                processingWorks: "Does the sample dataset process?"
            }
        },
        csi: {
            title: "CSI/General",
            questions: {
                correctVersion: "Does the CSI Title bar and about box show the correct version?",
                studySeriesCorrect: "Do the patient study and individual series lists display correctly?",
                userManual: "Does the User Manual (IFU) open correctly?"
            }
        },
        qbs: {
            title: "QBS",
            questions: {
                appOpens: "Does QBS Open?",
                datasetLoads: "Does the sample dataset ‘QBS TEST 01’ load?",
                processingWorks: "Does the sample dataset process?"
            }
        },
        autoReconMocopp: {
            title: "AutoRecon/MoCo",
            questions: {
                pageAvailable: "Is the AutoRecon Page available?",
                itWorks: "Can the sample data be motion corrected and reconstructed?"
            }
        },
        qpet: {
            title: "QPET",
            questions: {
                    // We don't ship with any sample PET datasets, so we check for normal limit availability instead.
                normalLimitsAvailable: "Are the PET normal limit databases available?"
            }
        },
        reporting: {
            title: "Reporting",
            questions: {
                reportPanel: "Does QGS+QPS open with a reporting panel?",
                draftReport: "Are you able to preview a draft report?"
            }
        },
        csview: {
            title: "CSView",
            questions: {
                opens: "Does CSView open with the sample data?"
            }
        }
    };


    // License Platforms
    vocabulary.licensePlatforms = new Map();
    vocabulary.licensePlatforms.set( 0, 'Unknown');
    vocabulary.licensePlatforms.set( 1, 'Philips/ADAC');
    vocabulary.licensePlatforms.set( 2, 'Philips/AQMD');
    vocabulary.licensePlatforms.set( 3, 'Prism');
    vocabulary.licensePlatforms.set( 4, 'GE/Elgems');
    vocabulary.licensePlatforms.set( 5, 'Summit');
    vocabulary.licensePlatforms.set( 6, 'Siemens');
    vocabulary.licensePlatforms.set( 7, 'Digirad');
    vocabulary.licensePlatforms.set( 8, 'NCS');
    vocabulary.licensePlatforms.set( 9, 'Parkmed');
    vocabulary.licensePlatforms.set(10, 'Toshiba');
    vocabulary.licensePlatforms.set(11, 'Thinksys');
    vocabulary.licensePlatforms.set(12, 'CTI');
    vocabulary.licensePlatforms.set(13, 'CSMC');
    vocabulary.licensePlatforms.set(14, 'Extreme');
    vocabulary.licensePlatforms.set(15, 'CVIT');
    vocabulary.licensePlatforms.set(17, 'Philips/JetStream');
    vocabulary.licensePlatforms.set(18, 'NUD/Hermes');
    vocabulary.licensePlatforms.set(19, 'CSMC Public');
    vocabulary.licensePlatforms.set(20, 'Demo');
    vocabulary.licensePlatforms.set(21, 'Philips/Xcelera');
    vocabulary.licensePlatforms.set(22, 'BC Technical');
    vocabulary.licensePlatforms.set(23, 'Trionix');
    vocabulary.licensePlatforms.set(24, 'Segami');
    vocabulary.licensePlatforms.set(25, 'Philips EBW');
    vocabulary.licensePlatforms.set(26, 'MedImage');
    vocabulary.licensePlatforms.set(27, 'Vital Images');
    vocabulary.licensePlatforms.set(28, 'Spectrum Dynamics');
    vocabulary.licensePlatforms.set(29, 'Mediso');
    vocabulary.licensePlatforms.set(30, 'CSMC Direct');
    vocabulary.licensePlatforms.set(31, 'Mirada');
    vocabulary.licensePlatforms.set(33, 'CSMC Vendor');
    vocabulary.platformCSMCDirect = function() { return 30; };
    vocabulary.platformCSMCVendor = function() { return 33; };
    vocabulary.platformHasSubvendors = function(platform) { return platform === 30 || platform === 33; };
    vocabulary.activeLicensePlatforms = new Set([2,4,6,7,11,13,14,15,17,18,19,20,22,24,25,26,27,28,29,30,31,33]);
    vocabulary.isLicensePlatformActive = function(platform) { return vocabulary.activeLicensePlatforms.has(platform); };
    vocabulary.isLicensePlatformInternal = function(platform) { return platform === 0 || platform === 13 || platform === 14 || platform === 19 || platform === 20; };

    // License Features
    // primary = shown on License Reconciliation page when 'Abbreviated View' (for Tech Transfer) is checked
    vocabulary.licenseFeatures = [
        { id:  0, name: 'QGS+QPS', primary:true },        // Changed from 'QCA' to 'QGS+QPS' per Tejas 12/9/2020 (for Tech Transfer to understand better)
        { id: 22, name: 'QPET', primary:true },
        { id:  3, name: 'QBS', primary:true },
        { id:  5, name: 'Companion', primary:true },
        { id: 21, name: 'PlusPack20', primary:true },
        { id: 23, name: 'FusionCT', primary:true },
        { id: 13, name: 'AutoRecon', primary:true },
        { id: 10, name: 'MOCO', primary:true },
        { id:  7, name: 'Fast Recon', primary:true },
        { id: 28, name: 'Remote Desktop', primary:false },
        { id: 16, name: 'SPECT CFR', primary:false },
        { id: 30, name: 'Flow AutoMoCo', primary:false },
        { id: 31, name: 'Flow RAC', primary:false },
        { id: 32, name: 'Flow Configuration', primary:false },
        { id: 33, name: 'Flow Pack', primary:false },
        { id: 15, name: 'CSView', primary:true },
        { id:  4, name: 'CSImport', primary:true },
        { id:  8, name: 'ARG Local', primary:true },
        { id:  6, name: 'QARG Local', primary:true },
        { id: 18, name: 'No Ping', primary:false },
        { id: 20, name: 'CedarsReport', primary:true },
        { id: 24, name: 'PowerPoint', primary:false },
        { id: 26, name: 'MFSC', primary:false },
        { id: 11, name: 'Clinical Use', primary:false },
        { id: 25, name: 'CSMC Button', primary:false },
        { id: 19, name: 'QRegKey', primary:false },
        { id:  1, name: 'Deprecated (QGS)', primary:true },
        { id:  2, name: 'Deprecated (QPS)', primary:true },
        { id: 17, name: 'Suite2017', primary:false },
        { id: 14, name: 'Suite2015', primary:false },
        { id: 12, name: 'Suite2013', primary:false },
        { id: 29, name: 'Suite2012', primary:false },
        { id: 27, name: 'Suite2010', primary:false },
    ];

    // order an array of feature IDs by their order in vocabulary.licenseFeatures before concatenating their names into a string
    vocabulary.licenseFeaturesSortedText = function (features, argAsXmlResults) {
        var sorted = [];
        if (features && features.includes) {
            vocabulary.licenseFeatures.forEach(function(f) {
                if (features.includes(f.id)) {
                    sorted.push((f.id !== 8 || !argAsXmlResults) ? f.name : 'XML Results');
                }
            });
        }
        return sorted.join(', ');
    };

        // Vern's note: not sure why the license features are an array. Easier to access via a map, so I added this too. (id is the key)
        // Jack's response: didn't realize JavaScript Map was ordered by insertion order, unlike Map in other languages. The order is for display. Also not sure how Map works in ng-repeat
    vocabulary.licenseFeaturesMap = {};
    for (var n=0; n < vocabulary.licenseFeatures.length; n++)
        vocabulary.licenseFeaturesMap[n] = vocabulary.licenseFeatures[n];

    vocabulary.licenseFeaturesCopy = function() { return angular.copy(vocabulary.licenseFeatures); };
    vocabulary.internalLicenseFeatures = new Set([1,2,18,25,19,17,14,12,29,27]);
    vocabulary.isLicenseFeatureInternal = function(feature) { return vocabulary.internalLicenseFeatures.has(feature); };
    vocabulary.licenseUnlimitedFloatingFeatures = new Set([17,14,12,29,27,21,5,11,28,24]); // features typically given 99 floating
    vocabulary.isLicenseFeatureUnlimitedFloating = function(feature) { return vocabulary.licenseUnlimitedFloatingFeatures.has(feature); };

    // Common License Packages
    vocabulary.licensePackages = new Map();
    var basicSPECT = [0, 5, 4, 26, 11, 17]; // QCA, COMPANION, CSIMPORT, MFSC, CLINICAL USE, SUITE2017
    vocabulary.licensePackages.set('BASIC SPECT', new Set(basicSPECT));
    var deluxeSPECT = basicSPECT.slice(); deluxeSPECT.push(21); // BASIC SPECT + PLUSPACK20
    vocabulary.licensePackages.set('DELUXE SPECT', new Set(deluxeSPECT));
    var basicSPECT_BP = basicSPECT.slice(); basicSPECT_BP.push(3); // BASIC SPECT + QBS
    vocabulary.licensePackages.set('BASIC SPECT + BP', new Set(basicSPECT_BP));
    var deluxeSPECT_BP = deluxeSPECT.slice(); deluxeSPECT_BP.push(3); // DELUXE SPECT + QBS
    vocabulary.licensePackages.set('DELUXE SPECT + BP', new Set(deluxeSPECT_BP));
    var deluxePET = [22, 5, 21, 23, 4, 26, 11, 17]; // QPET, COMPANION, PLUSPACK20, FUSIONCT, CSIMPORT, MFSC, CLINICAL USE, SUITE2017
    vocabulary.licensePackages.set('DELUXE PET', new Set(deluxePET));
    var basicWorkstation = basicSPECT_BP.slice(); basicWorkstation.push(13, 10, 7, 15); // BASIC SPECT_BP + AutoRecon, MoCo, Fast Recon, CSView
    vocabulary.licensePackages.set('BASIC WORKSTATION', new Set(basicWorkstation));
    var deluxeWorkstation = basicWorkstation.slice(); deluxeWorkstation.push(21); // BASIC WORKSTATION + PLUSPACK20
    vocabulary.licensePackages.set('DELUXE WORKSTATION', new Set(deluxeWorkstation));
    var demoPackage = [0, 22, 3, 5, 21, 23, 13, 10, 7, 26, 28, 15, 4, 11, 17];
    vocabulary.licensePackages.set('DEMO', new Set(demoPackage));

    // License Subvendors
    vocabulary.licenseSubvendors = new Map();
    vocabulary.licenseSubvendors.set( 2, 'CSMC Direct');
    vocabulary.licenseSubvendors.set( 3, 'AIS');
    vocabulary.licenseSubvendors.set( 4, 'BCTECHNICAL');
    vocabulary.licenseSubvendors.set( 5, 'CVIT');
    vocabulary.licenseSubvendors.set( 6, 'DIGIRAD');
    vocabulary.licenseSubvendors.set( 7, 'KEOSYS');
    vocabulary.licenseSubvendors.set( 8, 'MEDISO');
    vocabulary.licenseSubvendors.set( 9, 'MIE');
    vocabulary.licenseSubvendors.set(10, 'MIM SOFTWARE');
    vocabulary.licenseSubvendors.set(11, 'NICESOFT');
    vocabulary.licenseSubvendors.set(12, 'NIS');
    vocabulary.licenseSubvendors.set(13, 'SCIMAGE');
    vocabulary.licenseSubvendors.set(14, 'THINKING SYSTEMS');
    vocabulary.licenseSubvendors.set(15, 'SIEMENS PERSONAL');
    vocabulary.licenseSubvendors.set(16, 'Segami');
    vocabulary.licenseSubvendors.set(17, 'Hermes');
    vocabulary.licenseSubvendors.set(18, 'Special T Electronics');
    vocabulary.licenseSubvendors.set(19, 'Hitachi');
    vocabulary.licenseSubvendors.set(20, 'Ovis');
    vocabulary.licenseSubvendors.set(21, 'Infinitt');
    vocabulary.licenseSubvendors.set(22, 'TTG Imaging Solutions');
    vocabulary.licenseSubvendors.set(23, 'FujiFilm');
    vocabulary.licenseSubvendors.set(24, 'Convergent Imaging Solutions');
    vocabulary.licenseSubvendors.set(25, 'Universal Medical Resources');
    vocabulary.activeLicenseSubvendors = new Set(vocabulary.licenseSubvendors.keys());
    vocabulary.isLicenseSubvendorActive = function(subvendor) { return vocabulary.activeLicenseSubvendors.has(subvendor); };
    vocabulary.isPlatformActive = function(platform) {
        return platform <= 1000 ? vocabulary.isLicensePlatformActive(platform) : vocabulary.isLicenseSubvendorActive(platform - 1000);
    };

    // License Platforms (key) that have been replaced by Subvendors (value)
    vocabulary.platformToSubvendor = new Map();
    vocabulary.platformToSubvendor.set(7, 6);   // Digirad
    vocabulary.platformToSubvendor.set(11, 14); // Thinksys
    vocabulary.platformToSubvendor.set(15, 5);  // CVIT
    vocabulary.platformToSubvendor.set(22, 4);  // BC Technical
    vocabulary.platformToSubvendor.set(24, 16); // Segami
    vocabulary.platformToSubvendor.set(29, 8);  // Mediso
    vocabulary.platformToSubvendor.set(18, 17); // Hermes
    // License Subvendors (key) that have replaced Platforms (value)
    vocabulary.subvendorToPlatform = new Map();
    vocabulary.platformToSubvendor.forEach(function(v, k) { vocabulary.subvendorToPlatform.set(v, k); });
    
    // License Vendors (all)
    vocabulary.licenseVendorsCombined = new Map(vocabulary.licensePlatforms);
    vocabulary.licenseSubvendors.forEach(function(v, k) { vocabulary.licenseVendorsCombined.set(1000 + k, v); });
    vocabulary.licenseVendorsActive = new Map();
    vocabulary.licensePlatforms.forEach(function(v, k) {
        // exclude inactive platforms, internal platforms, CSMC Direct/Vendor platforms, and platforms that have been replaced by subvendors
        if (vocabulary.isLicensePlatformActive(k) && !vocabulary.isLicensePlatformInternal(k) && !vocabulary.platformHasSubvendors(k) &&
            !vocabulary.platformToSubvendor.has(k)) {
                vocabulary.licenseVendorsActive.set(k, { name: v, id: k, platforms: [k] });
        }
    });
    vocabulary.licenseSubvendors.forEach(function(v, k) {
        var platforms = [1000 + k], temp;
        if (vocabulary.isLicenseSubvendorActive(k)) {
            // a subvendor that have replaced a platform? include the original platform for references
            temp = vocabulary.subvendorToPlatform[k];
            if (temp) platforms.push(temp);
            vocabulary.licenseVendorsActive.set(1000 + k, { name: v, id: (1000 + k), platforms: platforms });
        }
    });

    // License Duration Pre-defined Types
    vocabulary.licenseDurations = new Map([
        [ 1, { name: 'Permanent',               days: 0 }],
        [ 2, { name: 'DEMO: Customer',          days: 45 }],
        [ 3, { name: 'DEMO: Trade Show',        days: 45 }],
        [ 4, { name: 'TEMP: Sales Staff',       days: 180 }],
        [ 5, { name: 'TEMP: Engineering Staff', days: 180 }],
        [ 6, { name: 'TEMP: Other',             days: 180 }],
        // [ 7, { name: 'Pay Per Click',           days: -1 }],
        // [ 8, { name: 'Manual',                  days: -1 }],
        // [ 9, { name: 'Floating Client',         days: 1 }],
        // [11, { name: 'Special 1-Year',          days: 365 }],
        // [12, { name: 'Special 2-Year',          days: 730 }],
        [13, { name: 'SHORT DEMO: Customer',    days: 10 }],
        [15, { name: 'DEMO: 15-day',            days: 15 }],
        [16, { name: 'DEMO: 30-day',            days: 30 }],
        // limited terms, TTG only as of 2022-04-06
        [17, { name: 'PAID: 1 Year',            days: 366,  years: 1 }], // potentially 1 leap year
        [18, { name: 'PAID: 3 Years',           days: 1096, years: 3 }], // potentially 1 leap year
        [19, { name: 'PAID: 5 Years',           days: 1827, years: 5 }], // potentially 2 leap years
        [101, { name: 'TEMP: 3-day',            days: 3 }],
        [102, { name: 'TEMP: 14-day',           days: 14 }],
    ]);
    // Special pre-defined types for custom license duration length
    // not included in licenseDurations map for it requires special handling
    vocabulary.licenseDurationCustom = 1000;        // place holder in dropdown
    vocabulary.licenseDurationCustomized = 1001;    // added after a custom date is chosen

    // License Types Paid vs Free
    vocabulary.licenseDurationsPaid = new Set([1, /*11, 12,*/ 17, 18, 19]);
    vocabulary.licenseDurationsFree = new Set(vocabulary.licenseDurations.keys());
    vocabulary.licenseDurationsPaid.forEach(function(v) { vocabulary.licenseDurationsFree.delete(v); });

    // Simplified license duration types for vendor requests
    vocabulary.simplifiedLicenseDurations = new Set([1, 2, 6]);

    // New 1-, 3-, 5-year terms
    vocabulary.licenseDurationsLimitedTerm = [17, 18, 19];

    // License Quantity Options
    vocabulary.licenseQuantityOptions = [{ qty: 1, name: 'Node Locked'}];
    for (i = 1; i <= 99; i++) {
        vocabulary.licenseQuantityOptions.push({ qty: i + 1, name: i + ' Floating'});
    }

    // Vendor-Specific License Packages and Add-Ons
    vocabulary.licenseVendorPackages = new Map();
    vocabulary.licenseVendorAddOns = new Map();
    //
    // CSMC Direct (1002)
    //
    temp = new Map([
        [1, { name: 'BASIC SPECT', features: basicSPECT.slice() }],
        [2, { name: 'DELUXE SPECT', features: deluxeSPECT.slice() }],
        [3, { name: 'DELUXE PET', features: deluxePET.slice() }],
        [4, { name: 'BASIC WORKSTATION', features: basicWorkstation.slice() }],
        [5, { name: 'DELUXE WORKSTATION', features: deluxeWorkstation.slice() }],
    ]);
    vocabulary.licenseVendorPackages.set(1002, temp);
    vocabulary.licenseVendorAddOns.set(1002, [3]);
    //
    // Spectrum Dynamics (28)
    //
    function nocsi(features) {
        return (features && features.filter) ? features.filter(function(v) { return v !== 4; }) : [];
    }
    var dspectBase = nocsi(deluxeSPECT).concat(16); // -CSI, +CFR
    temp = new Map([
        // [1, { name: 'BASIC SPECT', features: basicSPECT.filter(function(v) { return v !== 4; }) }], // no CSI
        // [2, { name: 'DELUXE SPECT', features: deluxeSPECT.filter(function(v) { return v !== 4; }) }], // no CSI
        // [3, { name: 'DELUXE PET', features: deluxePET.filter(function(v) { return v !== 4; }) }], // no CSI
        // [4, { name: 'BASIC WORKSTATION', features: basicWorkstation.filter(function(v) { return v !== 4; }) }], // no CSI
        // [5, { name: 'DELUXE WORKSTATION', features: deluxeWorkstation.filter(function(v) { return v !== 4; }) }], // no CSI
        [6, { name: '(Integrated) DELUXE SPECT', features: dspectBase.slice() }],
        [7, { name: '(Integrated) DELUXE SPECT + BP', features: dspectBase.concat(3) }], // +QBS
        [8, { name: '(Standalone) DELUXE SPECT', features: dspectBase.concat(4) }], // +CSI
        [9, { name: '(Standalone) DELUXE SPECT + BP', features: dspectBase.concat(4, 3) }], // +CSI, +QBS
        [10, { name: '(Standalone) DELUXE PET', features: deluxePET.filter(function(v) { return v !== 23; }).concat(16) }], // -FusionCT, +CFR
    ]);
    vocabulary.licenseVendorPackages.set(28, temp);
    temp = new Map([
        ['Automated Report Generator - Complete', [8, 6]], // ARG + QARG
        ['Fusion/CT with Calcium Scoring', [23]],          // FusionCT
        ['CSView - general NM viewer with QC Tools', [15]] // CSview
    ]);
    vocabulary.licenseVendorAddOns.set(28, temp);
    //
    // BC Technical (1004)
    //
    temp = new Map([
        [1, { name: 'Ascent Basic FULL', features: basicSPECT_BP.concat(13, 10, 15) }],        // +RECON, +MOCO, +CSView
        [2, { name: 'Ascent Deluxe FULL', features: deluxeSPECT_BP.concat(13, 10, 15) }],      // +RECON, +MOCO, +CSView
        [3, { name: 'Ascent Basic Reader-Only', features: basicSPECT_BP.slice() }],
        [4, { name: 'Ascent Deluxe Reader-Only', features: deluxeSPECT_BP.slice() }],
        [5, { name: 'Ascent Basic Tech-Only', features: basicSPECT_BP.concat(13, 10, 15) }],   // +RECON, +MOCO, +CSView
        [6, { name: 'Ascent Deluxe Tech-Only', features: deluxeSPECT_BP.concat(13, 10, 15) }], // +RECON, +MOCO, +CSView
    ]);
    vocabulary.licenseVendorPackages.set(1004, temp);
    vocabulary.licenseVendorAddOns.set(1004, [8, 6, 23]);
    //
    // Hermes (1017)
    //
    temp = new Map([
        [1, { name: 'SPECT', features: nocsi(basicSPECT) }],                             // -CSI
        [2, { name: 'SPECT Plus Pack', features: nocsi(deluxeSPECT) }],                  // -CSI
        [3, { name: 'PET', features: nocsi(deluxePET) }],                                // -CSI
        [4, { name: 'SPECT Blood Pool', features: nocsi(basicSPECT_BP) }],               // -CSI
        [5, { name: 'SPECT Blood Pool and PlusPack', features: nocsi(deluxeSPECT_BP) }], // -CSI
    ]);
    vocabulary.licenseVendorPackages.set(1017, temp);
    vocabulary.licenseVendorAddOns.set(1017, [22, 23]); // QPET, FusionCT
    //
    // TTG Imaging Solutions (1022)
    //
    temp = new Map([
        [1, { name: 'Basic Workstation Package', features: basicWorkstation.slice() }],
        [2, { name: 'Deluxe Workstation Package', features: deluxeWorkstation.slice() }],
        [3, { name: 'Basic Perfusion SPECT', features: basicSPECT.slice() }],
        [4, { name: 'Basic Perfusion SPECT + Blood Pool', features: basicSPECT_BP.slice() }],
        [5, { name: 'Deluxe Perfusion SPECT + Blood Pool', features: deluxeSPECT_BP.slice() }],
        [6, { name: 'Deluxe Perfusion PET/CT', features: deluxePET.concat(16) }],                           // +CFR
        [7, { name: 'Deluxe Perfusion SPECT + Blood Pool + PET/CT', features: deluxePET.concat(16, 0, 3) }] // +CFR + QCA + QBS
    ]);
    vocabulary.licenseVendorPackages.set(1022, temp);
    temp = new Map([
        ['Automated Report Generator', [8, 6]],                     // ARG + QARG
        ['Fusion CT for SPECT with Calcium Scoring', [23]],         // FusionCT
        ['CSView - General NM Viewer with QC Tools', [15]],          // CSView
        ['Automated Reconstruction + Motion Correction', [13, 10]], // AutoRecon, MOCO
    ]);
    vocabulary.licenseVendorAddOns.set(1022, temp);
    //
    // FujiFilm (1023)
    //
    var fujiBasicTest = basicSPECT_BP.filter(function(v) { return v !== 4 && v !== 11; }).concat(8);       // -CSI, -Clinical Use (Test Server), +ARG Local
    var fujiDeluxeTest = deluxeSPECT_BP.filter(function(v) { return v !== 4 && v !== 11; }).concat(16, 8); // -CSI, -Clinical Use (Test Server), +CFR +ARG Local
    var fujiPETTest = deluxePET.filter(function(v) { return v !== 4 && v !== 11; }).concat(16, 8);         // -CSI, -Clinical Use (Test Server), +CFR +ARG Local
    var fujiSPECT_PETTest = fujiPETTest.concat(0, 3);                                                      // +QGS/QPS +QBS
    temp = new Map([
        [1, { name: '(PRODUCTION) Basic Perfusion SPECT + Blood Pool', features: fujiBasicTest.concat(11) }],                                // +Clinical Use
        [2, { name: '(PRODUCTION) Deluxe Perfusion SPECT + Blood Pool', features: fujiDeluxeTest.concat(11) }],                              // +Clinical Use
        [3, { name: '(PRODUCTION) Deluxe Perfusion PET', features: fujiPETTest.concat(11) }],               // +Clinical Use
        [4, { name: '(PRODUCTION) Deluxe Perfusion SPECT + PET + Blood Pool', features: fujiSPECT_PETTest.concat(11) }], // +Clinical Use
        [5, { name: '(TEST) Basic Perfusion SPECT + Blood Pool', features: fujiBasicTest.slice() }],
        [6, { name: '(TEST) Deluxe Perfusion SPECT + Blood Pool', features: fujiDeluxeTest.slice() }],
        [7, { name: '(TEST) Deluxe Perfusion PET', features: fujiPETTest.slice() }],
        [8, { name: '(TEST) Deluxe Perfusion SPECT + PET + Blood Pool', features: fujiSPECT_PETTest.slice() }],
    ]);
    vocabulary.licenseVendorPackages.set(1023, temp);
    temp = new Map([
        ['Automated Report Generator', [8, 6]],                                 // ARG + QARG
        ['Automated Reconstruction + Motion Correction', [13, 10]],             // AutoRecon + MoCo
        ['Automated Reconstruction + Motion Correction + Fast3D', [13, 10, 7]], // AutoRecon + MoCo + FastRecon
        ['Fusion CT for SPECT (includes Calcium Scoring)', [23]]                // FusionCT
    ]);
    vocabulary.licenseVendorAddOns.set(1023, temp);
    //
    // Convergent Imaging Solutions (1024)
    //
    temp = new Map([
        [1, { name: 'Basic Perfusion SPECT', features: nocsi(basicSPECT)}],                            // -CSI
        [2, { name: 'Deluxe Perfusion SPECT', features: nocsi(deluxeSPECT).concat(16)}],               // -CSI +CFR
        [3, { name: 'Deluxe Perfusion PET/CT', features: nocsi(deluxePET).concat(16)}],                // -CSI +CFR
        [4, { name: 'Deluxe Perfusion SPECT + PET + CT', features: nocsi(deluxePET).concat(0, 16)}],   // -CSI +QCA +CFR
    ]);
    vocabulary.licenseVendorPackages.set(1024, temp);
    temp = new Map([
        ['Bloodpool (aka MUGA) processing', [3]],                               // QBS
        ['Automated Report Generator', [8, 6]],                                 // ARG + QARG
        ['Fusion CT for SPECT with Calcium Scoring', [23]],                     // FusionCT
        ['Automated Reconstruction + Motion Correction + Fast3D', [13, 10, 7]], // AutoRecon + MoCo + FastRecon
    ]);
    vocabulary.licenseVendorAddOns.set(1024, temp);
    //
    // Universal Medical Resources (1025)
    //
    // JW 2024-06-26: need clarifications on packages listed in Uni-Med agreement
    var unimedSPECT = [0, 21, 5, 8, 26, 11, 17];    // QCA, PlusPack, Companion, ARG, MFSC, Clinical Use, Suite2017
    var unimedPET = [22, 21, 5, 8, 23, 26, 11, 17]; // QPET, PlusPack, Companion, ARG, FusionCT, MFSC, Clinical Use, Suite2017
    var unimedSuite = unimedPET.concat(0);          // unimedPET +QCA
    var unimedSuitePro = unimedSuite.concat([13, 10, 7, 3, 15, 6]);   // unimedSuite +AutoRecon +MOCO +FastRecon +QBS +CSView +QARG
    temp = new Map([
        [1, { name: 'CEDARS SPECT (integrated)', features: unimedSPECT.slice()}],
        [2, { name: 'CEDARS SPECT (non-integrated)', features: unimedSPECT.concat(4)}],                     // +CSI
        [3, { name: 'CEDARS PET PRO (integrated)', features: unimedPET.slice()}],
        [4, { name: 'CEDARS PET PRO (non-integrated)', features: unimedPET.concat(4)}],                     // +CSI
        [5, { name: 'MOLECULAR CARDIAC SUITE (integrated)', features: unimedSuite.slice()}],
        [6, { name: 'MOLECULAR CARDIAC SUITE (non-integrated)', features: unimedSuite.concat(4)}],          // +CSI
        [7, { name: 'MOLECULAR CARDIAC SUITE PRO (integrated)', features: unimedSuitePro.slice()}],
        [8, { name: 'MOLECULAR CARDIAC SUITE PRO (non-integrated)', features: unimedSuitePro.concat(4)}],   // +CSI
    ]);
    vocabulary.licenseVendorPackages.set(1025, temp);
    // License features that may be implicitly included and are not shown to vendors
    vocabulary.licenseFeatureHiddenFromVendors = new Set([26, 11]); // MFSC, Clinical Use
    vocabulary.isLicenseFeatureHiddenFromVendors = function(feature) { return vocabulary.licenseFeatureHiddenFromVendors.has(feature); };

    // JW 2022-03-02: Special handling of PowerPoint license feature
    // Only Hermes needs/can have this for now
    // JW 2023-10-13: added MIM and Thinking Systems
    // JW 2023-11-17: added CSMC Direct (including Siemens Personal)
    vocabulary.vendorsGetPowerPoint = new Set([1017, 1010, 1014, 1002, 1015]);
    vocabulary.vendorGetsPowerPoint = function(vendor) { return vocabulary.vendorsGetPowerPoint.has(vendor); };

    // Vendor License Request Status
    vocabulary.licenseVendorRequestStatuses = ['pending', 'approved', 'declined', 'ignore'];

    // Detecting MAC address embedded in floating license server VM System ID
    vocabulary.isMacAddressFloatingLicenseVM = function(macAddress) {
        // MAC address in System ID generated for a floating license server on a VM has bit #2 of the first byte set to 1
        var c = (macAddress + '').toUpperCase().charAt(1);
        return c === '2' || c === '3' || c === '6' || c === '7' || c === 'A' || c === 'B' || c === 'E' || c === 'F';
    };

    // Detecting MAC address embedded in node-lock VM System ID
    vocabulary.isMacAddressNodeLockedVM = function(macAddress) {
        return (macAddress + '') === '00:00:00:00:00:00';
    };

    // Pre-defined list of countries (keep in sync with the Country Distribution List in Greenlight Guru)
    vocabulary.directSaleCountryListNoUS = [
        'Austria', 'Belgium', 'Bulgaria', 'Cyprus', 'Canada', 'Croatia', 'Czech Republic', 'Denmark',
        'Estonia', 'Finland', 'France', 'Germany', 'Greece', 'Hungary', 'Iceland', 'Ireland', 'Italy',
        'Latvia', 'Liechtenstein', 'Lithuania', 'Luxembourg', 'Malta', 'Netherlands', 'Norway',
        'Poland', 'Portugal', 'Romania', 'Slovakia', 'Slovenia', 'Spain', 'Sweden', 'Northern Ireland', 
        'United Kingdom', 'Switzerland', 'Australia', 'Israel'
    ];
    vocabulary.directSaleCountryList = ['United States'].concat(vocabulary.directSaleCountryListNoUS);
    vocabulary.integratedSaleCountryList = [
        'Afghanistan', 'Albania', 'Algeria', 'Andorra', 'Angola', 'Antigua and Barbuda', 'Argentina', 
        'Armenia', 'Aruba', 'Azerbaijan', 'The Bahamas', 'Bahrain', 'Bangladesh', 'Barbados', 'Belarus', 
        'Belize', 'Benin', 'Bhutan', 'Bolivia', 'Bosnia and Herzegovina', 'Botswana', 'Brazil', 'Brunei', 
        'Burkina Faso', 'Burma', 'Burundi', 'Cambodia', 'Cameroon', 'Cabo Verde', 'Central African Republic',
        'Chad', 'Chile', 'China', 'Colombia', 'Comoros', 'Democratic Republic of the Congo',
        'Republic of the Congo', 'Costa Rica', 'Cote d\'Ivoire', 'Croatia', 'Curacao', 'Czechia', 'Djibouti', 
        'Dominica', 'Dominican Republic', 'East Timor', 'Ecuador', 'Egypt', 'El Salvador', 'Equatorial Guinea', 
        'Eritrea', 'Ethiopia', 'Japan', 'Fiji', 'Gabon', 'Gambia', 'Georgia', 'Ghana', 'Guatemala', 'Guinea', 
        'Guinea-Bissau', 'Guyana', 'Haiti', 'Holy See', 'Honduras', 'Hong Kong', 'India', 'Indonesia', 'Iraq', 
        'Jamaica', 'Jordan', 'Kazakhstan', 'Kenya', 'Kiribati', 'Kosovo', 'Kuwait', 'Kyrgyzstan', 
        'Laos', 'Lebanon', 'Lesotho', 'Liberia', 'Libya', 'Macau', 'Macedonia', 'Madagascar', 'Malawi', 
        'Malaysia', 'Maldives', 'Mali', 'Marshall Islands', 'Mauritania', 'Mauritius', 'Mexico', 'Micronesia', 
        'Moldova', 'Monaco', 'Mongolia', 'Montenegro', 'Morocco', 'Mozambique', 'Namibia', 'Nauru', 'Nepal', 
        'New Zealand', 'Nicaragua', 'Niger', 'Nigeria', 'Oman', 'Pakistan', 'Palau', 'Palestinian Territories', 
        'Panama', 'Papua New Guinea', 'Paraguay', 'Peru', 'Philippines', 'Qatar', 'Russia', 'Rwanda', 
        'Saint Kitts and Nevis', 'Saint Lucia', 'Saint Vincent and the Grenadines', 'Samoa', 'San Marino', 
        'Sao Tome and Principe', 'Saudi Arabia', 'Senegal', 'Serbia', 'Seychelles', 'Sierra Leone', 'Singapore', 
        'Sint Maarten', 'Solomon Islands', 'Somalia', 'South Africa', 'South Korea', 'South Sudan', 'Sri Lanka', 
        'Suriname', 'Swaziland', 'Taiwan', 'Tajikistan', 'Tanzania', 'Thailand', 'Timor-Leste', 'Togo', 'Tonga',
        'Trinidad and Tobago', 'Tunisia', 'Turkey', 'Turkmenistan', 'Tuvalu', 'Uganda', 'Ukraine', 
        'United Arab Emirates', 'Uruguay', 'Uzbekistan', 'Vanuatu', 'Venezuela', 'Vietnam', 'Yemen', 
        'Zambia', 'Zimbabwe'
    ];
    vocabulary.combinedCountryList = function() {
        var combined = vocabulary.directSaleCountryListNoUS.concat(vocabulary.integratedSaleCountryList).sort();
        combined.unshift('United States');
        return combined;
    };
    return vocabulary;
})

// Filter mapping Reminder Category value to text
.filter('reminderCategoryLabel', function(qmsVocabulary) {
    return function(category) {
        for (var k in qmsVocabulary.reminderCategoryGroups) {
            var g = qmsVocabulary.reminderCategoryGroups[k];
            for (var i = 0; i < g.length; i++) {
                if (category === g[i].value) return g[i].text;
            }
        }
        return category || 'Unknown';
    };
})

// Filter mapping Reminder Status value to text
.filter('reminderStatusLabel', function(qmsVocabulary) {
    return function(status) {
        var o = qmsVocabulary.reminderStatusOptions.find(function(v) { return status === v.value; });
        return (o && o.text) || 'Unknown';
    };
})

// Filter mapping Support Incident Status value to text
/*
.filter('supportIncidentStatusLabel', function(qmsVocabulary) {
    return function(status) {
        var s = qmsVocabulary.supportIncidentStatuses.find(function(v) { return status === v.id; });
        return (s && s.type) || 'Unknown';
    };
})*/

// Filter mapping Support Incident Initiation value to text
.filter('supportIncidentInitiationLabel', function(qmsVocabulary) {
    return function(initiation) {
        var opts = qmsVocabulary.supportIncidentInitiations;
        if (!initiation) return opts[opts.length - 1].text;
        var i = opts.find(function(v) { return v.value === initiation.toLowerCase(); });
        return i ? i.text : (initiation.slice(0, 1).toUpperCase() + initiation.slice(1));
    };
})

// Filter mapping License Platform value to text
.filter('licensePlatformLabel', function(qmsVocabulary) {
    return function(platform) {
        return qmsVocabulary.licensePlatforms.get(platform) || 'Unknown';
    };
})

// Filter mapping License Feature ID value to text
.filter('licenseFeatureLabel', function(qmsVocabulary) {
    return function(id) {
        var f = qmsVocabulary.licenseFeatures.find(function(v) { return v.id === id; });
        return (f && f.name) || 'Unknown';
    };
})

// Filter mapping License Vendor value to text
.filter('licenseVendorLabel', function(qmsVocabulary) {
    return function(vendor) {
        return qmsVocabulary.licenseVendorsCombined.get(vendor) || 'Unknown';
    };
})

// Filter mapping License Duration Pre-defined Type value to text
.filter('licenseDurationLabel', function(qmsVocabulary) {
    return function(type) {
        // accepting both a simple type or { type: <type>, duration: <duration in days> }
        var iType = type.type ? type.type : type;
        var iDays = type.days ? type.days : (type.duration ? type.duration : undefined);
        var d;
        if (iType === -1) return 'Reuse (not extending)';
        else if (iType === qmsVocabulary.licenseDurationCustomized) {
            return iDays ? 'Customized (' + iDays + ')' : 'Customized';
        }
        else {
            d = qmsVocabulary.licenseDurations.get(iType);
            if (d) {
                if (d.years > 0) {
                    return d.name + ' (' + d.years + (d.years > 1 ? ' years' : ' year') + ')';
                }
                else if (d.days > 0) {
                    return d.name + ' (' + d.days + (d.days > 1 ? ' days' : ' day') + ')';
                }
                else {
                    return d.name || 'Unknown';
                }
            }
            return 'Unknown';
        }
    };
})

// Filter mapping License Quantity value to text
.filter('licenseQuantityLabel', function() {
    return function(qty) {
        if (qty === 1) return 'Node Locked';
        else if (qty > 1) return (qty - 1) + ' Floating';
        else return 'None';
    };
})

// Filter computing expiration date of licenses
.filter('licenseExpirationDate', function(uibDateParser) {
    return function(license) {
        var d;
        if (license.duration === 0) return 'Permanent';
        else if (license.duration > 0) {
            d = uibDateParser.parse(license.start, 'yyyy-MM-dd');
            if (d) {
                d.setDate(d.getDate() + license.duration);
                return d.getFullYear() + '-' + (d.getMonth() < 9 ? '0' : '') + (d.getMonth() + 1) +
                    '-' + (d.getDate() < 10 ? '0' : '') + d.getDate();
            }
        }
        return 'Unknown';
    };
})

// Filter prettifying System ID
.filter('prettifySystemId', function() {
    return function(systemId) {
        var matches = /^(.+)#(\d+)$/.exec(systemId), subId;
        if (matches) {
            systemId = matches[1];
            subId = matches[2];
        }
        if (systemId.length === 30) {
            systemId = systemId.slice(0, 6) + '-' + systemId.slice(6, 12) + '-' + systemId.slice(12, 18) + '-' + systemId.slice(18, 24) + '-' + systemId.slice(24);
            if (subId) systemId += '#' + subId;
        }
        return systemId;
    };
})

// Filter normalizing System ID
.filter('normalizeSystemId', function() {
    return function(systemId) {
        systemId = systemId.trim().toUpperCase();
        while (systemId.match(/-[A-Z0-9]{4}$/)) systemId = systemId.slice(0, -5);
        systemId = systemId.replace(/[-_ ]/g, '');
        return systemId;
    };
});
