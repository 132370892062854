angular.module('qms').controller('LicenseEventEditCtrl', function($scope, $uibModalInstance, $q, QMSFactory, qmsVocabulary, data) {
    'use strict';

    $scope.license = data.license;
    $scope.event = data.event;
    $scope.vendors = [];

    $scope.ok = function() {
        var req = {
            eventId: $scope.event._id,
            reference: $scope.event.reference,
            reconciled: $scope.event.reconciled,
            note: $scope.event.note,
            vendor: $scope.event.vendor,
        };
        QMSFactory.reconcileLicenseEvent(req)
        .then(function(resp) {
            if (resp.status !== 200 || !resp.data || !resp.data.data || resp.data.data.eventId !== req.eventId) {
                return $q.reject(new Error('Backend error'));
            }
            $uibModalInstance.close(resp.data.data);
        });
    };

    $scope.cancel = function() {
        $uibModalInstance.dismiss("User canceled replacing licenses");
    };

    // initialization

    if (qmsVocabulary.licensePlatforms.has(data.license.platform)) {
        if (qmsVocabulary.platformHasSubvendors(data.license.platform)) {
            qmsVocabulary.licenseSubvendors.forEach(function(v, k) {
                $scope.vendors.push({ id: 1000 + k, name: v });
            });
        }
        else {
            $scope.vendors.push({ id: data.license.platform, name: qmsVocabulary.licensePlatforms.get(data.license.platform) });
        }
    }
});