// Widget for viewing an AIMDoc changelist
/*globals showMyAlert */
(function() {
    'use strict';
    angular
        .module('qms')
        .controller('ListMineController', ListMineController);

		// AuthTokenFactory, QMSFactory, AutoSaveFactory, 
    function ListMineController( AIMDocFactory, AuthTokenFactory, $location, $routeParams, dialogs, $window) {
        var vm = this;
		
        vm.init = function()
        {
            vm.showAllCheckboxState = false;

                // So we can hide the Delete button for changelists not owned by the current user.
            let userObj = AuthTokenFactory.getUser();
            this.curUser = userObj.username;

            vm.load();
        };

        vm.load = function()
        {
            vm.changelists = [];
            AIMDocFactory.getChangelists( vm.showAllCheckboxState )
				.success( function(result) {
					vm.changelists = result.data;

						// Remove changelists that are length 0  [they might have items in redoChangelist, but we don't care about that]
                        // Re-enabled 7/8/2020, because now AIMDoc *does* let you open an AIMDoc another user currently has a changelist for, as long as that changelist has 0 changes.
					for (var n=0; n < vm.changelists.length; n++)
					{
						if (vm.changelists[n].changes.length === 0)
						{
							vm.changelists.splice(n, 1);	// delete this item
							n--;
						}
					}
					
					console.log("AIMDocFactory.getChangelists() result: ", result.data);
                })
				.error( function(error) 
				{
					showMyAlert("Error", "AIMDocFactory.getChangelists() failed: " + error);	
				});
        };

        vm.checkboxClicked = function(event)
        {
            console.log("Checkbox value: ", vm.showAllCheckboxState);
            vm.load();
        };
        
        vm.viewChangelistA = function( item )
		{
			console.log(" Item clicked: ", item);
			
            $location.path( "/aimdoc/viewcl/" + item.id );
		};

        //  DISABLED: Did not work when deployed to production server. Only works on local server.
     /* function viewChangelistB( item )
		{
			console.log(" Item clicked: ", item);
			
			var titleText = "AIMDoc changelist for " + item.name + " " + item.suite + " (" + item.id + "): " + item.status;

				// This is how you open an "AIMDoc Changelist" dialog box.
				// Currently this requires that we've already loaded the changelist, and pass it in as an object.
				// However this wouldn't be hard to modify the dialog to do this itself (dialogs/aimdoc_viewcl/viewcl.js)
			var dlg = dialogs.create('dialogs/aimdoc_viewcl/viewcl.html', 'AimDocViewCLController as ViewCL', 
					{title: titleText, cl:item}, {backdrop: 'static', windowClass: 'AIMDocViewCLDialog'} );
			dlg.result.then(function (data)
			{
				
			});
		}*/

        vm.openInAIMDoc = function(item)
        {
            $location.path("/aimdoc/openbycl/" + item.id);
		};

        vm.deleteCL = function(item)
        {
            /* jshint validthis: true */        // Tell jslint to ignore the warning for using 'this' below. This function is always called by Angulaar so 'this' will be defined.

            AIMDocFactory.deleteChangelist( item.id )
				.success( function(result) 
				{
                    vm.load();
                })
                .error( function(error) 
                {
                    window.alert( "Could not delete changelist " + item.id + ". Error from server: " + error);
                });
        };
		
		vm.getDateFromMongoDBTimestamp = function( timestamp )
		{
            if (typeof timestamp === 'undefined')
                return "";

				// multiplied by 1000 so that the argument is in milliseconds, not seconds
			var date = new Date( parseInt(timestamp) );
			return (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear();
		};

        
        vm.init();
    }

})();
