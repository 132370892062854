// To add a new dialog: this file must be included in the project's index.html!

(function() {
	'use strict';
	angular
		.module('qms')
		.controller('AimDocNewController',AimDocNewController);

	
	function AimDocNewController( AIMDocFactory, $scope, $modalInstance, $document, $rootScope, data )
	{
        $scope.existingSuitesAndApps = {};
        $scope.suite = "";
        $scope.app = "";
        $scope.errorMsg = "";
		
		$scope.init = function()
        {
            AIMDocFactory.listAllAIMDocs() 
				.success( function(json)
				{
					if ( Object.keys(json.data).length > 0 && json.data)
                    {
                        $scope.existingSuitesAndApps = json.data;
                        console.log("Loaded apps: ", json.data);
                    }
				})
				.error( function(error) 
				{
					console.log("Error attempting to load Suites and applications for Open dialog: " + error);
					window.alert("Error getting list of AIMDoc Types and Suites. Server message: " + error);	
				});

                // Return key should dismiss dialog
			$modalInstance.rendered.then(function()
            {
                $document.bind('keydown', function(event)
                { 
                    console.log("AimDocOpenController: Key pressed!", event);

                    if (event.keyCode === 27)	// 27 = Escape key
                        $scope.cancel();
                //    else if (event.keyCode === 13) // return
                //        $scope.doOpen();
                });
            });
		};

        $scope.appChanged = function(event)
        {
            $scope.app = event.target.value;
        };

        $scope.suiteChanged = function(event)
        {
            $scope.suite = event.target.value;
        };
        
		$scope.doNew = function()
        {
			var data = {};

            if ( $scope.existingSuitesAndApps[ $scope.suite ] && $scope.existingSuitesAndApps[ $scope.suite ].indexOf( $scope.app.toUpperCase()) !== -1 )
            {
                $scope.errorMsg = "An AIMDoc with that suite and name already exists.";
                return;
            }

			data.suite =  $scope.suite; // $( "#openDialogSuite option:selected" ).text();
			data.app = $scope.app; // $( "#openDialogApp option:selected" ).text();
		
            $document.unbind('keydown');
			$modalInstance.close(data);	// Pass back data to user
		};
		
		$scope.cancel = function()
        {
			console.log("cancel() for open dialog selected!");
		//	$modalInstance.dismiss('canceled'); // Doesn't call user callback it seems
            $document.unbind('keydown');
			$modalInstance.close();
		};
		
		$scope.init();
	}

})();
