
(function() {
    'use strict';
    angular
        .module('qms')
        .controller('DevMeetingController', DevMeetingController);

    function DevMeetingController(AutoSaveFactory, $routeParams, QMSFactory) {
        var vm=this;
        vm.record = {};

        vm.jobs = ["job002009",
            "job002010",
            "job002011",
            "job002012",
            "job002013",
            "job002014",
            "job002015",
            "job002016",
            "job002017",
            "job002018",
            "job002019",
            "job002020",
            "job002021",
            "job002022",
            "job002023",
            "job002024",
            "job002025",
            "job002026",
            "job002027",
            "job002028",
            "job002029",
            "job002030",
            "job002031",
            "job002032",
            "job002033",
            "job002034",
            "job002035",
            "job002036",
            "job002037",
            "job002038"

        ];

        vm.init = init;
        vm.load = load;
        vm.save = save;

        function init() {
            if ($routeParams['id']) {
                vm.load($routeParams['id']);
            }
        }

        function load(id) {
            QMSFactory.loadDevMeeting(id).success(function (result) {
                vm.record = result.data;
                vm.autoSave = new AutoSaveFactory(vm.record, vm.save );
            });
        }

        function save() {
            return QMSFactory.saveDevMeeting(vm.record);
        }

        vm.init();
    }

})();