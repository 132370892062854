angular.module('qms').controller('ReminderNewCtrl', function($scope, $uibModalInstance, uibDateParser, QMSFactory, AuthTokenFactory, qmsVocabulary, data) {

    $scope.record = {
        source: '',
        due: uibDateParser.parse(printISODate(new Date()), 'yyyy-MM-dd'),
        hasDueTime: false,
        category: 'other',
        description: '',
        status: 'scheduled',
        note: '',
        owner: null,
    };
    $scope.source = 'Unknown';
    $scope.customer = {};
    $scope.dueDateOpened = false;
    $scope.reminderCategories = [];
    $scope.users = [];
    $scope.user = null;

    $scope.init = function() {
        $scope.dueTime = new Date(1970, 0, 1, 0, 0, 0); // due time defaults to 00:00:00
        $scope.record.source = data.source;
        // source is customer?
        var matches = /^customer\/(C\d+)$/i.exec(data.source);
        if (matches) {
            $scope.source = 'Customer';
            $scope.reminderCategories = qmsVocabulary.reminderCategoriesForGroup('customer+');
            $scope.loadCustomer(matches[1]);
        }
        // populate $scope.users
        QMSFactory.getUsersForGroup('*').success(function(result) {
            $scope.users = [];
            for (var i = 0; i < result.data.length; i++) {
                $scope.users.push({ $id: (result.data[i]._id.$oid || result.data[i]._id.$id), name: result.data[i].fullname });
            }
        });
        $scope.user = AuthTokenFactory.getUser();
        if ($scope.user._id) $scope.record.owner = $scope.user._id.$oid || $scope.user._id.$id;
    };

    $scope.loadCustomer = function(id) {
        QMSFactory.loadCustomer(id).success(function(result) {
            $scope.customer = result.data;
        });
    };

    $scope.save = function() {
        // copy the time part of due time, if selected, to the due date
        var due = $scope.record.due, dueTime = $scope.dueTime;
        if ($scope.record.hasDueTime) {
            due.setHours(dueTime.getHours(), dueTime.getMinutes(), dueTime.getSeconds());
        }
        QMSFactory.saveReminder($scope.record).success(function(data) {
            console.log("created new reminder: " + data.data.id);
            $uibModalInstance.close(data.data);
        });
    };

    $scope.cancel = function() {
        $uibModalInstance.dismiss('canceled');
    };

    $scope.openDueDate = function($event) {
        $event.preventDefault();
        $event.stopPropagation();
        $scope.dueDateOpened = true;
    };

    $scope.init();
});