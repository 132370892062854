angular.module('qms').controller('CustomerByResellerController', function($location, $routeParams, /* uiGridConstants, */$filter, QMSFactory, qmsVocabulary, Title) {
    'use strict';

    var vm = this, temp, i;

    // filter by reseller options
    vm.filters = {
        resellerOpts: [],   // options of specific resellers (vendors)
        reseller: null ,     // array of hybrid vendor IDs of the selected vendor
        showOnlyWithInstallEvents: false
    };

    // to be shown by csListControl/ui-grid
    // var idTemplate = '<div class="ui-grid-cell-contents"><a ng-href="#/customer/{{row.entity.id}}"><i class="fa fa-external-link"></i> {{COL_FIELD}}</a></div>';
    // vm.customers = {
    //     data: [],
    //     cols: [
    //         { field: 'id', displayName: 'ID', width: '15%', cellTemplate: idTemplate,
    //             sort: { direction: uiGridConstants.ASC, ignoreSort: false, priority: 0 } },
    //         { field: 'name', displayName: 'Name', width: '35%' },
    //         { field: 'reseller', displayName: 'Reseller', width: '25%', cellFilter: 'licenseVendorLabel' },
    //         { field: 'supportends', displayName: 'Support Ends', width: '25%' },
    //     ],
    // };
    vm.customers = [];

    vm.ui = {
        sortBy: 'name',
        sortAscending: true,
        pagination: {
            total: 0,
            perPage: 50,
            maxSize: 9,
            current: 1,
            perPageOpts: [
                { label: '25', value: 25 },
                { label: '50', value: 50 },
                { label: '100', value: 100 },
            ],
        },
        customersInView: [],
    };

        // loads customer list
    vm.load = function() {
        var id = (vm.filters.reseller && vm.filters.reseller.length > 0 && vm.filters.reseller.join(',')) || 'any';

        if ( vm.filters.showOnlyWithInstallEvents )
        {
            QMSFactory.loadCustomersWithInstallEvents().success(function(result) {
                vm.finishedLoadingCustomers(result);
            });
        }
        else
            QMSFactory.customerByReseller(id).success(function(result) {
                vm.finishedLoadingCustomers(result);
            });
    };

    vm.finishedLoadingCustomers = function(result)
    {
            // vm.customers.data = (result.data && result.data.length > 0 && result.data) || [];
        vm.customers = [];
        if (result.data && result.data.forEach) {
            result.data.forEach(function(v) {
                v.id = v.id || '';
                v.name = v.name || '';
                v.resellerLabel = (v.reseller && v.reseller > 0 && $filter('licenseVendorLabel')(v.reseller)) || '';
                v.supportends = v.supportends || '';
                vm.customers.push(v);
            });
        }
        vm.ui.pagination.total = vm.customers.length;
        vm.ui.pagination.perPageOpts.push({ label: 'All', value: vm.customers.length });
        vm.sort();
    };

    // refreshes vendor list (by changing the search query of $location)
    vm.refresh = function() {
        var search = { id: (vm.filters.reseller && vm.filters.reseller.length > 0 && vm.filters.reseller.join(',')) || 'any' };
        $location.search(search);
    };

    // sorts customer list
    vm.sort = function() {
        vm.customers/*.data*/.sort(function(a, b) {
            var va = a[vm.ui.sortBy], vb = b[vm.ui.sortBy];
            return vm.ui.sortAscending ? va.localeCompare(vb) : vb.localeCompare(va);
        });
        vm.changePage();
    };

    // toggles the sort setting
    vm.toggleSort = function(field) {
        if (vm.ui.sortBy === field) {
            vm.ui.sortAscending = !vm.ui.sortAscending;
        }
        else {
            vm.ui.sortBy = field;
        }
        vm.sort();
    };

    // populates ${vm.ui.customersInView} according to ${vm.ui.pagination}
    vm.changePage = function() {
        var begin = (vm.ui.pagination.current - 1) * vm.ui.pagination.perPage, end = begin + vm.ui.pagination.perPage;
        vm.ui.customersInView = (vm.customers && vm.customers.slice && vm.customers.slice(begin, end)) || [];
    };

    //
    // initialization
    //

    // vendor options
    qmsVocabulary.licensePlatforms.forEach(function(platformName, platformId) {
        // skip inactive/internal platforms and platforms meant only for subvendors
        if (platformId === 0 || qmsVocabulary.platformHasSubvendors(platformId) || !qmsVocabulary.isPlatformActive(platformId) ||
            qmsVocabulary.isLicensePlatformInternal(platformId)) return;
        var ids = [platformId];
        // some platforms may also have a subvendor ID, e.g., Digirad
        var subvendorId = qmsVocabulary.platformToSubvendor.get(platformId);
        if (subvendorId !== undefined) ids.unshift(1000 + subvendorId);
        vm.filters.resellerOpts.push({ label: platformName, value: ids });
    });
    qmsVocabulary.licenseSubvendors.forEach(function(subvendorName, subvendorId) {
        // skip inactive subvendors
        if (!qmsVocabulary.isLicenseSubvendorActive(subvendorId)) return;
        var platformId = qmsVocabulary.subvendorToPlatform.get(subvendorId);
        // only add subvendors that don't have their specific platforms (which were already added above)
        if (platformId === undefined) vm.filters.resellerOpts.push({ label: subvendorName, value: [1000 + subvendorId] });
    });
    // sort by vendor names
    vm.filters.resellerOpts.sort(function(a, b) { return a.label.toUpperCase().localeCompare(b.label.toUpperCase()); });
    vm.filters.resellerOpts.unshift({ label: 'Any', value: [] });

    // initial filter option
    vm.filters.reseller = vm.filters.resellerOpts[0].value; // any
    if ($routeParams.id) {
        if ($routeParams.id === 'any') i = 0;
        else {
            temp = parseInt($routeParams.id);
            i = vm.filters.resellerOpts.findIndex(function(v) { return v.value.includes(temp); });
        }
        if (i >= 0) {
            vm.filters.reseller = vm.filters.resellerOpts[i].value;
            vm.load();
        }
    }

        // Load for intial option
    Title.setTitle("Customers");    // Modern Angular method that sets the tab's title text
    vm.refresh();
});
