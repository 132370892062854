// Resizable Table Columns.
//  version: 1.0
//
// (c) 2006, bz
//
// 25.12.2006:  first working prototype
// 26.12.2006:  now works in IE as well but not in Opera (Opera is @#$%!)
// 27.12.2006:  changed initialization, now just make class='resizable' in table and load script
//
// From:
// http://bz.var.ru/comp/web/resizable.html
// with small fixes by Vern Jensen 3/2015


function preventEvent(e) {
    var ev = e || window.event;
    if (ev.preventDefault) ev.preventDefault();
    else ev.returnValue = false;
    if (ev.stopPropagation)
        ev.stopPropagation();
    return false;
}

function getStyle(x, styleProp) {
	var y="";
    if (x.currentStyle)
        y = x.currentStyle[styleProp];
    else if (window.getComputedStyle)
        y = document.defaultView.getComputedStyle(x,null).getPropertyValue(styleProp);
    return y;
}

function getWidth(x) {
	var y=0;
    if (x.currentStyle)
    // in IE
        y = x.clientWidth - parseInt(x.currentStyle["paddingLeft"]) - parseInt(x.currentStyle["paddingRight"]);
    // for IE5: var y = x.offsetWidth;
    else if (window.getComputedStyle)
    // in Gecko
        y = document.defaultView.getComputedStyle(x,null).getPropertyValue("width");
    return y;
}

/*
function setCookie (name, value, expires, path, domain, secure) {
    document.cookie = name + "=" + escape(value) +
    ((expires) ? "; expires=" + expires : "") +
    ((path) ? "; path=" + path : "") +
    ((domain) ? "; domain=" + domain : "") +
    ((secure) ? "; secure" : "");
}

function getCookie(name) {
    var cookie = " " + document.cookie;
    var search = " " + name + "=";
    var setStr = null;
    var offset = 0;
    var end = 0;
    if (cookie.length > 0) {
        offset = cookie.indexOf(search);
        if (offset !== -1) {
            offset += search.length;
            end = cookie.indexOf(";", offset);
            if (end === -1) {
                end = cookie.length;
            }
            setStr = unescape(cookie.substring(offset, end));
        }
    }
    return(setStr);
}
*/

// main class prototype
function ColumnResize(table) {
    if (table.tagName !== 'TABLE') return;

    this.id = table.id;

    // ============================================================
    // private data
    var self = this;

    var dragColumns  = table.rows[0].cells; // first row columns, used for changing of width
    if (!dragColumns) return; // return if no table exists or no one row exists

    var dragColumnNo; // current dragging column
    var dragX;        // last event X mouse coordinate
    var origX;		  // location of mouse when user first started dragging
    var origColWidthA;
    var origColWidthB;

    var saveOnmouseup;   // save document onmouseup event handler
    var saveOnmousemove; // save document onmousemove event handler
    var saveBodyCursor;  // save body cursor property

    // ============================================================
    // methods
    
    this.init = function()
	{
		// prepare table header to be draggable
		// it runs during class creation
		for (var i=0; i < dragColumns.length - 1; i++)
		{
			dragColumns[i].innerHTML = "<div style='position:relative;height:100%;width:100%'>"+
			"<div style='"+
			"position:absolute;height:100%;width:15px;margin-right:15px;"+
			"left:100%;top:0px;cursor:w-resize;z-index:10;'>"+
			"</div>"+
			dragColumns[i].innerHTML+
			"</div>";
			
				
		//	console.log("New innerHTML for table column header:");
		//	console.log(dragColumns[i].innerHTML);
			
			dragColumns[i].firstChild.firstChild.onmousedown = this.startColumnDrag;
		}
	};

    // ============================================================
    // do changes columns widths
    // returns true if success and false otherwise
    this.changeColumnWidth = function(no, offsetX)
    {
		if (!dragColumns) return false;
		
		if (no < 0) return false;
		if (dragColumns.length < no) return false;
		
		var minWidth = self.getMinWidth( dragColumns[no] );
		
	//	if (origColWidthA <= -offsetX) return false;
	//	if (dragColumns[no+1] && origColWidthB <= offsetX) return false;
		
			// Fix by Vern: Avoid weirdness when resizing columns whose css table-layout is set to 'auto' instead of 'fixed'
		if (origColWidthA + offsetX < minWidth && offsetX < 0)
			offsetX = minWidth - origColWidthA;
		
		console.log("Dragging column with offset " + offsetX);
		dragColumns[no].style.width = origColWidthA + offsetX +'px';
		console.log("dragColumns[" + no + "].style.width = " + dragColumns[no].style.width);
		
		if (dragColumns[no+1])
		{
			minWidth = self.getMinWidth( dragColumns[no+1] );
			if (origColWidthB - offsetX >= minWidth)
				dragColumns[no+1].style.width = origColWidthB - offsetX + 'px';
		}
		
		return true;
	};
    
		// Vern's hack to get a column's minimum width when its layout is set to auto instead of fixed
    this.getMinWidth = function( element )
	{
		var origWidth = element.style.width;
		
		element.style.width = 10 + "px";			// Temporarily change to 0
		var minWidth = element.offsetWidth;		// Now this is the minimum width
		element.style.width = origWidth;		// Restore when done
		
		return minWidth;
	};

    // ============================================================
    // do drag column width. Called by document.onmousemove
    this.columnDrag = function(e) {
        e = e || window.event;
        var X = e.clientX || e.pageX;
        if (self.changeColumnWidth(dragColumnNo, X-origX))
        {
            dragX = X; // dragX - prev horiz mouse position
        }

        // prevent other event handling
        preventEvent(e);
        return false;
    };

    // ============================================================
    // stops column dragging
    this.stopColumnDrag = function(e) {
        e = e || window.event;
        if (!dragColumns) return;

        // restore handlers & cursor
        document.onmouseup  = saveOnmouseup;
        document.onmousemove = saveOnmousemove;
        document.body.style.cursor = saveBodyCursor;

        // remember columns widths in cookies for server side
        var colWidth = '';
        var separator = '';
        for (var i=0; i<dragColumns.length; i++) {
            colWidth += separator + parseInt( getWidth(dragColumns[i]) );
            separator = '+';
        }
        var expire = new Date();
        expire.setDate(expire.getDate() + 365); // year
        document.cookie = self.id + '-width=' + colWidth +
        '; expires=' + expire.toGMTString();

        preventEvent(e);
    };

    // ============================================================
    // init data and start dragging
    this.startColumnDrag = function(e) {
        e = e || window.event;

        // if not first button was clicked
        //if (e.button != 0) return;

        // remember dragging object
        dragColumnNo = (e.target || e.srcElement).parentNode.parentNode.cellIndex;
        dragX = e.clientX || e.pageX;
        origX = e.clientX || e.pageX;

        // set up current columns widths in their particular attributes
        // do it in two steps to avoid jumps on page!
        var colWidth = [];
        for (var i=0; i<dragColumns.length; i++)
            colWidth[i] = parseInt( getWidth(dragColumns[i]) );
        for (i=0; i<dragColumns.length; i++) {
            dragColumns[i].width = ""; // for sure
            dragColumns[i].style.width = colWidth[i] + "px";
        }
        
		origColWidthA = parseInt(dragColumns[dragColumnNo].style.width);
		origColWidthB = parseInt(dragColumns[dragColumnNo+1].style.width);

        saveOnmouseup       = document.onmouseup;
        document.onmouseup  = self.stopColumnDrag;

        saveBodyCursor             = document.body.style.cursor;
        document.body.style.cursor = 'w-resize';

        // fire!
        saveOnmousemove      = document.onmousemove;
        document.onmousemove = self.columnDrag;

        preventEvent(e);
	};
	
	this.init();
}

// select all tables and make resizable those that have 'resizable' class
var resizableTables = [];
function ResizableColumns() {

    var tables = document.getElementsByTagName('table');
    for (var i=0; tables.item(i); i++) {
        if (tables[i].className.match(/resizable/)) 
        {
				// generate id
			if (!tables[i].id) 
				tables[i].id = 'table'+(i+1);
			
			makeTableResizable( tables[i] );
        }
    }
//	alert(resizableTables.length + ' tables was added.');
}

	// index is an index assigned to this table as an ID if it doesn't already have an id
function makeTableResizable( element )
{
	resizableTables[resizableTables.length] = new ColumnResize(element);
}

// init tables
/*
 if (document.addEventListener)
 document.addEventListener("onload", ResizableColumns, false);
 else if (window.attachEvent)
 window.attachEvent("onload", ResizableColumns);
 */
try {
    window.addEventListener('load', ResizableColumns, false);
} catch(e) {
    window.onload = ResizableColumns;
}

//document.body.onload = ResizableColumns;

//============================================================
//
// Usage. In your html code just include the follow:
//
//============================================================
// <table id='objectId'>
// ...
// </table>
// < script >
// var xxx = new ColumnDrag( 'objectId' );
// < / script >
//============================================================
//
// NB! spaces was used to prevent browser interpret it!
//
//============================================================
