// Input data expected by this dialog controller:
//      data.contacts: (required, R/O) [] of customer contacts (straight from customer records)
//      data.invited:  (optional, R/O) name of the customer contact (or the whole customer contact object) to select by default
angular.module('qms').controller('SurveyInviteDlgCtrl',function($scope, $modalInstance, $location, QMSFactory, data) {

    $scope.invited = null;
    $scope.emailInvitation = false;
    $scope.ticket = '';
    $scope.ui = {
        contacts: [],
        saveButtonText: 'Generate',
        link: '',
        showLink: false,
        emailDisabled: true,
        showAlert: false,
        alertClass: 'alert',
        alertText: ''
    };

    $scope.invitedChanged = function() {
        $scope.ui.emailDisabled = !$scope.invited.email;
        if ($scope.ui.emailDisabled) $scope.emailInvitation = false;
    };

    $scope.save = function() {
        // done?
        if ($scope.ui.saveButtonText === 'Done') {
            $modalInstance.close({ invited: $scope.invited, ticket: $scope.ticket });
            return;
        }
        // create a new ticket
        let email = $scope.emailInvitation ? $scope.invited.email : null;
        QMSFactory.createSurveyTicket(email, $scope.invited.name)
        .then(function(resp) {
            if (resp.status !== 200 || !resp.data || !resp.data.data) {
                return $q.reject('Backend Error');
            }
            if (resp.data.data.worked) {
                $scope.ticket = resp.data.data.ticket;
                $scope.ui.link = $location.protocol() + '://' + $location.host() +
                    (($location.protocol() === 'http' && $location.port() === 80) || ($location.protocol() === 'https' && $location.port() === 443) ? '' : (':' + $location.port())) +
                    '/survey/questionnaire.php?ticket=' + $scope.ticket;
                $scope.ui.showLink = true;
                if (!$scope.invited.email || !$scope.emailInvitation) {
                    $scope.ui.showAlert = true;
                    $scope.ui.alertClass = 'alert alert-success';
                    $scope.ui.alertText = 'Invitation was created.';
                    $scope.ui.saveButtonText = 'Done';
                }
                else if (resp.data.data.emailed) {
                    $scope.ui.showAlert = true;
                    $scope.ui.alertClass = 'alert alert-success';
                    $scope.ui.alertText = 'Invitation was created and emailed.';
                    $scope.ui.saveButtonText = 'Done';
                }
                else {
                    $scope.ui.showAlert = true;
                    $scope.ui.alertClass = 'alert alert-warning';
                    $scope.ui.alertText = 'Invitation was created but could not be emailed.';
                }
            }
            else {
                $scope.ticket = '';
                $scope.ui.link = '';
                $scope.ui.showLink = false;
                $scope.ui.showAlert = true;
                $scope.ui.alertClass = 'alert alert-danger';
                $scope.ui.alertText = 'Unable to create a survey invitation.';
            }
        })
        .catch(function(e) {
            $scope.ticket = '';
            $scope.ui.link = '';
            $scope.ui.showLink = false;
            $scope.ui.showAlert = true;
            $scope.ui.alertClass = 'alert alert-danger';
            $scope.ui.alertText = 'Unable to communicate with server.';
        });
    };

    $scope.cancel = function() {
        $modalInstance.dismiss('canceled');
    };

    $scope.copyLink = function() {
        navigator.clipboard.writeText($scope.ui.link);
    };

    // initialization
    $scope.ui.contacts = angular.copy(data.contacts);
    $scope.ui.contacts.unshift({ name: 'Unspecified', unspecified: true });
    $scope.ui.contacts.forEach(e => {
        if (e.unspecified) {
            e.tag = e.name;
        }
        else {
            e.tag = e.name + ' (' + (e.email || 'email n/a') + ')';
        }
    });

    $scope.invited = (data.invited && $scope.ui.contacts.find((e) => e.name === data.invited || e.name === data.invited.name)) || $scope.ui.contacts[0];
    $scope.invitedChanged();
});
