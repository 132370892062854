angular.module('qms').controller('VendorRequestListController', function($location, $routeParams, $filter, $q, QMSFactory, qmsVocabulary, dialogs, Title) {
    'use strict';

    var vm = this;
    vm.filters = {
        vendorOpts: [],
        vendor: null,

        statusOpts: [],
        status: null,

        dateOpts: [],
        date: null,
    };
    vm.sort = {
        field: 'date',
        ascending: true,
    };
    vm.requests = [];
    vm.loaded = false;

    // for toggling the current sort option
    vm.toggleSort = function(field) {
        if (vm.sort.field !== field) {
            vm.sort.field = field;
        }
        else {
            vm.sort.ascending = !vm.sort.ascending;
        }
        vm.sort();
    };

    // sort requests for display
    vm.sort = function() {
        var comp;
        if (vm.sort.field === 'date') {
            if (vm.sort.ascending) {
                comp = function(a, b) { return a.date < b.date ? -1 : (a.date > b.date ? +1 : 0); };
            }
            else {
                comp = function(a, b) { return a.date < b.date ? +1 : (a.date > b.date ? -1 : 0); };
            }
        }
        else if (vm.sort.field === 'vendor') {
            if (vm.sort.ascending) {
                comp = function(a, b) { return a.vendorName.localeCompare(b.vendorName); };
            }
            else {
                comp = function(a, b) { return b.vendorName.localeCompare(a.vendorName); };
            }
        }
        if (comp) vm.requests.sort(comp);
    };

    // load report data from backend
    vm.load = function() {
        QMSFactory.queryForVendorRequests(vm.filters.status, vm.filters.vendor, vm.filters.date.from, vm.filters.date.to)
        .success(function(data) {
            if (!data || !data.data || !data.data.forEach) return;
            vm.loaded = true;
            vm.requests = data.data;
            vm.requests.forEach(function(r) {
                var temp;
                r.expanded = false;
                if (r.licenses && r.licenses.package && r.licenses.package.features && r.licenses.package.features.length > 0) {
                    temp = [];
                    r.licenses.package.features.forEach(function(f) {
                        temp.push($filter('licenseFeatureLabel')(f));
                    });
                    r.licenses.package.featureText = '[' + temp.join(', ') + ']';
                }
                if (r.licenses && r.licenses.add_on && r.licenses.add_on.length > 0) {
                    temp = [];
                    r.licenses.add_on.forEach(function(a) {
                        temp.push($filter('licenseFeatureLabel')(a));
                    });
                    r.licenses.addOnText = temp.join(', ');
                }
            });
            vm.sort();
        });
    };

    // factor filter parameters to query search
    vm.refresh = function() {
        var search = { status: vm.filters.status || 'any' };
        search.fromDate = (vm.filters.date && vm.filters.date.from) || 'any';
        search.toDate = (vm.filters.date && vm.filters.date.to) || 'any';
        search.vendor = vm.filters.vendor && vm.filters.vendor.length > 0 ? vm.filters.vendor.join(',') : 'any';
        $location.search(search);
    };

    // decline a request
    vm.decline = function(req) {
        QMSFactory.declineVendorRequest(req.id)
        .then(function(resp) {
            if (resp.status !== 200 || !resp.data || !resp.data.data || resp.data.data.id !== req.id) {
                return $q.reject((resp.data && resp.data.data && resp.data.data.error) || 'Backend Error');
            }
            if (resp.data.data.status) req.status = resp.data.data.status;
        })
        .catch(function(e) {
            console.log('VendorRequestListController.decline(): ' + e);
            dialogs.error('Error', 'Unable to update request status. Please try again.');
        });
    };

    // ignore a request
    vm.ignore = function(req) {
        QMSFactory.ignoreVendorRequest(req.id)
        .then(function(resp) {
            if (resp.status !== 200 || !resp.data || !resp.data.data || resp.data.data.id !== req.id) {
                return $q.reject((resp.data && resp.data.data && resp.data.data.error) || 'Backend Error');
            }
            if (resp.data.data.status) req.status = resp.data.data.status;
        })
        .catch(function(e) {
            console.log('VendorRequestListController.decline(): ' + e);
            dialogs.error('Error', 'Unable to update request status. Please try again.');
        });
    };

    // reconsider (reset to pending) a request
    vm.reconsider = function(req) {
        QMSFactory.reconsiderVendorRequest(req.id)
        .then(function(resp) {
            if (resp.status !== 200 || !resp.data || !resp.data.data || resp.data.data.id !== req.id) {
                return $q.reject((resp.data && resp.data.data && resp.data.data.error) || 'Backend Error');
            }
            if (resp.data.data.status) req.status = resp.data.data.status;
        })
        .catch(function(e) {
            console.log('VendorRequestListController.decline(): ' + e);
            dialogs.error('Error', 'Unable to update request status. Please try again.');
        });
    };

    // initialization

    Title.setTitle("Vendor Requests");

    // vendor options
    qmsVocabulary.licensePlatforms.forEach(function(platformName, platformId) {
        if (platformId === 0 || qmsVocabulary.platformHasSubvendors(platformId) || !qmsVocabulary.isPlatformActive(platformId) ||
            qmsVocabulary.isLicensePlatformInternal(platformId)) return;
        var ids = [platformId];
        var subvendorId = qmsVocabulary.platformToSubvendor.get(platformId);
        if (subvendorId !== undefined) ids.unshift(1000 + subvendorId);
        vm.filters.vendorOpts.push({ label: platformName, value: ids });
    });
    qmsVocabulary.licenseSubvendors.forEach(function(subvendorName, subvendorId) {
        if (!qmsVocabulary.isLicenseSubvendorActive(subvendorId)) return;
        var platformId = qmsVocabulary.subvendorToPlatform.get(subvendorId);
        if (platformId === undefined) vm.filters.vendorOpts.push({ label: subvendorName, value: [1000 + subvendorId] });
    });
    vm.filters.vendorOpts.sort(function(a, b) { return a.label.toUpperCase().localeCompare(b.label.toUpperCase()); });
    vm.filters.vendorOpts.unshift({ label: 'Any', value: [] });

    // status options
    vm.filters.statusOpts = [{ label: 'Any', value: 'any' }];
    qmsVocabulary.licenseVendorRequestStatuses.forEach(function(v) {
        var label = v.slice(0, 1).toUpperCase() + v.slice(1);
        vm.filters.statusOpts.push({ label: label, value: v });
    });

    // date options
    var today = new Date(), d, year = today.getFullYear(), i, l, opt;
    d = new Date(today.getTime() - 1000 * 86400 * 7);
    vm.filters.dateOpts.push({ label: 'Last 7 days', value: { from: printISODate(d), to: printISODate(today) } });
    d = new Date(today.getTime() - 1000 * 86400 * 30);
    vm.filters.dateOpts.push({ label: 'Last 30 days', value: { from: printISODate(d), to: printISODate(today) } });
    d = new Date(today.getTime() - 1000 * 86400 * 90);
    vm.filters.dateOpts.push({ label: 'Last 90 days', value: { from: printISODate(d), to: printISODate(today) } });
    for (i = 0; i < 5; i++)
    {
        vm.filters.dateOpts.push({ label: 'Year ' + year, value: { from: year + '-01-01', to: year + '-12-31' } });
        year--;
    }

    // defaults of filter choices
    vm.filters.vendor = vm.filters.vendorOpts[0].value;
    vm.filters.status = vm.filters.statusOpts[1].value;
    vm.filters.date = vm.filters.dateOpts[0].value;

    // filters specified as search parameters?
    if ($routeParams.fromDate && /^[0-9]{4}-[01][0-9]-[0-3][0-9]$/.test($routeParams.fromDate)) {
        vm.filters.date = { from: $routeParams.fromDate, to: vm.filters.date.to };
    }
    if ($routeParams.toDate && /^[0-9]{4}-[01][0-9]-[0-3][0-9]$/.test($routeParams.toDate)) {
        vm.filters.date = { from: vm.filters.date.from, to: $routeParams.toDate };
    }
    i = vm.filters.dateOpts.findIndex(function(v) { return v.value && v.value.from === this.from && v.value.to === this.to; }, vm.filters.date);
    if (i >= 0) {
        vm.filters.date = vm.filters.dateOpts[i].value;
    }
    else {
        opt = { label: (vm.filters.date.from || '') + ' to ' + (vm.filters.date.to || ''), value: vm.filters.date };
        vm.filters.dateOpts.splice(-1, 0, opt);
    }
    if ($routeParams.vendor) {
        if ($routeParams.vendor === 'any') vm.filters.vendor = [];
        else {
            l = $routeParams.vendor.split(',');
            vm.filters.vendor = [];
            l.forEach(function(v) { var id = parseInt(v, 10); if (!isNaN(id)) this.push(id); }, vm.filters.vendor);
        }
        i = vm.filters.vendorOpts.findIndex(function(v) {
            if (!v.value || v.value.length !== this.length) return false;
            for (var j = 0; j < this.length; j++) {
                if (!v.value.includes(this[j])) return false;
            }
            return true;
        }, vm.filters.vendor);
        if (i >= 0) vm.filters.vendor = vm.filters.vendorOpts[i].value;
    }
    if ($routeParams.status) {
        vm.filters.status = $routeParams.status.toLowerCase();
    }
    
    if ($routeParams.fromDate || $routeParams.toDate || $routeParams.vendor || $routeParams.status) vm.load();
});
