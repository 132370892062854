// Angular 2 has a great module system, but Angular 1 doesn't appear to as much.
// Based on urban_raccoons answer: https://stackoverflow.com/questions/19614545/how-can-i-add-some-small-utility-functions-to-my-angularjs-application

var ADUtils = ADUtils || {};
ADUtils.debugLoggingOn = true;
ADUtils.logIndent = 0;


ADUtils.test = function()
{
    
};

    // Grunt requires an OLD version of grunt-ngmin, which was deprecated LONG ago. This ngmin does NOT support function(...args) style args, so I had to rewrite it to be this way:
ADUtils.log = function(param1, param2, param3, param4, param5, param6, param7)
{
	let indent = "";
	for (let i = 0; i < ADUtils.logIndent; i++)
		indent += " ";
    
	if (ADUtils.debugLoggingOn || typeof ADUtils.debugLoggingOn === 'undefined')
	{
		if (typeof param2 === 'undefined')			console.log(indent, param1);
		else if (typeof param3 === 'undefined')		console.log(indent, param1, param2);
		else if (typeof param4 === 'undefined')		console.log(indent, param1, param2, param3);
		else if (typeof param5 === 'undefined')		console.log(indent, param1, param2, param3, param4);
		else if (typeof param6 === 'undefined')		console.log(indent, param1, param2, param3, param4, param5);
		else if (typeof param7 === 'undefined')		console.log(indent, param1, param2, param3, param4, param5, param6);
		else
		{
			console.log("ERROR: Utils.log doesn't support more than 6 parameters.");
			console.trace();
		}
	}
};

	// Useful for debugging, so you can see what an object actually was *when it was logged*, rather than
	// its "current" value (stupid JavaScript! The logs *change* as values change)
ADUtils.logActual = function( obj )
{
	if (typeof obj === 'undefined' || obj === null)
	{
		console.log("Could NOT logActual() since input object is undefined!");
		return;
	}

	try
	{
        let indent = "";
		for (let i = 0; i < ADUtils.logIndent; i++)
			indent += " ";

		console.log(indent, JSON.parse(JSON.stringify(obj)));
	}
	catch (e)
	{
		ADUtils.logError("logActual() failed to parse the object into a JSON and back.");
		console.trace();
	}
};

ADUtils.logError = function(param1, param2, param3, param4, param5, param6, param7)
{
	ADUtils.logInColor("red", "ERROR: " + param1, param2, param3, param4, param5, param6, param7);
};

ADUtils.logWarning = function(param1, param2, param3, param4, param5, param6, param7)
{
	ADUtils.logInColor("orange", param1, param2, param3, param4, param5, param6, param7);
};

	// Pass color string in first parameter ("green", "red", "orange", etc.) Must be a valid CSS color name.
ADUtils.logInColor = function(colorText, param1, param2, param3, param4, param5, param6, param7)
{
	let css = 'color: ' + colorText;

	let indent = "";
	for (let i = 0; i < ADUtils.logIndent; i++)
		indent += " ";
		
		// During start-up, this variable will be undefined if this is called from a service before app.ts can initialize it
    if (ADUtils.debugLoggingOn || typeof ADUtils.debugLoggingOn === 'undefined')
	{
		if (typeof param2 === 'undefined')			console.log('%c' + indent + param1, css);
		else if (typeof param3 === 'undefined')		console.log('%c' + indent + param1, css, param2);
		else if (typeof param4 === 'undefined')		console.log('%c' + indent + param1, css, param2, param3);
		else if (typeof param5 === 'undefined')		console.log('%c' + indent + param1, css, param2, param3, param4);
		else if (typeof param6 === 'undefined')		console.log('%c' + indent + param1, css, param2, param3, param4, param5);
		else if (typeof param7 === 'undefined')		console.log('%c' + indent + param1, css, param2, param3, param4, param5, param6);
		else
		{
			console.log("ERROR: Utils.logInColor doesn't support more than 6 parameters.");
			console.trace();
		}
    }
};
