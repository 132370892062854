// To add a new dialog: this file must be included in the project's index.html!

(function() {
	'use strict';
	angular
		.module('qms')
		.controller('AimDocMergeController',AimDocMergeController);

	
	function AimDocMergeController( AIMDocFactory, $scope, $modalInstance, $document, $sce, $rootScope, data )
	{
        $scope.data = data;
        $scope.loadedData = {};
        $scope.curCLDesc = "";
		
		$scope.init = function()
        {
                // Return key should dismiss dialog
			$modalInstance.rendered.then(function()
            {
                $document.bind('keydown', function(event)
                { 
                    console.log("AimDocMergeController: Key pressed!", event);

                    if (event.keyCode === 27)	// 27 = Escape key
                        $scope.cancel();
                    else if (event.keyCode === 13) // return
                        $scope.doMerge();
                });
            });

            $scope.curCLDesc = "";
            $scope.dateSaved = "";
            
			AIMDocFactory.listAllAIMDocs() 
				.success( function(json)
				{
					console.log("Got suites/applications from server: ", json);

					if ( Object.keys(json.data).length > 0)
					{
						if (json.data)
                        {
                            $scope.loadedData = json.data;
							$scope.suiteItems = Object.keys(json.data);
                            $scope.selectedSuiteItem = $scope.suiteItems[0]; // selectedSuiteItem and selectedCLItem

                            $scope.suiteChanged();  // Select top-most item based on suite
                        }
						else
							console.log("WARNING: Open dialog could not get items, since json.data is not defined: ", json);
					}
					else
						console.log("ERROR: No AIMDocs found to open.");
				})
				.error( function(error) 
				{
					console.log("Error attempting to load Suites and applications for Open dialog: " + error);
					window.alert("Error getting list of AIMDoc Types and Suites. Server message: " + error);	
				});
		};

        $scope.suiteChanged = function()
        {
            console.log("User chose new suite: ", $scope.selectedSuiteItem);

                // PARAMS: name, suite
            AIMDocFactory.getClosedChangelistsForDoc( this.data.appName, $scope.selectedSuiteItem )
            	.success( function(result) 
				{
					if (result.data !== null)
					{
						$scope.changelists = result.data;
                        $scope.selectedCLItem = $scope.changelists[0];    // Select top-most item
                        $scope.clChanged();
					}
					else
						$scope.changelists = [];
				})
				.error( function(error) 
				{
					console.log("Merge dialog: getClosedChangelistsForDoc() HTTP ERROR: ", error);
				});
        };
        
        $scope.clChanged = function()
        {
            console.log("User chose new changelist: ", $scope.selectedCLItem);
            if (typeof $scope.selectedCLItem !== 'undefined')
            {
                $scope.curCLDesc = "Description: " + $scope.selectedCLItem.desc;
                let date;
                if (typeof $scope.selectedCLItem.dateSaved !== 'undefined')
                    date = new Date( $scope.selectedCLItem.dateSaved );
                else
                    date = new Date( $scope.selectedCLItem.changes[0].modifiedDate * 1000 );
                console.log("date: ", date);
                $scope.dateSaved = "Date: " + date.toLocaleDateString("en-US");
            }
            else
            {
                $scope.curCLDesc = "";
                $scope.dateSaved = "";
            }
        };
		
		$scope.doMerge = function()
        {
			var data = {};

			data.suite =  $scope.selectedSuiteItem; // $( "#openDialogSuite option:selected" ).text();
            data.changelist = $scope.selectedCLItem;
		
			console.log("open() for open dialog selected!", data);
            $document.unbind('keydown');
			$modalInstance.close(data);	// Pass back data to user
		};
		
		$scope.cancel = function()
        {
			console.log("cancel() for open dialog selected!");
		//	$modalInstance.dismiss('canceled'); // Doesn't call user callback it seems
            $document.unbind('keydown');
			$modalInstance.close();
		};
        
		$scope.init();
	}
	

})();
