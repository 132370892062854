// For instructions on how to convert these legacy function-based factories to ES6 class-based services
// that can be upgraded to use with modern Angular, see this guide:
//
// https://www.codelord.net/2017/05/08/moving-anuglar-factories-to-services-with-classes/
//
// *** Also don't forget to change all variable and member function references to be preceded with 'this.'


/**
 * GLOBAL Functions *
*/
 

console.log("setup.js entered.");


function pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

Date.prototype.isValid = function () {
    // An invalid date object returns NaN for getTime() and NaN is the only
    // object not strictly equal to itself.
    return this.getTime() === this.getTime();
};

function printISODate(d) {
    if (typeof(d) !== 'object' || !(d instanceof Date) || !d.isValid()) return d;
    return pad(d.getFullYear(), 4) + '-' + pad(d.getMonth() + 1, 2) + '-' + pad(d.getDate(), 2);
}

function printISODateTimeLocal(d, T = 'T') {
    if (typeof(d) !== 'object' || !(d instanceof Date) || !d.isValid()) return d;
    return printISODate(d) + T + pad(d.getHours(), 2) + ':' + pad(d.getMinutes(), 2) + ':' + pad(d.getSeconds(), 2);
}

function AIMArray(type) {
    if (type==='groups') {
        return ['AIM', 'Engineering', 'QA', 'Management', 'Regulatory', 'Admin', 'R&D', 'Research Only', 'SUPPORT'];
    }
}




const app = angular.module('qms',
	[
		'ui.bootstrap',
		'ui.utils',
		'ngRoute',
		'ngAnimate',
		'ngSanitize',
		'dialogs.main',
		'xeditable',
		'ui.grid',
		'ui.grid.autoResize',
		'ui.grid.resizeColumns',
		'ui.grid.selection',
		'ui.bootstrap.datepicker',
		'chart.js',
		'qmsVocabulary',
	]);



(function () {
    app.constant('API_URL', '/api');

    app.filter('encodeURIComponent', function($window) {
        return $window.encodeURIComponent;
    });
	
    app.config(function($routeProvider, dialogsProvider) {
        $routeProvider

			.when('/home', {templateUrl: 'ng1/partial/home/home.html', controller: 'HomeController as home'})
			.when('/login', {templateUrl: 'ng1/partial/login/login.html', controller: 'LoginController as login'})
			
			
				// This is how we do Angular 2 pages (that are downgraded to angular.js in app.module.ts)
                // The angular.js wrapper classes are in src/ng1/ng2 and are included in angular.json 
                // Below the [jobid]="..." is an Angular 2 syntax that passes along a property (jobid) that is set in the Angular 1 controller
			.when('/jobs', {template: '<jobs-page></jobs-page>'})
            .when('/job/:id', {template: '<job-page [jobid]="jobid"></job-page>', controller: 'JobPageWrapperController'})
            .when('/tickets', {template: '<tickets-page></tickets-page>'})
            .when('/ticket/:id', {template: '<ticket-page [ticketid]="ticketid"></job-page>', controller: 'TicketPageWrapperController'})
            .when('/tw/:jobid/:twid', {template: '<tw-page [jobid]="jobid" [twid]="twid"></tw-page>', controller: 'TestWorksheetWrapperController'})
            .when('/testsummary', {template: '<testsummary-page></testsummary-page>'})
			.when('/developer', {template: '<developer-page></developer-page>'})
            
            // when('/upload',{templateUrl: 'ng1/partial/upload/upload.html', controller: 'EnterinfoCtrl'}).
            // CRM pages
            .when('/search/:query',{templateUrl: 'ng1/partial/search/search.html', controller: 'SearchController as Search'})
            .when('/support/:id',{templateUrl: 'ng1/partial/support/support.html', controller: 'SupportController as Support'}) // Support Incident editor
         //    when('/sales/:id',{templateUrl: 'ng1/partial/sales/sales.html', controller: 'SalesCtrl'}).
            .when('/admin/usermanager',{templateUrl: 'ng1/partial/usermanager/usermanager.html', controller: 'UserManagerController as UserManager'})
            .when('/prefs',{templateUrl: 'ng1/partial/prefs/prefs.html', controller: 'PrefsController as Prefs'})
            .when('/admin/debug',{templateUrl: 'ng1/partial/debug/debug.html', controller: 'DebugController as Debug'})
            .when('/customer/:id',{templateUrl: 'ng1/partial/customer/customer.html', controller: 'CustomerController as customer'})
            .when('/devmeeting/:id',{templateUrl: 'ng1/partial/devmeeting/devmeeting.html', controller: 'DevMeetingController as dev'})
            // activation pages
            .when('/activate/:id',{templateUrl: 'ng1/partial/activate/activate.html', controller: 'ActivateCtrl'})
            .when('/activate',{templateUrl: 'ng1/partial/activatehome/activatehome.html', controller: 'ActivateHomeCtrl'})
            .when('/enterinfo/:eventid',{templateUrl: 'ng1/partial/enterinfo/enterinfo.html', controller: 'EnterinfoCtrl'})

                // AIMDoc
            .when('/aimdoc/default',{templateUrl: 'ng1/partial/aimdoc/aimdoc.html', controller: 'AIMDocController as vm'})
            .when('/aimdoc/new',{templateUrl: 'ng1/partial/aimdoc/aimdoc.html', controller: 'AIMDocController as vm'})
            .when('/aimdoc/open/:year/:appName',{templateUrl: 'ng1/partial/aimdoc/aimdoc.html', controller: 'AIMDocController as vm'})
            .when('/aimdoc/openbycl/:clID',{templateUrl: 'ng1/partial/aimdoc/aimdoc.html', controller: 'AIMDocController as vm'})
            .when('/aimdoc/listmine',{templateUrl: 'ng1/partial/aimdoc_listmine/listmine.html', controller: 'ListMineController as vm'})
            .when('/aimdoc/viewcl/:clID',{templateUrl: 'ng1/partial/aimdoc_viewcl/viewcl.html', controller: 'ViewCLController as vm'})
            

                // Reports Menu
            .when('/customerbyreseller', {templateUrl: 'ng1/partial/customer/customerbyreseller.html', controller: 'CustomerByResellerController as vm'})
            .when('/report/support', {templateUrl: 'ng1/reports/support/supportreport.html', controller: 'SupportReportController'})
            .when('/report/supportending', {templateUrl: 'ng1/partial/supportending/supportending.html', controller: 'SupportEndingController'})
            .when('/license/reconciledreport', {templateUrl: 'ng1/partial/license/reconciledreport.html', controller: 'ReconciledReportController as vm'})
            .when('/reminder/list', {templateUrl: 'ng1/partial/reminder/reminderlist.html', controller: 'ReminderListController as ReminderList'})
            .when('/releases', {templateUrl: 'ng1/partial/releases/releases.html', controller: 'ReleasesController as vm'})
            .when('/survey/engagement', {templateUrl: 'ng1/partial/survey/engagement.html', controller: 'SurveyEngagementController as vm'})

                // Tools Menu
            .when('/keyvalidate', {templateUrl: 'ng1/partial/keyvalidator/keyvalidator.html', controller: 'KeyValidatorController as vm'})

            .when('/license/generatecoupon/:systemID?', {templateUrl: 'ng1/partial/coupon/generatecoupon.html', controller: 'GenerateCouponController as vm'})
            .when('/vendor/loginmanager', {templateUrl: 'ng1/partial/vendor/loginmanager.html', controller: 'VendorLoginManagerController as vm'})
            .when('/vendor/requestlist', {templateUrl: 'ng1/partial/vendor/requestlist.html', controller: 'VendorRequestListController as vm'})

                // Misc
            .when('/regkey/:systemID?',{templateUrl: 'ng1/partial/regkey/regkey.html', controller: 'RegkeyController as vm'})
            .when('/system/:id',{templateUrl: 'ng1/partial/system/system.html', controller: 'SystemCtrl'})
            .when('/license/history/:id', {templateUrl: 'ng1/partial/license/history.html', controller: 'LicenseHistoryController as vm'})
            .when('/license/importold/:id', {templateUrl: 'ng1/partial/license/importold.html', controller: 'ImportOldLicenseController as vm'})
            .when('/vendor/requestapproval/:id', {templateUrl: 'ng1/partial/vendor/requestapproval.html', controller: 'VendorRequestApprovalController as vm'})
            
                // Survey
            .when('/survey/invite', {templateUrl: 'ng1/partial/survey/invite.html', controller: 'SurveyInviteController as vm'})
            
            //
            .otherwise({redirectTo:'/home'});
        // this provider is only available in the 4.0.0+ versions of angular-dialog-service
        dialogsProvider.useBackdrop(true);
        dialogsProvider.useEscClose(true);
        dialogsProvider.useCopy(false);
        dialogsProvider.setSize('lg');
    });
	

    app.factory("globals", function() {
        return { modalUP: false, modalType: 0 };
    });

    app.run(function($rootScope, editableOptions) {
        editableOptions.theme = 'bs3'; // click to edit
        $rootScope.safeApply = function(fn) {
            var phase = $rootScope.$$phase;
            if (phase === '$apply' || phase === '$digest') {
                if (fn && (typeof(fn) === 'function')) {
                    fn();
                }
            } else {
                this.$apply(fn);
            }
        };

    });

    app.directive('bsTooltip', function() {
        return function(scope, element, attrs) {
            element.find("a[rel=tooltip]").tooltip({ placement: 'bottom', html: 'true'});
        };
    });

/*
    app.factory('AuthTokenFactory', function AuthTokenFactory($window, $rootScope, $location) {
        'use strict';
        var store = $window.localStorage;
        var key = 'auth-token';

        return {
            logout: logout,
            getToken: getToken,
            setToken: setToken,
            getUser: getUser // gets the user object (stored in the token)
        };

        function getToken() {
            return store.getItem(key);
        }

        function setToken(token) {
            // Don't override token if user is older
            if (token && typeof getUser().timestamp !== 'undefined') {
                var older = getUser().timestamp.sec;
                var base64Url = token.split('.')[1];
                var base64 = base64Url.replace('-', '+').replace('_', '/');
                var happyToken = JSON.parse($window.atob(base64));
                var current = happyToken.user.timestamp.sec;
                if (current<older) return;
            }
        
            if (token) {
                store.setItem(key, token);
            } else {
                store.removeItem(key);
            }
            $rootScope.$broadcast("TokenChanged");
        }
        
        function getUser() {
            var token = getToken();
            if (!token) return {};
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace('-', '+').replace('_', '/');
            var happyToken = JSON.parse($window.atob(base64));

            var user = happyToken.user;
            if (typeof user === 'undefined') {
                console.log("AuthTokenFactory: Error getting user information (unable to parse token)");
                user = {};
			}
			return user;   
		}

        function logout()
        {
            console.log("QMS factory logout called!");
            $location.path("/login");

           // TO DO : Close all open dialogs here.
        }
    });
*/

    // handles all the "token-ness"
    app.factory('AuthInterceptor', function AuthInterceptor(AuthTokenFactory, $location, $injector) {
        'use strict';
        return {
            request: addToken,
            response: updateToken
        };

        function updateToken(response) {

            // see if the token is json data
            try {
                if (response.data && response.data.token) {
                    AuthTokenFactory.setToken(response.data.token);
                }

                if (response.data.alert) {
                    console.log("updateToken() got an alert for response: ", response);
                    if (response.data.alert === 1) {
                        console.log("Alert: " + response.data.message);
                    }

                    if (response.data.alert === 10) {
                        var globals = $injector.get('globals');
                        if (globals.modalUP && globals.modalType === 10 ) {
                            console.log("Duplicate Dialog. Aborting display");
                        } else {
                            globals.modalUP = true;
                            globals.modalType = 10;
                            var dialogs = $injector.get('dialogs');
                            var x = dialogs.notify("Session Expired", "Your session has expired. Redirecting to login page", "Okay");
                            AuthTokenFactory.setToken();    // log the user out
                            x.result.then(function () {
                                globals.modalUP = false;
                                AuthTokenFactory.logout();
                            });
                        }
                    }
                }

            } catch (err) {
                // normal to get here if token not set
                // console.log("AuthInterceptor::updateToken: error");
            }
            return response;
        }

        function addToken(config) {
            var token = AuthTokenFactory.getToken();
            if (token) {
                config.headers = config.headers || {};
                config.headers.Authorization = 'Bearer ' + token;
                console.log("Adding token to outgoing request: ", config);
            }
            else
                console.log("NOT adding token to outgoing request!", config);
            return config;
        }
    });

    app.config( function($httpProvider ) {
        $httpProvider.interceptors.push('AuthInterceptor');
    });

    app.factory('QMSFactory', function QMSFactory($http, API_URL) {
        'use strict';
        return {
            tokenTest: tokenTest,
            testPOST: testPOST,
            search: search,
            saveCustomer: saveCustomer,
            loadCustomer: loadCustomer,
            loadCustomersWithInstallEvents: loadCustomersWithInstallEvents,
            loadSupportForCustomer: loadSupportForCustomer,
            // loadEventsForIncident: loadEventsForIncident,
            saveSupport: saveSupport,
            loadSupport: loadSupport,
            deleteSupport: deleteSupport,
            getSupportByIDs: getSupportByIDs,
            saveSales: saveSales,
            loadSales: loadSales,
            saveUser: saveUser,
            savePrefs: savePrefs,
            saveUserPassword: saveUserPassword,
            unlockUser: unlockUser,
            loadDevMeeting: loadDevMeeting,
            saveDevMeeting: saveDevMeeting,
            loadUsers: loadUsers,
            loadOneUser: loadOneUser,
            login: login,
            getUsersForGroup: getUsersForGroup,
            customerBySupportEnds: customerBySupportEnds,
            supportReport: supportReport,
            customerByReseller: customerByReseller,
            saveReminder: saveReminder,
            loadReminderForCustomer: loadReminderForCustomer,
            removeReminder: removeReminder,
            loadReminderByDue: loadReminderByDue,
            queryForReminders: queryForReminders,
            loadCurrentLicense: loadCurrentLicense,
            searchCustomer: searchCustomer,
            newLicenseSet: newLicenseSet,
            requestLicense: requestLicense,
            replaceLicense: replaceLicense,
            loadLicenseHistory: loadLicenseHistory,
            reconcileLicenseEvent: reconcileLicenseEvent,
            loadOldLicenses: loadOldLicenses,
            hasOldLicenses: hasOldLicenses,
            importOldLicenses: importOldLicenses,
            licenseReconciledReport: licenseReconciledReport,
            loadVendorByPlatform: loadVendorByPlatform,
            saveVendor: saveVendor,
            queryForVendorRequests: queryForVendorRequests,
            loadVendorRequest: loadVendorRequest,
            declineVendorRequest: declineVendorRequest,
            ignoreVendorRequest: ignoreVendorRequest,
            reconsiderVendorRequest: reconsiderVendorRequest,
            resetVendorLockout: resetVendorLockout,
            requestCoupon: requestCoupon,
            validateSystemId: validateSystemId,
            validateLicenseKey: validateLicenseKey,
            googleCloudListBuckets: googleCloudListBuckets,
            googleCloudListBucketObjects: googleCloudListBucketObjects,
            googleCloudDeleteObjects: googleCloudDeleteObjects,
            loadReleases: loadReleases,
            saveReleases: saveReleases,
            createSurveyTicket: createSurveyTicket,
            getSurveyEngagementStats: getSurveyEngagementStats,
            addComplaint: addComplaint
        };

        function tokenTest(id) {
            return $http.get(API_URL + '/tokentest');
        }

        function testPOST(id) {
            var record = {
                username: "Vern",
                password: "was here"
            };
            var xsrf = $.param({data: JSON.stringify(record)});
            return $http.post(API_URL + '/testpost', xsrf, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}});
        }

        function login(username,password ){
            var record = {
                username: username,
                password: password
            };
            var xsrf = $.param({data: JSON.stringify(record)});

            return $http.post(API_URL + '/login', xsrf, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}});
        }

        function search(query) {
            var url_query =  encodeURIComponent(query.trim()); // just making sure
            return $http.get(API_URL + '/search/' + url_query);
        }

        function saveCustomer(record) {
            var xsrf = $.param({data: JSON.stringify(record)});
            return $http.post(API_URL + '/customer', xsrf, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}});
        }

        function loadCustomer(id) {
            return $http.get(API_URL + '/customer/' + encodeURIComponent(String(id).trim()));
        }

            // Returns all customers that have 1 or more training events
        function loadCustomersWithInstallEvents() {
            return $http.get(API_URL + '/customers/installevents');
        }

        function loadUsers() {
            return $http.get(API_URL + '/users' );
        }

        function loadOneUser(id) {
            return $http.get(API_URL + '/user/one/' + encodeURIComponent(String(id).trim()));
        }

        function listFiles(folder) {
            var xsrf = $.param({folder: JSON.stringify(folder)});
            return $http.post(API_URL + '/files/list', xsrf, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}});
        }

            // Not used: the API endpoint is called directly from the HTML using the href/target property of the link.
            // We have this here just so we can find this note when searching for 'file'
        function getFile(type, id, filename) {
               // API_URL + "/file/" + type + "/" + id + "/" + filename)
        }

        function saveUserPassword(id, current,  newpass) {
            var record = {
                current: current,
                newpass: newpass
            };
            var xsrf = $.param({data: JSON.stringify(record)});
            console.log(record);

            return $http.post(API_URL + '/user/pass/' + encodeURIComponent(String(id).trim()), xsrf, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}});
        }

        function unlockUser(id) {
            return $http.get(API_URL + '/user/resetlockout/' + encodeURIComponent(String(id).trim()));
        }

        function savePrefs(record) {
            var xsrf = $.param({data: JSON.stringify(record)});
            return $http.post(API_URL + '/user/me/prefs', xsrf, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}});
        }

        function saveUser(record) {
            var xsrf = $.param({data: JSON.stringify(record)});
            return $http.post(API_URL + '/user', xsrf, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}});
        }

        function savePass(record) {
            var xsrf = $.param({data: JSON.stringify(record)});
            return $http.post(API_URL + '/user', xsrf, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}});
        }

        function getUsersForGroup(group) {
            return $http.get(API_URL + '/user/group/' + encodeURIComponent(String(group).trim()));
        }

        function loadSupportForCustomer(id) {
            return $http.get(API_URL + '/customer/support/' + encodeURIComponent(String(id).trim()));
        }

        // function loadEventsForIncident(id) {
        //     return $http.get(API_URL + '/support/events/' + id);
        // }

        function saveSupport(record) {
            var xsrf = $.param({data: JSON.stringify(record)});
            return $http.post(API_URL + '/support', xsrf, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}});
        }

        function loadSupport(id) {
            return $http.get(API_URL + '/support/' + encodeURIComponent(String(id).trim()));
        }

        function deleteSupport(id) {
            return $http.delete(API_URL + '/support/' + encodeURIComponent(String(id).trim()));
        }

            // Pass in an array of IDs (the person-friendly 'id' not the mongoDB _id) to load
            // We use POST for this because 
        function getSupportByIDs( idArray ) {
            var xsrf = $.param({data: idArray});
            return $http.post(API_URL + '/support/getbyids', xsrf, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}});
        }

        function saveDevMeeting(record) {
            var xsrf = $.param({data: JSON.stringify(record)});
            return $http.post(API_URL + '/devmeeting', xsrf, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}});
        }

        function loadDevMeeting(id) {
            return $http.get(API_URL + '/devmeeting/' + encodeURIComponent(String(id).trim()));
        }

        function saveSales(record) {
            var xsrf = $.param({data: JSON.stringify(record)});
            return $http.post(API_URL + '/sales', xsrf, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}});
        }

        function loadSales(id) {
            return $http.get(API_URL + '/sales/' + encodeURIComponent(String(id).trim()));
        }

        function customerBySupportEnds(query) {
            return $http.get(API_URL + '/customer/bysupportends/' + encodeURIComponent(String(query).trim()));
        }

        function supportReport(groupBy, timeGroup, dateRangeFrom, dateRangeTo) {
            return $http.get(API_URL + '/supportreport/' + 
                encodeURIComponent(String(groupBy).trim()) + "/" + encodeURIComponent(String(timeGroup).trim()) + "/" +
                encodeURIComponent(String(dateRangeFrom).trim()) + "/" + encodeURIComponent(String(dateRangeTo).trim()));
        }

        function customerByReseller(query) {
            return $http.get(API_URL + '/customer/byreseller/' + encodeURIComponent(String(query).trim()));
        }

        function saveReminder(record) {
            var xsrf = $.param({ data: JSON.stringify(record) });
            return $http.post(API_URL + '/reminder', xsrf,
                { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
        }

        function loadReminderForCustomer(id) {
            return $http.get(API_URL + '/reminder/customer/' + encodeURIComponent(String(id).trim()));
        }

        function removeReminder(id) {
            var xsrf = $.param({data: {id: id}});
            return $http.post(API_URL + '/reminder/remove', xsrf,
                { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
        }

        function loadReminderByDue(query) {
            return $http.get(API_URL + '/reminder/due/' + encodeURIComponent(String(query).trim()));
        }

        function queryForReminders(query) {
            var xsrf = $.param({ data: JSON.stringify(query) });
            return $http.post(API_URL + '/reminder/query', xsrf,
                { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
        }

        function loadCurrentLicense(systemId, subId, withCustomer) {
            if (subId) systemId += '#' + subId;
            return $http.get(API_URL + (withCustomer ? '/license/system/customer/' : '/license/system/') + encodeURIComponent(String(systemId).trim()));
        }

        function searchCustomer(query) {
            return $http.get(API_URL + '/search/customer/' + encodeURIComponent(String(query).trim()));
        }

        function newLicenseSet(systemId) {
            var xsrf = $.param({ data: JSON.stringify({ systemId: systemId }) });
            return $http.post(API_URL + '/license/system/new', xsrf,
                { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
        }

        function requestLicense(request) {
            var xsrf = $.param({ data: JSON.stringify(request) });
            return $http.post(API_URL + '/license/generate', xsrf,
                { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
        }

        function replaceLicense(request) {
            var xsrf = $.param({ data: JSON.stringify(request) });
            return $http.post(API_URL + '/license/replace', xsrf,
                { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
        }

        function loadLicenseHistory(systemId, subId) {
            if (subId) systemId += '#' + subId;
            return $http.get(API_URL + '/license/history/' + encodeURIComponent(String(systemId).trim()));
        }

        function reconcileLicenseEvent(request) {
            var xsrf = $.param({ data: JSON.stringify(request) });
            return $http.post(API_URL + '/license/reconcile', xsrf,
                { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
        }

        function loadOldLicenses(systemId) {
            return $http.get(API_URL + '/license/old/' + encodeURIComponent(String(systemId).trim()));
        }

        function hasOldLicenses(systemId) {
            return $http.get(API_URL + '/license/old/check/' + encodeURIComponent(String(systemId).trim()));
        }

        function importOldLicenses(systemId, subId, events, reference, reconciled, note) {
            var xsrf = $.param({ data: JSON.stringify({
                systemId: systemId, subId: subId, events: events, reference: reference, reconciled: reconciled, note: note
            }) });
            return $http.post(API_URL + '/license/old/import', xsrf,
                { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
        }

        function licenseReconciledReport(reconciled, fromDate, toDate, vendors, types) {
            var data = {};
            if (reconciled !== undefined && reconciled !== null) data.reconciled = reconciled ? true : false;
            if (fromDate) data.fromDate = fromDate;
            if (toDate) data.toDate = toDate;
            if (vendors && vendors.length > 0) data.vendors = vendors;
            if (types && types.length > 0) data.types = types;
            var xsrf = $.param({ data: JSON.stringify(data) });
            return $http.post(API_URL + '/license/report/reconciled', xsrf,
                { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
        }

        function loadVendorByPlatform(platformId) {
            return $http.get(API_URL + '/vendor/platform/' + encodeURIComponent(String(platformId).trim()));
        }

        function saveVendor(record) {
            var xsrf = $.param({data: JSON.stringify(record)});
            return $http.post(API_URL + '/vendor', xsrf, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}});
        }

        function queryForVendorRequests(status, vendors, fromDate, toDate) {
            var data = {};
            if (status && status.toLowerCase() !== 'any') data.status = status;
            if (vendors && vendors.length > 0) data.vendors = vendors;
            if (fromDate) data.fromDate = fromDate;
            if (toDate) data.toDate = toDate;
            var xsrf = $.param({ data: JSON.stringify(data) });
            return $http.post(API_URL + '/vendor/request/query', xsrf,
                { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
        }

        function loadVendorRequest(id) {
            return $http.get(API_URL + '/vendor/request/' + encodeURIComponent(String(id).trim()));
        }

        function declineVendorRequest(id) {
            return $http.get(API_URL + '/vendor/request/decline/' + encodeURIComponent(String(id).trim()));
        }

        function ignoreVendorRequest(id) {
            return $http.get(API_URL + '/vendor/request/ignore/' + encodeURIComponent(String(id).trim()));
        }

        function reconsiderVendorRequest(id) {
            return $http.get(API_URL + '/vendor/request/reconsider/' + encodeURIComponent(String(id).trim()));
        }

        function resetVendorLockout(id, email) {
            var data = {vendorId: id, email: email};
            var xsrf = $.param({data: JSON.stringify(data)});
            return $http.post(API_URL + '/vendor/unlock', xsrf, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}});
        }

        function requestCoupon(request) {
            var xsrf = $.param({ data: JSON.stringify(request) });
            return $http.post(API_URL + '/license/coupon/generate', xsrf, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
        }

        function validateSystemId(id) {
            return $http.get(API_URL + '/license/validate/system/' + encodeURIComponent(String(id).trim()));
        }

        function validateLicenseKey(key) {
            return $http.get(API_URL + '/license/validate/license/' + encodeURIComponent(String(key).trim()));
        }

            // Not used. Was used initially for testing purposes.
        function googleCloudListBuckets() {
            return $http.get(API_URL + '/buckets/' );
        }

        function googleCloudListBucketObjects(bucketName) {
            return $http.get(API_URL + '/bucketobjects/' + encodeURIComponent(String(bucketName).trim()));
        }

        function googleCloudDeleteObjects(bucketName, objectsToDelete) {
            let record = {
                bucketName: bucketName,
                objectsToDelete: objectsToDelete
            };
            var xsrf = $.param( { data: JSON.stringify(record)} );

                // Turns out that deleting multiple items via POST instead of DELETE is actually the correct way to go, even if attempting RESTful. lol.
                // https://stackoverflow.com/questions/2421595/restful-way-for-deleting-a-bunch-of-items
                // See Luka's answer on how Amazon S3 does it (via POST with a body)
            return $http.post(API_URL + '/bucketobjects/delete', xsrf, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}});
        }
        
        function saveReleases(record) {
            var xsrf = $.param({data: JSON.stringify(record)});
            return $http.post(API_URL + '/releases', xsrf, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}});
        }

        function loadReleases(id) {
            return $http.get(API_URL + '/releases');
        }

        function createSurveyTicket(email, fullname) {
            let url = API_URL + '/survey/createticket';
            if (email) {
                url += '/' + encodeURIComponent(String(email).trim());
                if (fullname) {
                    url += '/' + encodeURIComponent(String(fullname).trim());
                }
            }
            return $http.get(url);
        }

        function getSurveyEngagementStats() {
            let url = API_URL + '/survey/engagement';
            return $http.get(url);
        }

        function addComplaint(record) {
            var xsrf = $.param({data: JSON.stringify(record)});
            return $http.post(API_URL + '/complaint/new', xsrf, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}});
        }
    });

})();
