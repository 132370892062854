(function() {
    'use strict';

    /**

     */
    angular.module('qms')
        .directive('ngBindCustomerid', ngBindCustomerid)
        .directive('customerSelect', customerSelect);

    function ngBindCustomerid($compile) {
        return{
            link:function(scope,element,attr){
                element[0].removeAttribute('ng-bind-customerid');
                element[0].setAttribute('customerid',attr.ngBindCustomerid);
                //console.log("binding to: " + attr.ngBindSelections);
                $compile(element[0])(scope);
            }
        };
    }

    function customerSelect() {
        return {
            restrict: 'C',
            replace: true,
            transclude: true,
            scope: {
                customerid:'=',
                changed: '&'           // Output
            },
            templateUrl: '/ng1/templates/cs_customerselect.html',
            controller: ['$scope', 'QMSFactory','dialogs', customerSelectController]
        };
    }

    function customerSelectController($scope,QMSFactory,dialogs) {
        console.log("customerSelectController");
        $scope.init = init;
        $scope.reassign = reassign;
        $scope.reload = reload;
        $scope.record = {name:"loading..."};

        function init() {
            $scope.$watch('customerid', $scope.reload, true);
        }

        function reload() {
            if (typeof($scope.customerid)!== 'undefined') {
                QMSFactory.loadCustomer($scope.customerid).success(function (result) {
                    $scope.record = result.data;
                    console.log(result);
                });
            }
        }

        function reassign() {
            var dlg = dialogs.create('/ng1/dialogs/customernew/customernew.html', 'NewCustomerController as vm', {} );
            console.log("new customer");
            dlg.result.then(function (data) {
                if (typeof data === 'undefined') {
                    console.log("User Canceled");
                } else {
                    $scope.customerid = data.id;
                    $scope.changed( {value: data.id} );
                }
            });
        }

        $scope.init();
    }

})();
