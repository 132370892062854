angular.module('qms').controller('ReminderEditCtrl', function($scope, QMSFactory, $uibModalInstance, qmsVocabulary, data) {

    $scope.record = {};
    $scope.source = 'Unknown';
    $scope.sourceName = 'Unknown';
    $scope.customer = {};
    $scope.dueDateOpened = false;
    $scope.reminderCategories = [];
    $scope.reminderStatusOptions = qmsVocabulary.reminderStatusOptions;
    $scope.users = [];

    $scope.init = function() {
        var due;
        // assuming a shallow copy is enough to protect the original from modification by the form
        Object.assign($scope.record, data);
        $scope.dueTime = new Date(1970, 0, 1, 0, 0, 0); // due time defaults to 00:00:00
        // get actual due time, if in use, from the time part of the due date
        if ($scope.record.due && $scope.record.hasDueTime) {
            due = $scope.record.due instanceof Date ? $scope.record.due : new Date($scope.record.due);
            $scope.dueTime.setHours(due.getHours(), due.getMinutes(), due.getSeconds());
        }
        // source is customer?
        var matches = /^customer\/(C\d+)$/i.exec(data.source);
        if (matches) {
            $scope.source = 'Customer';
            $scope.reminderCategories = qmsVocabulary.reminderCategoriesForGroup('customer+');
            $scope.loadCustomer(matches[1]);
        }
        // populate $scope.users
        QMSFactory.getUsersForGroup('*').success(function(result) {
            $scope.users = [];
            for (var i = 0; i < result.data.length; i++) {
                $scope.users.push({ $id: (result.data[i]._id.$oid || result.data[i]._id.$id), name: result.data[i].fullname });
            }
        });
    };

    $scope.loadCustomer = function(id) {
        QMSFactory.loadCustomer(id).success(function(result) {
            $scope.sourceName = result.data.name;
        });
    };

    $scope.save = function() {
        // copy the time part of due time, if selected, to the due date
        var due = $scope.record.due instanceof Date ? $scope.record.due : new Date($scope.record.due), dueTime = $scope.dueTime;
        if ($scope.record.hasDueTime) {
            due.setHours(dueTime.getHours(), dueTime.getMinutes(), dueTime.getSeconds());
            if (!($scope.record.due instanceof Date)) {
                $scope.record.due = due.toISOString().replace(/\.[0-9]{3}/, ''); // remove milliseconds
            }
        }
        QMSFactory.saveReminder($scope.record).success(function(data) {
            console.log("reminder saved: " + data.data.id);
            $uibModalInstance.close(data.data);
        });
    };

    $scope.cancel = function() {
        $uibModalInstance.dismiss('canceled');
    };

    $scope.openDueDate = function($event) {
        $event.preventDefault();
        $event.stopPropagation();
        $scope.dueDateOpened = true;
    };

    $scope.init();
});
