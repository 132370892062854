
(function() {
	'use strict';
	angular
		.module('qms')
		.controller('AimDocDownloadReportController', AimDocDownloadReportController);

	function AimDocDownloadReportController( $scope, $modalInstance, $document, data )
	{
		$scope.suite = data.suite;
        $scope.name = data.name;
        $scope.hasValidDiff = data.hasValidDiff;

		$scope.init = function()
        {
            $scope.addKeyListeners();

            $modalInstance.rendered.then(function()
            {
                let element = document.getElementById("srsReport");
                element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data.srsData));
                element.setAttribute('download', data.srsFilename);

                element = document.getElementById("vtpReport");
                element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data.vtpData));
                element.setAttribute('download', data.vtpFilename);

                element = document.getElementById("testResults");
                element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data.testResultsData));
                element.setAttribute('download', data.testResultsFilename);

                element = document.getElementById("traceReport");
                element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data.traceData));
                element.setAttribute('download', data.traceFilename);
                
                    // Diffs:
                element = document.getElementById("srsReportDiff");
                element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data.srsDiffData));
                element.setAttribute('download', data.srsDiffFilename);
            
                element = document.getElementById("vtpReportDiff");
                element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data.vtpDiffData));
                element.setAttribute('download', data.vtpDiffFilename);

                element = document.getElementById("traceReportDiff");
                element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data.traceDiffData));
                element.setAttribute('download', data.traceDiffFilename);
            });
		};

        $scope.addKeyListeners = function()
        {
                // Return key should dismiss dialog
			$modalInstance.rendered.then(function()
            {
                $document.bind('keydown', function(event)
                    { 
                        if (event.keyCode === 27)	// 27 = Escape key
                            $scope.close();
                        else if (event.keyCode === 13) // return
                            $scope.close();
                    }
                );
            });
        };
		
		$scope.close = function()
        {
            $document.unbind('keydown');
			$modalInstance.close();
        };
				
		$scope.init();
	}

})();
